import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Emojis from "../../../assets/images/emojis";
import EmojiSelection from "../components/emojiSelection";

function PlayerEmojiChoice({ classRoom, student }) {
  const [showEmojiSelection, setShowEmojiSelection] = useState(false),
    [showEmojiButton, setShowEmojiButton] = useState(!classRoom.oneLocation),
    handleHideEmojis = () => {
      setShowEmojiButton(false);
      setShowEmojiSelection(false);
      setTimeout(() => setShowEmojiButton(true), 5000);
    };

  return (
    <AnimatePresence>
      {student.emojiCharacter && showEmojiButton && (
        <motion.div
          initial={{ x: -200 }}
          animate={{ x: 0 }}
          exit={{ x: -200 }}
          onMouseLeave={() => setShowEmojiSelection(false)}
          className="emoji-button-selection absolute z-10 top-0 left-0 rounded-br-lg"
        >
          <AnimatePresence>
            {!showEmojiSelection && (
              <motion.div
                initial={{ x: -100 }}
                animate={{ x: 0 }}
                exit={{ x: -100 }}
                onMouseEnter={() => setShowEmojiSelection(true)}
                onClick={() => setShowEmojiSelection(true)}
                className="w-20 p-2 absolute z-10 top-0 left-0 bg-navy-600 rounded-br-lg"
              >
                {Emojis[student.emojiCharacter][student.emojiEmotion]}
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {showEmojiSelection && (
              <motion.div
                onClick={handleHideEmojis}
                className="bg-navy-700 rounded-br-lg w-32 z-30 py-2"
                initial={{ x: -200 }}
                animate={{ x: 0, transition: { ease: "easeOut" } }}
                exit={{ x: -200, transition: { ease: "easeIn" } }}
              >
                <EmojiSelection />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default PlayerEmojiChoice;
