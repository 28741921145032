import { useState, useEffect } from 'react';

export default ( socket ) => {
  const
    [activity, setActivity] = useState({
      lastUpdated:0,
      type:'joining',
      starttime:0,
      endtime:0,
      nextActivity:'',
      data:{},
      duration:0
    }),

    set = (attribute, newState) => {
      setActivity(prev => ({ ...prev, [attribute]:newState } ));
      if(socket)
        socket.emit('update activity', ({ ...activity, [attribute]:newState, attribute }));
    };

  useEffect(() => {
    if(socket) {
      socket.on('activity changes', updatedActivity => {
        if (updatedActivity.lastUpdated > activity.lastUpdated ) {
          setActivity(prev => ({...prev, ...updatedActivity}));
        }
      });
    }
  }, [socket]);

  return { ...activity, set };
};
