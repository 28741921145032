import {AnimatePresence, motion} from "framer-motion";
import React, { useState } from "react";
import MultipleChoiceDetails from "./multipleChoice";
import { secondsToHms, hmsToSeconds } from '../utils';

function InteractionDetails({ warningKey, id, type, time, duration, data, expanded, onExpand, forceRefresh, updateInteraction, removeInteraction, setPlaying }) {
    const
        [tempTime, setTempTime] = useState(secondsToHms(time)),
        interactionType = type.replace('-', ' '),
        interactionTypes = ['multiple choice'],
        handleTimeChange = event => {
            let newTime = event.target.value;
            updateInteraction({
                key:id,
                time:newTime
            });
            setTempTime(newTime);
        },
        handleUpdateDuration = event => {
            let durationTime = event.target.value;
            updateInteraction({
                key: id,
                duration: durationTime
            });
        }

    return (
        <motion.div
            initial={false}
            id={`interaction-${id}`}
            animate={{ backgroundColor: expanded ? '#FEEEEB' : '#e9e9e9' }}
            className="overflow-hidden rounded-lg my-4">
            <div className="px-2 py-3 sm:p-4">
                <div
                    className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 text-sm flex items-center justify-center h-10 w-10 sm:mr-2 mt-1 rounded-full bg-navy-600 text-white sm:mx-0 ">
                        {tempTime}
                    </div>
                    <div className="mt-3 sm:mt-1 sm:ml-4 sm:text-left sm:flex-1 px-20 sm:px-0">
                        <h5 className="font-bold text-navy-800">
                            {data.question                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                        </h5>
                        <h6 className="text-sm text-navy-600 italic capitalize">
                            {interactionType}
                        </h6>
                    </div>
                    <AnimatePresence initial={false}>
                        {!expanded &&
                        <motion.a
                            onClick={onExpand}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="sm:flex-none font-extrabold text-coral-600 inline-flex leading-none py-4 cursor-pointer">

                            {warningKey === id &&
                                <svg className="h-5 w-5 text-coral-600 -mt-0.5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                     fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                          clipRule="evenodd"/>
                                </svg>
                            }
                            <span>Edit Interaction</span>
                            <svg className="ml-2 h-5 w-5 text-coral-600 group-hover:text-coral-800"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                        </motion.a>
                        }
                    </AnimatePresence>
                </div>
            </div>

            <AnimatePresence initial={false}>
                {expanded &&
                <motion.div
                    className="px-20 py-6"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 }
                    }}>


                    {interactionTypes[1] &&
                        <div className="w-full mb-5">
                            <label htmlFor="type" className="block mb-1 font-medium text-navy-700">
                                Interaction Type
                            </label>
                            <div className="mt-1">
                                <select name="type"
                                        className="shadow-sm py-2 px-3 focus:ring-indigo-500 text-navy-900 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                    <option>Multiple Choice</option>
                                </select>
                            </div>
                        </div>
                    }

                    <MultipleChoiceDetails {...{forceRefresh, updateInteraction, data, id }} />


                    <div className="my-7">
                        <div className="md:w-2/3">
                            <label
                                htmlFor="time"
                                className="block mt-3 font-medium text-navy-700"
                            >
                                Time in Video
                                <span className="text-xs float-right">(hh:mm:ss)</span>
                            </label>
                            <input
                                type="text"
                                name="time"
                                className="w-full block border border-gray-300 rounded-md inset-shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                onChange={handleTimeChange}
                                value={tempTime}
                            />
                        </div>

                        <div className="md:w-2/3">
                            <label
                                htmlFor="duration"
                                className="block mt-5 font-medium text-navy-700"
                            >
                                Pause Video For This Interaction
                                <span className="text-xs float-right">(in seconds)</span>
                            </label>
                            <input
                                type="text"
                                name="duration"
                                className="w-full block border border-gray-300 rounded-md inset-shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                value={duration}
                                onChange={handleUpdateDuration}
                            />
                        </div>
                    </div>

                    <div className="flex justify-end -mr-6">
                        <button
                            onClick={()=>removeInteraction(id)}
                            type="button"
                            className="py-2 px-4 border border-navy-400 rounded-md text-sm font-medium text-navy-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Delete Interaction
                        </button>

                        <button
                            onClick={() => setPlaying(true)}
                            type="button"
                            className="ml-3 py-2 px-4 border border-transparent rounded-md bg-coral-600 text-sm font-medium text-white focus:outline-none"
                        >
                            Save & Play
                        </button>
                    </div>

                </motion.div>
                }
            </AnimatePresence>

        </motion.div>
    )
}

export default InteractionDetails