import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import { useInView } from "react-intersection-observer";
import Images from "../../../assets/images";
import BgParticles from "../../components/bgParticles";

function Hero() {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <main className="w-full bg-gray-100 overflow-hidden pt-4 lg:pt-10 sloppy-bottom">
      <BgParticles />
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="relative max-w-6xl mx-auto lg:py-32 pt-24 pb-8 px-8"
      >
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div
            variants={variants.fromLeft}
            className="md:mx-auto col-span-9"
          >
            <h1 className="lg:mt-2 text-4xl lg:text-5xl lg:text-6xl font-semibold text-navy-700 leading-tight mb-4">
              FilmDoo Academy <i>for</i>{" "}
              <span className="text-teal-600">
                Teachers & Heads of Department
              </span>
            </h1>
          </motion.div>

          <motion.div
            variants={variants.fromLeft2}
            className=" md:max-w-4xl md:mx-auto lg:col-span-6 text-left"
          >
            <p className="text-navy-600 mt-3 text-base mx-auto w-full font-bold">
              Welcome to the future of learning! The age of film- and
              video-based learning has arrived.
            </p>
            <p className="text-navy-600 mt-3 text-base mx-auto w-full">
              The average Gen Z student now spends at least four hours a day
              watching mobile videos. While traditional teaching methods
              struggle to keep students engaged, the modern students now respond
              to film- and video-based learning - which is at the heart of what
              we do at FilmDoo!
            </p>
            <p className="text-navy-600 mt-3 text-base mx-auto w-full">
              FilmDoo Academy is an edtech tool that empowers teachers to easily
              turn any film and video into an interactive lesson. The tool can
              be used with students of all ages and learning levels as well as
              for any subject using a film or video that you have selected. Not
              only that, teachers are able to check for understanding of
              knowledge retention and “recall” by adding quizzes, questions and
              puzzles to the video.
            </p>
            <p className="text-teal-600 italic mt-3 text-base mx-auto w-full font-extrabold">
              This innovative new tool saves teachers time by allowing them to
              create a fun and engaging game-based lesson in minutes!
            </p>
            <p className="text-navy-600 mt-3 text-base mx-auto w-full">
              The same lesson can be played as a group in classrooms or as part
              of online classes, as well as used for homework and independent
              revisions by the students at their own pace!
            </p>
          </motion.div>

          <motion.div
            variants={variants.fromRight}
            className="mt-4 lg:mt-1 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 flex flex-col lg:col-span-5"
          >
            <div className="relative mx-auto w-full lg:max-w-lg mb-6 lg:mb-12 mt-8 lg:mt-0">
              {Images.students.h}
            </div>
          </motion.div>
        </div>

        <div className="lg:grid lg:grid-cols-12 lg:gap-8 lg:mt-20">
          <motion.div
            variants={variants.fromLeft}
            className="hidden lg:inline-block mt-4 relative sm:max-w-lg sm:mx-auto lg:max-w-none lg:mx-0 flex flex-col lg:col-span-5"
          >
            <div className="relative mx-auto w-full lg:max-w-lg mb-6 lg:mb-12 mt-0">
              {Images.students.a}
            </div>
          </motion.div>

          <motion.div
            variants={variants.fromRight}
            className="md:max-w-4xl md:mx-auto lg:col-span-7 text-left"
          >
            <p className="text-navy-600 mt-3 text-base mx-auto w-full font-bold">
              Stay ahead of the pandemic and kick off your 2021 classes with
              exciting film- and video-based learning games for your students!
              Students stay engaged and focused, especially online, while having
              a fun, positive learning experience at the same time - helping
              students to stay motivated and continuing to make great learning
              progress!
            </p>
            <p className="text-navy-600 mt-3 text-base mx-auto w-full">
              FilmDoo Academy is making our edtech tool free during this period
              of the lockdown so that teachers like yourself can go ahead and
              use this with your students straightaway!
            </p>

            <p className="text-pink-600 uppercase mt-10 text-center text-lg text-base mx-auto w-full font-bold">
              Ignite your students’ THIRST FOR LEARNING!
            </p>
          </motion.div>
        </div>
      </motion.div>
    </main>
  );
}

export default Hero;
