import React, { useState } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import WebinarCta from "./webinarCta";
import Hero from "./hero";
import HomePopUp from "../home/homePopUp";
import Benefits from "./benefits";
import BgParticles from "../../components/bgParticles";

function Membership() {
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false),
    [masterShowMembership, setMasterShowMembership] = useState(false);

  return (
    <>
      <Menu
        {...{
          masterShowCreateAccount,
          setMasterShowCreateAccount,
          masterShowMembership,
          setMasterShowMembership,
        }}
      />
      <HomePopUp {...{ setMasterShowMembership }} />
      <Hero {...{ setMasterShowMembership }} />
      <Benefits />

      <div className="bg-gray-100 sloppy-top relative overflow-hidden">
        <BgParticles />
        <div className="max-w-6xl mx-auto text-center py-12 sm:px-6 lg:py-16 px-8">
          <h2 className="text-4xl font-bold tracking-tight text-navy-700 sm:text-5xl pt-6">
            <span className="block md:w-3/4 mx-auto">
              Don't Miss Out On Our{" "}
              <span className="font-extrabold text-teal-600">
                Free Membership
              </span>{" "}
              Programme!
            </span>
          </h2>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md fd-shadow">
              <button
                onClick={setMasterShowMembership}
                className="transition duration-500 ease-in-out hover:bg-teal-700 hover:fd-shadow transform focus:outline-none inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 focus:outline-none"
              >
                Sign Up Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <WebinarCta />
      <Footer />
    </>
  );
}

export default Membership;
