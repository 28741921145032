import React from "react";
import { motion } from "framer-motion";
import Option from "../../../components/common/option";

function Options({ options, setOptions, disabledOptions, answerCount }) {
  const handleOptionClick = (clicked) => {
      if (disabledOptions) return;
      setOptions((prev) =>
        prev.map((option, key) => {
          if (key === clicked && !option.feedback)
            option.selected = !option.selected;
          else if (answerCount === 1) option.selected = false;
          return option;
        })
      );
    },
    variants = {
      background: {
        visible: {
          transition: {
            staggerChildren: 0.2,
            delayChildren: 2.2,
          },
        },
        hidden: {},
      },
      option: {
        visible: { y: 0, opacity: 1 },
        hidden: { y: -50, opacity: 0 },
      },
    };

  return (
    <motion.div
      variants={variants.background}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="w-full max-w-2xl mx-auto sm:px-2"
    >
      <fieldset>
        <legend id="radiogroup-label" className="sr-only">
          Answers
        </legend>
        <ul role="radiogroup" aria-labelledby="radiogroup-label">
          {options.map((option, index) => (
            <div className="overflow-hidden py-2">
              <motion.div variants={variants.option} key={index}>
                <Option {...{ option, index, handleOptionClick }} />
              </motion.div>
            </div>
          ))}
        </ul>
      </fieldset>
    </motion.div>
  );
}

export default Options;
