import React, { useState } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import Images from "../../../assets/images";
import { Link } from "react-router-dom";

function GuideFindingFilms() {
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false);

  return (
    <div className="bg-gray-100">
      <Menu
        {...{
          masterShowCreateAccount,
          setMasterShowCreateAccount,
        }}
      />
      <div className="flex-grow container px-4 md:px-0 max-w-6xl mx-auto pt-32">
        <div className="sm:w-3/4 mx-auto relative bg-white rounded-lg flex flex-wrap rounded z-2 fd-shadow-lg overflow-hidden pb-4 mb-8">
          <div className="px-12 py-6 text-sm  tracking-wide">
            <h1 className="text-3xl lg:text-4xl font-semibold text-navy-700 leading-tight">
              4 Simple Steps To{" "}
              <span className="text-teal-600">
                Creating Your First Interactive, Video-Based Lesson
              </span>
            </h1>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              So, you’ve subscribed to FilmDoo Academy’s{" "}
              <Link to={{ pathname: "/pricing" }} className="underline">
                Teacher Pro
              </Link>{" "}
              service (or, if you haven’t yet, then sign up for your free trial{" "}
              <span
                className="underline cursor-pointer"
                onClick={() => setMasterShowCreateAccount(true)}
              >
                here!
              </span>
              ), giving you access to our exclusive film- and video-based course
              authoring tool. But, you may be wondering, what can you do now,
              and how can you make full use of the new features at you disposal?
            </p>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              If you need a little help getting started, then this is the page
              for you. Below we present 4 simple steps for creating your first
              interactive FilmDoo Academy lesson:
            </p>

            <h2 className="text-xl lg:text-2xl font-bold text-navy-700 leading-tight mt-6">
              Step 1
            </h2>

            <p className="text-navy-600 lg:mt-5 text-base mx-auto w-full pr-6 font-bold">
              Enter a URL for a film or video (accepted sources include Youtube
              and Vimeo) that you would like to turn into an interactive lesson.
            </p>

            {Images.blog.step1}

            <h2 className="text-xl lg:text-2xl font-bold text-navy-700 leading-tight">
              Step 2
            </h2>

            <p className="text-navy-600 lg:mt-5 text-base mx-auto w-full pr-6 font-bold">
              Cut and crop the video to the desired section needed for your
              lesson.
            </p>

            {Images.blog.step2}

            <h2 className="text-xl lg:text-2xl font-bold text-navy-700 leading-tight">
              Step 3
            </h2>

            <p className="text-navy-600 lg:mt-5 text-base mx-auto w-full pr-6 font-bold">
              Add your very own interactive, multiple choice questions at your
              chosen points in the video.
            </p>

            {Images.blog.step3}

            <h2 className="text-xl lg:text-2xl font-bold text-navy-700 leading-tight">
              Step 4
            </h2>

            <p className="text-navy-600 lg:mt-5 text-base mx-auto w-full pr-6 font-bold">
              Add the language, language level and hashtags (i.e. subjects and
              themes) - then save your lesson.
            </p>

            {Images.blog.step4}

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6 font-bold">
              And that’s it! Start using your new interactive lesson with your
              students straight away!
            </p>

            {Images.blog.step5}

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              Teachers from your school and other schools will also be able to
              find your lesson, and edit, differentiate and personalise it for
              their own students.
            </p>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              And so can you!  In seconds, you can find lessons designed by
              other teachers from your school or schools globally, then edit,
              differentiate and personalise these for your students (and if you
              would like to keep your lessons private, with a{" "}
              <Link to={{ pathname: "/pricing" }} className="underline">
                Teacher Pro
              </Link>{" "}
              subscription, you can do that too!). This is a fantastic way to be
              part of our active and rapidly growing community of teachers from
              around the world!
            </p>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              If you have any further questions about how to get the most out of
              our platform and our interactive learning tool, feel free to
              message us at:{" "}
              <a href="mailto:info@filmdoo.academy">info@filmdoo.academy</a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GuideFindingFilms;
