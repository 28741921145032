import React, { useState } from "react";
import ModalBg from "../../../components/common/modalBg";
import { Warning } from "../../../components/common/alerts";

function FullDetails({
  show,
  setShow,
  title,
  title2,
  showLoginLink,
  setShowLogin,
  handleSubmit,
  showUserPassword,
  checkDetails,
}) {
  const [stage, setStage] = useState(1);
  const [warning, setWarning] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [position, setPosition] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [tcAgreed, setTcAgreed] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [notifyLinkedIn, setNotifyLinkedIn] = useState(false);
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const handleClickClose = () => {
    hideModal();
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setWarning("");
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setWarning("");
  };
  const handleUsernameChange = (event) => {
    setUsername(
      event.target.value
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, "_")
    );
    setWarning("");
  };
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value.trim());
    setWarning("");
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value.trim());
    setWarning("");
  };
  const handleSchoolNameChange = (event) => {
    setSchoolName(event.target.value);
    setWarning("");
  };
  const handleClickLogin = () => {
    setShow(false);
    setShowLogin(true);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value.trim().toLowerCase());
    setWarning("");
  };
  const handlePositionChange = (event) => {
    setPosition(event.target.value.trim());
    setWarning("");
  };
  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
    setWarning("");
  };
  const handleTcAgreedClick = (event) => {
    setTcAgreed((prev) => !prev);
    setWarning("");
  };
  const handleNextClick = () => {
    if (!emailRegex.test(email)) {
      setWarning("Please enter a valid email address");
    } else if (showUserPassword && !username) {
      setWarning("Please choose a username");
    } else if (showUserPassword && !password) {
      setWarning("Please enter a password");
    } else if (showUserPassword && password !== confirmPassword) {
      setWarning("passwords do not match");
    } else if (!emailRegex.test(email)) {
      setWarning("Please enter a valid email address");
    } else {
      checkDetails(email, username, setWarning, () => {
        setStage(2);
      });
    }
  };
  const handleSignUpButton = () => {
    if (!tcAgreed) {
      setWarning("You must agree to the terms and conditions");
    } else if (!firstName || !lastName) {
      setWarning("Please enter your first and last name");
    } else if (!phoneNumber) {
      setWarning("Please enter your phone number");
    } else if (!firstName || !lastName) {
      setWarning("Please enter your school name and job title");
    } else if (!phoneNumber) {
      setWarning("Please enter your phone number");
    } else {
      handleSubmit(
        {
          firstname: firstName,
          lastname: lastName,
          company: schoolName,
          jobtitle: position,
          phone: phoneNumber,
          username,
          password,
          email,
        },
        setWarning
      );
      setNotifyLinkedIn(true);
    }
  };

  const hideModal = () => {
    !emailRegex.test(email)
      ? setShow(false)
      : fetch(`/api/v1/contacts/closedModal`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            firstname: firstName,
            lastname: lastName,
            company: schoolName,
            jobtitle: position,
            phone: phoneNumber,
            username,
            password,
            email,
          }),
        })
          .then((res) => res.json())
          .then(() => {
            setShow(false);
          });
  };

  return (
    <ModalBg {...{ show, setShow }}>
      <div className="grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-8 max-w-md">
        <div
          onClick={handleClickClose}
          className="absolute top-0 right-0 mt-3 mr-3"
        >
          <button className="inline-flex bg-pink-500 rounded-md p-1.5 text-white">
            <span className="sr-only">Dismiss</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <h2 className="sm:col-span-2 mb-2 text-lg font-medium tracking-tight leading-tighter text-navy-500 sm:text-xl -mt-2 pr-12">
          {stage === 1 && title}
          {stage === 2 && <b>{title2}</b>}
        </h2>

        {stage === 1 && (
          <>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-navy-600"
              >
                Your Email
              </label>
              <input
                type="text"
                name="email"
                placeholder="Email"
                onChange={handleEmailChange}
                className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
              />
            </div>

            {showUserPassword && (
              <div className="sm:col-span-2 grid sm:grid-cols-2 sm:gap-x-4">
                <div className="sm:col-span-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-navy-700"
                  >
                    Password
                  </label>
                  <input
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                  />
                </div>
                <div className="sm:col-span-1">
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium text-navy-700"
                  >
                    Confirm Password
                  </label>
                  <input
                    name="confirmPassword"
                    type="password"
                    placeholder="Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                  />
                </div>
              </div>
            )}

            {showUserPassword && (
              <div className="sm:col-span-2">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-navy-700"
                >
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  placeholder="Choose A Username"
                  value={username}
                  onChange={handleUsernameChange}
                  className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                />
              </div>
            )}
          </>
        )}

        {stage === 2 && (
          <>
            <div className="sm:col-span-2 grid sm:grid-cols-2 sm:gap-x-4">
              <div className="sm:col-span-1">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-navy-600"
                >
                  First Name
                </label>

                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  onChange={handleFirstNameChange}
                  className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                />
              </div>

              <div className="sm:col-span-1">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-navy-600"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={handleLastNameChange}
                  className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-navy-600"
              >
                Mobile Number / Your Direct Personal Number
              </label>
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Contact Number"
                onChange={handlePhoneNumberChange}
                className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
              />
            </div>

            <div className="sm:col-span-2 grid sm:grid-cols-2 sm:gap-x-4">
              <div className="sm:col-span-1">
                <label
                  htmlFor="schoolName"
                  className="block text-sm font-medium text-navy-600"
                >
                  Name of School/Institution
                </label>
                <input
                  type="text"
                  name="schoolName"
                  placeholder="Name of School/Institution"
                  onChange={handleSchoolNameChange}
                  className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                />
              </div>

              <div className="sm:col-span-1">
                <label
                  htmlFor="position"
                  className="block text-sm font-medium text-navy-600"
                >
                  Job Title
                </label>
                <input
                  type="text"
                  name="position"
                  placeholder="Job Title"
                  onChange={handlePositionChange}
                  className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <div className="flex items-center mt-1 px-1">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  value={tcAgreed}
                  onClick={handleTcAgreedClick}
                  className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember_me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  I agree to FilmDoo’s{" "}
                  <a
                    href="/term-and-conditions"
                    target="_blank"
                    className="text-teal-700 text-bold"
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    className="text-teal-700 underlined text-bold"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>
            </div>
          </>
        )}

        {stage === 1 && (
          <div className="sm:col-span-2">
            <div className="overflow-hidden">
              <Warning text={warning} show={!!warning} />
            </div>
            <button
              onClick={handleNextClick}
              className="inline-flex items-center justify-center w-full py-2 mt-4 border border-transparent rounded-md fd-shadow text-base font-medium transition-all text-white bg-pink-600 hover:bg-pink-700"
            >
              Next
            </button>
          </div>
        )}

        {stage === 2 && (
          <div className="sm:col-span-2">
            <div className="overflow-hidden">
              <Warning text={warning} show={!!warning} />
            </div>
            <button
              onClick={handleSignUpButton}
              className="inline-flex items-center justify-center w-full py-2 mt-4 border border-transparent rounded-md fd-shadow text-base font-medium transition-all text-white bg-pink-600 hover:bg-pink-700"
            >
              Sign Up Now
            </button>
          </div>
        )}

        {showLoginLink && (
          <div className="text-center text-sm text-navy-600 mb-2 sm:col-span-2">
            <p>
              Already Have an Account?
              <a
                onClick={handleClickLogin}
                className="text-teal-700 cursor-pointer"
              >
                {" "}
                Login
              </a>
            </p>
          </div>
        )}

        {notifyLinkedIn && (
          <img
            height={1}
            width={1}
            style={{ display: "none" }}
            alt="linkedin pixel"
            src="https://px.ads.linkedin.com/collect/?pid=76957&conversionId=4083465&fmt=gif"
          />
        )}
      </div>
    </ModalBg>
  );
}

export default FullDetails;
