import React, { useState, useEffect } from "react";
import ModalBg from "../../../components/common/modalBg";
import window from "global";

function ThankYou({ show, setShow }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const handleClickClose = () => {
    if (loggedIn.username) {
      window.location.href = "/content?author=filmdoo&languageLevel=b1";
    }
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      setLoggedIn(JSON.parse(window.localStorage.getItem("fda_user")));
    }
  }, [show]);

  return (
    <ModalBg {...{ show, setShow }}>
      <div className="max-w-lg">
        <div className="absolute top-0 right-0 mt-3 mr-3">
          <button
            onClick={handleClickClose}
            className="inline-flex bg-pink-500 rounded-md p-1.5 text-white"
          >
            <span className="sr-only">Dismiss</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <h2 className="sm:col-span-2 mb-2 text-2xl font-bold tracking-tight leading-tighter text-navy-800 sm:text-3xl -mt-2 pr-12">
          CONGRATULATIONS,
          <br />& welcome
        </h2>
        <p className="mt-4 text-lg text-navy-500">
          You are now signed up to use the FimDoo Academy tool to build
          inspiring and interactive video class material easily.
        </p>
        <p className="mt-4 text-lg text-navy-500">
          You will shortly receive an email of confirmation and an invite to a
          free 15 minute demonstration of the tool where we will show you how to
          create the most impactful lessons in just a few minutes as well as
          show you how to use every feature and functionality so you get the
          most out of this ultra-powerful tool.
        </p>
        <p className="mt-4 text-lg text-navy-500">
          We really hope you enjoy this tool, and your students adore the
          content you create for them.
        </p>
        <p className="mt-4 text-lg font-bold text-navy-500">
          Warm regards,
          <br />
          The FilmDoo Academy Team
        </p>
      </div>
    </ModalBg>
  );
}

export default ThankYou;
