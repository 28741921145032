import React, { useContext, useState } from "react";
import Emojis from "../../../assets/images/emojis";
import { Context } from "../context";

function EmojiSelection() {
  const { student } = useContext(Context),
    [disabledButtons, setDisabledButtons] = useState(false),
    handleEmoteClick = (emotion) => {
      if (disabledButtons) return;
      student.set("emojiEmotion", emotion);
      setDisabledButtons(true);
      setTimeout(() => setDisabledButtons(false), 5000);
    };

  return (
    <div className="emoji-button-box mb-4">
      {["happy", "sad", "angry", "love", "wow"].map((emotion) => (
        <button
          className={`emoji-button focus:outline-none ${
            disabledButtons ? "grayscale" : ""
          }`}
          onClick={() => handleEmoteClick(emotion)}
        >
          {Emojis[student.emojiCharacter][emotion]}
        </button>
      ))}
    </div>
  );
}

export default EmojiSelection;
