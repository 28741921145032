import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";

function StartedDropDown() {
  return (
    <motion.div
      initial={{ y: 30, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { ease: "easeOut", duration: 0.15 },
      }}
      exit={{
        y: 30,
        opacity: 0,
        transition: { ease: "easeIn", duration: 0.15 },
      }}
      style={{ width: "34rem" }}
      className="hidden xl:block absolute z-20 inset-x-0 transform mt-5 transform px-2 max-w-xl sm:px-0 -ml-48"
    >
      <div className="bg-white rounded-lg fd-shadow overflow-hidden border border-1 border-gray-300">
        <div className="relative bg-white px-2 py-3">
          <Link
            to={{ pathname: "/blog/playing-your-first-interactive-lesson" }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">
                Play Your First Lesson
              </p>
            </div>
          </Link>

          <Link
            to={{
              pathname:
                "/blog/guide-to-hosting-your-first-interactive-group-lesson",
            }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">
                Host Your First Group Lesson
              </p>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/blog/creating-your-first-interactive-lesson",
            }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">
                Create Your First Interactive Lesson
              </p>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/blog/personalise-pre-built-lessons",
            }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">
                Find and Personalise Pre-Existing Lessons
              </p>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/blog/guide-to-finding-film-and-video-resources",
            }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">
                A Teacher's Guide to Finding Video Resources
              </p>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/blog/reasons-to-use-film-for-online-lessons",
            }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">
                5 Reasons to Use Video for Online and In-School Lessons
              </p>
            </div>
          </Link>

          <Link
            to={{
              pathname:
                "/blog/tips-for-creating-the-perfect-primary-school-Lesson",
            }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">
                5 Tips for Creating the Perfect Primary School Lesson
              </p>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/blog/reasons-to-use-film-for-language-lessons",
            }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">
                5 Reasons to Use Video in Your Language Lessons
              </p>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/blog/reasons-to-use-film-with-sen-students",
            }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">
                5 Reasons to Use Video with Your SEN Students
              </p>
            </div>
          </Link>
        </div>
      </div>
    </motion.div>
  );
}

export default StartedDropDown;
