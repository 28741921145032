import { motion } from "framer-motion";
import Images from "../../../assets/images";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import window from "global";

function MobileMenu({
  setShowMobileMenu,
  handleCreateAccountClick,
  handleViewLessons,
  handleLoginClick,
  handleLogoutClick,
  loggedIn,
}) {
  const closeMobileMenu = () => {
    setShowMobileMenu(false);
  };

  return (
    <div className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right xl:hidden">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { ease: "easeOut" } }}
        exit={{ opacity: 0, transition: { ease: "easeIn" } }}
        className="rounded-lg fd-shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-navy-50"
      >
        <div className="pt-5 pb-6 px-5 sm:pb-8">
          <div className="flex items-center justify-between">
            <div>{Images.logo}</div>
            <div className="-mr-2">
              <button
                type="button"
                onClick={closeMobileMenu}
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-navy-400 hover:text-navy-500 hover:bg-navy-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500"
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="py-6 px-8">
          <div className="grid grid-cols-2 gap-4 px-6">
            <Link
              to={{ pathname: "/for_schools" }}
              className="rounded-md text-base font-medium text-navy-900 hover:text-navy-700"
            >
              For Schools
            </Link>

            <Link
              to={{ pathname: "/languages" }}
              className="rounded-md text-base font-medium text-navy-900 hover:text-navy-700"
            >
              Languages & Film
            </Link>

            <Link
              to={{ pathname: "/testimonials" }}
              className="rounded-md text-base font-medium text-navy-900 hover:text-navy-700"
            >
              Testimonials
            </Link>
            <Link
              to={{ pathname: "/webinars" }}
              className="rounded-md text-base font-medium text-navy-900 hover:text-navy-700"
            >
              Webinars
            </Link>

            <Link
              to={{ pathname: "/about_us" }}
              className="rounded-md text-base font-medium text-navy-900 hover:text-navy-700"
            >
              Meet The Team
            </Link>

            <Link
              to={{ pathname: "/pricing" }}
              className="rounded-md text-base font-medium text-navy-900 hover:text-navy-700"
            >
              Pricing
            </Link>

            <Link
              to={{ pathname: "/contact" }}
              className="rounded-md text-base font-medium text-navy-900 hover:text-navy-700"
            >
              Contact Us
            </Link>
          </div>

          {!loggedIn && (
            <div className="mt-6">
              <button
                onClick={handleCreateAccountClick}
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md fd-shadow-sm text-base font-medium text-white bg-pink-600 hover:bg-pink-700"
              >
                Sign Up To FilmDoo Academy Today!
              </button>
              <p className="mt-6 text-center text-base font-medium text-navy-500">
                Existing customer?
                <button
                  onClick={handleLoginClick}
                  className="ml-1 text-teal-600 hover:text-teal-500"
                >
                  Sign in
                </button>
              </p>
            </div>
          )}

          {loggedIn && (
            <div className="mt-6">
              <button
                onClick={handleViewLessons}
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md fd-shadow-sm text-base font-medium text-white bg-pink-600 hover:bg-pink-700"
              >
                My Lessons
              </button>
              <p className="mt-6 text-center text-base font-medium text-navy-500">
                <button
                  onClick={handleLogoutClick}
                  className="ml-1 text-teal-600 hover:text-teal-500"
                >
                  Logout
                </button>
              </p>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
}

export default MobileMenu;
