import React from "react";
import { motion } from "framer-motion";

function Cta({ children, onClick }) {
  return (
    <div className={`
        relative
        w-full
        max-w-2xl
        mx-auto
        px-4
        sm:px-6
        overflow-hidden
        py-4`}>
          <motion.button
            initial={{
                y: 200,
                rotate: 5,
                scale: 0.9
            }}
            animate={{
                y: 0,
                rotate: 0,
                scale: 1,
                transition: {
                    duration: 0.1
                }
            }}
            exit={{
                y: 300,
                rotate: 5,
                scale: 0.9,
                transition: {
                    duration: 0.1
                }
            }}
            onClick={onClick}
            type="button"
            className={`
                cta-button
                uppercase
                tracking-widest
                mt-6
                mb-3
                focus:outline-none
                w-full
                bg-teal-600
                rounded-lg
                py-5
                text-sm
                leading-5
                mo-medium
                text-navy-600
                `}>
        {children}
      </motion.button>
    </div>
  );
}

export default Cta;
