import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

function HomePopUp({ setMasterShowMembership }) {
  const [show, setShow] = useState(false),
    handleClickClose = () => {
      setShow(false);
    },
    handleClickPopupOpen = () => {
      setMasterShowMembership(true);
    };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 3000);
  }, []);

  return (
    <motion.div
      animate={show ? "visible" : "hidden"}
      initial={{ x: -500 }}
      variants={{
        visible: { x: 0, transition: { duration: 1 } },
        hidden: { x: -500 },
      }}
      className="fixed bottom-0 left-0 mb-6 ml-6 z-40"
    >
      <div className="bg-navy-600 fd-shadow-black  rounded-xl bg-navy-gradient">
        <div
          onClick={handleClickClose}
          className="absolute top-0 right-0 mt-3 mr-3"
        >
          <button className="transition duration-500 ease-in-out hover:bg-pink-700 transform focus:outline-none inline-flex bg-pink-500 fd-shadow-black rounded-md p-1.5 text-white">
            <span className="sr-only">Dismiss</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div className="px-4 py-3 sm:p-6">
          <div className="max-w-xl text-md text-white pr-12 font-bold pl-1">
            <p>Register today to join our</p>
            <p>FREE Membership Programme</p>
          </div>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between w-full">
            <button
              onClick={handleClickPopupOpen}
              type="button"
              className="transition duration-500 ease-in-out hover:bg-teal-400 transform focus:outline-none mt-2 w-full font-extrabold justify-center text-center inline-flex items-center px-4 py-2 border border-transparent fd-shadow-black text-md font-medium rounded-md text-navy-600 bg-teal-600"
            >
              FREE School & Academy Membership
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default HomePopUp;
