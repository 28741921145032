import React from 'react';
import { motion  } from "framer-motion"

function PlayerIcon({playing}) {

  const
    lineVariants = {
      pause: { pathLength: [1,.28], opacity:[1,.7,1], pathOffset: [.7,0], transition: { duration: .3 } },
      play: { pathLength: [.28,1], opacity:[1,.7,1], pathOffset: [0,.7], transition: { duration: .3 } },
    },
    cornerVariants = {
      pause: { x:[5,0], opacity:[0,1], transition: { duration: .1, delay:.2 } },
      play: { x:[0,5], opacity:[1,0], transition: { duration: .1 } },
    }

  return (
    <svg viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" className="playIcon">
      <circle cx="31.5" cy="31.5" r="29.5" stroke="white" fill="none" strokeWidth="4"/>

      <motion.path
        variants={cornerVariants}
        animate={playing ? "play" : "pause"}
        d="M25 46L28 44V43H25V46Z" fill="white"/>
      <motion.path
        variants={cornerVariants}
        animate={playing ? "play" : "pause"}
        d="M25 15L28 17V18H25V15Z" fill="white"/>

      <rect x="25" y="18" width="4" height="25" fill="white"/>
      <motion.path
        variants={lineVariants}
        stroke="white"
        fill="none"
        strokeWidth="4"
        animate={playing ? "play" : "pause"}
        d="M26 43L46.0002 29.4446C51.5 26 55.5 20.5 50 15.0001C44.5 9.50009 37.0002 13.4446 37.0002 18.4446C37.0002 20.8446 37.0002 36.1113 37.0002 43.4446"/>
      <motion.path
        variants={lineVariants}
        stroke="white"
        fill="none"
        strokeWidth="4"
        animate={playing ? "play" : "pause"}
        d="M26 18L46.0002 32C51.5 35.4446 55.5 40.9446 50 46.4445C44.5 51.9445 37.0002 48 37.0002 43C37.0002 40.6 37.0002 25.3333 37.0002 18"/>
    </svg>

  )
}

export default PlayerIcon;
