import { useState, useEffect, useMemo } from "react";
import sessionStorage from "sessionstorage";
import window from "global/window";

// https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
function ordinal_suffix_of(i) {
  let j = i % 10,
    k = i % 100;

  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
}

export default (socket, isPrefect) => {
  const classRoomNameFromStorage = sessionStorage?.getItem("classRoomName"),
    [overlay, setOverlay] = useState(""),
    [makeItRain, setRain] = useState(false),
    [isDesktop, setIsDesktop] = useState(
      window.innerWidth > 1000 && window.innerHeight > 600
    ),
    [classRoom, setClassRoom] = useState({
      lastUpdated: 0,
      name: "",
      anyoneCanPause: false,
      oneLocation: true,
      lessonDatabaseId: null,
      classRoomEmpty: false,
      emotes: [],
      allStudents: [],
    }),
    leaderboard = useMemo(
      () =>
        !classRoom.allStudents[0]
          ? []
          : classRoom.allStudents
              .map((student) => {
                student.score = !student.responses[0]
                  ? 0
                  : student.responses
                      .map((response) => response.score)
                      .reduce((acc, curr) => acc + curr);
                return student;
              })
              .sort((a, b) => b.score - a.score)
              .map((student, key) => {
                student.position = key + 1;
                student.positionSuffix = ordinal_suffix_of(key + 1);
                return student;
              }),
      [classRoom.allStudents]
    ),
    set = (attribute, newState) => {
      setClassRoom((prev) => ({ ...prev, [attribute]: newState }));
      if (socket)
        socket.emit("update classRoom", {
          ...classRoom,
          [attribute]: newState,
          attribute,
        });
    };

  // update on window resize
  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > 1000 && window.innerHeight > 600);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("classRoom changes", (updatedClassRoom) => {
        if (updatedClassRoom.lastUpdated > classRoom.lastUpdated) {
          setClassRoom((prev) => ({ ...prev, ...updatedClassRoom }));

          // keep the latest classroom in storage
          if (!updatedClassRoom.isEmpty) {
            sessionStorage.setItem("classRoomName", updatedClassRoom.name);

            // if the user already has a classroom open. close the last classroom
            const lastClassRoomName = window.localStorage.getItem(
              "classRoomName"
            );
            if (
              isPrefect &&
              lastClassRoomName &&
              lastClassRoomName !== updatedClassRoom.name
            ) {
              socket.emit("end lesson", { lastClassRoomName });
            }
            window.localStorage.setItem("classRoomName", updatedClassRoom.name);
          } else {
            window.location.href = "/party";
            sessionStorage.removeItem("classRoomName");
          }
        }
      });

      // if the database id is set before the socket is set. send it now
      if (classRoom.lessonDatabaseId)
        set("lessonDatabaseId", classRoom.lessonDatabaseId);

      // if classroom was stored in session then rejoin it
      if (classRoomNameFromStorage) set("name", classRoomNameFromStorage);
    }
  }, [socket]);

  return {
    ...classRoom,
    makeItRain,
    setRain,
    isDesktop,
    set,
    overlay,
    setOverlay,
    leaderboard,
  };
};
