import React, { useState } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import LanguageHero from "./languageHero";
import LanguageFeatured from "./languageFeatured";
import LargeQuote from "./largeQuote";
import LanguageDropOut from "./languageDropOut";
import LanguageImprove from "./languageImprove";
import HowItWorks from "./howItWorks";
import HomeCreate from "../home/homeCreate";
import LanguageWhy from "./languageWhy";
import HomeWebinarCta from "../home/homeWebinarCta";
import ComingSoon from "./comingSoon";
import SampleLessons from "../../components/sampleLessons";

function Languages() {
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false);
  const openCreateAccountModal = () => setMasterShowCreateAccount(true);

  return (
    <>
      <Menu
        {...{
          masterShowCreateAccount,
          setMasterShowCreateAccount,
        }}
      />
      <LanguageHero {...{ setMasterShowCreateAccount }} />
      <LanguageFeatured />
      <LargeQuote number={0} />
      <LanguageDropOut {...{ openCreateAccountModal }} />
      <LargeQuote number={1} />
      <LanguageImprove />
      <HowItWorks />
      <HomeCreate {...{ openCreateAccountModal }} />
      <LanguageWhy />
      <ComingSoon />
      <SampleLessons forLanguages={true} extraHeadings={true} />
      <HomeWebinarCta />
      <LargeQuote number={2} />
      <Footer />
    </>
  );
}

export default Languages;
