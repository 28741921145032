import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import PlayIcon from "../../components/icons/playIcon";
import { Info, Warning } from "../../../components/common/alerts";
import { motion } from "framer-motion";
import { secondsToHms, hmsToSeconds } from "../utils";

function Step2({
  handleStepClick,
  url,
  endTime,
  setEndTime,
  startTime,
  setStartTime,
}) {
  const player = useRef(),
    seekBar = useRef(),
    playBarWidth = seekBar.current?.offsetWidth,
    [showWarning, setShowWarning] = useState(false),
    [mouseDown, setMouseDown] = useState(false),
    [tempStartTime, setTempStartTime] = useState(secondsToHms(startTime)),
    [tempEndTime, setTempEndTime] = useState(secondsToHms(endTime)),
    [playing, setPlaying] = useState(false),
    [currentTime, setCurrentTime] = useState(0),
    [mouseX, setMouseX] = useState(-2000),
    [duration, setDuration] = useState(0),
    [dragPositionStart, setDragPositionStart] = useState(0),
    [dragPositionEnd, setDragPositionEnd] = useState(0),
    mapTimes = (t) => 100 - ((t - 0) * 100) / duration + "%",
    onMouseDown = () => {
      setMouseDown(true);
    },
    onMouseUp = () => {
      setMouseDown(false);
    },
    onClick = (event) => {
      let offsetX = event.currentTarget.getBoundingClientRect().left,
        clickPosition = (event.clientX - offsetX) / playBarWidth,
        newTime = clickPosition * duration;

      setCurrentTime(newTime);

      if (player.current) player.current.seekTo(newTime);
    },
    handleGotDuration = (retrievedDuration) => {
      if (!endTime) {
        setEndTime(retrievedDuration);
        setTempEndTime(secondsToHms(retrievedDuration));
      }
      setDuration(retrievedDuration);
    },
    onMouseMove = (event) => {
      let offsetX = event.currentTarget.getBoundingClientRect().left;
      setMouseX(playBarWidth - (event.clientX - offsetX));

      if (mouseDown) {
        let clickPosition = (event.clientX - offsetX) / playBarWidth,
          newTime = clickPosition * duration;
        setCurrentTime(newTime);

        if (player.current) player.current.seekTo(newTime);
      }
    },
    handleProgress = (p) => {
      setCurrentTime(p.playedSeconds);
    },
    handleLeftDragEnd = (event, info) => {
      setStartTime((prev) => {
        let adjustBy = (info.offset.x / playBarWidth) * duration,
          newValue = prev + adjustBy;

        setShowWarning(newValue > endTime);
        if (newValue >= duration) {
          newValue = duration;
          setStartTime(duration);
        } else if (newValue < 0) {
          newValue = 0;
          setStartTime(0);
        }

        setTempStartTime(secondsToHms(newValue));
        return newValue;
      });
    },
    handleRightDragEnd = (event, info) => {
      setEndTime((prev) => {
        let adjustBy = (info.offset.x / playBarWidth) * duration,
          newValue = prev + adjustBy;

        setShowWarning(newValue < startTime);
        if (newValue > duration) {
          newValue = duration;
          setEndTime(duration);
        } else if (newValue <= 0) {
          newValue = 0;
          setEndTime(0);
        }

        setTempEndTime(secondsToHms(newValue));
        return newValue;
      });
    },
    handleInputBlur = () => {
      let endTimeSeconds = hmsToSeconds(tempEndTime),
        startTimeSeconds = hmsToSeconds(tempStartTime);

      if (endTimeSeconds > duration || endTimeSeconds < 0) {
        endTimeSeconds = duration;
        setTempEndTime(secondsToHms(duration));
        setDragPositionEnd(0);
      }
      if (startTimeSeconds < 0 || startTimeSeconds > duration) {
        setTempStartTime(secondsToHms(0));
        startTimeSeconds = 0;
        setDragPositionStart(0);
      }

      setShowWarning(endTimeSeconds < startTimeSeconds);
      setEndTime(endTimeSeconds);
      setStartTime(startTimeSeconds);
    },
    handleEndTimeChange = (event) => {
      let newTime = event.target.value.replace(/[^\d:-]/g, ""),
        newTimeInSeconds = hmsToSeconds(newTime),
        secondWidth = playBarWidth / duration,
        dragPosition = newTimeInSeconds * secondWidth;

      setTempEndTime(newTime);
      setDragPositionEnd(dragPosition - playBarWidth);
    },
    handleStartTimeChange = (event) => {
      let newTime = event.target.value.replace(/[^\d:-]/g, ""),
        newTimeInSeconds = hmsToSeconds(newTime),
        secondWidth = playBarWidth / duration,
        dragPosition = newTimeInSeconds * secondWidth;

      setTempStartTime(newTime);
      setDragPositionStart(dragPosition);
    };

  // position drag handles once loaded
  useEffect(() => {
    if (playBarWidth && duration) {
      let secondWidth = playBarWidth / duration;
      setDragPositionEnd(secondWidth * endTime - playBarWidth);
      setDragPositionStart(secondWidth * startTime);
    }
  }, [playBarWidth, duration, startTime, endTime]);

  return (
    <form className="space-y-8 divide-y divide-gray-200">
      <div>
        <div className="pb-3">
          <h3 className="text-3xl font-extrabold font-medium text-gray-900">
            2. Cut/Crop Video
          </h3>
          <p className="text-base text-gray-500 mb-4">
            If you want to use just a clip of this video, crop it by choosing
            the lesson start and end time.
          </p>

          <Info
            text="You can also drag the start and endtime in the seek bar."
            className="text-coral-600 block text-xs italic"
          />
        </div>

        <div
          className="relative rounded-lg overflow-hidden shadow-sm"
          style={{ paddingBottom: 50 }}
        >
          <ReactPlayer
            ref={player}
            url={url}
            onPause={() => setPlaying(false)}
            onPlay={() => setPlaying(true)}
            volume={0.7}
            onProgress={handleProgress}
            playing={playing}
            progressInterval={200}
            onDuration={handleGotDuration}
            width="100%"
          />

          <div className="playerControlls relative">
            <div
              onClick={() => setPlaying((prev) => !prev)}
              className="playButton playButtonActive bg-navy-600"
            >
              <div className="playButtonFiller" />
              <PlayIcon playing={playing} />
            </div>

            <motion.div
              drag="x"
              dragMomentum={false}
              onDragEnd={handleLeftDragEnd}
              dragConstraints={{ left: 0, right: playBarWidth - 8 }}
              animate={{ x: dragPositionStart }}
              className="h-full absolute block z-10 ew-drag ew-drag-left w-full"
            />
            <motion.div
              drag="x"
              onDragEnd={handleRightDragEnd}
              dragMomentum={false}
              animate={{ x: dragPositionEnd }}
              dragConstraints={{ left: -(playBarWidth - 8), right: 0 }}
              className="h-full absolute block z-10 ew-drag ew-drag-right w-full"
            />

            <div
              className="relative"
              {...{ onMouseMove, onMouseDown, onMouseUp, onClick }}
              onMouseLeave={() => {
                setMouseDown(false);
                setMouseX(-4000);
              }}
              ref={seekBar}
              id="playerProgressBar"
              style={{ right: 0 }}
            >
              <motion.div
                animate={{ right: mapTimes(currentTime) }}
                transition={{ ease: "linear", duration: 0.2 }}
                className="playerSeekLocation"
              />
              <div style={{ right: mouseX }} className="playerProgressGuide" />
            </div>
          </div>
        </div>

        <div className="mt-4 grid grid-cols-1 sm:grid-cols-4 gap-6">
          <div className="sm:col-span-1">
            <label
              htmlFor="start-time"
              className="block text-sm font-medium text-gray-700"
            >
              Start Time
            </label>
            <input
              value={tempStartTime}
              onChange={handleStartTimeChange}
              onBlur={handleInputBlur}
              type="text"
              name="start-time"
              className="mt-1 block w-full border border-gray-300 rounded-md inset-shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            />
          </div>

          <div className="sm:col-span-2" />

          <div className="sm:col-span-1 text-right">
            <label
              htmlFor="end-time"
              className="block text-sm font-medium text-gray-700"
            >
              End Time
            </label>
            <input
              value={tempEndTime}
              onChange={handleEndTimeChange}
              onBlur={handleInputBlur}
              type="text"
              name="end-time"
              className="mt-1 block w-full border border-gray-300 rounded-md inset-shadow py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm text-right"
            />
          </div>
        </div>

        <Warning
          text="End time can't be before start time"
          show={showWarning}
        />
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={() => {
              handleStepClick(0);
            }}
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Back to Choose Video
          </button>
          <button
            onClick={(e) =>
              showWarning
                ? e.preventDefault()
                : handleStepClick(2, "allowProgress")
            }
            type="submit"
            className={`
                ${
                  showWarning
                    ? "bg-gray-300"
                    : "bg-coral-600 hover:bg-coral-700 shadow-sm"
                } 
                ml-3 inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </form>
  );
}

export default Step2;
