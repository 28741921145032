import React, { useEffect, useState } from "react";
import window from "global/window";
import { Link } from "react-router-dom";
import Images from "../../assets/images";
import { FeedbackFish } from "@feedback-fish/react";
import StartedDropDown from "../../website/components/menu/startedDropDown";
import { AnimatePresence } from "framer-motion";

function MiniMenu({ withCreateButton }) {
  const [user, setUser] = useState();
  const [showStartedDropDown, setShowStartedDropDown] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);
  const [pro, setPro] = useState(false);
  const handleLogout = () => {
      window.localStorage.removeItem("fda_user");
      window.location.href = "/";
    },
    handleAuthoringClick = () => {
      window.location.href = "/authoring";
    };

  const handleHoverStarted = () => {
    setShowStartedDropDown(true);
  };
  const handleMouseLeave = () => {
    setShowStartedDropDown(false);
  };

  // if not logged in redirect to homepage
  useEffect(() => {
    if (window.localStorage && !window.localStorage.getItem("fda_user")) {
      window.location.href = "/";
    } else {
      const gotUser = JSON.parse(window.localStorage.getItem("fda_user"));

      // add a start date to any user without one
      if (gotUser.date_created) {
        setUser(gotUser);
      } else {
        window.localStorage.removeItem("fda_user");
        window.location.href = "/";
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      // check if on paid account
      if (user.permission_level === 1) {
        let createdTimeStamp = Date.parse(user.date_created);

        const todayTimestamp = +new Date();
        const days = Math.floor(
          14 +
            createdTimeStamp / (1000 * 3600 * 24) -
            todayTimestamp / (1000 * 3600 * 24)
        );

        setDaysLeft(days);
      } else {
        setPro(true);
      }
    }
  }, [user]);

  return (
    <nav className="bg-gray-800">
      <div
        onMouseLeave={handleMouseLeave}
        className="w-full flex justify-between flex-rows px-3 py-1"
      >
        <Link to={{ pathname: "/" }} className="px-2 py-2">
          {Images.logo}
        </Link>

        <div className="relative mt-3 lg:visible invisible">
          <button
            type="button"
            onMouseEnter={handleHoverStarted}
            className="py-2 px-3 border border-navy-200 hover:border-white -mb-12 group rounded-md text-white inline-flex items-center text-base font-medium hover:text-white focus:outline-none"
          >
            <Link
              to={{
                pathname: "/blog/playing-your-first-interactive-lesson",
              }}
            >
              Getting Started
            </Link>

            <svg
              className="ml-0.5 h-5 w-5 text-white group-hover:text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <AnimatePresence exitBeforeEnter>
            {showStartedDropDown && <StartedDropDown />}
          </AnimatePresence>
        </div>

        <div className="flex items-center justify-end">
          <div className="flex items-center mx-4 justify-between w-full">
            <div>
              {!pro && daysLeft >= 1 && (
                <div className="text-navy-200 px-3 py-2 text-sm font-medium italic">
                  <Link to={{ pathname: "/pricing" }} className="text-pink-500">
                    {daysLeft} days left of your free trial <b>upgrade now</b>
                  </Link>
                </div>
              )}
            </div>

            <div className="flex space-x-1 py-2">
              <FeedbackFish projectId="ab943c41919951">
                <div className="cursor-pointer text-navy-200 hover:text-white px-3 py-2 text-sm font-medium">
                  Send Feedback
                </div>
              </FeedbackFish>
              <a
                href="#"
                onClick={handleLogout}
                className="text-navy-200 hover:text-white pl-3 pr-6 py-2 text-sm font-medium"
              >
                Logout
              </a>
              {withCreateButton && (pro || daysLeft >= 1) && (
                <Link
                  to={{ pathname: "/authoring" }}
                  className="py-2 px-3 bg-pink-400 text-white rounded-md shadow"
                >
                  + Create New Lesson
                </Link>
              )}
              {withCreateButton && !pro && daysLeft < 1 && (
                <Link
                  to={{ pathname: "/pricing" }}
                  className="ml-1 py-2 px-3 bg-pink-400 text-white rounded-md shadow"
                >
                  Upgrade to pro account
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default MiniMenu;
