import React, { useState } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import HomeCreate from "../home/homeCreate";
import SampleLessons from "../../components/sampleLessons";
import HomeWebinarCta from "../home/homeWebinarCta";
import Hero from "./hero";
import TeacherImprove from "./teacherImprove";

function ForTeachers() {
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false);

  return (
    <>
      <Menu
        {...{
          masterShowCreateAccount,
          setMasterShowCreateAccount,
        }}
      />
      <Hero />
      <TeacherImprove />
      <HomeCreate />

      <div className="bg-white overflow-hidden">
        <div className="max-w-5xl mx-auto text-center pt-16 px-4 sm:pt-20 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-extrabold text-navy-600 sm:text-5xl">
            Sample Lessons for Teachers
          </h2>
        </div>
      </div>

      <SampleLessons />
      <SampleLessons forLanguages={true} />
      <HomeWebinarCta />
      <Footer />
    </>
  );
}

export default ForTeachers;
