import React, {useEffect} from 'react';
import {motion, useAnimation} from "framer-motion";

function Question({ question, instructionText, countDown, progressStop, duration, wrongCount }) {

  const
    controls = useAnimation(),
    reduceProgressBar = (velocity, ease) => {
      if(!progressStop) {
        controls.stop();
        controls.start({
          width: countDown * (wrongCount ? 1 : 10) + '%',
          transition: {
            duration: duration / velocity,
            ease
          }
        });
      }
    },

    variants = {
      background: {
        visible:{ y:0,
          transition: {
            delay:0.4,
            duration:0.4,
            staggerChildren: 0.3,
            delayChildren: 1 }
        },
        hidden:{ y:-400 }
      },
      text: {
        visible: { y:0, opacity:1 },
        hidden:{ y:-50, opacity:0 }
      },
      progress: {
        visible: { y:0, opacity:1, transition: { delay:3 } },
        hidden: { y:20, opacity:0 }
      }
    };

  useEffect(() => reduceProgressBar(10, "linear"),[countDown]);
  useEffect(() => reduceProgressBar(20, "circOut"),[wrongCount]);

  useEffect(()=>{
    if(progressStop) {
      controls.stop();
      if(countDown === 0) controls.start({ width: '0%' });
    }
  },[progressStop]);


  return (
    <motion.div
      variants={variants.background}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className="bg-navy-600 question-heading-bg">

      <div className="max-w-screen-xl mx-auto pt-3 pb-2 px-8 sm:pt-6 sm:pb-5 sm:px-4 lg:px-6">

        <motion.div
          variants={variants.progress}
          className="mx-4 my-4 h-px block bg-navy-700 mb-4 sm:mb-8 overflow-hidden">
            <motion.div
              animate={controls}
              className={`h-px block ${wrongCount ? 'bg-coral-600':  'bg-teal-600' } w-1/2`} />
        </motion.div>

        <div className="text-center">

          <div className="overflow-hidden">
            <motion.h1
              variants={variants.text}
              className="text-base leading-6 mo-light font-semibold text-gray-500 tracking-wide uppercase">
              Multiple Choice
            </motion.h1>
          </div>

          <div className="overflow-hidden">
            <motion.p
              variants={variants.text}
              className="px-4 sm:px-2 w-full max-w-2xl mx-auto mt-1 text-2xl pf-light lg:pb-4 xl:pb-6 text-white leading-tight sm:text-3xl lg:text-4xl xl:text-5xl lg:leading-snug lg:text-4xl">
                {question}
            </motion.p>
          </div>

          <div className="overflow-hidden">
            <motion.p
              variants={variants.text}
              className="max-w-xl mt-4 mx-auto text-md leading-7 text-coral-500 pf-medium-italic text-base lg:text-lg xl:text-xl">
                {instructionText}
            </motion.p>
          </div>

        </div>
      </div>
    </motion.div>
  )
}

export default Question;
