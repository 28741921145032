import React, { useState } from "react";
import ModalBg from "../../../components/common/modalBg";
import { Warning } from "../../../components/common/alerts";
import window from "global/window";

function LoginModal({ show, setShow, setShowCreateAccount }) {
  const [warning, setWarning] = useState(""),
    [email, setEmail] = useState(""),
    [password, setPassword] = useState(""),
    handleClickClose = () => {
      setShow(false);
    },
    handleEmailChange = (event) => {
      setEmail(event.target.value.trim().toLowerCase());
      setWarning("");
    },
    handlePasswordChange = (event) => {
      setPassword(event.target.value);
      setWarning("");
    },
    handleClickCreateAccount = () => {
      setShow(false);
      setShowCreateAccount(true);
    },
    handleLoginClick = () => {
      fetch(`/api/v1/user/login/${email}/${password}`)
        .then((res) => res.json())
        .then((user) => {
          if (user.username) {
            user.state = user.firstlogin ? "logged-in" : "updating";
            //setUsername(user.username);
            //setLogin(user);
            window.localStorage.setItem("fda_user", JSON.stringify(user));

            // don't redirect if on pricing page
            const uri = window.location.href.split("/").pop();
            if (uri !== "pricing" && uri !== "pricing#") {
              window.location.href = "/content?author=" + user.username;
            } else {
              window.location.reload();
            }

            setShow(false);

            fetch("/api/v1/log", {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: user.email,
                language: navigator.languages.join(", "),
              }),
              method: "POST",
            });
          } else {
            setWarning("Email and Password not recognised");
          }
        });
    };

  return (
    <ModalBg {...{ show, setShow }}>
      <div className="grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-8">
        <div
          onClick={handleClickClose}
          className="absolute top-0 right-0 mt-3 mr-3"
        >
          <button className="inline-flex bg-pink-500 rounded-md p-1.5 text-white">
            <span className="sr-only">Dismiss</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <h2 className="sm:col-span-2 mb-4 text-3xl font-extrabold tracking-tight text-navy-700 sm:text-4xl -mt-2 pr-20">
          Welcome Back
        </h2>

        <div className="sm:col-span-2">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-navy-700"
          >
            Email
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="email"
              onChange={handleEmailChange}
              className="py-2 px-3 block w-full inset-shadow border border-navy-100 rounded-md"
            />
          </div>
        </div>

        <div className="sm:col-span-2">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-navy-700"
          >
            Password
          </label>
          <div className="mt-1">
            <input
              name="password"
              type="password"
              onChange={handlePasswordChange}
              className="py-2 px-3 block w-full inset-shadow border border-navy-100 rounded-md"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="overflow-hidden">
            <Warning text={warning} show={!!warning} />
          </div>
          <button
            onClick={handleLoginClick}
            className="inline-flex items-center justify-center w-full py-2 mt-4 border border-transparent rounded-md fd-shadow text-base font-medium transition-all text-white bg-pink-600 hover:bg-pink-700"
          >
            Sign In
          </button>
        </div>

        <div className="text-center text-sm text-navy-600 mb-2 sm:col-span-2">
          <p>
            New To Us?
            <button
              onClick={handleClickCreateAccount}
              className="text-teal-600 cursor-pointer"
            >
              {" "}
              Sign Up
            </button>
          </p>
        </div>
      </div>
    </ModalBg>
  );
}

export default LoginModal;
