import React from "react";
import { motion } from "framer-motion";

function CheckBox({ checked, small }) {
  const lineVariants = {
    tick: { pathLength: [0, 0.55], transition: { duration: 0.2 } },
    circle: { pathLength: [0.55, 0], transition: { duration: 0.1 } },
  };

  const circleVariant = {
    tick: { pathLength: [1, 0], transition: { duration: 0.1 } },
    circle: { pathLength: [0, 1], transition: { duration: 0.2 } },
  };

  return (
    <svg
      className="quiz-option-checkbox"
      width={small ? 18 : 30}
      height={small ? 18 : 30}
      viewBox="0 0 20 20"
    >
      <motion.path
        fill="none"
        strokeWidth="2"
        stroke="currentColor"
        animate={checked ? "tick" : "circle"}
        variants={lineVariants}
        d="M18.1245 2L9.62445 12L3 4.5C4.646 2.37097 7.22512 1 10.1245 1C10.9467 1 11.7432 1.11027 12.5 1.31683"
      />

      <motion.path
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        stroke="currentColor"
        d="M10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 9.66199 18.9814 9.32833 18.9451 9C18.9298 8.8614 18.9113 8.72374 18.8897 8.58711"
      />

      <motion.path
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        stroke="currentColor"
        animate={checked ? "tick" : "circle"}
        variants={circleVariant}
        d="M19 10C19 5.02944 14.9706 1 10 1"
      />
    </svg>
  );
}

export default CheckBox;
