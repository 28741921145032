import React, { useContext, useState, useRef, useEffect } from 'react';
import { Context } from '../context';
import PlayerWrapper from "./playerWrapper";
import { useInterval } from 'beautiful-react-hooks';

function Index() {

  const
    { lesson, student, classRoom, activity } = useContext(Context),
    [latestEmotes, setLatestEmotes] = useState([]),
    [currentlySeeking, setCurrentlySeeking] = useState(false),
    [volume, setVolume] = useState(0.7),
    player = useRef(),

    pauseVideo = () => {
      if(student.isPrefect) {
        lesson.set('lastSavedPlayingState', false);
        lesson.setPlaying(false);
      }
    },
    playVideo = () => {
      if(student.isPrefect) {
        lesson.set('lastSavedPlayingState', true);
        lesson.setPlaying(true);
      }
    },
    seekTo = time => {
      const numericTime = parseFloat(time);
      if(player.current && numericTime) {
        lesson.setCurrentTime(numericTime);
        player.current.seekTo(numericTime, 'seconds');
      }
      setCurrentlySeeking(false);
    },
    onSeek = () => setCurrentlySeeking(false),
    onProgress = p => {
      if(currentlySeeking) return;

      const
        { playedSeconds } = p;

      lesson.setCurrentTime(playedSeconds);

      if(
        lesson.playing &&
        !!playedSeconds &&
        playedSeconds >= activity.endtime &&
        playedSeconds <= (activity.endtime + 5)) {
          if(activity.nextActivity === 'end-game') {
            classRoom.setOverlay('leaderboard');
            setTimeout(() => classRoom.setOverlay('results'),4000);

            if(student.isPrefect)
              setTimeout(()=>lesson.end(), 10000);

          } else {
            student.setWaiting('next activity', activity.endtime);
            classRoom.setOverlay('next activity');
          }
          lesson.setCurrentTime(activity.endtime);
          lesson.setPlaying(false);
      }
    },
    onEnded = () => {
      classRoom.setOverlay('leaderboard');
      setTimeout(() => classRoom.setOverlay('results'),4000);

      if(student.isPrefect)
        setTimeout(()=>lesson.end(), 10000);
    },
    [checkingPlayingState, setCheckingPlayingState] = useState(false),
    [isCleared, clearInterval] = useInterval(()=> {
      if (
          player?.current?.player?.isReady
          && typeof player.current.seekTo === 'function'
          && typeof lesson.lastSavedPlayingState === 'boolean'
      ) {

        // first set to the right time and try and set the correct play state
        if(!checkingPlayingState) {
          setCurrentlySeeking(true);
          seekTo(lesson.lastSavedCurrentTime || lesson.currentTime || activity.starttime);
          lesson.setPlaying(lesson.lastSavedPlayingState);
          setCheckingPlayingState(true);
        // then keep looping till we have the correct play state
        } else if(
            lesson.playing === lesson.lastSavedPlayingState &&
            lesson.playing === player?.current?.player?.isPlaying) {
              clearInterval();
        } else {
          lesson.setPlaying(lesson.lastSavedPlayingState);
        }


      }
    },1000);

  useInterval(()=> {
    setLatestEmotes(prev => prev.filter(
        emote => (emote.timestamp + 5000) > +new Date()
    ))
  }, 300)

  // sync playing state and play time with any returns from the server
  useEffect(() => {
    setCurrentlySeeking(true);
    seekTo(lesson.lastSavedCurrentTime);
  },[lesson.lastSavedCurrentTime]);

  useEffect(() => {
    // make sure the video is actually loaded
    if(typeof lesson.lastSavedPlayingState === 'boolean') {
      lesson.setPlaying(lesson.lastSavedPlayingState);
    } else {
      lesson.setPlaying(false);
    }
  },[lesson.lastSavedPlayingState]);

  // make sure the correct wait screen is set every time the player loads
  useEffect(() => {
    classRoom.setOverlay('watching');
  }, []);



  useEffect(() => setLatestEmotes([]),[activity.type]);

  useEffect(() =>
      setLatestEmotes(
        classRoom.emotes
          .reverse()
          .slice(0, 5)
          .reverse()
          .filter(
            emote => (emote.timestamp + 5000) > +new Date()
          )
      )
    ,[classRoom.emotes]);

  return (
    <PlayerWrapper {...{
      activity,
      classRoom,
      student,
      lesson,
      onProgress,
      volume,
      onSeek,
      player,
      pauseVideo,
      playVideo,
      seekTo,
      subtitles: lesson.subtitles,
      setCurrentlySeeking,
      setVolume,
      latestEmotes,
      onEnded
    }} />
  )
}

export default Index;
