import React from "react";

function Item({title, description, state, number, onClick }) {
    return (
        <li className="relative overflow-hidden lg:flex-1" key={number}>
            <div className="border border-gray-200 overflow-hidden border-t-0 rounded-b-md lg:border-0">
                <a href="#" className="group" {...{onClick}}>
                <span
                    className={`
                    ${{
                        completed: 'bg-transparent group-hover:bg-gray-200',
                        current: 'bg-teal-600',
                        upcoming: ''
                    }[state] }
                    absolute top-0 left-0 w-1 h-full lg:w-full lg:h-1 lg:bottom-0 lg:top-auto`}
                    aria-hidden="true"
                />
                    <span className="px-6 py-5 flex items-start text-sm font-medium lg:pl-9">


                  <span className="flex-shrink-0">
                    <span

                        className={`
                        ${{
                            completed: 'bg-teal-600',
                            current: 'border-teal-600 border-2',
                            upcoming: 'border-gray-300 border-2'
                        }[state] }
                        w-10 h-10 flex items-center justify-center rounded-full`}>

                        {state === 'completed' &&
                            <svg className="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                        }

                        {state !== 'completed' &&
                            <span className={`${state === 'current' ? 'text-teal-600' : 'text-gray-300'}`}>{number}</span>
                        }

                    </span>
                  </span>

                  <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                    <span className={`
                        ${{
                            completed: 'text-gray-900',
                            current: 'text-teal-600',
                            upcoming: 'text-gray-300'
                        }[state] }
                        text-xs font-semibold uppercase tracking-wide`}>
                            {title}
                    </span>
                    <span className={`
                        ${state === 'upcoming' ? 'text-gray-300' : 'text-gray-500'}
                        text-sm font-medium`}>
                            {description}
                    </span>
                  </span>
                </span>
                </a>

            </div>
        </li>
    )
}

function Wizard({ steps, handleStepClick }) {

  return (
    <div className="lg:border-t lg:border-b lg:border-gray-200">
      <nav
        className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
        aria-label="Progress"
      >

        <ol className="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none">

            {steps
                .map((data, key) =>
                    <Item
                        key={key}
                        onClick={() => handleStepClick(key)} { ...{...data, number: key+1}} />
                )
            }

        </ol>
      </nav>
    </div>
  );
}

export default Wizard;
