import React, { useContext } from 'react'
import Confetti from 'react-confetti'
import { Context } from '../context'
import  window from "global/window";

function ConfettiShower() {

  const
    { classRoom } = useContext(Context);

  return (
    <>
      {!!classRoom.makeItRain &&
      <Confetti
        className="z-20"
        width={window.innerWidth}
        height={window.innerHeight}
        colors={['#26BBC2', '#F55F4B', '#242954', '#FA9284', '#89D0D3', '#AD42EA', '#E05679']}
        numberOfPieces={classRoom.makeItRain}
        tweenDuration={30000}
        recycle={false}
        run={!!classRoom.makeItRain}
        onConfettiComplete={()=>classRoom.setRain(false)}
      />
      }
    </>
  )
}

export default ConfettiShower
