import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";

function AboutDropDown() {
  return (
    <motion.div
      initial={{ y: 30, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { ease: "easeOut", duration: 0.15 },
      }}
      exit={{
        y: 30,
        opacity: 0,
        transition: { ease: "easeIn", duration: 0.15 },
      }}
      style={{ width: "12rem" }}
      className="hidden xl:block absolute z-20 inset-x-0 transform mt-5 transform px-2 max-w-xs sm:px-0 -ml-10"
    >
      <div className="bg-white rounded-lg fd-shadow overflow-hidden border border-1 border-gray-300">
        <div className="relative bg-white px-2 py-3">
          <Link
            to={{ pathname: "/about_us" }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">
                Meet The Team
              </p>
            </div>
          </Link>

          <Link
            to={{ pathname: "/pricing" }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">Pricing</p>
            </div>
          </Link>

          <Link
            to={{ pathname: "/contact" }}
            className="m-1 p-2 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-navy-500">Contact Us</p>
            </div>
          </Link>
        </div>
      </div>
    </motion.div>
  );
}

export default AboutDropDown;
