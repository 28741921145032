import { useState, useEffect, useMemo } from 'react';
import sessionStorage from "sessionstorage";

export default ( socket ) => {

  const
    [waitingFor, setWaitingFor] = useState('party to start'),
    [isWaiting, setIsWaiting] = useState(true),
    [student, setStudent] = useState({
      lastUpdated:0,
      name: '',
      nameTaken: false,
      score: 0,
      emojiCharacter:'',
      emojiEmotion:'',
      emojiPosition:'',
      responses:[],
      isPrefect:false,
      waitingAt:0
    }),

    totalScore = useMemo(() =>
        !student.responses[0] ? '0' :
          student.responses
            .map(response => response.score)
            .reduce( (acc, curr) => acc + curr )
      , [student.responses]),

    lastScore = useMemo(() =>
        !student.responses[0] ? '0' :
          student.responses[student.responses.length - 1].score
      ,[student.responses]),

    setWaiting = (waitingFor, waitingAt) => {
      setIsWaiting(true);
      setWaitingFor(waitingFor);
      set('waitingAt', waitingAt);
    },

    set = (attribute, newState) => {
      setStudent(prev => ({ ...prev, [attribute]:newState } ));
      if(socket)
        socket.emit('update student', ({ ...student, [attribute]:newState, attribute }));
    };


  useEffect(()=>{
    if(socket) {
      socket.on('student changes', updatedStudent => {
        if (updatedStudent.lastUpdated > student.lastUpdated ) {
          setStudent(prev => ({...prev, ...updatedStudent}));

          // keep the latest name used in storage
          updatedStudent.name ?
            sessionStorage.setItem("name", updatedStudent.name):
            sessionStorage.removeItem("name");

          if(updatedStudent.doesntExist)
            sessionStorage.removeItem("name");
        }
      });
    }
  },[socket]);

  return { ...student, set, setWaitingFor, waitingFor, isWaiting, setIsWaiting, setWaiting, totalScore, lastScore };

};
