import HttpsRedirect from "react-https-redirect";
import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import "./website/assets/css/website.css";
import "./webapp/assets/css/party.css";
import Party from "./webapp/party";
import Homework from "./webapp/homework";
import Authoring from "./webapp/authoring";
import Content from "./webapp/courseList";
import Profile from "./webapp/courseList/profile";
import Home from "./website/pages/home/";
import Contact from "./website/pages/contact";
import Pricing from "./website/pages/pricing";
import Webinars from "./website/pages/webinars/";
import AboutUs from "./website/pages/aboutUs/";
import ForSchools from "./website/pages/forSchool";
import ForTeachers from "./website/pages/forTeachers/";
import Languages from "./website/pages/langauges/";
import Membership from "./website/pages/membership/";
import SampleLessons from "./website/pages/sampleLessons/";
import Testimonials from "./website/pages/testimonials";
import WhitePaper from "./website/pages/whitePaper";
import Sample from "./website/pages/sampleLessons/sample";
import Stats from "./website/pages/misc/stats";
import StatsLessons from "./website/pages/misc/statsLessons";
import window from "global/window";
import PrivacyPolicy from "./website/pages/misc/privacyPolicy";
import SimpleSteps from "./website/pages/misc/simple-steps-to-create-your-first-lesson";
import FirstPlayThrough from "./website/pages/misc/first-lesson-play-through";
import GuideToFindingFilmsAndVideoResources from "./website/pages/misc/guide-to-finding-films-and-video-resources";
import TermsAndConditions from "./website/pages/misc/termsConditions";
import GuideFindingFilms from "./website/pages/misc/guide-finding-films";
import Blog from "./website/pages/blog";
import DooDiversityWebinars from "./website/pages/webinars/dooDiveristyWebinars";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <HttpsRedirect>
      <Switch>
        <Route exact path="/stats-filmdoo-internal" component={Stats} />
        <Route
          exact
          path="/lesson-stats-filmdoo-internal"
          component={StatsLessons}
        />

        <Route
          exact
          path="/a-teachers-guide-to-finding-film-and-video-resources-for-lessons"
          component={GuideFindingFilms}
        />
        <Route
          exact
          path="/simple-steps-to-create-your-first-lesson"
          component={SimpleSteps}
        />
        <Route
          exact
          path="/guide-to-finding-films-and-video-resources"
          component={GuideToFindingFilmsAndVideoResources}
        />
        <Route
          exact
          path="/first-lesson-play-through"
          component={FirstPlayThrough}
        />

        <Route exact path="/" component={Home} />
        <Route exact path="/webinars" component={Webinars} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about_us" component={AboutUs} />
        <Route exact path="/for_schools" component={ForSchools} />
        <Route exact path="/for_teachers" component={ForTeachers} />
        <Route exact path="/languages" component={Languages} />
        <Route exact path="/membership" component={Membership} />
        <Route exact path="/sample_lessons" component={SampleLessons} />
        <Route exact path="/testimonials" component={Testimonials} />

        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/term-and-conditions"
          component={TermsAndConditions}
        />

        <Route
          exact
          path="/doo-diversity-webinars"
          component={DooDiversityWebinars}
        />

        <Route exact path="/sample/:listName" component={Sample} />

        <Route exact path="/white_paper/:file" component={WhitePaper} />

        <Route exact path="/authoring" component={Authoring} />
        <Route exact path="/authoring/:lessonId" component={Authoring} />

        <Route exact path="/party" component={Party} />
        <Route exact path="/party/:lessonId" component={Party} />

        <Route exact path="/homework/:lessonId" component={Homework} />

        <Route exact path="/blog/:slug" component={Blog} />

        <Route exact path="/content" component={Content} />
        <Route exact path="/profile/:profileId" component={Profile} />
        <Route exact path="/:profileId" component={Profile} />
      </Switch>
    </HttpsRedirect>
  );
}

export default App;
