import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import { useInView } from "react-intersection-observer";
import BgParticles from "../../components/bgParticles";

function LanguageHero({ setMasterShowCreateAccount }) {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <main className="w-full bg-gray-100 sloppy-bottom overflow-hidden pt-4 lg:pt-6">
      <BgParticles color="pink" />
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="relative max-w-6xl mx-auto lg:py-32 pt-24 pb-14 px-8"
      >
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div variants={variants.fromLeft} className=" col-span-9">
            <h1 className="lg:mt-2 text-5xl lg:text-6xl font-semibold text-navy-700 leading-tight mb-4">
              Languages & Film
            </h1>
          </motion.div>

          <motion.div
            variants={variants.fromLeft}
            className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left"
          >
            <p className=" text-navy-600 text-lg md:w-3/4 mx-auto lg:w-full font-bold">
              From emotional engagement to cultural immersion, films are a great
              and powerful way to help students stay engaged and motivated to
              learn a language.
            </p>
            <p className="text-navy-600 mt-3 lg:mt-5 text-lg md:w-3/4 mx-auto lg:w-full">
              Through dialects, slangs and colloquial expressions, regular
              engagement with authentic native content helps students learn a
              language the way it’s actually spoken!
            </p>
            <div className="mt-3 lg:mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 pt-4">
              <button
                onClick={setMasterShowCreateAccount}
                className="transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-lg transform focus:outline-none rounded-md fd-shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white bg-pink-600 md:py-4 md:text-lg md:px-10"
              >
                Activate Free Account
              </button>
            </div>
          </motion.div>
          <motion.div
            variants={variants.fromRight}
            className="mt-8 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center"
          >
            <div className="relative mx-auto w-full lg:max-w-lg">
              <iframe
                className="rounded-xl fd-shadow w-full h-72"
                src="https://www.youtube.com/embed/SQ_j110-Ves"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </motion.div>
        </div>
      </motion.div>
    </main>
  );
}

export default LanguageHero;
