import React, { useEffect } from "react";
import Images from "../../../assets/images";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import { Link } from "react-router-dom";

function WebinarCta({ slopped }) {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      className={`relative bg-pink-300 ${
        slopped ? "sloppy-bottom-navy" : ""
      } pb-8 overflow-hidden`}
    >
      <div className="h-56 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <motion.div
          variants={variants.fromLeft}
          className="w-full h-full bg-contain bg-fixed"
          style={{
            backgroundImage: Images.meeting,
          }}
        />
      </div>
      <div className="relative max-w-6xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <motion.div
          variants={variants.fromRight}
          className="md:ml-auto md:w-1/2 md:pl-10"
        >
          <h4 className="text-xs font-semibold uppercase tracking-wider text-navy-600">
            WEBINARS
          </h4>
          <h2 className="mt-2 text-navy-600 text-3xl font-extrabold tracking-tight sm:text-4xl">
            Don’t forget to join our educational, fun and high-impact online
            events!
          </h2>
          <p className="mt-3 text-lg text-navy-600">
            Attend our online events, webinars and roundtables to learn more
            about how schools, academies and teachers increased student
            engagement and accelerated outcomes using FilmDoo Academy.
          </p>
          <div className="mt-8 text-center md:text-left">
            <div className="inline-flex rounded-md fd-shadow">
              <Link
                to={{ pathname: "/webinars" }}
                className="transition duration-500 ease-in-out hover:bg-navy-900 hover:fd-shadow-black transform focus:outline-none inline-flex items-center justify-center px-5 py-3 border border-transparent fd-shadow-xl text-base font-medium rounded-md text-pink-100 bg-navy-800 hover:bg-navy-50"
              >
                Attend Our Free Webinars
              </Link>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default WebinarCta;
