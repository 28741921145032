import React, { useState } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import { Warning, Success } from "../../../components/common/alerts";
import BgParticles from "../../components/bgParticles";

function Contact() {
  const [firstName, setFirstName] = useState(""),
    [lastName, setLastName] = useState(""),
    [schoolName, setSchoolName] = useState(""),
    [position, setPosition] = useState(""),
    [phoneNumber, setPhoneNumber] = useState(""),
    [message, setMessage] = useState(""),
    [sent, setSent] = useState(false),
    [tcAgreed, setTcAgreed] = useState(false),
    [email, setEmail] = useState(""),
    [warning, setWarning] = useState(""),
    emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    handleFirstNameChange = (event) => {
      setFirstName(event.target.value);
    },
    handleLastNameChange = (event) => {
      setLastName(event.target.value);
    },
    handleSchoolNameChange = (event) => {
      setSchoolName(event.target.value);
    },
    handleEmailChange = (event) => {
      setEmail(event.target.value);
    },
    handlePositionChange = (event) => {
      setPosition(event.target.value);
    },
    handlePhoneNumberChange = (event) => {
      setPhoneNumber(event.target.value);
    },
    handleTcAgreedClick = (event) => {
      setTcAgreed((prev) => !prev);
      setWarning("");
    },
    handleTcMessageChange = (event) => {
      setMessage(event.target.value);
    },
    handleSubmitForm = (event) => {
      event.preventDefault();
      if (!tcAgreed) {
        setWarning("You must agree to the terms and conditions");
      } else if (!firstName || !lastName) {
        setWarning("Please enter your first and last name");
      } else if (!schoolName || !position) {
        setWarning("Please enter your school name and job title");
      } else if (!phoneNumber) {
        setWarning("Please enter your phone number");
      } else if (!message) {
        setWarning("Please enter your message");
      } else if (!emailRegex.test(email)) {
        setWarning("Please enter a valid email address");
      } else {
        fetch(`/api/v1/contacts/contactUs`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            firstname: firstName,
            lastname: lastName,
            company: schoolName,
            jobtitle: position,
            phone: phoneNumber,
            message,
            email,
          }),
        })
          .then((res) => res.json())
          .then(() => {
            setSent(true);
          });
      }
    };

  return (
    <>
      <Menu />
      <div className="relative bg-white mt-20">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-navy-600">
            <BgParticles />
          </div>
        </div>
        <div className="relative sm:pt-16 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="py-8 px-8">
            <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-navy-600">
                Contact Us
              </h2>
              <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                Get in touch to effortlessly create interactive video lessons
                today. We will aim to get back to you as promptly as possible.
              </p>
              <form
                action="#"
                method="POST"
                className="mt-9 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6"
              >
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-bold text-navy-600"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      name="email"
                      onChange={handleEmailChange}
                      placeholder="Email"
                      className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 grid sm:grid-cols-2 sm:gap-x-4">
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-bold text-navy-600"
                    >
                      Your Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="firstName"
                        onChange={handleFirstNameChange}
                        placeholder="First Name"
                        className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-bold text-navy-600"
                    >
                      &nbsp;
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="lastName"
                        onChange={handleLastNameChange}
                        placeholder="Last Name"
                        className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-bold text-navy-600"
                  >
                    Mobile Number / Your Direct Personal Number
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      onChange={handlePhoneNumberChange}
                      placeholder="Phone Number"
                      className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 grid sm:grid-cols-2 gap-x-4">
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="jobtitle"
                      className="block text-sm font-bold text-navy-600"
                    >
                      Job Title
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="jobtitle"
                        onChange={handlePositionChange}
                        placeholder="Job Title"
                        className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="position"
                      className="block text-sm font-bold text-navy-600"
                    >
                      Your Institution
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="position"
                        onChange={handleSchoolNameChange}
                        placeholder="School or Institution Name"
                        className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-bold text-navy-600"
                  >
                    Message
                  </label>
                  <div className="mt-1">
                    <textarea
                      name="message"
                      onChange={handleTcMessageChange}
                      placeholder="How can we help?"
                      className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div className="flex items-center mt-1 px-1">
                    <input
                      id="remember_me"
                      name="remember_me"
                      type="checkbox"
                      value={tcAgreed}
                      onChange={handleTcAgreedClick}
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember_me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      I agree to FilmDoo’s{" "}
                      <a
                        href="/term-and-conditions"
                        target="_blank"
                        className="text-teal-700 underline text-bold"
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        className="text-teal-700 underlined text-bold"
                      >
                        Privacy Policy
                      </a>
                    </label>
                  </div>
                </div>

                <div className="text-right sm:col-span-2">
                  <div className="overflow-hidden">
                    <Warning text={warning} show={!!warning} />
                    <Success
                      text="Thank you for your message we'll be in contact soon"
                      show={sent}
                    />
                  </div>
                  {!sent && (
                    <button
                      type="submit"
                      onClick={handleSubmitForm}
                      className="transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-lg transform focus:outline-none mt-2 inline-flex justify-center py-3 px-5 border border-transparent fd-shadow text-base font-medium rounded-md text-white bg-pink-600"
                    >
                      Submit message
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>

          <div className="relative mt-6 lg:mt-0 py-12 lg:py-4 px-12 sm:py-8 lg:max-w-4xl lg:mx-auto lg:bg-transparent mb-12">
            <div className="lg:pl-8 lg:pr-44">
              <h2 className="text-2xl font-extrabold text-teal-500 sm:text-3xl">
                Sales & Partnerthips
              </h2>
              <div className="mt-3">
                <p className="text-base text-navy-50 text-teal-20">
                  Our sales department is available to help you from 9am to 6pm
                  GMT, Monday through Friday.
                </p>
              </div>
              <div className="mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-6 w-6 text-navy-50"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                  </div>
                  <div className="ml-3 text-base text-white">
                    <a className="cursor-pointer" href="tel:+44-208-123-7749">
                      +44 208 123 7749
                    </a>
                    <p className="mt-1 text-xs  text-navy-50">
                      Mon-Fri 8am to 6pm GMT
                    </p>
                  </div>
                </div>
                <div className="mt-3 flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-6 w-6 text-navy-50"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <a
                      className="cursor-pointer text-base text-white"
                      href="mailto:info@filmdoo.academy"
                    >
                      info@filmdoo.academy
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:pl-8 lg:pr-44 mt-12">
              <h2 className="text-2xl font-extrabold text-teal-500 sm:text-3xl">
                Customer Support & Enquiries
              </h2>
              <div className="mt-3">
                <p className="text-base text-navy-50">
                  Our Customer Support department is available to help you from
                  9am to 6pm GMT, Monday through Friday.
                </p>
              </div>
              <div className="mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-6 w-6 text-navy-50"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                  </div>
                  <div className="ml-3 text-base text-white">
                    <a className="cursor-pointer" href="tel:+44-20-3290-2380">
                      +44 20 3290 2380
                    </a>
                    <p className="mt-1 text-xs  text-navy-50">
                      Mon-Fri 8am to 6pm GMT
                    </p>
                  </div>
                </div>
                <div className="mt-3 flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-6 w-6 text-navy-50"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <a
                      className="cursor-pointer text-base text-white"
                      href="mailto:sales@filmdoo.academy"
                    >
                      info@filmdoo.academy
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:pl-8 lg:pr-44 mt-12">
              <h2 className="text-2xl font-extrabold text-teal-500 sm:text-3xl">
                Are You A Filmmaker?
              </h2>
              <div className="mt-3">
                <p className="text-base text-navy-50">
                  Get in touch to find out how your films can be discovered and
                  seen by FilmDoo language learners around the world - while
                  finding a new monetisation opportunity!
                </p>
                <p className="text-base text-navy-50 mt-2">
                  Expand your audience, diversify your income and help language
                  learners through your film.
                </p>
                <a
                  className="text-base text-white pt-4 cursor-pointer underline block"
                  href="https://www.filmdoo.com/welcome-upload/"
                  target="_blank"
                >
                  Submit Your Film{" "}
                  <svg
                    className="-mr-1 ml-1 h-4 w-4 -mt-1 text-white inline"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
