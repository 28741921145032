import React from "react";

const Images = {
  alert: <img height={20} src={require("./alert.svg")} alt="Alert" />,
  blob1: require("./blob1.svg"),
  blob2: require("./blob2.svg"),
  blob3: require("./blob3.svg"),
  logo: (
    <img
      height={50}
      width={170}
      src={require("./logo.svg")}
      alt="FilmDoo Academy"
    />
  ),
  creativeEurope: (
    <img
      className="mx-auto"
      height={40}
      width={256}
      src={require("./creative_europe.svg")}
      alt="Creative Europe"
    />
  ),
  pencil: <img width={300} src={require("./pencil.png")} alt="pencil" />,
  laptopBoy: (
    <img
      className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
      src={require("./laptop-boy.png")}
      alt="Boy Using Laptop"
    />
  ),
  studentGroup: (
    <img
      className="rounded-lg fd-shadow-lg w-full"
      src={require("./student-group.jpg")}
      alt="Student Group"
    />
  ),
  withTeacher: (
    <img
      className="rounded-lg fd-shadow-lg w-full"
      src={require("./with-teacher.jpg")}
      alt="Teacher and Student"
    />
  ),
  meeting: "url(" + require("./meeting.png") + ")",
  sofaFriends: "url(" + require("./sofa-friends.png") + ")",
  eBook: (
    <img
      className="w-1/3 lg:w-full z-10 relative mx-auto"
      src={require("./ebook.png")}
      alt="eBook"
    />
  ),

  threeDee: {
    calendar: (
      <img className="h-72" src={require("./calendar.png")} alt="Calendar" />
    ),
  },

  blog: {
    one: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/1.jpg")}
        alt="students"
      />
    ),
    two: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/2.jpg")}
        alt="students"
      />
    ),
    three: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/3.jpg")}
        alt="students"
      />
    ),
    four: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/4.png")}
        alt="students"
      />
    ),
    step1: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/step-1.png")}
        alt="step-1"
      />
    ),
    step2: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/step-2.png")}
        alt="step-2"
      />
    ),
    step3: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/step-3.png")}
        alt="step-3"
      />
    ),
    step4: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/step-4.png")}
        alt="step-4"
      />
    ),
    step5: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/step-5.png")}
        alt="step-5"
      />
    ),
    finding1: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/finding-1.png")}
        alt="finding-1"
      />
    ),
    finding2: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/finding-2.png")}
        alt="finding-2"
      />
    ),
    finding3: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/finding-3.png")}
        alt="finding-3"
      />
    ),
    first1: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/first-1.png")}
        alt="first-1"
      />
    ),
    first2: (
      <img
        className="w-full md:w-1/2 mx-auto  my-6 rounded-lg"
        src={require("./blog/first-2.png")}
        alt="first-2"
      />
    ),
    first3: (
      <img
        className="w-full my-6 rounded-lg"
        src={require("./blog/first-3.png")}
        alt="first-3"
      />
    ),
    first4: (
      <img
        className="w-full md:w-1/2 mx-auto  my-6 rounded-lg"
        src={require("./blog/first-4.png")}
        alt="first-4"
      />
    ),
    first5: (
      <img
        className="w-full  md:w-2/3 mx-auto my-6 rounded-lg"
        src={require("./blog/first-5.png")}
        alt="first-5"
      />
    ),
    first6: (
      <img
        className="w-full  md:w-1/2 mx-auto  my-6 rounded-lg"
        src={require("./blog/first-6.png")}
        alt="first-6"
      />
    ),
  },

  icons: {
    analytics: require("./icons/analytics.gif"),
    browser: require("./icons/browser.gif"),
    diploma: require("./icons/diploma.gif"),
    globe: require("./icons/globe.gif"),
    news: require("./icons/news.gif"),
    chat: require("./icons/chat.gif"),
    shield: require("./icons/shield.gif"),
    ticket: require("./icons/ticket.gif"),
    heart: require("./icons/heart.gif"),
  },

  profiles: {
    englishGang: require("./profiles/english-gang.svg"),
    englishTodayIndonesia: require("./profiles/english-today-indonesia.svg"),
    ileap: require("./profiles/ileap.svg"),
    natwest: require("./profiles/natwest.svg"),
    kbc: require("./profiles/kbc.png"),
    barclays: require("./profiles/barclays.jpeg"),
    citi: require("./profiles/citi.png"),
    transcom: require("./profiles/transcom.svg"),
    spu: require("./flags/spu.svg"),
    globish: require("./profiles/globish.svg"),
    matthayom: require("./profiles/matthayom.svg"),
    pcccr: require("./profiles/pcccr.svg"),
    plapakwit: require("./profiles/plapakwit.svg"),
    starfish: require("./profiles/starfish.png"),
  },

  students: {
    frustrated1: (
      <img
        className="rounded-lg fd-shadow-lg w-full"
        src={require("./students/frustrated1.png")}
        alt="Frustrated Student"
      />
    ),
    frustrated2: (
      <img
        className="rounded-lg fd-shadow-lg w-full"
        src={require("./students/frustrated2.png")}
        alt="Frustrated Student"
      />
    ),
    a: (
      <img
        className="rounded-lg fd-shadow-lg w-full"
        src={require("./students/1.png")}
        alt="Student"
      />
    ),
    b: (
      <img
        className="rounded-lg fd-shadow-lg w-full"
        src={require("./students/2.png")}
        alt="Student"
      />
    ),
    c: (
      <img
        className="rounded-lg fd-shadow-lg w-full"
        src={require("./students/3.png")}
        alt="Student"
      />
    ),
    d: (
      <img
        className="rounded-lg fd-shadow-lg w-full"
        src={require("./students/4.png")}
        alt="Student"
      />
    ),
    e: (
      <img
        className="rounded-lg fd-shadow-lg w-full"
        src={require("./students/5.png")}
        alt="Student"
      />
    ),
    f: (
      <img
        className="rounded-lg fd-shadow-lg w-full"
        src={require("./students/6.png")}
        alt="Student"
      />
    ),
    g: (
      <img
        className="rounded-lg fd-shadow-lg w-full"
        src={require("./students/7.png")}
        alt="Student"
      />
    ),
    h: (
      <img
        className="rounded-lg fd-shadow-lg w-full"
        src={require("./students/8.png")}
        alt="Student"
      />
    ),
  },

  team: {
    dave: require("./team/dave.png"),
    rainie: require("./team/rainie.png"),
    christina: require("./team/christina.png"),
    julio: require("./team/julio.png"),
    charles: require("./team/charles.png"),
    varada: require("./team/varada.png"),
    rob: require("./team/rob.png"),
  },

  quoteBg: [
    "url(" + require("./quoteBg/1.png") + ")",
    "url(" + require("./quoteBg/2.png") + ")",
    "url(" + require("./quoteBg/3.png") + ")",
    "url(" + require("./quoteBg/4.png") + ")",
  ],

  recommended: {
    edTechImpact: (
      <img
        className="mx-auto h-18 md:h-48"
        src={require("./edtech-impact.svg")}
        alt="EdTech Impact"
      />
    ),
    uclEducate: (
      <img
        className="mx-auto h-12 md:h-32 mt-6 md:mt-8"
        src={require("./ucl-educate.svg")}
        alt="UCL Educate"
      />
    ),
    launchpad: (
      <img
        className="mx-auto h-14 md:h-36 mt-3 md:mt-5"
        src={require("./launchpad.svg")}
        alt="Launchpad"
      />
    ),
    csvCup: (
      <img
        className="mx-auto h-12 md:h-36 mt-2 md:mt-3"
        src={require("./csv-cup.svg")}
        alt="Launchpad"
      />
    ),
  },

  filmdoo: {
    a: require("./filmdoo/1.jpg"),
    b: require("./filmdoo/2.png"),
    c: require("./filmdoo/3.jpg"),
    d: require("./filmdoo/4.png"),
  },

  testimonials: {
    ana: require("./testimonials/ana.png"),
    chris: require("./testimonials/chris.png"),
    francisco: require("./testimonials/francisco.png"),
    lyvia: require("./testimonials/lyvia.png"),
    a: require("./testimonials/1.png"),
    b: require("./testimonials/2.png"),
    c: require("./testimonials/3.png"),
    d: require("./testimonials/4.png"),
    e: require("./testimonials/5.png"),
    f: require("./testimonials/6.png"),
    alrick: require("./testimonials/alrick.png"),
    katherine: require("./testimonials/katherine.png"),
    stefania: require("./testimonials/stefania.png"),
  },

  flags: {
    arabic: require("./flags/arabic.svg"),
    chinese: require("./flags/chinese.svg"),
    computer_science: require("./flags/computer_science.svg"),
    dutch: require("./flags/dutch.svg"),
    danish: require("./flags/denmark.svg"),
    english: require("./flags/english.svg"),
    "business-english": require("./flags/english.svg"),
    english_literature: require("./flags/english_literature.svg"),
    food_technology: require("./flags/food_technology.svg"),
    french: require("./flags/french.svg"),
    geography: require("./flags/geography.svg"),
    german: require("./flags/german.svg"),
    history: require("./flags/history.svg"),
    korean: require("./flags/korean.svg"),
    luxembourgish: require("./flags/luxembourgish.svg"),
    maths: require("./flags/maths.svg"),
    media_studies: require("./flags/media_studies.svg"),
    portuguese: require("./flags/portuguese.svg"),
    spanish: require("./flags/spanish.svg"),
    virus: require("./flags/virus.svg"),
    diversity: require("./flags/diversity.svg"),
  },

  webinars: [
    require("./webinars/1.png"),
    require("./webinars/2.png"),
    require("./webinars/3.png"),
    require("./webinars/4.png"),
    require("./webinars/5.png"),
    require("./webinars/6.png"),
    require("./webinars/7.png"),
    require("./webinars/8.png"),
    require("./webinars/9.png"),
    require("./webinars/10.png"),
    require("./webinars/11.png"),
    require("./webinars/12.png"),
  ],
};

export default Images;
