import React, { useEffect, useState } from "react";
import MiniMenu from "../../components/common/miniMenu";
import CheckBox from "../components/icons/checkbox";
import { motion, AnimatePresence } from "framer-motion";
import Select from "react-select";
import window from "global/window";
import sessionStorage from "sessionstorage";
import Hashids from "hashids/cjs";
const hashids = new Hashids();

function Content(props) {
  const [thisUser, setThisUser] = useState({}),
    [editPermissions, setEditPermissions] = useState(false),
    [lessons, setLessons] = useState([]),
    [tags, setTags] = useState([]),
    [selectedTags, setSelectedTags] = useState([]),
    [user, setUser] = useState(""),
    [users, setUsers] = useState([]),
    [language, setLanguage] = useState(""),
    [languageLevel, setLanguageLevel] = useState(""),
    [timestamp, setTimestamp] = useState(0),
    [showCount, setShowCount] = useState(10),
    languages = [
      { value: "afrikaans", label: "Afrikaans" },
      { value: "arabic", label: "Arabic" },
      { value: "chinese", label: "Chinese" },
      { value: "czech", label: "Czech" },
      { value: "danish", label: "Danish" },
      { value: "dutch", label: "Dutch" },
      { value: "english", label: "English" },
      { value: "french", label: "French" },
      { value: "german", label: "German" },
      { value: "greek", label: "Greek" },
      { value: "indonesian", label: "Indonesian" },
      { value: "italian", label: "Italian" },
      { value: "japanese", label: "Japanese" },
      { value: "korean", label: "Korean" },
      { value: "luxembourgish", label: "Luxembourgish" },
      { value: "malay", label: "Malay" },
      { value: "mongolian", label: "Mongolian" },
      { value: "polish", label: "Polish" },
      { value: "portuguese", label: "Portuguese" },
      { value: "spanish", label: "Spanish" },
      { value: "swedish", label: "Swedish" },
      { value: "russian", label: "Russian" },
      { value: "thai", label: "Thai" },
    ],
    languageLevels = [
      { value: "a1", label: "Beginner (A1)" },
      { value: "a2", label: "Elementary (A2)" },
      { value: "b1", label: "Intermediate (B1)" },
      { value: "b2", label: "Upper Intermediate (B2)" },
      { value: "c1", label: "Advanced (C1)" },
      { value: "c2", label: "Proficiency (C2)" },
    ],
    handleLanguageChange = (newLanguage) => {
      setLanguage(newLanguage);
    },
    handleTagChange = (newTags) => {
      console.log(newTags);
      setSelectedTags(newTags);
    },
    handleLanguageLevelChange = (newLanguageLevel) => {
      setLanguageLevel(newLanguageLevel);
    },
    handleUserChange = (newUser) => {
      setUser(newUser);
    },
    handleClickUsername = (usernameText) => {
      setUser(users.filter((user) => user.label === usernameText)[0]);
    },
    handleClickLanguage = (languageText) => {
      setLanguage(
        languages.filter((language) => language.label === languageText)[0]
      );
    },
    handleClickLanguageLevel = (languageLevelText) => {
      setLanguageLevel(
        languageLevels.filter(
          (languageLevel) => languageLevel.label === languageLevelText
        )[0]
      );
    },
    handleClickHashTag = (hashTagText) => {
      // if it's already a selected tag ignore it
      if (
        selectedTags &&
        selectedTags.filter((tag) => tag.label === hashTagText)[0]
      )
        return;
      setSelectedTags((prev) =>
        prev && prev[0]
          ? [...prev, tags.filter((tag) => tag.label === hashTagText)[0]]
          : [tags.filter((tag) => tag.label === hashTagText)[0]]
      );
    },
    handleEditClick = (id) => {
      window.location.href = "/authoring/" + id;
    },
    handleDeleteClick = (id) => {
      fetch("/api/v1/lesson/delete/" + id);
      setLessons((prev) => prev.filter((lesson) => lesson._id !== id));
    },
    handleDuplicateClick = (id) => {
      fetch("/api/v1/lesson/duplicate/" + id + "/" + thisUser.id)
        .then((response) => response.json())
        .then(
          (newLesson) => (window.location.href = "/authoring/" + newLesson.id)
        );
    },
    [updatedFromUrl, setUpdatedFromUrl] = useState(false),
    updateUrl = () => {
      if (!updatedFromUrl) return;

      let query = [];

      if (selectedTags && selectedTags[0])
        query.push({
          key: "tags",
          value: selectedTags.map((t) => t.value).join(","),
        });

      if (user) query.push({ key: "author", value: user.label });

      if (language) query.push({ key: "language", value: language.value });

      if (languageLevel)
        query.push({ key: "languageLevel", value: languageLevel.value });

      let search = query[0]
        ? "?" + query.map((i) => i.key + "=" + i.value).join("&")
        : "";
      window.history.replaceState("", "", "/content" + search);
    },
    fetchLessons = () => {
      fetch("/api/v1/lesson/getAll", {
        body: JSON.stringify({
          user: user ? user.value : "",
          languageLevel: languageLevel ? languageLevel.value : "",
          language: language ? language.value : "",
          tags:
            selectedTags && selectedTags[0]
              ? selectedTags.map((t) => t.value).join("|")
              : "",
          timestamp: +new Date(),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          // sending and checking timestamp to remove race conditions
          if (parseInt(data.timestamp) > timestamp) {
            setLessons(data.lessons);
            setShowCount(10);
            setTimestamp(parseInt(timestamp));
          }
        });

      updateUrl();
    },
    updateFromUrl = () => {
      const queryString = props.location.search.replace("?", "").split("&");

      queryString.forEach((query) => {
        let parts = query.split("=");
        if (parts[0] === "author")
          setUser(users.filter((user) => user.label === parts[1])[0]);
        if (parts[0] === "language")
          setLanguage(
            languages.filter((language) => language.value === parts[1])[0]
          );
        if (parts[0] === "languageLevel")
          setLanguageLevel(
            languageLevels.filter(
              (languageLevel) => languageLevel.value === parts[1]
            )[0]
          );
        if (parts[0] === "tags") {
          let tagArray = parts[1].split(",");
          setSelectedTags(tags.filter((tag) => tagArray.includes(tag.value)));
        }
      });

      // push it to the back of the que to make sure they're updated before anything else happens
      setUpdatedFromUrl(true);
    },
    loadMore = () => {
      setShowCount((prev) => prev + 10);
    },
    handleLoadLessonClick = (lessonId) => {
      // ensure they don't load a previous game
      sessionStorage.removeItem("classRoomName");
      sessionStorage.removeItem("name");
      window.open(`/party/${hashids.encode(lessonId)}`, "_blank").focus();
    };

  const customTheme = (theme) => ({
    ...theme,
    padding: 1,
    colors: {
      ...theme.colors,
      primary25: "#F5C9D6",
      primary: "#e16b8e",
      activeText: "red",
    },
  });

  const setFeatured = (value, type) => {
    setLanguageLevel("");
    setUser(users.filter((user) => user.label === "filmdoo")[0]);
    setSelectedTags([]);
    setLanguage("");

    if (type === "language") {
      handleClickLanguage(value);
    } else {
      setSelectedTags([tags.filter((tag) => tag.label === value)[0]]);
    }
  };

  const featuredLanguageLessons = [
    "Arabic",
    "Chinese",
    "Danish",
    "Dutch",
    "English",
    "French",
    "German",
    "Korean",
    "Luxembourgish",
    "Portuguese",
    "Spanish",
  ];

  const featuredOtherLessons = [
    { title: "COVID 19", tags: "#covid" },
    { title: "History", tags: "#history" },
    {
      title: "Media Studies",
      tags: "#mediaStudies",
    },
    {
      title: "Diversity & Inclusion",
      tags: "#diversity",
    },
    { title: "Maths", tags: "#maths" },
    {
      title: "English Literature",
      tags: "#englishLiterature",
    },
    { title: "Geography", tags: "#geography" },
    {
      title: "Food Technology",
      tags: "#foodTechnology",
    },
    {
      title: "Computer Science",
      tags: "#computerScience",
    },
  ];

  useEffect(() => {
    if (updatedFromUrl) {
      fetchLessons();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [user, language, languageLevel, selectedTags, updatedFromUrl]);

  // update from url once the users and tags are loaded
  useEffect(() => {
    if (tags[0] && users[0]) updateFromUrl();
  }, [tags, users]);

  useEffect(() => {
    if (window.localStorage) {
      const user = JSON.parse(window.localStorage.getItem("fda_user"));

      // if they created an account before 10th May set their creation date to 24th July
      // this is so they can be on new free trail for two weeks.
      // after those two weeks we can assume these people are no longer on a free trial and rewrite this funciton
      const createdDate = user.date_created
        ? new Date(user.date_created).toJSON().slice(0, 10)
        : 0;
      const previousSiteCreationDate = "2021-05-11";
      if (!createdDate || createdDate < previousSiteCreationDate) {
        user.date_created = new Date("2021-07-24");
      }

      setThisUser(user);

      const createdTimeStamp = Date.parse(user.date_created);
      const todayTimestamp = +new Date();
      const daysLeft = Math.floor(
        14 +
          createdTimeStamp / (1000 * 3600 * 24) -
          todayTimestamp / (1000 * 3600 * 24)
      );

      setEditPermissions(user.permission_level > 1 || daysLeft > 0);
    }

    fetch("/api/v1/info/getAllTags", {
      headers: {
        "Cache-Control": "no-cache",
      },
    })
      .then((res) => res.json())
      .then((tags) => {
        setTags(tags);
      });

    fetch("api/v1/user/getAll", {
      headers: {
        "Cache-Control": "no-cache",
      },
    })
      .then((res) => res.json())
      .then((users) => {
        setUsers(
          users.map((user) => ({
            value: user.id,
            label: user.username,
          }))
        );
      });
  }, []);

  return (
    <div className="flex flex-col" style={{ minHeight: "100vh" }}>
      <MiniMenu withCreateButton={true} />

      <div className="flex flex-row flex-1 bg-gray-100 h-full items-stretch">
        <div className="flex-1 bg-white fd-shadow max-w-xs">
          <div className="h-full bg-white">
            <div
              className={`${user ? "bg-pink-20" : "bg-gray-20"} px-4 pt-4 pb-2`}
            >
              <label
                htmlFor="language-level"
                className="block text-sm font-medium text-navy-700 mb-1"
              >
                <b>Author</b>
              </label>
              <Select
                value={user}
                onChange={handleUserChange}
                isClearable={true}
                defaultValue="english"
                theme={customTheme}
                className="fd-shadow-sm focus:ring-indigo-500 text-navy-900 focus:border-indigo-500 block w-full sm:text-sm border-navy-300 rounded-md"
                options={users}
              />
            </div>

            <div
              className={`${language ? "bg-pink-20" : "bg-gray-20"} px-4 py-2`}
            >
              <label
                htmlFor="language-level"
                className="block text-sm font-medium text-navy-700 mb-1"
              >
                <b>Language</b>
              </label>
              <Select
                value={language}
                onChange={handleLanguageChange}
                defaultValue="english"
                isClearable={true}
                theme={customTheme}
                className="fd-shadow-sm focus:ring-indigo-500 text-navy-900 focus:border-indigo-500 block w-full sm:text-sm border-navy-300 rounded-md"
                options={languages}
              />
            </div>

            <div
              className={`${
                languageLevel ? "bg-pink-20" : "bg-gray-20"
              } px-4 py-2`}
            >
              <label
                htmlFor="language-level"
                className="block text-sm font-medium text-navy-700 mb-1"
              >
                <b>Language Level</b>
              </label>
              <Select
                value={languageLevel}
                onChange={handleLanguageLevelChange}
                defaultValue="english"
                isClearable={true}
                theme={customTheme}
                className="fd-shadow-sm focus:ring-indigo-500 text-navy-900 focus:border-indigo-500 block w-full sm:text-sm border-navy-300 rounded-md"
                options={languageLevels}
              />
            </div>

            <div
              className={`${
                selectedTags && selectedTags[0] ? "bg-pink-20" : "bg-gray-20"
              } px-4 pt-2 pb-6`}
            >
              <label
                htmlFor="language-level"
                className="block text-sm font-medium text-navy-700 mb-1"
              >
                <b>Hash Tags</b>
              </label>
              <Select
                isMulti
                value={selectedTags}
                onChange={handleTagChange}
                options={tags}
                theme={customTheme}
                className="fd-shadow-sm focus:ring-indigo-500 text-navy-900 focus:border-indigo-500 block w-full sm:text-sm border-navy-300 rounded-md"
              />
            </div>

            <div className="px-6 pb-2 pt-4 border-t border-gray-200  ">
              <div className="block text-sm font-medium text-pink-700">
                Featured Language Lessons
              </div>
            </div>
            <div>
              {featuredLanguageLessons.map((lang) => (
                <div
                  onClick={() => setFeatured(lang, "language")}
                  className="cursor-pointer group flex items-center px-6 text-sm text-navy-300 hover:text-pink-500"
                >
                  <span className="truncate">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3 w-3 inline-block mr-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    {lang}
                  </span>
                </div>
              ))}
            </div>

            <div className="px-6 pb-2 pt-4">
              <div className="block text-sm font-medium text-pink-700">
                Other Featured Classes
              </div>
            </div>
            <div>
              {featuredOtherLessons.map(({ title, tags }) => (
                <div
                  onClick={() => setFeatured(tags)}
                  className="cursor-pointer group flex items-center px-6 text-sm text-navy-300 hover:text-pink-500"
                >
                  <span className="truncate">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3 w-3 inline-block mr-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    {title}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="max-w-5xl w-full mx-auto px-4 sm:px-6 lg:px-8 mt-6">
          <AnimatePresence exitBeforeEnter>
            {lessons.slice(0, showCount).map((lesson) => (
              <motion.div
                initial={{ opacity: 0, y: 100, scale: 0.9 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9, transition: { duration: 0.1 } }}
                key={lesson._id}
                className="w-full mx-auto relative bg-white flex flex-wrap rounded-lg z-2 fd-shadow-lg overflow-hidden mb-8"
              >
                <div className="absolute bottom-0 left-0 ml-2 mb-3 flex flex-row">
                  {editPermissions &&
                    thisUser &&
                    thisUser.username === lesson.author && (
                      <button
                        onClick={() => handleEditClick(lesson._id)}
                        type="button"
                        className="show-hover-parent mr-2 flex justify-center px-4 py-2 border fd-shadow-sm text-xs font-medium rounded text-navy-700 bg-white focus:outline-none"
                      >
                        <svg
                          className="-ml-1 mr-2 h-4 w-4 text-navy-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                        <span>Edit</span>
                      </button>
                    )}

                  {editPermissions && (
                    <button
                      onClick={() => handleDuplicateClick(lesson._id)}
                      type="button"
                      className="show-hover-parent mr-2 flex justify-center px-4 py-2 border fd-shadow-sm text-xs font-medium rounded text-navy-700 bg-white"
                    >
                      <svg
                        className="-ml-1 mr-2 h-4 w-4 text-navy-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                        />
                      </svg>
                      <span>Duplicate</span>
                    </button>
                  )}

                  {thisUser &&
                    thisUser.username === lesson.author &&
                    editPermissions && (
                      <button
                        onClick={() => handleDeleteClick(lesson._id)}
                        type="button"
                        className="show-hover-parent mr-2 flex justify-center px-4 py-2 border fd-shadow-sm text-xs font-medium rounded text-coral-700 bg-white"
                      >
                        <svg
                          className="-ml-1 mr-2 h-4 w-4 text-coral-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                        <span>Delete</span>
                      </button>
                    )}
                </div>

                <div className="w-full md:w-1/3 rounded-t">
                  <img
                    src={lesson.thumbnail}
                    className="h-full w-full fd-shadow"
                    alt={lesson.name}
                  />
                </div>
                <div className="w-full md:w-1/3 flex flex-col flex-grow flex-shrink">
                  <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden fd-shadow-lg">
                    <div className="mt-4 inline-block px-3 float-right">
                      {lesson.info && lesson.info.language && (
                        <span
                          onClick={() =>
                            handleClickLanguage(lesson.info.language.label)
                          }
                          className="cursor-pointer rounded py-1 px-3 bg-teal-300 text-white mo-medium text-sm ml-2"
                        >
                          {lesson.info.language.label}
                        </span>
                      )}
                      {lesson.info && lesson.info.languageLevel && (
                        <span
                          onClick={() =>
                            handleClickLanguageLevel(
                              lesson.info.languageLevel.label
                            )
                          }
                          className="cursor-pointer rounded py-1 px-3 bg-teal-300 text-white mo-medium text-sm ml-2"
                        >
                          {lesson.info.languageLevel.label}
                        </span>
                      )}
                    </div>
                    <h3 className="pf-medium fd-grey w-full font-bold text-2xl text-navy-700 pt-4 px-6">
                      {lesson.name}
                    </h3>
                    <div className="text-navy-500 px-6 text-xs mb-3 italic">
                      by{" "}
                      <a
                        onClick={() => handleClickUsername(lesson.author)}
                        className="text-teal-600 cursor-pointer"
                      >
                        {lesson.author}
                      </a>
                    </div>
                    <p className="mb-1 w-5/6 text-navy-500 text-xs md:text-sm px-6">
                      {lesson.description}
                    </p>
                    <p className="mb-2 w-5/6 text-gray-600 text-xs md:text-sm px-6 pb-2">
                      {lesson.info &&
                        lesson.info.tags.map((tag) => (
                          <a
                            onClick={() => handleClickHashTag(tag.label)}
                            className="mr-2 text-teal-600 cursor-pointer"
                          >
                            {tag.label}
                          </a>
                        ))}
                    </p>
                  </div>
                  <div className="flex-none bg-gray-100 mt-auto bg-white rounded-b rounded-t-none overflow-hidden fd-shadow-lg p-3">
                    <div className="flex items-center justify-between">
                      <div className="flex pl-4">
                        <svg
                          className="h-5 w-5 text-navy-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <p className="text-navy-600 mo-light text-xs md:text-sm flex-1 ml-1">
                          {Math.round(
                            (parseInt(lesson.lesson.endTime) -
                              parseInt(lesson.lesson.startTime)) /
                              60
                          )}{" "}
                          MINUTES
                        </p>
                      </div>
                      <div className="flex pl-4">
                        <div className="relative h-5 w-5 text-navy-600">
                          <CheckBox checked={true} small={true} />
                        </div>
                        <p className="text-navy-600 mo-light text-xs md:text-sm flex-1 ml-1">
                          {lesson.lesson.activities.length} QUESTIONS
                        </p>
                      </div>

                      <div className="flex pl-4" />

                      <button
                        onClick={() => handleLoadLessonClick(lesson._id)}
                        className="shadow-sm h-full inline-block px-8 py-2 bg-teal-600 flex text-white text-sm uppercase rounded mo-medium align-middle font-semibold fd-transition"
                      >
                        <p className="flex-1 mr-3">Play Now</p>
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>

          {showCount < lessons.length && (
            <div className="my-8 text-center">
              <button
                onClick={loadMore}
                className="mx-auto rounded-md fd-shadow items-center px-8 py-3 border border-transparent text-base font-medium text-white bg-pink-600 md:py-4 md:text-lg md:px-10 focus:outline-none"
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Content;
