import React, { useState, useEffect } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { Warning } from "../../../components/common/alerts";
import { secondsToHms } from "../utils";

function Step4({
  url,
  startTime,
  endTime,
  interactions,
  handleStepClick,
  info,
  exit,
  setInfo,
}) {
  const [forceUpdate, setForceUpdate] = useState(false),
    [showWarning, setShowWarning] = useState(""),
    [newSavedTags, setNewSavedTags] = useState([]),
    languages = [
      { value: "afrikaans", label: "Afrikaans" },
      { value: "arabic", label: "Arabic" },
      { value: "chinese", label: "Chinese" },
      { value: "czech", label: "Czech" },
      { value: "danish", label: "Danish" },
      { value: "dutch", label: "Dutch" },
      { value: "english", label: "English" },
      { value: "french", label: "French" },
      { value: "german", label: "German" },
      { value: "greek", label: "Greek" },
      { value: "indonesian", label: "Indonesian" },
      { value: "italian", label: "Italian" },
      { value: "japanese", label: "Japanese" },
      { value: "korean", label: "Korean" },
      { value: "luxembourgish", label: "Luxembourgish" },
      { value: "malay", label: "Malay" },
      { value: "mongolian", label: "Mongolian" },
      { value: "polish", label: "Polish" },
      { value: "portuguese", label: "Portuguese" },
      { value: "spanish", label: "Spanish" },
      { value: "swedish", label: "Swedish" },
      { value: "russian", label: "Russian" },
      { value: "thai", label: "Thai" },
    ],
    [tags, setTags] = useState([]),
    levels = [
      { value: "a1", label: "Beginner (A1)" },
      { value: "a2", label: "Elementary (A2)" },
      { value: "b1", label: "Intermediate (B1)" },
      { value: "b2", label: "Upper Intermediate (B2)" },
      { value: "c1", label: "Advanced (C1)" },
      { value: "c2", label: "Proficiency (C2)" },
    ],
    camelize = (str) => {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
    },
    updateInfo = (prop, value) => {
      setInfo((prev) => {
        prev[prop] = value;
        return prev;
      });
      setForceUpdate((prev) => !prev);
    },
    handleSubtitlesChange = (event) => {
      updateInfo("subtitles", event.target.checked ? "en" : "");
    },
    handleDescriptionChange = (event) =>
      updateInfo("description", event.target.value),
    handleTitleChange = (event) => updateInfo("title", event.target.value),
    handleTagChange = (tags) => {
      tags.map((tag) => {
        // add any new tags to the database
        if (tag.__isNew__) {
          tag.value = camelize(tag.value.replace("#", ""));
          tag.label = camelize(tag.value);

          if (!tag.label.startsWith("#")) tag.label = "#" + tag.label;

          if (!newSavedTags.includes(tag.value)) {
            setNewSavedTags((prev) => [...prev, tag.value]);
            fetch("/api/v1/info/newTag", {
              body: JSON.stringify({
                label: tag.label,
                value: tag.value,
              }),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              method: "POST",
            });
          }
        }
        return tag;
      });

      updateInfo("tags", tags);
    },
    handleLanguageChange = (language) => {
      updateInfo("language", language);
    },
    handleLanguageLevelChange = (languageLevel) => {
      updateInfo("languageLevel", languageLevel);
    };

  // select and priorotise warnings
  useEffect(() => {
    if (!info.title) setShowWarning("Make sure you add a title");
    else if (!info.description)
      setShowWarning("Make sure you add a description");
    else setShowWarning("");
  }, [info, forceUpdate]);

  // get all tags on load
  useEffect(() => {
    fetch("/api/v1/info/getAllTags")
      .then((res) => res.json())
      .then((tags) => {
        setTags(tags);
      });
  }, []);

  return (
    <form className="space-y-2 divide-y divide-navy-200" {...{ forceUpdate }}>
      <div>
        <h3 className="text-3xl font-extrabold font-medium text-navy-900">
          4. Add Title
        </h3>

        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-navy-900">
            Summary
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-navy-500">
            Please review details before saving
          </p>
        </div>

        <div className="px-4 pb-2 sm:px-6 sm:grid sm:grid-cols-4 sm:gap-4">
          <h5 className="text-sm font-medium text-navy-500">Url</h5>
          <p className="mt-1 text-sm text-navy-900 sm:col-span-3">{url}</p>
        </div>

        <div className="px-4 pb-2 sm:px-6 sm:grid sm:grid-cols-4 sm:gap-4">
          <h5 className="text-sm font-medium text-navy-500">Start Time</h5>
          <p className="mt-1 text-sm text-navy-900 sm:col-span-3">
            {secondsToHms(startTime)}
          </p>
        </div>

        <div className="px-4 pb-2 sm:px-6 sm:grid sm:grid-cols-4 sm:gap-4">
          <h5 className="text-sm font-medium text-navy-500">End Time</h5>
          <p className="mt-1 text-sm text-navy-900 sm:col-span-3">
            {secondsToHms(endTime)}
          </p>
        </div>

        <div className="px-4 pb-2 sm:px-6 sm:grid sm:grid-cols-4 sm:gap-4">
          <h5 className="text-sm font-medium text-navy-500">Total Play Time</h5>
          <p className="mt-1 text-sm text-navy-900 sm:col-span-3">
            {secondsToHms(endTime - startTime)}
          </p>
        </div>

        <div className="px-4 pb-2 sm:px-6 sm:grid sm:grid-cols-4 sm:gap-4">
          <h5 className="text-sm font-medium text-navy-500">
            Total Interactions
          </h5>
          <p className="mt-1 text-sm text-navy-900 sm:col-span-3">
            {interactions.length}
          </p>
        </div>
      </div>

      <div className="px-4 py-5 sm:px-6 mb-3">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-navy-700"
        >
          Course Title
        </label>
        <input
          onChange={handleTitleChange}
          value={info.title}
          type="text"
          name="title"
          className="mt-1 block w-full border border-navy-300 rounded-md inset-shadow py-2 px-3 focus:outline-none focus:ring-navy-900 focus:border-navy-900 sm:text-sm"
        />

        <label
          htmlFor="description"
          className="block text-sm font-medium text-navy-700 mt-7"
        >
          Description
        </label>
        <textarea
          type="text"
          name="description"
          value={info.description}
          onChange={handleDescriptionChange}
          rows="3"
          className="mt-1 block w-full border border-navy-300 rounded-md inset-shadow py-2 px-3 focus:outline-none focus:ring-navy-900 focus:border-navy-900 sm:text-sm"
        />

        <div className="sm:grid sm:grid-cols-4 sm:gap-6 mt-7 mb-5">
          <div>
            <label
              htmlFor="language"
              className="block text-sm font-medium text-navy-700"
            >
              Language
            </label>
            <Creatable
              value={info.language}
              onChange={handleLanguageChange}
              defaultValue="english"
              className="fd-shadow-sm mt-1 focus:ring-indigo-500 text-navy-900 focus:border-indigo-500 block w-full sm:text-sm border-navy-300 rounded-md"
              options={languages}
            />
          </div>
          <div>
            <label
              htmlFor="language-level"
              className="block text-sm font-medium text-navy-700"
            >
              Language Level
            </label>
            <div className="mt-1">
              <Select
                name="language-level"
                className="fd-shadow-sm focus:ring-indigo-500 text-navy-900 focus:border-indigo-500 block w-full sm:text-sm border-navy-300 rounded-md"
                value={info.languageLevel}
                onChange={handleLanguageLevelChange}
                options={levels}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="language-level"
              className="text-sm font-medium text-navy-700"
            >
              Extra Tags <span className="text-xs">(select up to 10)</span>
            </label>
            <Creatable
              isMulti
              value={info.tags}
              onChange={handleTagChange}
              options={tags}
              className="fd-shadow-sm mt-1 focus:ring-indigo-500 text-navy-900 focus:border-indigo-500 block w-full sm:text-sm border-navy-300 rounded-md"
            />
          </div>
        </div>

        {url.includes(".youtube.") && (
          <div className="relative flex items-start pt-2">
            <div className="flex items-center h-5">
              <input
                onChange={handleSubtitlesChange}
                checked={info.subtitles === "en"}
                id="subtitles"
                name="comments"
                type="checkbox"
                className="focus:ring-coral-500 h-4 w-4 text-coral-500 border-gray-300 rounded"
              />
            </div>
            <div className="ml-2 text-sm">
              <label htmlFor="subtitles" className="font-medium text-gray-700">
                Display English Captions/Subtitles
              </label>
            </div>
          </div>
        )}

        <Warning text={showWarning} show={!!showWarning} />
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={() => {
              handleStepClick(2);
            }}
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md fd-shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Back to Add Interactions
          </button>
          <button
            type="submit"
            onClick={exit}
            className={`
                            ${
                              showWarning
                                ? "bg-gray-300"
                                : "bg-coral-600 hover:bg-coral-700 fd-shadow-sm"
                            } 
                            ml-3 inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            Save & Exit
          </button>
        </div>
      </div>
    </form>
  );
}

export default Step4;
