import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import InteractionDetails from "../interactionDetails";
import { Warning } from "../../../components/common/alerts";
import PlayIcon from "../../components/icons/playIcon";
import { motion } from "framer-motion";
import { secondsToHms, hmsToSeconds } from "../utils";

function Step3({
  handleStepClick,
  url,
  startTime,
  endTime,
  interactions,
  setInteractions,
}) {
  const player = useRef(),
    seekBar = useRef(),
    playBarWidth = seekBar.current?.offsetWidth,
    [playing, setPlaying] = useState(false),
    [currentTime, setCurrentTime] = useState(0),
    [mouseDown, setMouseDown] = useState(false),
    [mouseX, setMouseX] = useState(-2000),
    duration = endTime - startTime,
    mapTimes = (t) => 100 - ((t - startTime) * 100) / duration + "%",
    [currentInteraction, setCurrentInteraction] = useState(-1),
    [showWarning, setShowWarning] = useState(""),
    [warningKey, setWarningKey] = useState(-1),
    [refresh, setRefresh] = useState(false),
    forceRefresh = () => setRefresh((prev) => !prev),
    onMouseMove = (event) => {
      let offsetX = event.currentTarget.getBoundingClientRect().left;
      setMouseX(playBarWidth - (event.clientX - offsetX));

      if (mouseDown) {
        let clickPosition = (event.clientX - offsetX) / playBarWidth,
          newTime = clickPosition * duration + startTime;

        setCurrentTime(newTime);
        setCurrentInteraction(-1);

        if (player.current) player.current.seekTo(newTime);
      }
    },
    onMouseDown = () => {
      setMouseDown(true);
    },
    onMouseUp = () => {
      setMouseDown(false);
    },
    onClick = (event) => {
      let offsetX = event.currentTarget.getBoundingClientRect().left,
        clickPosition = (event.clientX - offsetX) / playBarWidth,
        newTime = clickPosition * duration + startTime;

      setCurrentTime(newTime);
      setCurrentInteraction(-1);

      if (player.current) player.current.seekTo(newTime);
    },
    handleInteractionSelect = (key) => {
      let thisInteraction = interactions.filter(
        (interaction) => interaction.key === key
      )[0];

      setPlaying(false);

      if (player.current && thisInteraction)
        player.current.seekTo(thisInteraction.time);

      if (thisInteraction) setCurrentTime(thisInteraction.time);

      if (currentInteraction !== key) {
        setCurrentInteraction(key);

        // short timeout to let any other animations finish first
        setTimeout(() => {
          document
            .getElementById(`interaction-${key}`)
            .scrollIntoView({ behavior: "smooth" });
        }, 500);
      }
    },
    handleProgress = (p) => {
      let currentSeconds = p.playedSeconds;

      if (currentSeconds) setCurrentTime(currentSeconds);

      if (!playing) return;

      let isInteractionAtTime = interactions.filter(
        (interaction) =>
          interaction.time - 0.1 > currentSeconds &&
          interaction.time - 0.5 < currentSeconds &&
          interaction.key !== currentInteraction
      )[0];

      if (currentSeconds >= endTime) setPlaying(false);

      isInteractionAtTime
        ? handleInteractionSelect(isInteractionAtTime.key)
        : setCurrentInteraction(-1);
    },
    updateInteractionTime = (time, key) => {
      let seconds = hmsToSeconds(time);
      setInteractions((prev) =>
        prev
          .map((interaction) => {
            if (interaction.key === key) interaction.time = seconds;
            return interaction;
          })
          .sort((a, b) => (a.time > b.time ? 1 : -1))
      );
      if (seconds) setCurrentTime(seconds);
    },
    addInteraction = (time) => {
      let highestId = interactions[0]
          ? Math.max(...interactions.map((interaction) => interaction.key))
          : 0,
        key = highestId + 1;
      setInteractions((prev) => {
        prev.push({
          time,
          type: "multiple-choice",
          duration: 15,
          data: {
            question: "",
            answers: [""],
            correct: [0],
          },
          key,
        });
        return prev;
      });
      forceRefresh();
      setTimeout(() => handleInteractionSelect(key), 1000);
    },
    removeInteraction = (key) => {
      setInteractions((prev) =>
        prev.filter((interaction) => interaction.key !== key)
      );
      setCurrentInteraction(-1);
      setPlaying(true);
    },
    updateInteraction = ({ key, time, type, duration, data }) => {
      if (time) {
        time = hmsToSeconds(time);
        setCurrentTime(time);
      }
      setInteractions((prev) =>
        prev
          .map((interaction) => {
            return interaction.key === key
              ? {
                  key: interaction.key,
                  time: time === undefined ? interaction.time : time,
                  type: type === undefined ? interaction.type : type,
                  duration:
                    duration === undefined ? interaction.duration : duration,
                  data: data === undefined ? interaction.data : data,
                }
              : interaction;
          })
          .sort((a, b) => (a.time > b.time ? 1 : -1))
      );
    },
    handleReady = () => {
      if (player.current) player.current.seekTo(startTime);
    };

  // update warnings whenever the interactions change
  useEffect(() => {
    let outOfTime = interactions.filter(
        (interaction) =>
          interaction.time < startTime || interaction.time > endTime
      )[0],
      noQuestion = interactions.filter(
        (interaction) => !interaction.data.question
      )[0],
      notEnoughAnswers = interactions.filter(
        (interaction) => !interaction.data.answers[1]
      )[0],
      notEnoughCorrectAnswer = interactions.filter(
        (interaction) => interaction.data.correct[0] === undefined
      )[0],
      shortDuration = interactions.filter(
        (interaction) => interaction.duration < 5
      )[0];

    if (outOfTime) {
      setShowWarning("some question times are outside the start and end times");
      setWarningKey(outOfTime.key);
    } else if (noQuestion) {
      setShowWarning("not all interactions have question text");
      setWarningKey(noQuestion.key);
    } else if (notEnoughAnswers) {
      setShowWarning("not all interactions have multiple answers with text");
      setWarningKey(notEnoughAnswers.key);
    } else if (notEnoughCorrectAnswer) {
      setShowWarning("not all interactions have correct answers selected");
      setWarningKey(notEnoughCorrectAnswer.key);
    } else if (shortDuration) {
      setShowWarning("interaction duration should be at least 5 seconds");
      setWarningKey(shortDuration.key);
    } else {
      setShowWarning("");
      setWarningKey(-1);
    }
  }, [interactions]);

  // start at the cropped start time
  useEffect(() => {
    if (startTime) setCurrentTime(startTime);
  }, [startTime]);

  return (
    <form
      className="space-y-8 divide-y divide-gray-200"
      forcerefresh={refresh ? 1 : 0}
    >
      <div>
        <div className="pb-3">
          <h3 className="text-3xl font-extrabold font-medium text-navy-900">
            3. Add Interactions
          </h3>
        </div>

        <div
          className="relative rounded-lg overflow-hidden shadow-sm"
          style={{ paddingBottom: 50 }}
        >
          <ReactPlayer
            ref={player}
            url={url}
            onPause={() => setPlaying(false)}
            onPlay={() => setPlaying(true)}
            onReady={handleReady}
            volume={0.7}
            onProgress={handleProgress}
            playing={playing}
            progressInterval={200}
            width="100%"
          />

          <div className="playerControlls relative">
            <div
              onClick={() => setPlaying((prev) => !prev)}
              className="playButton playButtonActive bg-navy-600"
            >
              <div className="playButtonFiller" />
              <PlayIcon playing={playing} />
            </div>

            <div
              className="relative"
              {...{ onMouseMove, onMouseDown, onMouseUp, onClick }}
              onMouseLeave={() => {
                setMouseDown(false);
                setMouseX(-4000);
              }}
              ref={seekBar}
              id="playerProgressBar"
              style={{ right: 0 }}
            >
              <motion.div
                animate={{ right: mapTimes(currentTime) }}
                transition={{ ease: "linear", duration: 0.2 }}
                className="playerSeekLocation"
              />
              <div style={{ right: mouseX }} className="playerProgressGuide" />

              {interactions.map((interaction) => (
                <div
                  key={interaction.time}
                  className="h-full absolute block px-1 -mr-1"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleInteractionSelect(interaction.key);
                  }}
                  style={{ right: mapTimes(interaction.time) }}
                >
                  <div
                    className={`
                                                ${
                                                  interaction.key ===
                                                  currentInteraction
                                                    ? "bg-coral-700"
                                                    : "bg-navy-600 parent-hover:bg-coral-700"
                                                }   
                                                w-full h-full block
                                            `}
                    style={{ width: 1, marginRight: -0.5 }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <a
          onClick={() => {
            if (currentInteraction === -1) addInteraction(currentTime);
          }}
          className={`
                        ${
                          currentInteraction === -1
                            ? "text-coral-700"
                            : "text-gray-300"
                        }
                        block mb-1 text-lg font-bold inline-flex justify-center cursor-pointer mt-3 w-full`}
        >
          <svg
            className="mt-1 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          <span>Add interaction at {secondsToHms(currentTime)} </span>
        </a>

        {interactions[0] && (
          <div className="mt-6">
            <h5 className="text-md font-extrabold text-navy-800 leading-tight mb-3">
              Current Interactions
            </h5>

            {interactions
              .sort((a, b) => (a.time > b.time ? 1 : -1))
              .map((interaction) => (
                <InteractionDetails
                  id={interaction.key}
                  onExpand={() => handleInteractionSelect(interaction.key)}
                  {...{
                    forceRefresh,
                    ...interaction,
                    updateInteractionTime,
                    updateInteraction,
                    removeInteraction,
                    currentInteraction,
                    warningKey,
                    setPlaying,
                  }}
                  expanded={interaction.key === currentInteraction}
                />
              ))}
          </div>
        )}

        <Warning text={showWarning} show={showWarning} />
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={() => {
              handleStepClick(1);
            }}
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Back to Cut/Crop Video
          </button>
          <button
            onClick={(e) =>
              showWarning
                ? e.preventDefault()
                : handleStepClick(3, "allowProgress")
            }
            type="submit"
            className={`
                            ${
                              showWarning
                                ? "bg-gray-300"
                                : "bg-coral-600 hover:bg-coral-700 shadow-sm"
                            } 
                            ml-3 inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </form>
  );
}

export default Step3;
