function secondsToHms(d) {
    d = Number(d);
    let h = Math.floor(d / 3600),
        m = Math.floor(d % 3600 / 60),
        s = Math.floor(d % 3600 % 60);

    if(String(s).length === 1 && m) s = '0'+s
    if(String(m).length === 1 && h) m = '0'+m

    let
        hDisplay = h > 0 ? h + ":" : "",
        mDisplay = m > 0 ? m + ":" : "";

    return hDisplay + mDisplay + s;
}

function hmsToSeconds(hms) {
    if(!hms) return 0;
    let a = hms.split(':');

    return a[2] ?
        (Number(a[0])) * 60 * 60 + (Number(a[1])) * 60 + (Number(a[2])) :
        a[1] ?
            (Number(a[0])) * 60 + (Number(a[1])) :
            Number(a[0]);
}

export { secondsToHms, hmsToSeconds }