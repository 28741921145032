import React from 'react';
import { motion } from "framer-motion";

function Result({ result, index, percentages }) {

  const
    variants = {
      outer: {
        visible: { y:0, rotate:0, scale:1 },
        hidden: { y:200, rotate:10, scale:0.8 }
      },
      scale: {
        visible: { scale:1 },
        hidden: { scale:0 }
      },
      slide: {
        visible: { x:0, opacity:1 },
        hidden: { x:-200, opacity:0 }
      }
    }

  return(
    <motion.div
      className={`${result.correctFirstTry ? 'bg-teal-200' : 'bg-coral-200'} quiz-option mx-6 sm:mx-16 md:mx-20 overflow-hidden group relative rounded-lg`}
      variants={variants.outer}>

      <div className="px-6 pt-4 pb-2 pr-20 sm:flex sm:justify-between sm:space-x-4 result-item">
        <div className="flex items-center space-x-0">

          <div className={`${result.correctFirstTry ? 'bg-teal-600' : 'bg-coral-600' } pf-medium text-white rounded-full text-4xl results-score-circle absolute`}>
            <div className="result-score absolute">{Math.round(result.score)}</div>
          </div>

          <div className="relative z-2 leading-snug text-navy-600 mo-regular text-sm text-md md:text-lg">
            <p className="text-xs text-sm md:text-md">{result.question}</p>
            <p className="mo-bold">{result.answer}</p>
          </div>
        </div>
      </div>

      <div className="relative z-2 leading-snug text-navy-600 mo-regular text-sm text-md md:text-lg pb-1">
        <p className={`${result.correctFirstTry ? 'text-teal-600' : 'text-coral-600' } text-xs text-sm md:text-md mo-light-italic text-right px-4`}>
          <b className="mo-medium-italic">{percentages[index]}%</b> of people got this question correct
        </p>
      </div>

      <div className={`${result.correctFirstTry ? 'bg-teal-200': 'bg-coral-400'} w-full h-2`}>
        <motion.div
          className={` ${result.correctFirstTry ? 'bg-teal-600 left-0': 'bg-coral-600 right-0'} h-2 absolute`}
          animate={{ width: ( result.correctFirstTry ? percentages[index] : (100 - percentages[index]) ) +'%' }} />
      </div>
    </motion.div>
  )

}

export default Result;
