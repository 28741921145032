import React, { useContext, useMemo, useEffect } from "react";
import { motion } from "framer-motion";
import { Context } from "../context";
import Counter from "../../../components/common/counter";
import { use100vh } from "react-div-100vh";

function Score() {
  const { student, classRoom } = useContext(Context),
    height = use100vh(),
    halfHeight = height / 2,
    moreThanOneStudents = !!classRoom.allStudents[1],
    rankedStudent = useMemo(
      () =>
        classRoom.leaderboard.filter(
          (storedStudent) => storedStudent.name === student.name
        )[0],
      [classRoom.leaderboard]
    ),
    variants = {
      wrapper: {
        visible: {
          transition: {
            staggerChildren: 0.2,
          },
        },
        hidden: {
          transition: {
            duration: 0.4,
            staggerChildren: 0.2,
            staggerDirection: -1,
          },
        },
      },
      background: {
        visible: { y: 0, transition: { ease: "easeOut", duration: 0.4 } },
        hidden: { y: -2500, transition: { ease: "easeOut", duration: 1.5 } },
      },
      text: {
        visible: { y: 0, transition: { ease: "easeOut", delay: 1.3 } },
        hidden: { y: -200 },
      },
      meta: {
        visible: {
          y: 0,
          opacity: 1,
          transition: { ease: "easeOut", delay: 2, duration: 0.2 },
        },
        hidden: { y: -50, opacity: 0 },
      },
    };

  useEffect(() => {
    //if(moreThanOneStudents)
    setTimeout(() => classRoom.setOverlay("leaderboard"), 4000);
  }, []);

  return (
    <motion.div
      variants={variants.wrapper}
      className="fixed inset-0 overflow-y-auto"
    >
      <div className="relative w-full z-10 score-wrapper">
        <motion.div variants={variants.background} className="score-bg" />

        <motion.div
          variants={variants.meta}
          className="last-activity-score absolute w-1/4 z-10 text-white text-center"
        >
          <p className="pb-2 text-lg leading-5 mo-medium">Last Activity</p>
          <h4 className="pf-medium text-6xl leading-none">
            <span className="mo-bold text-5xl">+</span>
            {student.lastScore}
          </h4>
        </motion.div>

        <motion.div
          variants={variants.meta}
          className="current-position absolute w-1/4 z-10 text-white text-center"
        >
          <p className="pb-2 text-lg leading-5 mo-medium">Current Position</p>
          <h4 className="pf-medium text-6xl leading-none">
            {rankedStudent.position}
            <span className="text-4xl">{rankedStudent.positionSuffix}</span>
          </h4>
        </motion.div>

        <motion.div
          variants={variants.background}
          className="score-bg-separator px-4 sm:px-6 py-4"
        >
          <motion.div
            variants={variants.background}
            style={{ top: halfHeight }}
            className="score-badge z-30 relative"
          >
            <div className="overflow-hidden">
              <motion.div
                variants={variants.text}
                className="score-badge-your-score text-teal-100 uppercase"
              >
                Your Score
              </motion.div>
            </div>

            <div className="overflow-hidden">
              <div className="score-badge-text pf-bold text-navy-600">
                <Counter
                  from={student.totalScore - student.lastScore}
                  to={student.totalScore}
                  delay={2.7}
                />
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Score;
