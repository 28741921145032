import React, { useEffect, useRef } from 'react';
import {animate} from "framer-motion";

function Counter({ from, to, delay }) {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 1,
      delay:delay,
      onUpdate(value) {
        node.textContent = value.toFixed(0);
      }
    });

    return () => controls.stop();
  }, []);

  return <span ref={nodeRef}>{from}</span>;
}

export default Counter;
