import React, { useState, useEffect } from "react";
import ModalBg from "../../../components/common/modalBg";

function VideoModal({ show, setShow }) {
  const handleClickClose = () => {
      setShow(false);
    },
    [playing, setPlaying] = useState(false),
    videoClick = (e) => {
      e.preventDefault();
      let video = document.getElementsByTagName("VIDEO")[0],
        paused = video.paused;
      setPlaying(paused);
      paused ? video.play() : video.pause();
    },
    videoEnded = () => {
      setShow(false);
    };

  // auto play when the modal opens
  useEffect(() => {
    const video = document.getElementsByTagName("VIDEO")[0];
    show ? video.play() : video.pause();
  }, [show]);

  return (
    <ModalBg {...{ show, setShow }}>
      <div
        onClick={handleClickClose}
        className="absolute top-0 right-0 mt-3 mr-3 z-30"
      >
        <button className="inline-flex bg-pink-500 rounded-md p-1.5 text-white">
          <span className="sr-only">Dismiss</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      <video
        controls={playing}
        onClick={videoClick}
        onEnded={videoEnded}
        className="duration-300 ease-in-out pointer"
        poster={require("../../../assets/images/cover.png")}
      >
        <source
          src={require("../../assets/video/intro.mp4")}
          type="video/mp4"
        />
      </video>
    </ModalBg>
  );
}

export default VideoModal;
