import React, { useState } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import SampleLessonsComponent from "../../components/sampleLessons";
import HomeWebinarCta from "../home/homeWebinarCta";
import Hero from "./hero";

function SampleLessons() {
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false);
  const openCreateAccountModal = () => setMasterShowCreateAccount(true);

  return (
    <>
      <Menu
        {...{
          masterShowCreateAccount,
          setMasterShowCreateAccount,
        }}
      />
      <Hero {...{ openCreateAccountModal }} />

      <SampleLessonsComponent forLanguages={true} noButton={true} />
      <SampleLessonsComponent noButton={true} />
      <HomeWebinarCta />
      <Footer />
    </>
  );
}

export default SampleLessons;
