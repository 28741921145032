import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import variants from "../../components/animationVariants";
import BgParticles from "../../components/bgParticles";

function ComingSoon() {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      className="bg-gray-100 sloppy-top overflow-hidden relative"
    >
      <BgParticles />

      <motion.div
        variants={variants.fromBottom}
        className="max-w-3xl mx-auto text-left md:text-center py-16 px-8 sm:py-20"
      >
        <h2 className="text-4xl font-extrabold text-teal-600 sm:text-5xl">
          <span className="block">Coming Soon</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-navy-700 font-bold">
          FilmDoo will soon be launching dedicated language learning film
          collections for language schools and teachers to use to create
          teaching material and interactive lessons using our edtech tool
        </p>
        <p className="mt-4 text-lg leading-6 text-navy-600">
          The key language collections we will be starting out with are:
          English, Spanish, French, Mandarin Chinese, German and Dutch.
        </p>
        <p className="mt-4 text-lg leading-6 text-navy-600">
          If you are interested in finding out more and/ or to be notified when
          the language learning film collections go live, drop us a line!
        </p>
        <Link
          to={{ pathname: "/contact" }}
          className="transition duration-500 ease-in-out hover:bg-teal-700 hover:fd-shadow-lg transform focus:outline-none mt-8 md:w-1/2 font-bold w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 shadow-2xl"
        >
          Get In Touch
        </Link>
      </motion.div>
    </motion.div>
  );
}

export default ComingSoon;
