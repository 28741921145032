import React, { useState, useContext, useEffect, useMemo } from 'react'
import { motion } from "framer-motion";
import { Context } from "../context";

function TeacherProgress({ seekTo, setCurrentlySeeking }) {

  const
    { lesson,  activity, student } = useContext(Context),
    [mouseX, setMouseX] = useState(-3000),
    responses = !student.responses[0] ? [] :
        student.responses.reduce((returnArray, response) => {
          returnArray[response.key] = response.correctFirstTry
          return returnArray;
        }, {}),

    onClick = (e, time) => {
      let
        clickPosition = (e.clientX - 60) / e.currentTarget.offsetWidth,
        newTime = time || (clickPosition * lesson.duration) + lesson.starttime;

      setCurrentlySeeking(true);
      seekTo(newTime);
      lesson.set('lastSavedCurrentTime', newTime);
    },
    mapTimes = t => 100-( (t - lesson.starttime) * 100 / lesson.duration ) + '%',
    onMouseMove = e => setMouseX(e.currentTarget.offsetWidth - e.clientX+60);

  useEffect(() => {
    if(activity && activity.type !== 'watching') {
        lesson.setPlaying(false);
    }
  },[lesson.times]);

  return (
    <motion.div
      {...{onClick, onMouseMove}}
      onMouseLeave={()=>setMouseX(4000)}
      id="playerProgressBar">

      <motion.div
        animate={{ right:mapTimes(lesson.currentTime) }}
        transition={{ ease: "linear", duration: 0.35 }}
        className="playerSeekLocation" />

      <motion.div
        transition={{ ease: "linear", duration: 0.05 }}
        animate={{ right:mouseX }} className="playerProgressGuide" />

      <div className="progressLine">
        {lesson.activities
          .filter(activity => !!activity && !!activity.time)
          .map((activity,key) =>
            <div
              {...{key}}
              className="questionLine"
              onClick={e=> {
                e.stopPropagation();
                // TODO make another way to load the waiting screen when clicking on a dot
                // currently setting the time to just before it!
                onClick(e, activity.time-0.35);
              }}
              style={{right:mapTimes(activity.time) }}>
                <div
                  className="questionDot"
                  style={{
                    background: typeof(responses[activity.key]) !== 'boolean' ?
                      '#31376E' : responses[activity.key] ?
                        '#F7FCFD' : '#ff6453'
                  }}>
                </div>
            </div>
        )}
      </div>

    </motion.div>
  )
}

export default TeacherProgress
