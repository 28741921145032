import React, { useState } from "react";
import { motion } from "framer-motion";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import HomeRecognition from "../home/homeRecognition";
import BgParticles from "../../components/bgParticles";
import HomeCreate from "../home/homeCreate";
import Images from "../../../assets/images";

function Testimonials() {
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false),
    openCreateAccountModal = () => setMasterShowCreateAccount(true),
    testimonials = [
      {
        image: Images.testimonials.francisco,
        name: "Francisco F da Silva",
        job: "Modern Language Teacher",
        description:
          "Language learning shouldn’t feel like a task, it should feel like an introduction to a whole new culture, and films give a clear view to this.",
      },
      {
        image: Images.testimonials.chris,
        name: "Chris Durazzo Campbell",
        job: "English Teacher",
        description:
          "This is a great way to keep them entertained while learning!",
      },
      {
        image: Images.testimonials.stefania,
        name: "Stefania Rosas",
        job: "Spanish, Italian, French, English Teacher",
        description:
          "The best thing about the tool is its versatility. I can use it inside or outside the classroom, whether it’s virtual or with my students in the same room.",
      },
      {
        image: Images.testimonials.ana,
        name: "Ana Hernandez",
        job: "Spanish and English teacher",
        description:
          "Interacting with emotional scenes allows the learners to open up, while they are practicing the language.",
      },
      {
        image: Images.testimonials.b,
        name: "Jack Peck",
        job: "Year 4 Primary School Teacher",
        description:
          "FilmDoo Academy has enabled me to re-engage my students with a refreshing, new way of learning. My students have a blast with it, and continually ask, ‘Can we use FilmDoo Academy today, sir?’",
      },
      {
        image: Images.testimonials.alrick,
        name: "Alrick Ciserón",
        job: "Physics and Chemistry teacher",
        description:
          "With the subjects I teach, I don’t usually find relevant content available for me. What’s great about FIlmDoo Academy is that I can create content with videos I do myself, and have a course ready in no time!",
      },
      {
        image: Images.testimonials.katherine,
        name: "Katherine Virruso",
        job: "English and Spanish teacher",
        description:
          "I work with a lot of special education students, and it’s sometimes challenging to keep them engage and concentrated. FilmDoo Academy provided me with just what i needed in a way that is fun and seamless for them.",
      },
      {
        image: Images.testimonials.d,
        name: "Tiffany Barton",
        job: "Year 6 Primary School Teacher",
        description:
          "Thank you for helping bring a burst of energy back to my lessons. It’s not been an easy period for teachers or students, and your tool has helped empower me to keep the students focused. I will be passing your details to my fellow teachers so it can help them too.",
      },
      {
        image: Images.testimonials.c,
        name: "William Hansen",
        job: "Principal/head of School",
        description:
          "We are a special school located on the outskirts of Birmingham.  FilmDoo Academy is a great tool for us to use with our students.  They want to use it, ask to use it and we find the students' attention is more focused, engaged and most importantly - it genuinely helps them learn and improve!",
      },
      {
        image: Images.testimonials.a,
        name: "Regine Suissa",
        job: "Year 3 Primary School Teacher",
        description:
          "I began using FilmDoo Academy when students began returning to classes after the last lockdown period. It provides me with a superb, highly-engaging tool to integrate into my lessons. My students love it! I typically use two to three videos per lesson to stimulate the students' minds and ensure they are motivated and engaged.",
      },
      {
        image: Images.testimonials.e,
        name: "Evan Goodman",
        job: "Primary School Teacher",
        description:
          "Continuing to create classes that capture the students' imagination and keep them occupied throughout the class time has been very stressful and tough. I felt burnt-out and so did my students. I have used video in class many times before, but not seen the interaction and excitement like this before. A great tool to use in addition to my lesson.",
      },
      {
        image: Images.testimonials.f,
        name: "Brandy Jackson",
        job: "Head of Humanities",
        description:
          "FilmDoo Academy has been my go-to when I’m in a rush to create something for my lessons. One feature I love is that I can make it as long or as short as I please to fit into my lesson perfectly. I have actually loved creating the lessons using the tool.",
      },
    ];

  return (
    <>
      <Menu {...{ masterShowCreateAccount }} />

      <div className="relative px-4 sm:px-6 pt-32 lg:px-8 pb-16 bg-gray-100 sloppy-bottom overflow-hidden">
        <BgParticles />

        <div className="relative z-10 max-w-6xl mx-auto my-8">
          <div className="grid-cols-1 grid lg:grid-cols-2 gap-x-4 lg:gap-x-8 xl:gap-x-12 px-6 mt-4">
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              animate={{
                opacity: 1,
                x: 0,
                transition: { type: "easeOut", delay: 0.2, duration: 0.7 },
              }}
              className="text-left pl-6 pb-4"
            >
              <h1 className="mt-16 text-4xl lg:text-6xl font-semibold text-navy-700 leading-tight mb-4">
                Testimonials <i>for</i>{" "}
                <span className="text-teal-600">FilmDoo Academy</span>
              </h1>

              <p className="mt-3 mb-1 text-sm text-navy-600 sm:mt-5 sm:text-base sm:max-w-xl md:mt-5 md:text-lg lg:mx-0 pr-18 italic">
                A word from our happy language teachers
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 200 }}
              animate={{
                opacity: 1,
                x: 0,
                transition: { type: "easeOut", delay: 0.2, duration: 0.7 },
              }}
              className="sm:col-span-1"
            >
              <iframe
                className="rounded-xl fd-shadow w-full h-72"
                src="https://www.youtube.com/embed/Z_utjBRxlDA"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </motion.div>
          </div>
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { type: "easeOut", delay: 1, duration: 0.7 },
        }}
        className="relative px-12"
      >
        <div className="relative max-w-6xl mx-auto w-full pt-20 px-4 sm:px-6 lg:px-8 lg:pt-24 pb-10">
          <div className="relative lg:flex lg:items-center">
            <div className="hidden lg:block lg:flex-shrink-0">
              <img
                className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                src={Images.testimonials.lyvia}
                alt="Lyvia"
              />
            </div>

            <div className="relative lg:ml-10">
              <svg
                className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-teal-200 opacity-50"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 144 144"
                aria-hidden="true"
              >
                <path
                  strokeWidth="2"
                  d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                />
              </svg>
              <blockquote className="relative">
                <div className="text-2xl leading-9 font-medium text-navy-700">
                  <p>
                    The advantage also is to discover different Spanish-speaking
                    accents, native, without this being forced as in textbooks
                  </p>
                </div>
                <footer className="mt-8">
                  <div className="flex">
                    <div className="flex-shrink-0 lg:hidden">
                      <img
                        className="h-6 w-6 rounded-full"
                        src={Images.testimonials.lyvia}
                        alt="lyvia"
                      />
                    </div>
                    <div className="ml-4 lg:ml-0">
                      <div className="text-base font-medium text-navy-900">
                        Lyvia Rousell
                      </div>
                      <div className="text-base font-medium text-teal-600">
                        Spanish and French Teacher
                      </div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </motion.div>

      <section>
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { type: "easeOut", delay: 1.5, duration: 0.7 },
          }}
          className="w-full md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-16 max-w-8xl mx-auto mb-16"
        >
          {testimonials.map((testimonial) => (
            <div className="py-6 my-2 px-1 mx-1  sm:mx-2 sm:px-4 md:py-8 lg:mx-2 lg:px-6 rounded-lg bg-teal-20">
              <blockquote className="mt-6 md:flex-grow md:flex md:flex-col justify-between h-full">
                <div className="text-lg font-medium text-navy-600 md:flex-grow md:place-content-center grid">
                  <div className="relative">
                    <svg
                      className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-teal-100"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="relative">{testimonial.description}</p>
                  </div>
                </div>
                <footer className="my-5">
                  <div className="flex">
                    <div className="flex-shrink-0 inline-flex rounded-full">
                      <img
                        className="h-12 w-12 rounded-full border-2 border-white"
                        src={testimonial.image}
                        alt={testimonial.name}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-base font-medium text-navy-600">
                        {testimonial.name}
                      </div>
                      <div className="text-base font-medium text-teal-600">
                        {testimonial.job}
                      </div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          ))}
        </motion.div>
      </section>

      <HomeCreate {...{ openCreateAccountModal }} />
      <HomeRecognition />
      <Footer />
    </>
  );
}

export default Testimonials;
