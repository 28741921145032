import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Warning } from "../../../components/common/alerts";
import window from "global/window";

function getVideoCode(url, source) {
  let code = "";

  if (url && source === "youtube" && url.includes("?")) {
    let params = url.split("?")[1].split("&");
    params.forEach((param) => {
      let pair = param.split("=");
      if (pair[0] === "v") code = pair[1];
    });
  } else if (url && source === "vimeo")
    code = url
      .substring(url.lastIndexOf("/") + 1)
      .split("?")[0]
      .replace(" ", "");

  return code;
}

function Step1({ handleStepClick, url, setUrl, thumbnail, setThumbnail }) {
  const [gettingThumbnail, setGettingThumbnail] = useState(false),
    [playing, setPlaying] = useState(false),
    [saveDisabled, setSaveDisabled] = useState(true),
    [showWarning, setShowWarning] = useState(false),
    handleVideoError = () => {
      setShowWarning(true);
      setSaveDisabled(true);
    },
    getThumbnail = () => {
      // shit old code from the old version but it'll do for now!
      if (url.includes(".youtube.")) {
        let code = getVideoCode(url, "youtube"),
          img = new Image();
        img.src = "https://img.youtube.com/vi/" + code + "/mqdefault.jpg";
        img.onload = function () {
          if (this.width !== 120) {
            setThumbnail("https://i.ytimg.com/vi/" + code + "/mqdefault.jpg");
            setGettingThumbnail(false);
          }
        };
      } else if (url.includes(".vimeo.")) {
        let code = getVideoCode(url, "vimeo");
        fetch("https://vimeo.com/api/v2/video/" + code + ".json")
          .then((res) => res.json())
          .then((data) => {
            let thumbnail = data[0].thumbnail_large;
            if (thumbnail) {
              setThumbnail(thumbnail);
            }
            setGettingThumbnail(false);
          });
      } else {
        // there are not thumbnails for Facebook, Wistia, Mixcloud and file
        // TODO create thumbnails for above mentioned sites
        let loopCount = 0,
          interval = setInterval(() => {
            let img = document.getElementsByClassName(
              "react-player__preview"
            )[0];
            if (
              img &&
              (img.currentStyle || window.getComputedStyle(img, false))
            ) {
              let style =
                  img.currentStyle || window.getComputedStyle(img, false),
                bi = style.backgroundImage.slice(4, -1).replace(/"/g, "");
              clearInterval(interval);
              if (bi && !thumbnail) {
                setThumbnail(bi);
              }
              setGettingThumbnail(false);
            } else if (++loopCount > 3) {
              clearInterval(interval);
              setGettingThumbnail(false);
            }
          }, 1000);
      }
    },
    handleUrlChange = (event) => {
      setUrl(event.target.value);
    },
    handleVideoReady = () => {
      if (!thumbnail) {
        setGettingThumbnail(true);
      }
      setSaveDisabled(false);
      setShowWarning(false);
    };

  useEffect(() => {
    if (url) getThumbnail();
  }, [url]);

  useEffect(() => {
    console.log(thumbnail);
  }, [thumbnail]);
  return (
    <form className="space-y-8 divide-y divide-gray-200">
      <div className="col-span-6 sm:col-span-4 flex">
        <div className="flex-grow overflow-hidden transition duration-300 ease-in-out">
          <label
            htmlFor="video_url"
            className="block text-sm font-medium text-navy-700"
          >
            <h3 className="text-3xl font-extrabold font-medium text-gray-900">
              1. Select Video
            </h3>
            <p className="text-base text-gray-500 my-2">
              Paste video url from: Youtube, Vimeo, Facebook Video, Soundcloud,
              Twitch, Dailymotion or Mixcloud
            </p>
          </label>
          <input
            value={url}
            onChange={handleUrlChange}
            type="text"
            name="video_url"
            autoComplete="email"
            className="inset-shadow mt-1 block w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-teal-500 focus:border-indigo-500 sm:text-sm"
          />

          <Warning text="video url not recognised" show={showWarning} />
        </div>

        <div
          onClick={() => setPlaying((prev) => !prev)}
          style={{ maxHeight: 150 }}
          className="w-1/3 ml-3 overflow-hidden rounded-lg"
        >
          <ReactPlayer
            light={gettingThumbnail}
            url={url}
            onError={handleVideoError}
            volume={0.7}
            playing={playing}
            onReady={handleVideoReady}
            width="100%"
            height="100%"
          />
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={(e) =>
              saveDisabled
                ? e.preventDefault()
                : handleStepClick(1, "allowProgress")
            }
            type="submit"
            className={`
                ${
                  saveDisabled
                    ? "bg-gray-300"
                    : "bg-coral-600 hover:bg-coral-700 shadow-sm"
                } 
                ml-3 inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </form>
  );
}

export default Step1;
