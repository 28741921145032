import React, { useEffect, useState, useRef } from "react";
import ModalBg from "../../../components/common/modalBg";
import DropIn from "braintree-web-drop-in-react";
import window from "global";

function Subscribe({ show, setShow }) {
  const [clientToken, setClientToken] = useState();
  const [user, setUser] = useState();
  const [name, setName] = useState();
  const [instance, setInstance] = useState();
  const ref = useRef();
  const buy = async () => {
    const { nonce } = await instance.requestPaymentMethod();
    fetch("/api/v1/braintree/subscribe", {
      body: JSON.stringify({
        nonce,
        user,
        ...name,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then(() => {
        window.localStorage.setItem(
          "fda_user",
          JSON.stringify({ ...user, permission_level: 2 })
        );
        window.location.href = "/content?author=" + user.username;
        setShow(false);
      });
  };
  const handleClickClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (user && show && !name) {
      fetch("/api/v1/contacts/getHubspotData/" + user.email)
        .then((res) => res.json())
        .then((hubspotData) => {
          if (hubspotData) {
            setName(hubspotData);
          }
        });
    }
  }, [show, user]);

  useEffect(() => {
    if (show) {
      fetch("/api/v1/braintree/getClientToken")
        .then((res) => res.json())
        .then(({ clientToken }) => {
          setClientToken(clientToken);
        });
      setUser(JSON.parse(window.localStorage.getItem("fda_user")));
    }
  }, [show]);

  return (
    <ModalBg show={show && clientToken} {...{ setShow }}>
      <div className="absolute top-0 right-0 mt-3 mr-6">
        <button
          onClick={handleClickClose}
          className="inline-flex bg-pink-500 rounded-md p-1.5 text-white"
        >
          <span className="sr-only">Dismiss</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {clientToken && (
        <div className="max-w-3xl">
          <DropIn
            ref={ref}
            options={{
              paymentOptionPriority: ["card", "paypal"],
              authorization: clientToken,
              paypal: {
                flow: "vault",
              },
            }}
            onInstance={setInstance}
          />
          <button
            onClick={buy}
            className="float-right mt-2 transition duration-500 ease-in-out hover:bg-pink-700 transform focus:outline-none ml-6 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md fd-shadow-sm text-base font-medium transition-all text-white bg-pink-600 hover:bg-pink-700"
          >
            Subscribe
          </button>
        </div>
      )}
    </ModalBg>
  );
}

export default Subscribe;
