import React from "react";
import { motion, useTransform, useViewportScroll } from "framer-motion";

function CheckBox({offsets, size}) {
  const
    { scrollY } = useViewportScroll(),
    lineLength = useTransform(scrollY, offsets, [0, 0.55]),
    circleLength = useTransform(scrollY, offsets, [1, 0]);

  return (
      <svg
        className="text-gray-50"
        width={size}
        height={size}
        viewBox="0 0 20 20"
      >
        <motion.path
          fill="none"
          strokeWidth="2"
          stroke="currentColor"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          style={{ pathLength: lineLength }}
          d="M18.1245 2L9.62445 12L3 4.5C4.646 2.37097 7.22512 1 10.1245 1C10.9467 1 11.7432 1.11027 12.5 1.31683"
        />

        <path
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          stroke="currentColor"
          d="M10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 9.66199 18.9814 9.32833 18.9451 9C18.9298 8.8614 18.9113 8.72374 18.8897 8.58711"
        />

        <motion.path
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          stroke="currentColor"
          style={{ pathLength: circleLength }}
          d="M19 10C19 5.02944 14.9706 1 10 1"
        />
      </svg>
  );
}

export default CheckBox;
