import React, { useState } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import Images from "../../../assets/images";
import { Warning } from "../../../components/common/alerts";
import BgParticles from "../../components/bgParticles";

function WhitePaper(props) {
  const url = props.match.params.file.toLowerCase(),
    [warning, setWarning] = useState(""),
    [firstName, setFirstName] = useState(""),
    [lastName, setLastName] = useState(""),
    [schoolName, setSchoolName] = useState(""),
    [position, setPosition] = useState(""),
    [phoneNumber, setPhoneNumber] = useState(""),
    [tcAgreed, setTcAgreed] = useState(false),
    [email, setEmail] = useState(""),
    [notifyLinkedIn, setNotifyLinkedIn] = useState(false),
    emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    handleFirstNameChange = (event) => {
      setFirstName(event.target.value.trim());
      setWarning("");
    },
    handleLastNameChange = (event) => {
      setLastName(event.target.value.trim());
      setWarning("");
    },
    handleSchoolNameChange = (event) => {
      setSchoolName(event.target.value);
      setWarning("");
    },
    handleEmailChange = (event) => {
      setEmail(event.target.value.trim().toLowerCase());
      setWarning("");
    },
    handlePositionChange = (event) => {
      setPosition(event.target.value.trim());
      setWarning("");
    },
    handlePhoneNumberChange = (event) => {
      setPhoneNumber(event.target.value);
      setWarning("");
    },
    handleTcAgreedClick = (event) => {
      setTcAgreed((prev) => !prev);
      setWarning("");
    },
    handleSignUpButton = () => {
      if (!tcAgreed) {
        setWarning("You must agree to the terms and conditions");
      } else if (!firstName || !lastName) {
        setWarning("Please enter your first and last name");
      } else if (!schoolName || !position) {
        setWarning("Please enter your school name and job title");
      } else if (!phoneNumber) {
        setWarning("Please enter your phone number");
      } else if (!emailRegex.test(email)) {
        setWarning("Please enter a valid email address");
      } else {
        fetch(`/api/v1/contacts/download`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            firstname: firstName,
            lastname: lastName,
            company: schoolName,
            jobtitle: position,
            phone: phoneNumber,
            email,
          }),
        })
          .then((res) => res.json())
          .then(() => {
            const win = window.open("/download/" + url, "_blank");
            win.focus();
          });
      }
    };

  return (
    <>
      <Menu />

      <main className="w-full bg-gray-100 sloppy-bottom overflow-hidden pt-4 lg:pt-6">
        <BgParticles />

        <div className="relative max-w-5xl mx-auto lg:py-32 pt-24 pb-14 px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            {url ===
              "5_tips_for_creating_the_perfect_primary_school_lesson_using_film_and_video" && (
              <div className="col-span-12 text-center">
                <h1 className="lg:mt-2 text-xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-navy-700 leading-tight">
                  5 Tips For Creating{" "}
                  <span className="text-teal-600">
                    The Perfect Primary School Lesson
                  </span>{" "}
                  Using Film & Video
                </h1>
                <h3 className="text-navy-600 font-bold mt-4 text-lg lg:text-xl font-bold mb-4">
                  A <b>must-read</b> article for you, your colleagues and your
                  school
                </h3>
              </div>
            )}

            {url ===
              "5_reasons_you_should_be_using_film_and_video_as_part_of_your_online_lessons" && (
              <div className="col-span-12 text-center">
                <h1 className="lg:mt-2 text-xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-navy-700 leading-tight">
                  <span className="text-teal-600">5 Reasons</span> You Should Be
                  Using Film & Video
                </h1>
                <h1 className="text-xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-navy-700 leading-tight">
                  <span className="text-teal-600">
                    as Part of Your Online & In-School Lessons
                  </span>
                </h1>

                <h3 className="text-navy-600 font-bold mt-4 text-lg lg:text-xl font-bold mb-4">
                  A free <b>must-read</b> article for you, your colleagues and
                  your whole school
                </h3>
              </div>
            )}

            {url ===
              "5_reasons_you_should_be_using_film_and_video_with_your_special_education_needs_students" && (
              <div className="col-span-12 text-center">
                <h1 className="lg:mt-2 text-xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-navy-700 leading-tight">
                  <span className="text-teal-600">5 Reasons</span> You Should Be
                  Using Film & Video
                </h1>
                <h1 className="text-xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-navy-700 leading-tight">
                  <span className="text-teal-600">
                    With Your SEN{" "}
                    <span className="font-normal text-xl sm:text-2xl md:text-3xl lg:text-4xl">
                      (Special Education Needs)
                    </span>{" "}
                    Students
                  </span>
                </h1>
                <h3 className="text-navy-600 font-bold mt-4 text-lg lg:text-xl font-bold mb-4">
                  A <b>must-read</b> article for you, your colleagues and your
                  school
                </h3>
              </div>
            )}

            {url ===
              "5_reasons_you_should_be_using_film_and_video_as_part_of_your_language_lessons" && (
              <div className="col-span-12 text-center">
                <h1 className="lg:mt-2 text-xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-navy-700 leading-tight">
                  <span className="text-teal-600">5 Reasons</span> You Should Be
                  Using Film & Video
                </h1>
                <h1 className="text-xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-navy-700 leading-tight">
                  <span className="text-teal-600">
                    as Part of Your Language Lessons
                  </span>
                </h1>
                <h3 className="text-navy-600 font-bold mt-4 text-lg lg:text-xl font-bold mb-4">
                  A <b>must-read</b> article for you, your colleagues and your
                  school
                </h3>
              </div>
            )}
          </div>
        </div>
      </main>

      <div className="py-12 -mt-8 mb-8">
        <div className="max-w-xl mx-auto lg:max-w-6xl px-12">
          <div className="relative -mt-16 lg:-mt-32 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative mr-6 lg:-mt-4">{Images.eBook}</div>

            <div
              className="mt-10 -mx-4 relative lg:mt-0 z-10 lg:pr-12"
              aria-hidden="true"
            >
              {url ===
                "5_tips_for_creating_the_perfect_primary_school_lesson_using_film_and_video" && (
                <>
                  <p className="text-base text-navy-600 mt-4">
                    For teachers and educational leaders hoping to learn more
                    about the many advantages and benefits of incorporating film
                    and video into their classes, we’re offering a free download
                    of our new article covering{" "}
                    <b>
                      5 Tips For Creating the Perfect Primary School Lesson
                      Using Film & Video.
                    </b>
                  </p>
                  <p className="text-base text-navy-600 mt-4">
                    Our article draws on decades’ worth of independent research
                    to demonstrate how film and video can be a highly effective
                    and versatile tool for primary schools, teachers and
                    students. Find out how film- and video-based learning can
                    help you connect to the modern day students, increase
                    student engagement and transform learning outcomes.
                  </p>
                  <p className="text-base text-navy-600 mt-4">
                    Download your free copy below today!
                  </p>
                </>
              )}

              {url ===
                "5_reasons_you_should_be_using_film_and_video_as_part_of_your_online_lessons" && (
                <>
                  <p className="text-base font-bold text-navy-600 mt-4">
                    A pre-published EXCLUSIVE independently produced article
                    just for you!
                  </p>
                  <p className="mt-2 text-base text-navy-600">
                    This free compelling article includes key information and
                    insights based on independent research on the significant
                    impact film and video has within education.
                  </p>
                  <p className="mt-2 text-base text-navy-600">
                    Be one of the first to read this article now before general
                    release and publication over the coming weeks.
                  </p>
                  <p className="mt-2 text-base text-navy-600">
                    There is no better tool than FilmDoo Academy to use as part
                    of your school reopening strategies - help bring back your
                    students’ thirst for learning.
                  </p>
                </>
              )}

              {url ===
                "5_reasons_you_should_be_using_film_and_video_with_your_special_education_needs_students" && (
                <>
                  <p className="text-base text-navy-600 mt-4">
                    For teachers and educational leaders hoping to learn more
                    about the many advantages and benefits of incorporating film
                    and video into their classes, we’re offering a free download
                    of our new article covering the{" "}
                    <b>
                      Top 5 Reasons Your School Should Be Using Film & Video
                      With Your SEN Students.
                    </b>
                  </p>
                  <p className="mt-2 text-base text-navy-600">
                    Our article draws on decades’ worth of independent research
                    to demonstrate how film and video can be a highly effective
                    and versatile tool for SEN students. Find out how film- and
                    video-based learning can help you connect to the modern day
                    students, increase student engagement and transform learning
                    outcomes.
                  </p>
                  <p className="mt-2 text-base text-navy-600">
                    Download your free copy below today!
                  </p>
                </>
              )}

              {url ===
                "5_reasons_you_should_be_using_film_and_video_as_part_of_your_language_lessons" && (
                <>
                  <p className="text-base text-navy-600 mt-4">
                    For teachers and educational leaders hoping to learn more
                    about the many advantages and benefits of incorporating film
                    and video into their classes, we’re offering a free download
                    of our new article covering the{" "}
                    <b>
                      Top 5 Reasons You Should Be Using Film & Video as Part of
                      Your Language Lessons.
                    </b>
                  </p>
                  <p className="mt-2 text-base text-navy-600">
                    Our article draws on decades’ worth of independent research
                    to explain how film and video can be a highly effective and
                    versatile tool for language learning. Find out how film- and
                    video-based learning can help you connect to the modern day
                    students, increase student engagement and transform learning
                    outcomes.
                  </p>
                  <p className="mt-2 text-base text-navy-600">
                    Download your free copy below today!
                  </p>
                </>
              )}

              <div className="sm:col-span-2 grid sm:grid-cols-2 sm:gap-x-4 mt-6">
                <div className="sm:col-span-1">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-bold text-navy-600"
                  >
                    First Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      onChange={handleFirstNameChange}
                      className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-bold text-navy-600"
                  >
                    Last Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      onChange={handleLastNameChange}
                      className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-2 mt-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-bold text-navy-600"
                >
                  Your Email
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    onChange={handleEmailChange}
                    className="placeholder-gray-300 py-3 px-4 block w-full inset-shadow border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-2 grid sm:grid-cols-2 sm:gap-x-4 mt-2">
                <div className="sm:col-span-1">
                  <label
                    htmlFor="schoolName"
                    className="block text-sm font-bold text-navy-600"
                  >
                    Name of School/Institution
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="schoolName"
                      placeholder="Name of School/Institution"
                      onChange={handleSchoolNameChange}
                      className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label
                    htmlFor="position"
                    className="block text-sm font-bold text-navy-600"
                  >
                    Job Title
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="position"
                      placeholder="Job Title"
                      onChange={handlePositionChange}
                      className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-2 mt-2">
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-bold text-navy-600"
                >
                  Mobile Number / Your Direct Personal Number
                </label>
                <div className="mt-1">
                  <input
                    type="tel"
                    name="phoneNumber"
                    placeholder="Contact Number"
                    onChange={handlePhoneNumberChange}
                    className="placeholder-gray-300 py-2 px-3 block w-full inset-shadow border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-2 mt-2">
                <div className="flex items-center mt-1 px-1">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    value={tcAgreed}
                    onClick={handleTcAgreedClick}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember_me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    I agree to FilmDoo’s
                    <a
                      href="/term-and-conditions"
                      target="_blank"
                      className="text-teal-700 underline text-bold"
                    >
                      Terms of Service
                    </a>
                    and
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      className="text-teal-700 underlined text-bold"
                    >
                      Privacy Policy
                    </a>
                  </label>
                </div>
              </div>

              <div className="sm:col-span-2">
                <div className="overflow-hidden">
                  <Warning text={warning} show={!!warning} />
                </div>
                <button
                  onClick={handleSignUpButton}
                  className="inline-flex items-center justify-center w-full py-2 mt-4 border border-transparent rounded-md fd-shadow text-base font-medium transition-all text-white bg-pink-600 hover:bg-pink-700"
                >
                  Download Now
                </button>
              </div>

              {notifyLinkedIn && (
                <img
                  height={1}
                  width={1}
                  style={{ display: "none" }}
                  alt="linkedin pixel"
                  src="https://px.ads.linkedin.com/collect/?pid=76957&conversionId=4083465&fmt=gif"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default WhitePaper;
