import React, { useContext, useMemo, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Logo from "../../../components/common/logo";
import { Context } from "../context";
import Result from "../components/resault";
import sessionStorage from "sessionstorage";
import { CSVLink } from "react-csv";

function Results() {
  const { classRoom, student, lesson } = useContext(Context),
    [title, setTitle] = useState("filmdoo-results"),
    [csvData, setCsvData] = useState([]),
    [csvHeaders, setCsvHeaders] = useState([]),
    percentages = useMemo(
      () =>
        classRoom.allStudents
          .map((student) =>
            student.responses.map(
              (response) => response.correctFirstTry || false
            )
          )
          .reduce((acc, curr) => acc.map((a, key) => a + (curr[key] || 0)))
          .map((count) =>
            Math.round((count / classRoom.allStudents.length) * 100)
          ),
      [classRoom.allStudents]
    ),
    variants = {
      header: {
        visible: {
          y: 0,
          transition: {
            delay: 0.4,
            duration: 0.4,
            staggerChildren: 0.3,
            delayChildren: 1,
          },
        },
        hidden: {
          y: -400,
          transition: {
            duration: 0.8,
          },
        },
      },
      text: {
        visible: { y: 0, opacity: 1 },
        hidden: { y: -50, opacity: 0 },
      },
      result: {
        visible: {
          y: 0,
          transition: { ease: "easeOut" },
        },
        hidden: {
          y: 200,
          transition: { ease: "easeOut" },
        },
      },
      list: {
        visible: {
          transition: {
            staggerChildren: 0.7 / percentages.length,
            delayChildren: 1.7,
          },
        },
        hidden: {
          transition: { staggerChildren: 0.1, staggerDirection: -1 },
        },
      },
    };

  // remove the session storage once results are shown
  useEffect(() => {
    sessionStorage.removeItem("classRoomName");
    sessionStorage.removeItem("name");
  }, []);

  useEffect(() => {
    let headers = [{ label: "", key: "name" }].concat(
      classRoom.allStudents.map((student) => {
        return { label: student.name, key: student.name };
      })
    );

    setCsvData(
      lesson.activities
        .filter((activity) => activity && activity.type !== "watching")
        .map((activity) => {
          let data = { name: activity.data.question };

          classRoom.allStudents.forEach((student) => {
            let response = student.responses.filter(
              (response) => response.key === activity.key
            )[0];
            data[student.name] = response
              ? response.correctFirstTry
                ? "✓"
                : "✗"
              : "✗";
          });

          return data;
        })
    );

    setCsvHeaders(headers);
    setTitle(
      lesson.videoTitle
        .split(" ")
        .join("_")
        .replace(/[^\w\s]/g, "")
        .trim()
        .split(" ")
        .join("_")
        .toLowerCase() + "_results.csv"
    );
  }, []);

  return (
    <>
      <motion.div
        variants={variants.header}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="bg-navy-700 leaderboard-heading relative"
      >
        <div className="text-center">
          <div className="overflow-hidden pt-12 pb-16">
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              className="w-24 sm:w-48 mx-auto"
            >
              <Logo show={true} />
            </motion.div>
            <motion.p
              variants={variants.text}
              className="px-4 sm:px-2 w-full max-w-2xl mx-auto mt-1 text-xl pf-light text-white sm:text-3xl lg:text-4xl"
            >
              Final Score{" "}
              <b className="pf-medium text-2xl sm:text-6xl">
                {student.totalScore}
              </b>{" "}
              points
            </motion.p>

            <div className="overflow-hidden">
              <motion.p
                variants={variants.text}
                className="max-w-xl mt-4 mx-auto text-xs sm:text-lg leading-7 text-teal-300 pf-medium-italic"
              >
                {lesson.videoTitle}
              </motion.p>
            </div>

            {csvData && student && student.isPrefect && (
              <div className="overflow-hidden py-8">
                <motion.divz
                  className="my-5 text-center f-full"
                  variants={variants.text}
                >
                  <CSVLink
                    filename={title}
                    className="py-3 px-5 mx-auto rounded-md fd-shadow-lg items-center font-bold text-md text-white bg-coral-600"
                    data={csvData}
                    headers={csvHeaders}
                  >
                    Download Results
                  </CSVLink>
                </motion.divz>
              </div>
            )}
          </div>
        </div>
      </motion.div>

      <div className="w-full max-w-2xl mx-auto sm:px-2 -mt-6 pb-6">
        <motion.ul
          layout
          variants={variants.list}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          {student.responses.map((result, index) => (
            <li className="overflow-hidden py-3">
              <motion.div variants={variants.result} key={index}>
                <Result {...{ result, index, percentages }} />
              </motion.div>
            </li>
          ))}
        </motion.ul>
      </div>
    </>
  );
}

export default Results;
