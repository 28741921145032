import React, { useState } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import Hero from "./hero";
import HomeWebinarCta from "../home/homeWebinarCta";
import Team from "./team";
import HomeCreate from "../home/homeCreate";

function AboutUs() {
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false),
    openCreateAccountModal = () => setMasterShowCreateAccount(true);

  return (
    <>
      <Menu
        {...{
          masterShowCreateAccount,
          setMasterShowCreateAccount,
        }}
      />
      <Hero />
      <HomeCreate {...{ openCreateAccountModal }} />
      <Team />
      <HomeWebinarCta />
      <Footer />
    </>
  );
}

export default AboutUs;
