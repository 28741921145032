import React, { useState } from "react";
import HomeHero from "./homeHero";
import HomeCtas from "./homeCtas";
import HowItWorks from "./howItWorks";
import HomeDetails from "./homeDetails";
import HomeFeatured from "./homeFeatured";
import HomeWebinarCta from "./homeWebinarCta";
import HomeCreate from "./homeCreate";
import HomeRecognition from "./homeRecognition";
import { Footer, Menu } from "../../components";

function Home() {
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false);
  const [masterShowMembership, setMasterShowMembership] = useState(false);
  const openCreateAccountModal = () => setMasterShowCreateAccount(true);

  return (
    <>
      <Menu
        {...{
          masterShowCreateAccount,
          setMasterShowCreateAccount,
          masterShowMembership,
          setMasterShowMembership,
        }}
      />
      <HomeHero {...{ openCreateAccountModal }} />
      <HomeCtas {...{ openCreateAccountModal }} />
      <HowItWorks readMore={true} />
      <HomeDetails />
      <HomeFeatured {...{ openCreateAccountModal }} />
      <HomeWebinarCta slopped={true} />
      <HomeCreate {...{ openCreateAccountModal }} />
      <HomeRecognition />
      <Footer />
    </>
  );
}

export default Home;
