import React, { useEffect, useContext, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Logo from "../../../components/common/logo";
import Cta from "../../../components/common/cta";
import { Context } from "../context";
import { Warning } from "../../../components/common/alerts";

function Join() {
  const { student, classRoom, lesson } = useContext(Context),
    [classRoomName, setClassRoomName] = useState(""),
    [studentName, setStudentName] = useState(""),
    nameTaken = student.nameTaken && student.nameTaken === studentName,
    classRoomEmpty = classRoom.isEmpty && classRoom.isEmpty === classRoomName,
    isConnectedToClassRoom = classRoom.allStudents[0],
    [showLogo, setShowLogo] = useState(true),
    handleJoinButtonClick = () => {
      isConnectedToClassRoom
        ? student.set("name", studentName)
        : classRoom.set("name", classRoomName);
    },
    handleKeypress = (e) => {
      if (e.keyCode === 13 || e.which === 13) {
        handleJoinButtonClick();
      }
    },
    variants = {
      header: {
        visible: {
          y: 0,
          transition: {
            duration: 0.4,
          },
        },
        hidden: {
          y: -400,
          transition: {
            duration: 0.8,
          },
        },
      },
      input: {
        visible: {
          y: 0,
          transition: { ease: "easeOut", delay: 0.7 },
        },
        hidden: {
          y: 200,
          transition: { ease: "easeOut" },
        },
      },
    };

  // show the video title before they add their name
  useEffect(() => {
    if (lesson.videoTitle) {
      setShowLogo(false);
      setTimeout(() => setShowLogo(true), 600);
    }
  }, [lesson.videoTitle]);

  return (
    <motion.div
      transition={{ staggerChildren: 0.3 }}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <motion.div
        variants={variants.header}
        className="bg-navy-600 question-heading-bg"
      >
        <div className="max-w-screen-xl mx-auto pt-3 pb-2 px-8 sm:pt-6 sm:pb-5 sm:px-4 lg:px-6">
          {lesson.videoTitle && showLogo && (
            <div>
              <motion.h2
                className="text-center mo-light text-teal-600 text-md mb-2"
                initial={{ y: -25, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0, duration: 0.2, ease: "easeIn" }}
              >
                You're watching{" "}
                <span className="pf-medium-italic text-teal-200 text-lg">
                  {lesson.videoTitle}
                </span>{" "}
                on...
              </motion.h2>
            </div>
          )}

          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            className="w-24 sm:w-48 mx-auto logo-box"
          >
            <Logo show={showLogo} />
          </motion.div>
        </div>
      </motion.div>

      <div className="center-position w-full overflow-hidden py-2">
        {(!isConnectedToClassRoom || !!classRoom.isEmpty) && (
          <motion.div
            variants={variants.input}
            className="focus:border-none absolute relative max-w-2xl mx-auto px-12 sm:px-6 overflow-hidden py-4"
          >
            <label
              htmlFor="nickname"
              className="text-navy-600 block text-2xl pb-1 pl-2 pf-medium tracking-wide"
            >
              What's your room name?
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="roomCode"
                className="large-input-box focus:outline-none py-6 mo-medium px-5 block w-full text-xl sm:text-2xl rounded-md"
                onChange={(e) =>
                  setClassRoomName(e.target.value.toUpperCase().trim())
                }
                value={classRoomName}
                onKeyPress={handleKeypress}
                placeholder="type the room name here"
              />
            </div>

            <Warning
              show={classRoomEmpty}
              text={`The room ${classRoomName} is empty`}
            />
          </motion.div>
        )}

        {!!isConnectedToClassRoom && !classRoom.isEmpty && (
          <motion.div
            variants={variants.input}
            className="focus:border-none absolute relative max-w-2xl mx-auto px-12 sm:px-6 overflow-hidden py-4"
          >
            <label
              htmlFor="nickname"
              className="text-navy-600 block text-2xl pb-1 pl-2 pf-medium tracking-wide"
            >
              What's Your Nickname?
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="nickname"
                className="large-input-box focus:outline-none py-6 mo-medium px-5 block w-full text-xl sm:text-2xl rounded-md"
                onChange={(e) => setStudentName(e.target.value.trim())}
                onKeyPress={handleKeypress}
                value={studentName}
                placeholder="choose your nickname"
              />
            </div>

            <Warning
              show={nameTaken}
              text={`The name ${studentName} is already taken`}
            />
          </motion.div>
        )}
      </div>

      <div className="absolute bottom-0 w-full">
        <AnimatePresence>
          {classRoom.overlay === "join" &&
            !classRoomEmpty &&
            !nameTaken &&
            ((classRoomName && !isConnectedToClassRoom) || studentName) && (
              <Cta onClick={handleJoinButtonClick}>Join Game</Cta>
            )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
}

export default Join;
