import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Images from "../../../assets/images";
import variants from "../../components/animationVariants";

function LargeQuote({ number }) {
  const controls = useAnimation(),
    [ref, inView] = useInView(),
    text = [
      "Movies have a unique capacity to create empathy for people and actions we don't know or have not experienced",
      "We acquire language in one way, and only one way. When we get comprehensible input in a low anxiety environment",
      "Learning a language without the cultural framework in which it exists is like cooking ethnic food without the spices of the region. You simply will remove all of the flavour from the language",
    ][number],
    by = [
      <>
        <b>Ted Hope</b> Film Produced "21 Grams" | Former Head of Content |
        Amazon Original Movies
      </>,
      <>
        <b>Professor Steven Krashen</b> Professor of Linguistics & Education |
        University of South California
      </>,
      <>
        <b>Bill Zart</b> Managing Partner | INDY Translation
      </>,
    ][number];

  useEffect(() => {
    console.log("asf", Images.quoteBg[1]);
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      style={{
        backgroundImage: Images.quoteBg[number + 1],
      }}
      className="py-20 md:py-36 lg:py-56 px-12 bg-navy-800 bg-fixed bg-cover bg-center"
    >
      <motion.main
        ref={ref}
        animate={controls}
        initial="hidden"
        className="mx-auto max-w-7xl px-4"
      >
        <motion.div
          variants={variants.fromBottom}
          className="text-center relative"
        >
          <svg
            className="absolute top-0 left-0 transform -translate-x-8 -translate-y-20 h-36 w-36 text-pink-200 opacity-50"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 144 144"
            aria-hidden="true"
          >
            <path
              strokeWidth="2"
              d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
            />
          </svg>
          <h1
            style={{ lineHeight: "1.4em" }}
            className="text-xl md:text-2xl lg:text-3xl tracking-tight font-extrabold text-white md:text-4xl lg:text-5xl px-2 xl:px-4"
          >
            {text}
          </h1>
          <p className="mt-6 max-w-xl mx-auto text-xs md:text-sm lg:text-base text-navy-200 md:mt-8 md:text-lg md:max-w-4xl">
            {by}
          </p>
        </motion.div>
      </motion.main>
    </motion.div>
  );
}

export default LargeQuote;
