import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Images from "../../../assets/images";
import window from "global";
const GSheetReader = require("g-sheets-api");

function DooDiversityWebinars() {
  const [webinars, setWebinars] = useState([]);
  const [recordings, setRecordings] = useState([]);
  const handleWebinarSignupClick = (url) => {
    window.open(url, "_blank").focus();
  };

  const sortWebinars = (a, b) =>
    parseInt(a.date.replace(/-/g, "") + a.time.replace(/:/g, "")) >
    parseInt(b.date.replace(/-/g, "") + b.time.replace(/:/g, ""));

  useEffect(() => {
    GSheetReader(
      {
        apiKey: "AIzaSyAkbVsIweThhzqtODK3aK5XfQKpPvfLRQ4",
        sheetId: "1YlYcDn3Y7YfrOgZJBdaswujEqJuToqt7tir8we63iXk",
        sheetName: "previous_diversity",
      },
      (results) => {
        setRecordings(results);
      }
    );

    GSheetReader(
      {
        apiKey: "AIzaSyAkbVsIweThhzqtODK3aK5XfQKpPvfLRQ4",
        sheetId: "1YlYcDn3Y7YfrOgZJBdaswujEqJuToqt7tir8we63iXk",
        sheetName: "doo_diversity",
      },
      (results) => {
        setWebinars(
          results
            .filter(
              (result) =>
                moment(result.date + " " + result.time)
                  .tz("Europe/London")
                  .format("x") > +new Date()
            )
            .sort(sortWebinars)
            .map((result, key) => {
              let utc = moment.utc(result.date + " " + result.time),
                format = "h:mma z";
              result.times = {
                la: utc.tz("America/Los_Angeles").format(format),
                ny: utc.tz("America/New_York").format(format),
                ld: utc.tz("Europe/London").format(format),
              };

              if (key === 0) {
                let daysUntil = moment(result.date).diff(
                  moment(new Date()),
                  "days"
                );
                result.daysUntil =
                  daysUntil === 1
                    ? "Our Next Webinar is Coming Tomorrow"
                    : daysUntil
                    ? `Our Next Webinar is Coming in ${daysUntil} days`
                    : "Our Next Webinar is Coming Today";
              }

              result.formatedDate = moment(result.date).format("MMM Do YYYY");
              return result;
            })
        );
      }
    );
  }, []);

  return (
    <div className="relative z-20 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8 mt-12">
      <div className="relative max-w-6xl mx-auto">
        <div className="mt-12 mx-auto max-w-none">
          <h2 className="mt-2 text-2xl lg:text-3xl font-semibold text-navy-600  leading-tight mb-43">
            View our calendar of upcoming events below and simply select the
            event/events of interest to reserve your place
          </h2>
          <p className="mt-3 text-md md:text-md text-navy-600 font-bold sm:mt-5 sm:max-w-3xl md:mt-5 lg:mx-0 tracking-wide">
            <b>
              Our online events are available for free and designed to
              demonstrate how we can assist businesses with our unique D&I
              awareness training packages using film and video with our
              interactive software to accelerate employees understanding of D&I
              topics.
            </b>
          </p>
          <p className="mt-3 text-md md:text-md text-navy-600 sm:mt-5 sm:max-w-3xl md:mt-5 lg:mx-0 tracking-wide">
            We also have on-demand events, and recordings of previous webinars
            and events for you to view.
          </p>
          <h4 className="mt-8 mb-6 text-xl md:text-xl text-pink-600 font-bold sm:max-w-3xl lg:mx-0 tracking-wide">
            Upcoming Webinars
          </h4>
        </div>
        <div className="mx-auto lg:grid lg:gap-4 lg:grid-cols-3 lg:max-w-none">
          {webinars.map((webinar, key) => (
            <div className="lg:flex rounded-lg fd-shadow-lg overflow-hidden lg:col-span-3 mb-8">
              <div className="lg:flex-2 bg-white p-6 flex flex-col justify-between">
                <div className="lg:flex-2">
                  <p className="text-sm font-bold text-teal-600">
                    {webinar.formatedDate}
                  </p>
                  <div>
                    <p className="text-lg font-semibold text-navy-600">
                      {webinar.title}
                    </p>
                    <p className="mt-2 text-navy-300">
                      {webinar.times.ld}
                      <span className="opacity-40 mx-2">|</span>
                      {webinar.times.ny}
                      <span className="opacity-40 mx-2">|</span>
                      {webinar.times.la}
                    </p>
                    {webinar.description && (
                      <p className="mt-3 text-sm text-navy-500 list-disc mx-10">
                        {webinar.description}
                      </p>
                    )}
                    {webinar.bulletPoint1 && (
                      <ul className="mt-3 text-sm text-navy-500 list-disc mx-10">
                        {webinar.bulletPoint1 && (
                          <li>{webinar.bulletPoint1}</li>
                        )}
                        {webinar.bulletPoint2 && (
                          <li>{webinar.bulletPoint2}</li>
                        )}
                        {webinar.bulletPoint3 && (
                          <li>{webinar.bulletPoint3}</li>
                        )}
                        {webinar.bulletPoint4 && (
                          <li>{webinar.bulletPoint4}</li>
                        )}
                        {webinar.bulletPoint5 && (
                          <li>{webinar.bulletPoint5}</li>
                        )}
                        {webinar.bulletPoint6 && (
                          <li>{webinar.bulletPoint6}</li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>

                <div className="mt-5 flex justify-between w-full flex-1">
                  <div className="ml-3 pt-3 font-bold">
                    <div className="flex space-x-1 text-sm text-navy-600">
                      {webinar.duration} min webinar
                    </div>
                  </div>
                  {webinar.zoomRegistrationLink && (
                    <button
                      onClick={() =>
                        handleWebinarSignupClick(webinar.zoomRegistrationLink)
                      }
                      className="ml-6 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md fd-shadow-sm text-base font-medium transition-all text-white bg-pink-600 hover:bg-pink-700"
                    >
                      Register Now
                    </button>
                  )}
                </div>
              </div>

              <img
                className="hidden lg:inline-block flex-shrink-0 h-full lg:w-1/3 object-cover"
                src={Images.webinars[webinar.image - 1]}
                alt=""
              />
            </div>
          ))}
        </div>
        <h4 className="mt-12 text-xl md:text-xl text-pink-600 font-bold sm:max-w-3xl lg:mx-0 tracking-wide">
          Previous Webinars
        </h4>

        <div className="mt-6 mx-auto lg:grid lg:gap-5 lg:grid-cols-3 lg:max-w-none">
          {recordings.map((recording, key) => (
            <div
              key={key}
              className="flex flex-col rounded-lg fd-shadow-lg overflow-hidden mb-8 lg:mb-0"
            >
              <div className="flex-shrink-0">
                <div className="h-60 w-full object-cover">
                  <iframe
                    className="h-60 w-full"
                    src={`https://www.youtube.com/embed/${recording.youtubeCode}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <div className="block mt-2">
                    <p className="text-lg font-semibold text-navy-600">
                      {recording.title}
                    </p>
                    {recording.description && (
                      <p className="mt-3 text-sm text-navy-500 list-disc mr-10">
                        {recording.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DooDiversityWebinars;
