import React from "react";
import FullDetails from "./fullDetails";
import window from "global";

function CreateAccount({ show, setShow, setShowLogin, setShowThankYou }) {
  const props = {
    show,
    setShow,
    setShowLogin,
    showUserPassword: true,
    showLoginLink: true,
    title:
      "Unlock the power of film and video. Engage your students like never before.",
    title2: "We just need a few more details to set you up.",
    checkDetails: (email, username, setWarning, callback) => {
      const checkUrl = `/api/v1/user/check/${email}/${username}`;

      fetch(checkUrl)
        .then((res) => res.json())
        .then((data) => {
          if (data.emailTaken)
            setWarning("Account associated with email address already exists");
          else if (data.usernameTaken) setWarning("Username already taken");
          else {
            callback();
          }
        });
    },
    handleSubmit: (postData, setWarning) => {
      const checkUrl = `/api/v1/user/check/${postData.email}/${postData.username}`;

      fetch(checkUrl)
        .then((res) => res.json())
        .then((data) => {
          if (data.emailTaken)
            setWarning("Account associated with email address already exists");
          else if (data.usernameTaken) setWarning("Username already taken");
          else {
            fetch(`/api/v1/user/new`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({
                username: postData.username,
                password: postData.password,
                email: postData.email,
                firstLogin: true,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                data.date_created = new Date();
                window.localStorage.setItem("fda_user", JSON.stringify(data));

                // manually load react pixel to use on SSR
                import("react-facebook-pixel")
                  .then((module) => module.default)
                  .then((ReactPixel) => {
                    ReactPixel.track("SubmitApplication");
                  });

                fetch(`/api/v1/contacts/signUp`, {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  method: "POST",
                  body: JSON.stringify(postData),
                })
                  .then((res) => res.json())
                  .then(() => {
                    setShow(false);
                    setShowThankYou(true);
                  });
              });
          }
        });
    },
  };

  return <FullDetails {...props} />;
}

export default CreateAccount;
