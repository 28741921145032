import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import { useInView } from "react-intersection-observer";
import Images from "../../../assets/images";
import BgParticles from "../../components/bgParticles";

function Hero({ setMasterShowCreateAccount }) {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <main className="w-full bg-gray-100 sloppy-bottom overflow-hidden pt-4 lg:pt-10">
      <BgParticles />

      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="relative max-w-6xl mx-auto lg:py-32 pt-24 pb-14 px-8"
      >
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div
            variants={variants.fromLeft}
            className="md:mx-auto col-span-9"
          >
            <h1 className="lg:mt-2 text-5xl lg:text-6xl font-semibold text-navy-700 leading-tight mb-4">
              FilmDoo Academy <i>for</i>{" "}
              <span className="text-teal-600">
                Primary Schools, Secondary Schools, Special Schools & Multi
                Academy Trusts
              </span>
            </h1>
          </motion.div>
          <motion.div
            variants={variants.fromLeft2}
            className="md:max-w-2xl lg:col-span-8 text-left"
          >
            <p className="text-navy-600 text-base mx-auto w-full font-bold">
              Are you looking to introduce educational innovation to your school
              and improve learning outcomes? As schools and educational
              institutions were disrupted as a result of the COVID-19 pandemic,
              the need to raise and maintain student engagement in lessons has
              never been higher.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              The average Gen Z student now spends at least four hours a day
              watching mobile videos. While traditional teaching methods
              struggle to keep students engaged, the modern students now respond
              to film- and video-based learning - which is at the heart of what
              we do at FilmDoo!
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              A partnership with FilmDoo Academy can provide your school with a
              transformative new educational tool that leverages the power of
              engaging films and videos. Empower your teachers and staff to turn
              any film and video into an interactive lesson in minutes!
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              FilmDoo is a flexible multi-purpose edtech platform that can be
              used in classrooms, during online classes and for student homework
              and revision! Compatible for all ages and student levels across
              different teaching subjects, check out FilmDoo Academy for your
              school today!
            </p>
            <p className="text-teal-600 italic font-bold mt-2 lg:mt-5 text-base mx-auto w-full">
              Improve Student Learning Using Film & Video
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              Deliver interactive, immersive and super engaging lessons.
            </p>

            <div className="mt-3 lg:mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 pt-4">
              <button
                onClick={setMasterShowCreateAccount}
                className="transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-lg transform focus:outline-none rounded-md fd-shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white bg-pink-600 md:py-4 md:text-lg md:px-10"
              >
                Activate Free Account
              </button>
            </div>
          </motion.div>
          <motion.div
            variants={variants.fromRight}
            className="relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 flex flex-col lg:col-span-4"
          >
            <div className="relative mx-auto w-full lg:max-w-lg mb-6 lg:mb-12 mt-8 lg:mt-0">
              {Images.students.d}
            </div>
            <div className="relative mx-auto w-full lg:max-w-lg">
              <iframe
                className="rounded-xl fd-shadow w-full h-52"
                src="https://www.youtube.com/embed/9COc9mR1UE4"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </motion.div>
        </div>
      </motion.div>
    </main>
  );
}

export default Hero;
