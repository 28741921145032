import React, { useContext, useEffect } from "react";
import { Context } from "../context";
import Background from "../../../components/common/background";
import EmojiSelection from "../components/emojiSelection";
import Div100vh from "react-div-100vh";

function Watching() {
  const { classRoom, student, lesson } = useContext(Context);

  // make sure the correct wait screen is set every time the player loads
  useEffect(() => {
    classRoom.setOverlay("watching");
    lesson.setPlaying(true);
  }, []);

  useEffect(() => {
    if (classRoom.overlay === "watching") lesson.setPlaying(true);
  }, [classRoom.overlay]);

  return (
    <Div100vh className="absolute w-full h-full top-0 left-0 right-0 bottom-0 z-0">
      <Background dark={true}>
        <Div100vh className="flex flex-col justify-between h-full w-full overflow-x-hidden relative">
          <div>
            <h2 className="flex-none w-full text-center text-white text-4xl pf-medium mt-4">
              <span className="text-xl opacity-50">Hi</span> {student.name}
            </h2>
          </div>

          <div className="flex-none">
            <h3 className="w-full text-center text-white text-lg mo-medium opacity-50">
              You're watching...
            </h3>
            <h1 className="w-full text-center text-white text-3xl mo-medium">
              {lesson.videoTitle}
            </h1>
          </div>

          <div>
            <h2 className="flex-none w-full text-center text-white text-4xl pf-medium mb-4">
              {student.totalScore}{" "}
              <span className="text-xl opacity-50">Points</span>
            </h2>
            <EmojiSelection />
          </div>
        </Div100vh>
      </Background>
    </Div100vh>
  );
}

export default Watching;
