import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import Images from "../../../assets/images";
import BgParticles from "../../components/bgParticles";

function SchoolsBudget() {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <main className="w-full bg-gray-100 overflow-hidden sloppy-top relative">
      <BgParticles color="pink" />
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="max-w-6xl mx-auto lg:py-32 py-24 px-6 relative"
      >
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div
            variants={variants.fromLeft}
            className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left"
          >
            <h2>
              <span className="block text-2xl tracking-tight sm:text-4xl pr-12">
                <span className="block text-pink-600 pt-8">
                  Where can your school <b>find the budget</b> for our edtech
                  tool?
                </span>
              </span>
            </h2>
            <p className="mt-3 text-base text-navy-600 sm:mt-5 sm:text-sm lg:text-lg">
              We can work with you to help your school access funding available
              for new edtech tools, including the Catch up Fund, EEF, the
              Disadvantage Fund, People Premium and support for Off Campus
              students.
            </p>

            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 pt-4">
              <Link
                to={{ pathname: "/contact" }}
                className="transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-lg transform focus:outline-none rounded-md fd-shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white bg-pink-600 md:py-4 md:text-lg md:px-10"
              >
                Get In Touch
              </Link>
            </div>
          </motion.div>
          <motion.div
            variants={variants.fromRight}
            className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center"
          >
            <div className="relative mx-auto w-full lg:max-w-lg">
              {Images.students.c}
            </div>
          </motion.div>
        </div>
      </motion.div>
    </main>
  );
}

export default SchoolsBudget;
