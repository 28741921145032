import React from "react";
import Footer from "../../components/footer";
import Menu from "../../components/menu";

function TermsAndConditions() {
  return (
    <div className="bg-gray-100">
      <Menu />
      <div className="flex-grow container px-4 md:px-0 max-w-6xl mx-auto pt-32">
        <div className="sm:w-4/5 mx-auto relative bg-white flex flex-wrap rounded z-2 fd-shadow-lg overflow-hidden mb-8">
          <div
            className="px-8 py-6 mo-light text-sm  tracking-wide"
            id="text-page"
          >
            <h1>Term & Conditions</h1>

            <p>
              This agreement or terms of service (the “<b>Agreement</b>” or “
              <b>Terms of Service</b>”) was originally written in English (UK).
              To the extent any translated version of this agreement conflicts
              with the English version, the English version will prevail.
            </p>

            <ol>
              <li>
                General Terms
                <ol>
                  <li>
                    The various FilmDoo websites (“<b>Websites</b>”) and mobile
                    applications and related services, including the FilmDoo
                    Online Edutainment Tool (together with the Websites, the “
                    <b>Service</b>”) and the use of the Service are subject to
                    the following terms and conditions of Service (“
                    <b>Terms and Conditions</b>”). By accessing or using any
                    part of the Service, you represent that you have read,
                    understood, and agree to be bound by these Terms and
                    Conditions including any future modifications. FilmDoo may
                    amend, update or change these Terms and Conditions without
                    notice being provided and your subsequent use of the Service
                    indicates your accepted of these changes. If you do not
                    agree to abide by these Terms and Conditions, you are not
                    authorised to use, access or participate in the Service.
                  </li>
                  <li>
                    Educational institutions utilising the Service are
                    responsible for monitoring the rights and interests of their
                    students and must therefore take special care in the review
                    of this agreement. Educational institutions shall be
                    responsible for obtaining, where applicable, pertinent
                    consent from parents, legal guardians or eligible students
                    prior to utilising the service. FilmDoo shall not be
                    responsible for any negligence of the educational
                    institution in the review of this agreement or the
                    obtainment, where applicable, of the necessary parental
                    consent.
                  </li>
                  <li>
                    The Service allows users to access and use a variety of
                    educational services, including learning or practicing a
                    language, or a subject. FilmDoo may, in its sole discretion
                    and at any time, update, change, suspend, make improvements
                    to or discontinue any aspect of the Service, temporarily or
                    permanently.
                  </li>
                </ol>
              </li>

              <li>
                Certification and Accreditation
                <ol>
                  <li>
                    The Service is not accredited or connected in any way to the
                    United Kingdom Department of Education or any other
                    educational governing body, and therefore does not count
                    towards any governing course or exam material.
                  </li>
                  <li>
                    There is no certification in association with the Service,
                    its services or any course material or completion of any
                    work associated with the service.
                  </li>
                </ol>
              </li>

              <li>
                Registration; Submission of Content
                <ol>
                  <li>
                    <b>Registration</b> - In connection with registering for and
                    using the Service, you agree (i) to provide accurate,
                    current and complete information about you and/or your
                    organisation as requested by FilmDoo; (ii) to maintain the
                    confidentiality of your password and other information
                    related to the security of your account; (iii) to maintain
                    and promptly update any registration information you provide
                    to FilmDoo, to keep such information accurate, current and
                    complete; and (iv) to be fully responsible for all use of
                    your account and for any actions that take place through
                    your account.
                  </li>
                  <li>
                    <b>Course Contributor Submissions</b> - If you are or become
                    a Course Contributor, you may offer to contribute new
                    courses to the Service, as agreed between you and FilmDoo on
                    a case-by-case basis. Subject to any guidelines posted on
                    the Service, you may perform any such translations or create
                    any such courses in accordance with your own schedule and
                    using your own facilities and resources. You are not
                    required to become a Course Contributor and you may cease
                    your activities as a Course Contributor at any time. You
                    acknowledge that you do not desire and will not receive
                    compensation for your activities as a Course Contributor or
                    for our use of any Course Contributor Materials (as defined
                    below) you submit. Any translation of an existing FilmDoo
                    course you submit or have submitted and any new course you
                    submit or have submitted as a Course Contributor
                    (collectively, “<b>Course Contributor Materials</b>”),
                    whether for languages or other subjects, are recognised as
                    being made by you (subject of course to us retaining
                    ownership of the existing FilmDoo course you translated). By
                    submitting any Course Contributor Material, you grant us a
                    fully paid up, royalty-free, perpetual, sub-licensable
                    license to reproduce, display, perform, modify, create
                    derivative works of, distribute and otherwise use such
                    Course Contributor Material in any manner. In addition,
                    Course Contributors have the opportunity to benefit from
                    additional monetization opportunities through the sale of
                    their course to third parties by FilmDoo on a revenue-share
                    basis, if this is of interest. FilmDoo reserves the right to
                    use all Course Contributor Materials, whether the course in
                    its entirety or parts of the course, created on the Websites
                    for non-commercial purposes, such as marketing, publicity
                    and promotion.
                  </li>
                  <li>
                    <b>General Content</b> - As a condition of submitting any
                    ratings, reviews, information, data, text, photographs,
                    audio clips, audiovisual works, translations or other
                    materials on the Services (“<b>Content</b>”), you hereby
                    grant to FilmDoo a royalty free, perpetual, irrevocable,
                    worldwide, nonexclusive, transferable, and sub-licensable
                    license to use, reproduce, copy, adapt, modify, merge,
                    distribute, publicly display, create derivative works from,
                    incorporate such Content into other works; sublicense
                    through multiple tiers the Content, and acknowledge that
                    this license cannot be terminated by you once your Content
                    is submitted to FilmDoo. You represent that you own or have
                    secured all legal rights necessary for the Content submitted
                    by you to be used by you, FilmDoo, and others as described
                    and otherwise contemplated in these Terms of Service. You
                    understand that other users will have access to the Content
                    and that neither they nor FilmDoo have any obligation to you
                    or anyone else to maintain the confidentiality of the
                    Content.
                  </li>
                  <li>
                    FilmDoo reserves the right for content that is used for any
                    course to be removed at any time without explanation.
                    Content may be lost or not be available through no fault of
                    FilmDoo.
                  </li>
                </ol>
              </li>

              <li>
                Representations and Warranties
                <ol>
                  <li>
                    You represent and warrant to FilmDoo that your access and
                    use of the Service will be in accordance with these Terms of
                    Service and with all applicable laws, rules and regulations
                    of the United Kingdom and any other relevant jurisdiction,
                    including those regarding online conduct or acceptable
                    content, and those regarding the transmission of data or
                    information exported from the United Kingdom and/or the
                    jurisdiction in which you reside. You further represent and
                    warrant that you have created or own any material you submit
                    via the Service (including Course Contributor Materials,
                    Activity Materials, and Content) and that you have the
                    right, as applicable, to grant us a license to use that
                    material as set forth above or the right to assign that
                    material to us as set forth below. When you register with
                    the Service, you further represent and warrant that the
                    identification materials you submit to FilmDoo are true and
                    accurate representations of legal identification documents,
                    issued to you by a government entity in accordance with
                    local laws, and that you are registering for the Service to
                    be used as an educational tool. You further represent and
                    warrant that you will safeguard your password, user name,
                    and account information, will not share your password or
                    user name with any third party, and will not allow any third
                    party to access your Service account for any purposes.
                  </li>
                  <li>
                    You release us from all liability for you having acquired or
                    not acquired Content through the Services. We make no
                    representations concerning any Content contained in or
                    accessed through the Services, and we will not be
                    responsible or liable for the accuracy, copyright
                    compliance, or legality of material or Content contained in
                    or accessed through the Services.
                  </li>
                  <li>
                    The Services and content are provided 'as is', 'as
                    available' and without warranty of any kind, express or
                    implied, including, but not limited to, the implied
                    warranties of title, non-infringement, merchantability and
                    fitness for a particular purpose, and any warranties implied
                    by any course of performance or usage of trade, all of which
                    are expressly disclaimed.
                  </li>
                </ol>
              </li>

              <li>
                Customer Data
                <ol>
                  <li>
                    <b>Definition - "Customer Data"</b> means any and all
                    electronic data, Content, and information uploaded or
                    submitted to the Services by you or by a third party on your
                    behalf. You retain all of your rights to your Customer Data.
                  </li>
                  <li>
                    <b>License to Customer Data</b> - You hereby grant FilmDoo a
                    non-exclusive, worldwide, royalty-free, fully paid-up right
                    and license to use, copy, access, process, reproduce,
                    perform, display, modify, distribute, and transmit your
                    Customer Data in connection with the Services and with our
                    Privacy Policy. You acknowledge and agree that (i) the
                    quality of the Services and the Platform depend on the
                    uploading or other provisioning of the Customer Data into
                    the Platform or the Services, as applicable and (ii) FilmDoo
                    will not assume any responsibility for, or undertake to
                    verify, the accuracy or completeness of the Customer Data
                    entered by you. Furthermore, you also hereby do and shall
                    grant each user of the FilmDoo Website and/or the Services a
                    non-exclusive right and license to access your Customer Data
                    posted on portions of the Services that are viewable to
                    other users, and to use, edit, modify, reproduce,
                    distribute, and display such Customer Data in accordance
                    with the terms, features, and settings of the Services made
                    available by FilmDoo and any additional rights and
                    restrictions granted by you to such users.
                  </li>
                  <li>
                    <b>Aggregate Data</b> - Notwithstanding anything to the
                    contrary set forth herein, FilmDoo may collect and use data
                    regarding the use and performance of the Service in
                    anonymized and aggregated form, to analyze and improve the
                    Service and for distribution in general benchmarking data
                    and industry reports, provided that any reported user data
                    is aggregated and anonymized such that no personally
                    identifying information of Customer or its users is
                    revealed.
                  </li>
                  <li>
                    <b>Representations and Warranties</b> - You represent,
                    warrant, and covenant that (i) all Customer Data is accurate
                    and compliant with all applicable laws, rules, and
                    regulations; (ii) you own all rights, title, and interest in
                    and to the Customer Data, or have otherwise secured all
                    necessary rights in the Customer Data as may be necessary to
                    permit the access, use, and distribution thereof as
                    contemplated by these Terms; (iii) you shall only use the
                    Services in accordance with all applicable laws, rules, and
                    regulations, these Terms and any relevant documentation
                    provided by FilmDoo and (iv) you will not, and will not
                    permit any third party to upload, download, post, submit,
                    provide, transmit, distribute, or otherwise make available
                    to or through the Services any Customer Data that (A) is
                    unlawful, infringing, deceptive, fraudulent, invasive of
                    another's privacy, tortious, obscene, or that otherwise
                    violates any other right of any third party, including any
                    intellectual property, proprietary, or privacy rights, or
                    that is otherwise inappropriate, as determined by us in our
                    sole discretion; (B) contains any viruses, code, files, or
                    programs designed or intended to disrupt, damage, limit, or
                    interfere with the proper function of any software,
                    hardware, or telecommunications equipment or that is or can
                    be otherwise malicious or disruptive; (C) constitutes
                    unauthorized or unsolicited advertising, junk, or bulk email
                    ("spamming") or otherwise violates federal CAN-SPAM
                    regulation. You will not provide FilmDoo with any personally
                    identifying information except for User information as
                    necessary to allow Users to access the Services as set forth
                    in these Terms. You are responsible for complying with
                    CAN-SPAM, including without limitation for supplying an
                    "Unsubscribe" link in any emails required to include such
                    link.
                  </li>
                </ol>
              </li>

              <li>
                Inappropriate Use
                <p>
                  You will not upload, display or otherwise provide on or
                  through the Service any content that: (i) is libellous,
                  defamatory, abusive, threatening, harassing, hateful,
                  offensive or otherwise violates any law or infringes upon the
                  right of any third party (including copyright, trademark,
                  privacy, publicity or other personal or proprietary rights);
                  or (ii) in FilmDoo’s sole judgment, is objectionable or which
                  restricts or inhibits any other person from using the Service
                  or which may expose FilmDoo or its users to any harm or
                  liability of any kind. You will not use the Service to collect
                  information about our process or to develop outside of the
                  FilmDoo platform, or other copy any material from our service.
                </p>
              </li>

              <li>
                Indemnification of FilmDoo
                <ol>
                  <p>
                    You agree to defend, indemnify and hold harmless FilmDoo and
                    its directors, officers, employees, contractors, agents,
                    suppliers, licensors, successors and assigns, from and
                    against any and all losses, claims, causes of action,
                    obligations, liabilities and damages whatsoever, including
                    solicitors fees, arising out of or relating to your access
                    or use of the Service, any false representation made to us
                    (as part of these Terms and Conditions or otherwise), your
                    breach of any of these Terms and Conditions, or any claim
                    that any translation we provide to you is inaccurate,
                    inappropriate or defective in any way whatsoever.
                  </p>
                </ol>
              </li>

              <li>
                Payments and Billing
                <ol>
                  <li>
                    <b>Services</b> - Some of our Services may be subject to
                    payments now or in the future (the "<b>Premium Services</b>
                    "). Please note that any payment terms presented to you in
                    the process of using or signing up for a Services are deemed
                    part of these Terms of Service. All amounts are stated in,
                    and shall be paid in the currency stipulated in the payment
                    gateway during purchase. If you have any questions, please
                    click contact us at{" "}
                    <a href="mailto:info@filmdoo.com" target="_blank">
                      info@filmdoo.com
                    </a>
                    .
                  </li>
                  <li>
                    <b>Billing</b> - All Services must be paid for in advance.
                    We use a third-party payment processor (the "
                    <b>Payment Processor</b>") to bill you through a payment
                    account linked to your account on the Services (your "
                    <b>Billing Account</b>") for use of the Services. The
                    processing of payments will be subject to the terms,
                    conditions, and privacy policies of the Payment Processor in
                    addition to these Terms. We are not responsible for error by
                    the Payment Processor. We reserve the right to correct any
                    errors or mistakes that the Payment Processor makes even if
                    the Payment Processor has already requested or received
                    payment. The terms of your payment will be based on your
                    Payment Method and may be determined by agreements between
                    you and the financial institution, credit card issuer, or
                    other provider of your chosen Payment Method. If we, through
                    the Payment Processor, do not receive payment from you, you
                    agree to pay all amounts due on your Billing Account upon
                    demand. You may pay for our Services via our online payment
                    transaction system, whereby this can be for Services paid
                    for on a Transactional one-time basis or as part of a
                    Monthly Subscription or Annual Subscription Fee. We reserve
                    the right to change the fees applicable to any of our
                    Services, or the method of payment for the Services, at any
                    time by posting such information on the Website. Except as
                    required by law, you will not be entitled to any credit or
                    refund for any Video Content purchased/rented from us.
                  </li>
                  <li>
                    <b>Current Information Required</b> - You must provide
                    current complete and accurate information for your billing
                    account. You must promptly update all information to keep
                    your billing account current, complete and accurate. Please
                    contact us as{" "}
                    <a href="mailto:info@filmdoo.com" target="_blank">
                      info@filmdoo.com
                    </a>{" "}
                    if you need any assistance. If you fail to provide any of
                    the foregoing information, you agree that we may continue.{" "}
                  </li>
                  <li>
                    <b>Cancelling or Changing Services</b> - To stop using the
                    Services at any time, go to Account settings. Please contact
                    us at{" "}
                    <a href="mailto:info@filmdoo.com" target="_blank">
                      info@filmdoo.com
                    </a>{" "}
                    if you need any assistance. Any request for cancellation
                    must be sent at least five (5) business days prior to the
                    end of a subscription term if FilmDoo offers one in order to
                    allow for adequate processing time.
                  </li>
                  <li>
                    <b>Chargeback Policy; Disputes</b> - If you have a question
                    about charges made to your Account, please contact us
                    immediately. If the charges were made in error, we will
                    credit your billing account or payment method for the
                    appropriate amount. Please note that FilmDoo has a
                    zero-tolerance policy for chargebacks. Any customer who
                    disputes a credit card payment that is found to be valid
                    will be permanently banned from use of FilmDoo. Before
                    commencing any court action, you will first submit a ticket
                    through FilmDoo’s help desk. FilmDoo shall not be deemed in
                    breach of the Terms of Service unless you have given FilmDoo
                    notice of the breach and FilmDoo has failed to cure such
                    breach within thirty (30) days after receipt of such notice.
                    In no event shall any breach entitle you to rescind the
                    rights granted hereunder.
                  </li>
                  <li>
                    <b>Feedback</b> - At all times FilmDoo will have the
                    unrestricted right to use and act upon any suggestions,
                    ideas, enhancement requests, feedback and recommendations
                    you provide specifically relating to the features,
                    functionality and performance of the Services. To provide us
                    with feedback, please drop us an email at{" "}
                    <a href="mailto:info@filmdoo.com" target="_blank">
                      info@filmdoo.com
                    </a>
                  </li>
                  <li>
                    <b>Responsibility for third party actions</b> - In no event
                    will FilmDoo be responsible for the actions or inactions of
                    any third-party payment processor, including, but not
                    limited to, system downtime or payment service outages.
                  </li>
                  <li>
                    <b>Pricing changes</b> - FilmDoo reserves the right to
                    modify and change pricing models at any time as deemed
                    necessary to the business. A minimum notification period of
                    thirty (30) days will be sent to existing subscription
                    members with full changes and details of new charges that
                    will apply.
                  </li>
                </ol>
              </li>

              <li>
                Copyright Policy
                <ol>
                  <li>
                    We have adopted the following general policy toward
                    copyright infringement in accordance with the Digital
                    Millennium Copyright Act (
                    <a
                      href="http://lcweb.loc.gov/copyright/legislation/dmca.pdf"
                      target="_blank"
                    >
                      http://lcweb.loc.gov/copyright/legislation/dmca.pdf
                    </a>
                    ).
                  </li>
                  <li>
                    Procedure for Reporting Copyright Infringement:
                    <p>
                      If you believe that material or content residing on or
                      accessible through our Service infringes a copyright,
                      please send a notice of copyright infringement containing
                      the following information to the Designated Agent listed
                      below:
                      <ul>
                        <li>
                          A physical or electronic signature of a person
                          authorized to act on behalf of the owner of the
                          copyright that has been allegedly infringed;
                        </li>
                        <li>
                          Identification of works or materials being infringed;
                        </li>
                        <li>
                          Identification of the material that is claimed to be
                          infringing including information regarding the
                          location of the infringing materials that the
                          copyright owner seeks to have removed, with sufficient
                          detail so that we are capable of finding and verifying
                          its existence;
                        </li>
                        <li>
                          Contact information about the notifier including
                          address, telephone number and, if available, email
                          address;
                        </li>
                        <li>
                          A statement that the notifier has a good faith belief
                          that the material is not authorized by the copyright
                          owner, its agent, or the law; and
                        </li>
                        <li>
                          A statement made under penalty of perjury that the
                          information provided is accurate and the notifying
                          party is authorized to make the complaint on behalf of
                          the copyright owner.
                        </li>
                      </ul>
                    </p>
                  </li>
                </ol>
              </li>

              <li>
                Refund Policy
                <ol>
                  <li>
                    <b>Data Security.</b> FilmDoo shall maintain appropriate
                    administrative, physical, and technical safeguards designed
                    to protect the security of the Services and Customer Data.
                    If Customer's use of the Services involves processing
                    personal data pursuant to Regulation 2016/679 (the "
                    <b>GDPR</b>") and/or transferring personal data outside the
                    European Economic Area or Switzerland to any country not
                    deemed by the European Commission as providing an adequate
                    level of protection for personal data, the terms of the data
                    processing addendum shall apply to such personal data and be
                    incorporated into the Terms of Service upon the Effective
                    Date
                  </li>
                </ol>
              </li>

              <li>
                Refund Policy
                <ol>
                  <li>
                    In the event that FilmDoo suspends or terminates your use of
                    the Service or these Terms and Conditions or you close your
                    account voluntarily, you understand and agree that you will
                    receive no refund or exchange of any kind, including, any
                    Content or data associated with your use of the Service, or
                    for anything else.
                  </li>
                </ol>
              </li>

              <li>
                Third-Party Links, Sites, and Services
                <ol>
                  <li>
                    The Service may contain links to third-party websites,
                    advertisers, services, special offers, or other events or
                    activities that are not owned or controlled by FilmDoo. We
                    do not endorse or assume any responsibility for any such
                    third-party sites, information, materials, products, or
                    services. If you access any third party website, service, or
                    content from FilmDoo, you understand that these Terms and
                    Conditions do not apply to your use of such sites. You
                    expressly acknowledge and agree that FilmDoo shall not be
                    responsible or liable, directly or indirectly, for any
                    damage or loss arising from your use of any third-party
                    website, service, or content.
                  </li>
                  <li>
                    The Service may include advertisements, which may be
                    targeted to the Content or information on the Service, or
                    other information. The types and extent of advertising by
                    FilmDoo on the Service are subject to change. In
                    consideration for FilmDoo granting you access to and use of
                    the Service, you agree that FilmDoo and its third party
                    providers and partners may place such advertising in
                    connection with the display of content or information
                    submitted by you or others.
                  </li>
                </ol>
              </li>

              <li>
                Termination
                <ol>
                  <li>
                    FilmDoo may terminate your access and use of the Service
                    immediately at any time, for any reason, and at such time
                    you will have no further right to use the Service. You may
                    terminate your FilmDoo account at any time by following the
                    instructions available through the Service. The provisions
                    of these Terms and Conditions relating to the protection and
                    enforcement of FilmDoo’s proprietary rights, your
                    representations and warranties, disclaimer of
                    representations and warranties, release and indemnities,
                    limitations of liability and types of damages, ownership of
                    data and information, governing law and venue, and
                    miscellaneous provisions shall survive any such termination.
                  </li>
                  <li>
                    Termination or cancellation of your Account by either party
                    may result in the forfeiture and destruction of all
                    information and data, including Customer Data, associated
                    with your Account. Termination, cancellation or expiration
                    of your Account will result in a loss of your Customer Data;
                    we may delete or destroy all copies of your Customer Data in
                    our systems or otherwise in our possession or control,
                    unless legally prohibited. We reserve the right, including
                    after termination, to access, read, preserve, and disclose
                    any information, including without limitation Customer Data,
                    as we reasonably believe is necessary to (i) satisfy any
                    applicable law, regulation, legal process or governmental
                    request; (ii) enforce these Terms, including investigation
                    of potential violations hereof; (iii) detect, prevent, or
                    otherwise address fraud, security or technical issues; (iv)
                    respond to user support requests or (v) protect the rights,
                    property or safety of us, our users and the public.
                  </li>
                </ol>
              </li>

              <li>
                Proprietary Rights in Service Content and Activity Materials
                <ol>
                  <li>
                    All content available through the Service, including
                    designs, text, graphics, images, information, software,
                    audio and other files, and their selection and arrangement
                    (the "<b>Service Content</b>"), are the proprietary property
                    of FilmDoo or its licensors. No Service Content may be
                    modified, copied, distributed, framed, reproduced,
                    republished, downloaded, scraped, displayed, posted,
                    transmitted, or sold in any form or by any means, in whole
                    or in part, other than as expressly permitted in these Terms
                    and Conditions or on a signed agreement. You may not use any
                    data mining, robots, scraping or similar data gathering or
                    extraction methods to obtain Service Content. As between you
                    and FilmDoo, all data, information and materials generated
                    from your access and use of the educational activities made
                    available on or through the Service, including translated
                    content generated by you (collectively, the “
                    <b>Activity Materials</b>”), shall be exclusively owned by
                    FilmDoo, and you shall not have any right to use such
                    Activity Materials except as expressly authorized by these
                    Terms and Conditions. Activity Materials will not include
                    Translation Materials. By using the Service, you hereby
                    assign to FilmDoo any and all rights, title and interest,
                    including any intellectual property rights or proprietary
                    rights, in the Activity Materials. All rights of FilmDoo or
                    its licensors that are not expressly granted in these Terms
                    and Conditions are reserved to FilmDoo and its licensors.
                  </li>
                </ol>
              </li>

              <li>
                Intellectual Property Rights
                <ol>
                  <li>
                    All intellectual property rights relating to the Service
                    (including without limitation the content, data, layout and
                    design, video, graphics, text, images, software, database,
                    applications and underlying source code) are protected by
                    copyright, and are owned by us or our licensors and/or
                    Content Providers, unless otherwise specified.
                  </li>
                  <li>
                    We grant you a non-exclusive, royalty-free, nationwide,
                    revocable licence to use and access the Website solely in
                    accordance with these Terms.
                  </li>
                  <li>
                    Upon payment of the relevant fees (as specified in the
                    Service), we grant you a non-exclusive, royalty-free,
                    revocable licence to use and access the relevant Video
                    Content in accordance with the Usage Rules and any
                    applicable Additional Conditions. Such licence is subject to
                    your compliance with these Terms, the Usage Rules,
                    Additional Conditions and any applicable laws. Where Free
                    Content is made available to you, we grant you a
                    non-exclusive, royalty-free, revocable licence to use and
                    access the relevant Free Content in accordance with the
                    Usage Rules and any applicable Additional Conditions.
                  </li>
                  <li>
                    FilmDoo is a registered trade mark of FilmDoo Ltd. All other
                    trade-marks that appear as part of the Service are owned by
                    us or our licensors, unless otherwise specified.
                  </li>
                  <li>
                    You may not reproduce, transmit, copy, distribute, modify,
                    link, display, use, transfer or sell any content of this
                    Service (including without limitation videos, text,
                    graphics, software, names, trade-marks and logos), or use
                    any such content for commercial purposes, except as set out
                    in these Terms, without our prior written consent.
                  </li>
                </ol>
              </li>

              <li>
                Privacy Policy
                <p>
                  Please refer to{" "}
                  <a href="/privacy-policy">
                    our specific documentation titled Privacy Policy
                  </a>
                  .
                </p>
              </li>

              <li>
                Governing Law
                <p>
                  These terms and conditions and any dispute or claim arising
                  out of or in connection with it or its subject matter or
                  formation (including non-contractual disputes or claims) shall
                  be governed by and construed in accordance with the laws of
                  England and Wales. The parties irrevocably agree that the
                  courts of England and Wales shall have exclusive jurisdiction
                  to settle any dispute or claim that arises out of or in
                  connection with this agreement or its subject matter or
                  formation (including non-contractual disputes or claims)..
                </p>
              </li>

              <li>
                Miscellaneous
                <ol>
                  <li>
                    The Site and Services may be used and accessed solely for
                    lawful purposes. You agree to abide by all applicable local,
                    state, national and foreign laws, treatises and regulations
                    in connection with your use of the Service. In addition,
                    without limitation, you agree that you will not do any of
                    the following while using or accessing the Site and
                    Services:
                    <ol className="ol-roman-numerals">
                      <li>
                        Disable, hack, circumvent or otherwise interfere with
                        security related features of the Site or features that
                        prevent or restrict use or copying of any FilmDoo
                        content or materials;
                      </li>
                      <li>
                        Use any metadata, meta tags or other hidden text
                        utilizing a FilmDoo name, trademark, URL or product
                        name;
                      </li>
                      <li>
                        Upload, submit, post, email or otherwise transmit any
                        unsolicited or unauthorized advertising, promotional
                        materials, junk mail, spam, chain letters, so-called
                        pyramid schemes or any other form of solicitation;
                      </li>
                      <li>
                        Forge any TCP/IP packet header or any part of the header
                        information in any posting or in any way use the
                        Services to send altered, deceptive or false
                        source-identifying information;
                      </li>
                      <li>
                        Upload, submit, post, email, or otherwise transmit, via
                        the Services, any Content or other materials that are,
                        in the sole opinion of FilmDoo, unlawful, harmful,
                        threatening, obscene, harassing, defamatory or hateful
                        or that contain objects or symbols of hate, invade the
                        privacy of any third party, contain pornography,
                        erotica, child pornography or child erotica, are
                        deceptive, threatening, abusive, inciting of unlawful
                        action, defamatory, libelous, or constitute hate speech
                        or are otherwise objectionable;
                      </li>
                      <li>
                        Destroy, interfere with or disrupt, or attempt to
                        interfere with or disrupt, any web pages available,
                        servers or networks connected to the Site or the
                        technical delivery systems of Company’s providers or
                        break any requirements, procedures, policies or
                        regulations of networks connected to the Site;
                      </li>
                      <li>
                        Attempt to scan, probe or test the vulnerability of any
                        FilmDoo system or network or breach, impair or
                        circumvent any security or authentication measures
                        protecting and providing security for the Services;
                      </li>
                      <li>
                        Attempt to decompile, disassemble, decipher or reverse
                        engineer any of the software used to provide the
                        Services;
                      </li>
                      <li>
                        Attempt to search, meta-search or access the Service
                        with any engine, software, tool, agent, device or
                        mechanism other than software and/or search agents
                        provided by FilmDoo or other generally available
                        third-party web browsers (e.g., Internet Explorer,
                        Firefox, Safari), including, without limitation, any
                        software that sends queries to the Site to determine how
                        a website or web page ranks;
                      </li>
                      <li>
                        Collect or store personal data about other users of the
                        Services without their express and explicit permission;
                      </li>
                      <li>
                        Misrepresent or impersonate your affiliation with any
                        person or entity, through pretext or some other form of
                        social engineering or otherwise commit fraud;
                      </li>
                      <li>
                        Use the Services in any manner not permitted by the
                        Terms of Service; or
                      </li>
                      <li>
                        Instruct or encourage any other individual to do any of
                        the foregoing or to breach and/or violate any of the
                        Terms of Service.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>Entire Agreement and Severability.</b> These Terms are
                    the entire agreement between you and us with respect to the
                    Services, including use of the Site, and supersede all prior
                    or contemporaneous communications and proposals (whether
                    oral, written or electronic) between you and us with respect
                    to the Services. If any provision of these Terms is found to
                    be unenforceable or invalid, that provision will be limited
                    or eliminated to the minimum extent necessary so that these
                    Terms will otherwise remain in full force and effect and
                    enforceable. The failure of FilmDoo to exercise in any
                    respect any right provided for herein shall not be deemed a
                    waiver of any further rights hereunder.
                  </li>
                  <li>
                    <b>Force Majeure.</b> We shall not be liable for any failure
                    to perform our obligations hereunder where such failure
                    results from any cause beyond our reasonable control,
                    including, without limitation, mechanical, electronic or
                    communications failure or degradation.
                  </li>
                  <li>
                    <b>Subcontractors.</b> We use subcontractors to provide
                    certain aspects of the Services and in some cases permit
                    them to access Customer Data subject to appropriate
                    obligations of security, confidentiality and compliance with
                    applicable laws. We remain responsible for our
                    subcontractors' compliance with these Terms with respect to
                    their services provided hereunder.
                  </li>
                  <li>
                    <b>Assignment.</b> These Terms are personal to you, and are
                    not assignable, transferable or sublicensable by you except
                    with our prior written consent. We may assign, transfer or
                    sublicense any of our rights and obligations hereunder
                    without consent.
                  </li>
                  <li>
                    <b>Agency.</b> No agency, partnership, joint venture, or
                    employment relationship is created as a result of these
                    Terms and neither party has any authority of any kind to
                    bind the other in any respect.
                  </li>
                  <li>
                    <b>Notices.</b> Unless otherwise specified in these Term,
                    all notices under these Terms will be in writing and will be
                    deemed to have been duly given when received, if personally
                    delivered or sent by certified or registered mail, return
                    receipt requested; when receipt is electronically confirmed,
                    if transmitted by facsimile or email; or the day after it is
                    sent, if sent for next day delivery by recognized overnight
                    delivery service. Electronic notices should be sent to
                    info@filmdoo.com. You acknowledge and agree that FilmDoo may
                    occasionally send you communications regarding your account
                    or the Services via email or by posting on the Services. You
                    acknowledge and agree that FilmDoo may occasionally send
                    notices to you regarding your Account or the Services via
                    email or via the Services.
                  </li>
                  <li>
                    <b>No Waiver.</b> Our failure to enforce any part of these
                    Terms shall not constitute a waiver of our right to later
                    enforce that or any other part of these Terms. Waiver of
                    compliance in any particular instance does not mean that we
                    will waive compliance in the future. In order for any waiver
                    of compliance with these Terms to be binding, we must
                    provide you with written notice of such waiver through one
                    of our authorized representatives.
                  </li>
                  <li>
                    <b>Open Source.</b> The Service may contain or be
                    distributed with open source software or other third-party
                    software which may be covered by a different license.
                    Notwithstanding anything to the contrary, the obligations of
                    FilmDoo set forth in these Terms do not extend to any open
                    source software or such other third-party software which may
                    be made available by FilmDoo, or otherwise obtained or used
                    by you. You agree that all open source software or such
                    other third-party software shall be and shall remain subject
                    to the terms and conditions under which it is provided, and
                    you shall be responsible for compliance with such terms. To
                    the fullest extent possible, FilmDoo disclaims all
                    warranties and liability regarding such open source and
                    third-party software in accordance with the terms of these
                    Terms.
                  </li>
                  <li>
                    <b>Third Party Links.</b> The Website may contain links to
                    third party websites or advertising or other material
                    submitted by third parties. It is the responsibility of
                    these third parties to ensure that such content and such
                    websites and materials comply with all relevant laws and
                    regulations. We make no representation or warranty in
                    respect of such third-party websites or materials and
                    disclaim any and all liability in connection with such third
                    party material and websites.
                  </li>
                  <li>
                    <b>Social Media.</b> The Website may contain a function
                    enabling you to connect your account to various social media
                    websites. You should be aware that in doing so, people may
                    be able to see that you are a FilmDoo member and may be able
                    to view information about the Video Content you have watched
                    and other information relating to your use of the Services.
                    By using the site and/or consenting via your social media
                    Website (for example your Facebook account), you are
                    expressly consenting to the disclosure by FilmDoo of this
                    information in accordance with the terms of our Privacy
                    Policy. You should refer to the privacy settings and terms
                    and conditions of the relevant social media website(s) for
                    information about how your information may be shared on such
                    website(s).
                  </li>
                  <li>
                    <b>Headings; Interpretation.</b> The section and paragraph
                    headings in these Terms are for convenience only and shall
                    not affect their interpretation. Any use of "including" "for
                    example" or "such as" in these Terms shall be read as being
                    followed by "without limitation" where appropriate.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;
