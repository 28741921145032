import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import Images from "../../../assets/images";
import { useInView } from "react-intersection-observer";

function LanguageWhy({ openCreateAccountModal }) {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <main className="w-full overflow-hidden">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="max-w-6xl mx-auto py-12 lg:py-24 px-12"
      >
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div
            variants={variants.fromLeft}
            className="relative sm:max-w-lg sm:mx-auto lg:mt-0 pt-7 lg:max-w-none lg:mx-0 lg:col-span-3 lg:flex flex-col lg:items-center hidden lg:block"
          >
            <div className="relative mx-auto w-full lg:max-w-l my-4">
              {Images.students.b}
            </div>

            <div className="relative mx-auto w-full lg:max-w-lg my-4">
              {Images.students.f}
            </div>
          </motion.div>
          <motion.div
            variants={variants.fromRight}
            className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-9 lg:text-left"
          >
            <h2>
              <span className="mt-1 block text-2xl tracking-tight sm:text-4xl pr-6 lg:pr-28">
                <span className="block text-teal-600 pt-8">
                  <b>Why FilmDoo Expanded</b> Into Language Learning
                </span>
              </span>
            </h2>
            <p className="mt-3 text-base text-navy-600 sm:mt-5 font-bold">
              <a
                className="cursor-pointer underline"
                href="https://www.filmdoo.com"
                target="_blank"
              >
                FilmDoo
              </a>{" "}
              first launched as a movie-streaming platform helping people to
              discover and watch great films from around the world. FilmDoo now
              has over 280,000 registered users from 194 countries and over
              3,000 films. Today, FilmDoo has one of the largest international
              film catalogues online.
            </p>

            <p className="mt-3 text-base text-navy-600 sm:mt-5">
              In 2019, FilmDoo noticed that over 80% of their traffic was coming
              from language-related searches and keywords. In a survey that soon
              followed, over 70% of users said that the number one reason they
              are a watching a film on FilmDoo is because they are learning a
              foreign language. The company conducted more research and saw that
              there was an opportunity to expand into online edtech by providing
              film- and video-based learning which can help raise and retain
              student engagement through engaging films.
            </p>

            <p className="mt-3 text-base text-navy-600 sm:mt-5">
              FilmDoo went on to develop an innovative layer of edtech that can
              now turn any film and video into an interactive lesson.
            </p>

            <p className="mt-3 text-base text-navy-600 sm:mt-5">
              Today, FilmDoo is the world’s first edutainment platform to
              combine language learning with an extensive and powerful
              international catalogue of films.
            </p>
          </motion.div>
        </div>
      </motion.div>
    </main>
  );
}

export default LanguageWhy;
