import React, { useEffect } from "react";
import Images from "../../../assets/images";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import variants from "../../components/animationVariants";

function Team() {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const team = [
    {
      email: "info@filmdoo.academy",
      phone: "+44 20 3290 2380",
      image: Images.team.varada,
      name: "Weerada Sucharitkul",
      job: "CEO & CO-FOUNDER",
      description:
        "After starting her career teaching English in Japan, Weerada brings over 10 years of experience in management consultancy, digital strategy and IT transformation, counting global and major FTSE companies among some of the companies she has worked with.  Having lived and studied in 11 countries across 5 continents, Weerada launched FilmDoo to reflect the three things she is most passionate about:  films, language learning and edtech.",
    },
    {
      email: "info@filmdoo.academy",
      phone: "++44 208 123 7749",
      image: Images.team.dave,
      name: "David Page",
      job: "HEAD OF EDUCATION PARTNERSHIPS",
      description:
        "As a single dad, Dave is always on the lookout for ways to keep his son engaged with online learning. He brings his wealth of experience from sales and business development, working with both UK and international clients.  Dave is currently leading FilmDoo’s diversity efforts - a cause he is very passionate about - to help organisations improve their own diversity & inclusion training and awareness programmes through the power of impactful and engaging films.",
    },
    {
      email: "info@filmdoo.academy",
      phone: "+44 20 3290 2380",
      image: Images.team.rob,
      name: "Rob Sutcliffe",
      job: "HEAD OF EDTECH",
      description:
        "Rob was previously an English teacher in Seoul, Korea for 3 years.  Since then, Rob has worked for over 10 years developing applications for companies such as Oracle and Hitachi.  Prior to that, Rob has had extensive experience developing elearning and LMS for schools and corporate learning providers.",
    },
    {
      email: "info@filmdoo.academy",
      phone: "+44 20 3290 2380",
      image: Images.team.christina,
      name: "Christina Learmonth",
      job: "HEAD OF CONTENT & MARKETING",
      description:
        "Christina brings extensive experience in content acquisition, video editing and marketing.  She is actively involved in growing FilmDoo’s content library for language learners and language schools around the world.  More recently, Christina is helping to oversee the company’s promotional and marketing efforts for FilmDoo Academy. ",
    },
    {
      email: "info@filmdoo.academy",
      phone: "+44 20 3290 2380",
      image: Images.team.rainie,
      name: "Rainie Liu",
      job: "HEAD OF EDUTAINMENT RESEARCH & LINGUISTICS",
      description:
        "Speaking four languages - Mandarin, Korean, English and Spanish - Rainie is a passionate and experienced linguist.  Rainie helps to oversee the pedagogic and curriculum design at FilmDoo.  She is also involved in conducting edtech and user research while helping to onboard new teachers and schools to FilmDoo Academy.",
    },
    {
      email: "info@filmdoo.academy",
      phone: "+44 20 3290 2380",
      image: Images.team.charles,
      name: "Charles Normsaskul",
      job: "CONTENT MANAGER & EDTECH PARTNER",
      description:
        "Charles brings many years of experience as a video producer.  He joined FilmDoo as he shared our vision to help connect the world through film.  As a filmmaker himself, Charles also shares our mission to help underrepresented stories from around the world to get seen and heard.  Charles enjoys finding new audiences for these films by working with schools, universities and language schools who are looking for authentic, engaging content to turn into interactive lessons and help rejuvenate their students’ engagement.",
    },
    {
      email: "info@filmdoo.academy",
      phone: "+44 20 3290 2380",
      image: Images.team.julio,
      name: "Julio Marmol Corbacho",
      job: "EDTECH PARTNER & BUSINESS DEVELOPMENT",
      description:
        "Speaking four languages - Spanish, French, English & Portuguese - Julio brings many years of experience as a language teacher in Latin America and the UK.  Trained as a film producer, Julio understands first hand the power of films and videos in improving learning outcomes, especially in language learning.  Julio is  excited to be working with schools and educators to introduce them to FilmDoo’s transformative edtech tool.",
    },
  ];

  return (
    <div className="bg-white">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="mx-auto py-12 px-8 max-w-4xl sm:px-6 lg:py-24"
      >
        <div className="space-y-12">
          <motion.h2
            variants={variants.fromBottom}
            className="text-2xl font-extrabold tracking-tight sm:text-4xl text-navy-700"
          >
            Meet The FilmDoo Team
          </motion.h2>

          <ul>
            <AnimatePresence>
              {team.map((member, key) => (
                <motion.li
                  variants={{
                    visible: {
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1.2 + key * 0.5, duration: 0.5 },
                    },
                    hidden: { y: 40, opacity: 0 },
                  }}
                  className="mb-24"
                >
                  <div className="sm:grid sm:grid-cols-12 sm:gap-2 sm:space-y-0 lg:gap-4">
                    <div className="col-span-12 sm:col-span-4 md:col-span-3 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4 sm:pr-4">
                      <img
                        className="w-1/2 mx-auto mb-5 sm:w-full mt-1 object-cover fd-shadow-lg rounded-2xl"
                        src={member.image}
                        alt=""
                      />
                    </div>
                    <div className="col-span-12  sm:col-span-8 md:col-span-9">
                      <div className="space-y-3">
                        <div className="text-lg leading-6 text-navy-600 font-medium space-y-1">
                          <h3 className="font-bold">{member.name}</h3>
                          <p className="text-teal-600 text-base">
                            {member.job}
                          </p>
                        </div>
                        <div className="text-base text-navy-500">
                          <p className="text-navy-500">{member.description}</p>
                        </div>
                        <ul className="flex space-x-5">
                          <li>
                            <a
                              href={`mailto:${member.email}`}
                              className="transition duration-500 ease-in-out text-gray-400 hover:text-gray-500"
                            >
                              <span className="sr-only">Email</span>
                              <svg
                                className="w-5 h-5 inline-block mr-1"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                />
                              </svg>
                              {member.email}
                            </a>
                          </li>
                          <li>
                            <a
                              href={`tel:${member.phone}`}
                              className="transition duration-500 ease-in-out text-gray-400 hover:text-gray-500"
                            >
                              <span className="sr-only">Phone</span>
                              <svg
                                className="w-5 h-5 inline-block mr-1"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                />
                              </svg>
                              {member.phone}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </motion.li>
              ))}
            </AnimatePresence>
          </ul>
        </div>
      </motion.div>
    </div>
  );
}

export default Team;
