import React, {useState, useEffect} from 'react';
import {ContextProvider} from './context'
import Controller from "./controller";
import Loading from "./overlays/loading";
import {AnimatePresence} from "framer-motion";
import  window from "global/window";
import Div100vh from 'react-div-100vh';

function Party(props) {

  const
    lessonId = props.match.params.lessonId,
    [loaded, setLoaded] = useState(false);

  // hide menu bar in mobile by scrolling when loaded
  useEffect(()=>{
    setTimeout(()=> window.scrollTo(0,1), 5000);

    // manually import react pixel to use on SSR
    if(lessonId)
        import('react-facebook-pixel')
            .then(module => module.default)
            .then(ReactPixel => {
                ReactPixel.track( 'ViewContent', { video:lessonId });
            })

    // try hide address bar on mobile
    window.scrollTo(0,1);

  },[])

  return (
    <ContextProvider>
        <Div100vh>
          <AnimatePresence>
            {!loaded &&
              <Loading />
            }
          </AnimatePresence>
          <Controller {...{lessonId, setLoaded}} />
        </Div100vh>
    </ContextProvider>
  )
}

export default Party
