import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import Images from "../../../assets/images";
import { useInView } from "react-intersection-observer";

function LanguageDropOut({ openCreateAccountModal }) {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <main className="w-full overflow-hidden">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="max-w-6xl mx-auto py-6 md:py-12 lg:py-24 px-8"
      >
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div
            variants={variants.fromLeft}
            className="relative sm:max-w-lg sm:mx-auto lg:mt-0 pt-7 lg:max-w-none lg:mx-0 lg:col-span-3 lg:flex flex-col lg:items-center hidden lg:block"
          >
            <div className="relative mx-auto w-full lg:max-w-l my-4">
              {Images.students.frustrated1}
            </div>

            <div className="relative mx-auto w-full lg:max-w-lg my-4">
              {Images.students.frustrated2}
            </div>
          </motion.div>
          <motion.div
            variants={variants.fromRight}
            className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-9 lg:text-left"
          >
            <h2>
              <span className="mt-1 block text-2xl tracking-tight sm:text-4xl pr-6">
                <span className="block text-teal-600 pt-8">
                  Over 90% of language <b>learners drop out</b>
                </span>
              </span>
            </h2>
            <p className="mt-3 text-base text-navy-600 sm:mt-5">
              Across language learning solutions, there is currently an
              industry-wide problem of high dropout rates due to the lack of
              student engagement. This has only increased as learning moved
              online almost overnight!
            </p>

            <p className="mt-3 text-base text-navy-600 sm:mt-5">
              Many current language learning tools rely on tedious curricula and
              forms of delivery, such as flashcards and vocabulary exercises,
              which often provide little or no cultural and social context.
            </p>

            <p className="mt-3 text-base text-navy-600 sm:mt-5">
              Films and videos are a great way to help students stay engaged and
              motivated by evoking empathy and cultural understanding - while
              being entertained at the same time!
            </p>

            <p className="mt-3 text-base text-navy-600 sm:mt-5 font-bold">
              Moreover, the average Gen Z student now spends at least four hours
              a day watching mobile videos. While traditional teaching methods
              struggle to keep students engaged, the modern students now respond
              to film- and video-based learning - which is at the heart of what
              we do at FilmDoo!
            </p>
          </motion.div>
        </div>

        <motion.div className="text-center" variants={variants.fromBottom}>
          <p className="mx-6 sm:mx-36 lg:mx-0 mt-3 text-lg lg:text-xl text-pink-600 sm:mt-7 font-bold italic">
            81.6% of participants expect to increase their their use of film and
            video content for language learning.{" "}
            <div className="not-italic font-normal"> - Docebo survey</div>
          </p>

          <div className="mt-8">
            <button
              onClick={openCreateAccountModal}
              className="transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-lg transform focus:outline-none rounded-md fd-shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white bg-pink-600 md:py-4 md:text-lg md:px-10"
            >
              Join Today
            </button>
          </div>
        </motion.div>
      </motion.div>
    </main>
  );
}

export default LanguageDropOut;
