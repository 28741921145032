import React, { useState } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import HowItWorks from "../home/howItWorks";
import SampleLessons from "../../components/sampleLessons";
import Hero from "./hero";
import HomeWebinarCta from "../home/homeWebinarCta";
import SchoolsBudget from "./schoolsBudget";

function ForSchools() {
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false);
  const [masterShowMembership, setMasterShowMembership] = useState(false);

  return (
    <>
      <Menu
        {...{
          masterShowCreateAccount,
          setMasterShowCreateAccount,
          masterShowMembership,
          setMasterShowMembership,
        }}
      />
      <Hero {...{ setMasterShowCreateAccount }} />
      <HowItWorks />
      <SampleLessons />
      <SchoolsBudget />
      <HomeWebinarCta />
      <Footer />
    </>
  );
}

export default ForSchools;
