import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import variants from "../../components/animationVariants";
import { Link } from "react-router-dom";
import CheckBox from "../../components/checkbox";

function HowItWorks({ readMore }) {
  const controls = useAnimation(),
    [offsetTop, setOffsetTop] = useState(0),
    [ref, inView] = useInView(),
    containerRef = useRef();

  useLayoutEffect(() => {
    if (!containerRef.current) return null;
    setOffsetTop(containerRef.current.offsetTop);
  }, [containerRef]);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div ref={containerRef}>
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="bg-white relative overflow-hidden"
      >
        <div className="absolute -ml-28 left-0 bottom-0 mb-8">
          <CheckBox offsets={[offsetTop - 50, offsetTop + 300]} size={550} />
        </div>

        <div className="z-20 relative max-w-6xl mx-auto pt-16 pb-8 px-12 lg:pt-24 lg:pb-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <motion.div variants={variants.fromLeft}>
            <p className="text-xs font-semibold text-teal-600 uppercase tracking-wide">
              About FilmDoo
            </p>
            <h2 className="mt-2 text-4xl font-extrabold text-navy-800">
              How It Works
            </h2>
            <p className="mt-4 text-base text-navy-500">
              <b>
                Why hundreds of schools, academies and students around the world
                are using FilmDoo Academy
              </b>
            </p>
            <p className="mt-4 text-base text-navy-500">
              FilmDoo Academy is used in a large variety of high impact ways to
              get students excited in and out of the classroom, while also
              supplying teachers with the tools they need for teaching
              effectively.
            </p>
            {readMore && (
              <div className="w-full mt-6 text-center lg:text-left">
                <Link
                  to={{ pathname: "/for_schools" }}
                  className="transition duration-500 ease-in-out hover:bg-teal-700 hover:fd-shadow-lg transform focus:outline-none fd-shadow mx-auto inline-flex items-center justify-center px-7 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 w-auto"
                >
                  Read More
                </Link>
              </div>
            )}
          </motion.div>
          <motion.div
            variants={variants.fromRight}
            className="mt-12 lg:mt-0 lg:col-span-2"
          >
            <dl className="space-y-2 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-1 sm:grid-flow-col sm:gap-x-6 sm:gap-y-4 lg:gap-x-8 mb-8">
              <div className="flex">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium text-navy-900">
                    Stimulating In-Class Learning
                  </dt>
                  <dd className="mt-2 text-base text-navy-500">
                    Choose anything from YouTube or Vimeo. Play and pause the
                    film and check students’ understanding and recall… It’s
                    easy!
                  </dd>
                </div>
              </div>

              <div className="flex">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium text-navy-900">
                    Special Educational Needs
                  </dt>
                  <dd className="mt-2 text-base text-navy-500">
                    Support your SEN students using a proven methodology and
                    tool. Assign the appropriate film or video, complement their
                    preferred learning style and create fun & engaging quizzes.
                  </dd>
                </div>
              </div>
            </dl>
            <dl className="space-y-2 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-1 sm:grid-flow-col sm:gap-x-6 sm:gap-y-4 lg:gap-x-8 mb-8">
              <div className="flex">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium text-navy-900">
                    Learning Anytime, Anywhere Using Any Device
                  </dt>
                  <dd className="mt-2 text-base text-navy-500">
                    Create, assign and complete tasks in this 100% cloud based
                    application - FilmDoo Academy is COVID-proof.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium text-navy-900">
                    Homework and Revision
                  </dt>
                  <dd className="mt-2 text-base text-navy-500">
                    Set tasks to study, revise and find out more through videos.
                    Interactive questions accelerate learning, or even better,
                    students can create their own!
                  </dd>
                </div>
              </div>
            </dl>
            <dl className="space-y-2 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-1 sm:grid-flow-col sm:gap-x-6 sm:gap-y-4 lg:gap-x-8 mb-8">
              <div className="flex">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium text-navy-900">
                    Differentiation
                  </dt>
                  <dd className="mt-2 text-base text-navy-500">
                    FilmDoo Academy is used to assign the appropriate resources
                    based on the class or students ‘working at’ their levels,
                    including higher, middle and lower ability. The appropriate
                    resources can be assigned to students in seconds.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium text-navy-900">
                    Blended Learning
                  </dt>
                  <dd className="mt-2 text-base text-navy-500">
                    FilmDoo Academy complements best practice teaching and
                    learning methods and helps accelerate engagement and student
                    progress. Teach with a film in class or set separate tasks
                    to flip the classroom.
                  </dd>
                </div>
              </div>
            </dl>
            <dl className="space-y-2 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-1 sm:grid-flow-col sm:gap-x-6 sm:gap-y-4 lg:gap-x-8 mb-8">
              <div className="flex">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium text-navy-900">
                    Targeted Interventions
                  </dt>
                  <dd className="mt-2 text-base text-navy-500">
                    Teachers can assign film and video tasks to whole classes,
                    sub-groups within a class, individual students, any cohort
                    or their own groups.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium text-navy-900">
                    Personalised Learning
                  </dt>
                  <dd className="mt-2 text-base text-navy-500">
                    Using FilmDoo Academy, teachers can assign activities to
                    each and every individual student - creating a true
                    personalised approach to their learning.
                  </dd>
                </div>
              </div>
            </dl>

            <dl className="space-y-2 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-1 sm:grid-flow-col sm:gap-x-6 sm:gap-y-4 lg:gap-x-8 mb-8">
              <div className="flex">
                <svg
                  className="flex-shrink-0 h-6 w-6 text-teal-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium text-navy-900">
                    Educated Outside of School
                  </dt>
                  <dd className="mt-2 text-base text-navy-500">
                    Teach Code-B, excluded students and long term absentees.
                    Perfectly suited to lunchtime, after school clubs and school
                    holidays.
                  </dd>
                </div>
              </div>
            </dl>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}

export default HowItWorks;
