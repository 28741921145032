import React from 'react';
import Particles from "react-particles-js";

function BgParticles({ color }) {
    return <Particles
        className="absolute w-full h-full -mt-12"
        params={{
            particles: {
                number: {
                    value: 40,
                    density: {
                        enable: true,
                        value_area: 1200
                    }
                },
                size: {
                    value:2.5,
                    anim: {
                        speed: 5,
                        "size_min": 0.3
                    }
                },
                line_linked: {
                    enable: false
                },
                move: {
                    random: true,
                    speed: 1,
                    direction: "top",
                    "out_mode": "out"
                },
                color: {
                    value: color === "pink" ? "#DA6286" : "#12bcc3"
                }
            },
            interactivity: {
                events: {
                    onhover: {
                        enable: true,
                        mode: "repulse"
                    },
                    onclick: {
                        enable: true,
                        mode: "repulse"
                    }
                },
                modes: {
                    bubble: {
                        distance: 200,
                        duration: 2,
                        size: 0,
                        opacity: 0
                    },
                    repulse: {
                        distance: 200,
                        duration: 5
                    }
                }
            }
        }}
    />
}

export default BgParticles;