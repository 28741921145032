import React, { useEffect } from "react";
import ReactConfetti from "react-confetti";
import window from "global/window";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import variants from "../../components/animationVariants";
import Images from "../../../assets/images";

function HomeRecognition() {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      className="bg-navy-700 relative overflow-hidden"
    >
      <ReactConfetti
        gravity={0.08}
        numberOfPieces={35}
        width={window.innerWidth}
        height={600}
        colors={["#2F356D", "#89D0D3", "#222751", "#0DA9B0", "#F5C9D6"]}
      />
      <div className="max-w-5xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <motion.h3
          variants={variants.fromBottom}
          className="text-lg tracking-wider font-extrabold text-teal-300 text-center"
        >
          Industry Awards & Recognition
        </motion.h3>
        <motion.div
          variants={variants.fromBottom2}
          className="flow-root mt-8 lg:mt-10"
        >
          <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              {Images.recommended.uclEducate}
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              {Images.recommended.edTechImpact}
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              {Images.recommended.csvCup}
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              {Images.recommended.launchpad}
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default HomeRecognition;
