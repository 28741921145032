import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import window from "global/window";
import { Link } from "react-router-dom";

// this is used to show a cta above the menu when there is an event happening
function MenuCta() {
  const [show, setShow] = useState(false),
    handleClickClose = () => {
      setShow(false);
    };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto", transition: { delay: 1.7, duration: 1 } }}
          exit={{ height: 0, transition: { duration: 0.4 } }}
          className="md:items-center md:justify-between bg-navy-800 bg-navy-gradient -mt-1 overflow-hidden relative"
        />
      )}
    </AnimatePresence>
  );
}

export default MenuCta;
