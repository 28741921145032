import React from 'react';
import CheckBox from "../../webapp/components/icons/checkbox";

function Option({ option, index, handleOptionClick }) {
  return(
    <li
        key={index}
        onClick={()=>handleOptionClick(index)}
        tabIndex="-1" role="radio" aria-checked="false" className={`
                    ${option.selected ? "option-selected" : ""} 
                    ${'option-' + option.feedback}
                    text-white quiz-option mx-4 overflow-hidden group relative rounded-lg cursor-pointer`}>

      <div className="rounded-full option-bg-full" />
      <div className="rounded-full option-bg-circle" />
      <span className="text-white">
        <CheckBox checked={option.feedback !== 'wrong' && option.selected} />
      </span>
      <div className="px-6 py-4 lg:py-5 pl-16 sm:flex sm:justify-between sm:space-x-4">
        <div className="flex items-center space-x-0">
          <div className="flex-shrink-0 flex items-center hidden">
            <span aria-hidden="" className="form-radio"/>
          </div>
          <div className="relative z-2 text-md leading-snug">
            <p className="block font-medium text-navy-500 mo-regular text-md md:text-lg lg:text-xl">
              {option.text}
            </p>
          </div>
        </div>
      </div>
      <div className="border-transparent absolute inset-0 rounded-lg border-2 pointer-events-none" />
    </li>
  );
}

export default Option;
