import React, { useContext, useEffect, useMemo, useState } from "react";
import { Context } from "./context";
import { motion, AnimatePresence } from "framer-motion";
import Background from "../../components/common/background";
import Player from "./player";
import Watching from "./overlays/watching";
import MultipleChoice from "./interactions/multipleChoice";
import IntroduceNextActivity from "./overlays/introduceNextActivity";
import Score from "./overlays/score";
import Pause from "./overlays/pause";
import ConfettiShower from "./components/confettiShower";
import Leaderboard from "./overlays/leaderboard";
import Results from "./overlays/results";
import Join from "./overlays/join";
import window from "global";
import document from "global/document";
import sessionStorage from "sessionstorage";

function Controller({ lessonId, setLoaded }) {
  const { activity, classRoom, student, lesson } = useContext(Context),
    classRoomNameFromStorage = sessionStorage.getItem("classRoomName"),
    studentNameFromStorage = sessionStorage.getItem("name"),
    veryBeginning = useMemo(
      () => lesson.starttime === lesson.currentTime || lesson.currentTime === 0,
      [lesson.starttime, lesson.currentTime]
    ),
    // TODO remove all the mental waitingFor stuff around the site and clean up this
    darkBackground = useMemo(
      () =>
        classRoom.overlay === "watching" ||
        classRoom.overlay === "next activity" ||
        classRoom.overlay === "score" ||
        classRoom.overlay === "leaderboard" ||
        classRoom.overlay === "results",
      [classRoom.overlay]
    ),
    // decides if they will see the video on their screen or not
    watchingScreen = useMemo(
      () =>
        lesson.videoUrl && student.emojiCharacter ? (
          classRoom.oneLocation && !student.isPrefect ? (
            <Watching />
          ) : (
            <Player />
          )
        ) : undefined,
      [
        classRoom.oneLocation,
        lesson.videoUrl,
        student.emojiCharacter,
        student.isPrefect,
      ]
    ),
    [overlay, setOverlay] = useState();

  useEffect(() => {
    // remove loading screen on first load
    if (classRoom.overlay)
      setTimeout(() => setLoaded(true), lessonId ? 1300 : 300);
    setOverlay(
      lesson.playing
        ? undefined
        : {
            join: { el: <Join />, key: "join" },
            score: { el: <Score />, key: "score" },
            // temp check so that the overlay won't show when paused
            watching: !veryBeginning
              ? undefined
              : { el: <Pause />, key: "pause" },
            leaderboard: { el: <Leaderboard />, key: "leaderboard" },
            "next activity": { el: <IntroduceNextActivity />, key: "next" },
            "multiple-choice": {
              el: <MultipleChoice />,
              key: "multiple-choice",
            },
            results: { el: <Results />, key: "results" },
          }[classRoom.overlay]
    );
  }, [lesson.playing, classRoom.overlay]);

  // this will only fire when we find out if they're rejoining a class or joining a new class
  useEffect(() => {
    // session data wasn't removed after last game.
    // refresh the page now that it is clear
    if (classRoom.isEmpty && lessonId) {
      window.location.reload();
    } else if (classRoomNameFromStorage && studentNameFromStorage) {
      student.set("rejoinWithNameAndClassroomName", {
        classRoomNameFromStorage,
        studentNameFromStorage,
      });
    } else if (classRoomNameFromStorage && !studentNameFromStorage) {
      sessionStorage.removeItem("classRoomName");
      window.location.reload();
    }
  }, [classRoom.isEmpty]);

  // if they try and rejoin a game but the student doesn't exist on the server go to the join screen
  useEffect(() => {
    if (student.doesntExist) classRoom.setOverlay("join");
  }, [student.doesntExist]);

  // if it's the teacher get the film id from the url
  useEffect(() => {
    // only start a new game if they aren't already in the middle of a game
    // they should only have the bot session storage items if in the middle of a game
    if (lessonId) {
      // this is a prefect but are they rejoining or are they starting a new game
      if (!classRoomNameFromStorage) {
        student.set("isPrefect", true);
        classRoom.set("lessonDatabaseId", lessonId);
      }
      // just used to check if classroom is empty or not
      else classRoom.set("name", classRoomNameFromStorage);

      // if it's not a teacher and they aren't in the class send them to the join screen
    } else if (!classRoomNameFromStorage) classRoom.setOverlay("join");

    // remove them from the game when they leave the tab or application/browser
    document.addEventListener("visibilitychange", function () {
      if (!document.hidden) window.location.reload();

      // pause if the teacher leaves the tab
      if (student.isPrefect) {
        lesson.set("lastSavedPlayingState", false);
        lesson.setPlaying(false);
      }
    });
  }, []);

  // autoplay when changing back to watching state
  // don't autoplay the first time it loads i.e. when student has no name
  useEffect(() => {
    // if they aren't logged in yet then don't go further
    if (!student.emojiCharacter || student.nameTaken) return;

    if (activity.type === "watching") {
      classRoom.setOverlay("watching");
      student.setIsWaiting(false);

      // don't autoplay at the very start as you can wait for more students
      if (!veryBeginning) lesson.setPlaying(true);
    } else if (student.name) {
      lesson.setPlaying(false);
      classRoom.setOverlay(activity.type);
    }
  }, [activity.type, student.emojiCharacter]);

  return (
    <div>
      <Background
        hide={
          lesson.playing || (classRoom.overlay === "watching" && !veryBeginning)
        }
        clickthrough={
          lesson.playing || (classRoom.overlay === "watching" && !veryBeginning)
        }
        dark={darkBackground}
      >
        <ConfettiShower />

        <AnimatePresence exitBeforeEnter>
          {overlay && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              key={overlay.key}
            >
              {overlay.el}
            </motion.div>
          )}
        </AnimatePresence>
      </Background>
      {watchingScreen}
    </div>
  );
}

export default Controller;
