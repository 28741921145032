import React from "react";
import Emojis from "../../../assets/images/emojis";
import { motion } from "framer-motion";

function LeaderboardPositions({ person }) {
  const variants = {
    outer: {
      visible: { y: 0, rotate: 0, scale: 1 },
      hidden: { y: 200, rotate: 10, scale: 0.8 },
    },
    scale: {
      visible: { scale: 1 },
      hidden: { scale: 0 },
    },
    slide: {
      visible: { x: 0, opacity: 1 },
      hidden: { x: -200, opacity: 0 },
    },
  };

  return (
    <motion.div className="relative" variants={variants.outer}>
      <div className="text-center rounded-full bg-teal-600 absolute mt-6 text-sm z-10 mo-bold leaderboard-rank">
        {person.position}
      </div>
      <div
        key={person.position}
        className="leaderboard-position mx-4 overflow-hidden group mb-1 relative rounded-xl focus:outline-none"
      >
        <motion.div
          variants={variants.scale}
          transition={{ duration: 0.02 }}
          className="rounded-full leaderboard-bg-circle"
        />
        <motion.div className="emoji" variants={variants.slide}>
          {Emojis[person.emojiCharacter][person.emojiEmotion]}
        </motion.div>
        <motion.div
          variants={variants.slide}
          className="py-3 leaderboard-name-text relative z-2 leading-relaxed text-md leading-snug py-2"
        >
          <p className="block font-bold mo-regular text-xl">{person.name}</p>
          <p className="block font-medium mo-medium leading-relaxed text-teal-600 text-lg">
            {person.score}{" "}
            <span className="text-sm md:text-md text-teal-400">Points</span>
          </p>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default LeaderboardPositions;
