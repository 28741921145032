import React, { useState } from "react";
import Images from "../../../assets/images";
import VideoModal from "../../components/modals/video";
import { motion } from "framer-motion";

function HomeHero({ openCreateAccountModal }) {
  const [showVideo, setShowVideo] = useState(false),
    handleShowVideoClick = () => {
      setShowVideo(true);
    };

  return (
    <>
      <VideoModal show={showVideo} setShow={setShowVideo} />
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="pt-8 relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative pt-6 px-4 sm:px-6 lg:px-8" />

            <main className="mt-36 lg:mt-28 mx-auto max-w-8xl px-12">
              <div className="text-center lg:text-left">
                <h1 className="text-3xl mt-24 md:mt-36 tracking-tight font-extrabold text-navy-700 sm:text-4xl md:text-5xl">
                  <span className="text-teal-600 inline mt-2">
                    Learning Has Never Been More Easy <b>&</b> Engaging{" "}
                  </span>
                  <span className="inline">
                    Using Film <b>&</b> Video
                  </span>
                </h1>
                <p className="mt-3 text-xs text-navy-500 sm:mt-5 sm:text-sm sm:max-w-xl sm:mx-auto md:mt-5 md:text-md lg:mx-0">
                  Discover the power of film and video in improving learning
                  engagement.
                </p>
                <p className="text-xs text-navy-500 sm:mt-1 sm:text-sm sm:max-w-xl sm:mx-auto md:text-md lg:mx-0">
                  Turn any film or video into an interactive game and lesson in
                  just a few minutes.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md">
                    <button
                      onClick={openCreateAccountModal}
                      className="transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-xl transform focus:outline-none flex items-center md:w-auto sm:w-auto mx-auto fd-shadow-lg mb-4 md:mb-0 justify-center px-8 py-2 md:py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 md:py-4 md:text-lg md:px-10"
                    >
                      Activate Free Account
                    </button>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <button
                      onClick={handleShowVideoClick}
                      className="transition duration-500 ease-in-out hover:bg-pink-100 hover:fd-shadow hover:text-pink-900 transform focus:outline-none flex items-center md:w-auto sm:w-auto mx-auto justify-center px-8 py-2 md:py-3 border border-transparent text-base font-medium rounded-md text-pink-700 bg-pink-50 md:py-4 md:text-lg md:px-10"
                    >
                      Video Demo
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div
          onClick={handleShowVideoClick}
          className="relative lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2"
        >
          {Images.laptopBoy}

          <motion.div
            whileHover={{ scale: 1.2 }}
            className="absolute top-0 right-0 mx-auto w-full center-position lg:max-w-md opacity-60 flex justify-center"
          >
            <button
              type="button"
              className="block w-32 h-32 bg-white rounded-full fd-shadow-xl overflow-hidden"
            >
              <div
                className="inset-0 w-full h-full flex items-center justify-center"
                aria-hidden="true"
              >
                <svg
                  className="h-32 w-32 text-teal-500"
                  fill="currentColor"
                  viewBox="0 0 84 84"
                >
                  <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
                  <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                </svg>
              </div>
            </button>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default HomeHero;
