import React, { useState, useEffect } from "react";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import moment from "moment-timezone";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import Images from "../../../assets/images";
import window from "global";
import variants from "../../components/animationVariants";
import { useInView } from "react-intersection-observer";
import BgParticles from "../../components/bgParticles";
const GSheetReader = require("g-sheets-api");

function WebinarPage() {
  const controls = useAnimation(),
    [ref, inView] = useInView(),
    [webinars, setWebinars] = useState([]),
    handleWebinarSignupClick = (url) => {
      import("react-facebook-pixel")
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.track("SubmitApplication");
        });
      window.open(url, "_blank").focus();
    };

  const sortWebinars = (a, b) =>
    parseInt(a.date.replace(/-/g, "") + a.time.replace(/:/g, "")) >
    parseInt(b.date.replace(/-/g, "") + b.time.replace(/:/g, ""));

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  useEffect(() => {
    GSheetReader(
      {
        apiKey: "AIzaSyAkbVsIweThhzqtODK3aK5XfQKpPvfLRQ4",
        sheetId: "1YlYcDn3Y7YfrOgZJBdaswujEqJuToqt7tir8we63iXk",
        sheetName: "webinars",
      },
      (results) => {
        setWebinars(
          results
            .filter(
              (result) =>
                moment(result.date + " " + result.time)
                  .tz("Europe/London")
                  .format("x") > +new Date()
            )
            .sort(sortWebinars)
            .map((result, key) => {
              let utc = moment.utc(result.date + " " + result.time),
                format = "h:mma z";
              result.times = {
                la: utc.tz("America/Los_Angeles").format(format),
                ny: utc.tz("America/New_York").format(format),
                ld: utc.tz("Europe/London").format(format),
              };

              if (key === 0) {
                let daysUntil = moment(result.date).diff(
                  moment(new Date()),
                  "days"
                );
                result.daysUntil =
                  daysUntil === 1
                    ? "Our Next Webinar is Coming Tomorrow"
                    : daysUntil
                    ? `Our Next Webinar is Coming in ${daysUntil} days`
                    : "Our Next Webinar is Coming Today";
              }

              result.formatedDate = moment(result.date).format("MMM Do YYYY");
              return result;
            })
        );
      }
    );
  }, []);

  return (
    <>
      <Menu />
      <main className="w-full bg-gray-100 overflow-hidden pt-4 lg:pt-10 sloppy-bottom pb-32">
        <BgParticles />
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          className="relative max-w-6xl mx-auto lg:py-32 pt-20 pb-8 px-8"
        >
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <motion.div
              variants={variants.fromLeft}
              className="md:mx-auto col-span-9"
            >
              <p className="text-sm lg:text-md text-navy-600 uppercase tracking-wide">
                FilmDoo Academy Webinars & Roundtable Sessions
              </p>
              <h1 className="mt-2 text-4xl lg:text-6xl font-semibold text-navy-600  leading-tight mb-43">
                Why Not Join Us for Our{" "}
                <span className="text-teal-600">
                  Free Webinar & Online events?
                </span>
              </h1>
            </motion.div>

            <motion.div
              variants={variants.fromLeft2}
              className=" md:max-w-4xl md:mx-auto lg:col-span-7 text-left"
            >
              <p className="mt-3 text-lg md:text-lg text-navy-600 font-bold sm:mt-5 sm:max-w-3xl md:mt-5 lg:mx-0 tracking-wide">
                Our online events are available for free and designed to discuss
                and share high impact ways in which film and video accelerates
                students’ engagement and learning outcomes.
              </p>
              <p className="mt-3 text-lg md:text-lg text-navy-600 sm:mt-5 sm:max-w-3xl md:mt-5 lg:mx-0 tracking-wide">
                This is the perfect forum to learn and share how schools around
                the world are effectively utilizing film and video within their
                classes and for homework, revision, intervention, homeschooling
                as well as so much more.
              </p>
              <p className="mt-3 mb-24 lg:mb-0 text-lg md:text-lg text-navy-600 sm:mt-5 sm:max-w-3xl md:mt-5 lg:mx-0 tracking-wide">
                We will be joined by guest speakers and panelists and we would
                very much like to welcome you and your colleagues to join us.
              </p>
            </motion.div>

            <motion.div
              variants={variants.fromRight}
              className="mt-4 lg:mt-1 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 flex flex-col lg:col-span-4"
            >
              <div className="hidden lg:block lg:absolute right-0 top-0 lg:mt-12 -mr-28">
                {Images.threeDee.calendar}
              </div>
            </motion.div>
          </div>
        </motion.div>
      </main>

      <div className="relative z-20 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8 -mt-56">
        <div className="relative max-w-6xl mx-auto">
          <div className="mt-12 mx-auto lg:grid lg:gap-5 lg:grid-cols-3 lg:max-w-none">
            <AnimatePresence>
              {webinars[0] && (
                <motion.div
                  initial={{ y: 40, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: { delay: 0.6, duration: 0.5 },
                  }}
                  className="lg:flex rounded-lg fd-shadow-lg overflow-hidden lg:col-span-3 mb-12"
                >
                  <div className="lg:flex-2 bg-white p-6 flex flex-col justify-between">
                    <div className="lg:flex-2">
                      <p className="text-sm font-bold text-teal-600">
                        {webinars[0].formatedDate}
                      </p>
                      <div>
                        <p className="text-lg font-semibold text-navy-600">
                          {webinars[0].title}
                        </p>
                        <p className="mt-2 text-navy-300">
                          {webinars[0].times.ld}
                          <span className="opacity-40 mx-2">|</span>
                          {webinars[0].times.ny}
                          <span className="opacity-40 mx-2">|</span>
                          {webinars[0].times.la}
                        </p>
                        {webinars[0].description && (
                          <p className="mt-3 text-sm text-navy-500 list-disc mx-10">
                            {webinars[0].description}
                          </p>
                        )}
                        {webinars[0].bulletPoint1 && (
                          <ul className="mt-3 text-sm text-navy-500 list-disc mx-10">
                            {webinars[0].bulletPoint1 && (
                              <li>{webinars[0].bulletPoint1}</li>
                            )}
                            {webinars[0].bulletPoint2 && (
                              <li>{webinars[0].bulletPoint2}</li>
                            )}
                            {webinars[0].bulletPoint3 && (
                              <li>{webinars[0].bulletPoint3}</li>
                            )}
                            {webinars[0].bulletPoint4 && (
                              <li>{webinars[0].bulletPoint4}</li>
                            )}
                            {webinars[0].bulletPoint5 && (
                              <li>{webinars[0].bulletPoint5}</li>
                            )}
                            {webinars[0].bulletPoint6 && (
                              <li>{webinars[0].bulletPoint6}</li>
                            )}
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="mt-5 flex justify-between w-full flex-1">
                      <div className="ml-3 pt-3 font-bold">
                        <div className="flex space-x-1 text-sm text-navy-600">
                          {webinars[0].duration} min webinar
                        </div>
                      </div>
                      {webinars[0].zoomRegistrationLink && (
                        <button
                          onClick={() =>
                            handleWebinarSignupClick(
                              webinars[0].zoomRegistrationLink
                            )
                          }
                          className="ml-6 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md fd-shadow-sm text-base font-medium transition-all text-white bg-pink-600 hover:bg-pink-700"
                        >
                          Register Now
                        </button>
                      )}
                    </div>
                  </div>

                  <img
                    className="hidden lg:inline-block flex-shrink-0 h-full lg:w-1/3 object-cover"
                    src={Images.webinars[webinars[0].image - 1]}
                    alt=""
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {webinars
                .filter((webinar, key) => key !== 0)
                .map((webinar, key) => (
                  <motion.div
                    initial={{ y: 40, opacity: 0 }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { delay: 1 + key * 0.5, duration: 0.5 },
                    }}
                    key={key}
                    className="flex flex-col rounded-lg fd-shadow-lg overflow-hidden mb-8 lg:mb-0"
                  >
                    <div className="flex-shrink-0">
                      <img
                        className="h-60 w-full object-cover"
                        src={Images.webinars[webinar.image - 1]}
                        alt=""
                      />
                    </div>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                      <div className="flex-1">
                        <p className="text-sm font-bold text-teal-600">
                          {webinar.formatedDate}
                        </p>
                        <div className="block mt-2">
                          <p className="text-lg font-semibold text-navy-600">
                            {webinar.title}
                          </p>
                          <p className="mt-2 text-navy-300">
                            {webinar.times.ld}
                            <span className="opacity-40 mx-2">|</span>
                            {webinar.times.ny}
                            <span className="opacity-40 mx-2">|</span>
                            {webinar.times.la}
                          </p>
                          {webinar.description && (
                            <p className="mt-3 text-sm text-navy-500 list-disc mx-10">
                              {webinar.description}
                            </p>
                          )}
                          {webinar.bulletPoint1 && (
                            <ul className="mt-3 text-sm text-navy-500 list-disc mx-10">
                              {webinar.bulletPoint1 && (
                                <li>{webinar.bulletPoint1}</li>
                              )}
                              {webinar.bulletPoint2 && (
                                <li>{webinar.bulletPoint2}</li>
                              )}
                              {webinar.bulletPoint3 && (
                                <li>{webinar.bulletPoint3}</li>
                              )}
                              {webinar.bulletPoint4 && (
                                <li>{webinar.bulletPoint4}</li>
                              )}
                              {webinar.bulletPoint5 && (
                                <li>{webinar.bulletPoint5}</li>
                              )}
                              {webinar.bulletPoint6 && (
                                <li>{webinar.bulletPoint6}</li>
                              )}
                            </ul>
                          )}
                        </div>
                      </div>

                      <div className="mt-5 flex justify-between w-full">
                        <div className="ml-3 pt-3 font-bold">
                          <div className="flex space-x-1 text-sm text-navy-600">
                            {webinar.duration} min webinar
                          </div>
                        </div>

                        {webinar.zoomRegistrationLink && (
                          <a
                            target="_blank"
                            href={webinar.zoomRegistrationLink}
                            className="ml-6 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium transition-all text-white bg-pink-600 hover:bg-pink-700"
                          >
                            Register Now
                          </a>
                        )}
                      </div>
                    </div>
                  </motion.div>
                ))}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default WebinarPage;
