import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import useStudent from "./useStudent";
import useActivity from "./useActivity";
import useLesson from "./useLesson";
import useClassRoom from "./useClassRoom";
import sessionStorage from "sessionstorage";
import window from "global/window";

const Context = React.createContext({}),
  ContextProvider = (props) => {
    const [socket, setSocket] = useState(),
      student = useStudent(socket),
      classRoom = useClassRoom(socket, student.isPrefect),
      lesson = useLesson(socket),
      activity = useActivity(socket),
      party = {
        lesson,
        student,
        activity,
        classRoom,
      };

    useEffect(() => {
      // show the results if the canceled is canceled halfway through
      if (socket) {
        socket.on("classroom closed", (classRoomName) => {
          const lastClass = sessionStorage.getItem("classRoomName");
          if (classRoomName === lastClass) {
            // if they didn't have a score simply send them back to the start
            // otherwise show them the results
            if (student.responses[0] || student.isPrefect) {
              lesson.setPlaying(false);
              classRoom.setOverlay("results");
            } else {
              window.location.reload();
            }
          }
          const lastClassRoomName = window.localStorage.getItem(
            "classRoomName"
          );
          if (lastClassRoomName === classRoomName) {
            window.localStorage.removeItem("classRoomName");
          }
        });

        // if no lesson revert to join screen
        // probably put up an error message
        socket.on("no lesson here", () => {
          const url = window.location.href,
            newUrl = url.substring(0, url.lastIndexOf("/"));
          window.location.replace(newUrl);
        });

        // retry connection after an error
        socket.on("connect_error", () => {
          setTimeout(() => {
            socket.connect();
          }, 1000);
        });
      }
    }, [socket]);

    useEffect(() => {
      setSocket(
        io.connect("", {
          transports: ["websocket", "polling", "flashsocket"],
          secure: true,
          reconnect: true,
          rejectUnauthorized: false,
        })
      );
    }, []);

    return <Context.Provider value={party}>{props.children}</Context.Provider>;
  };

export { Context, ContextProvider };
