import React from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";

function PrivacyPolicy() {
  return (
    <div className="bg-gray-100">
      <Menu />
      <div className="flex-grow container px-4 md:px-0 max-w-6xl mx-auto pt-32">
        <div className="sm:w-4/5 mx-auto relative bg-white flex flex-wrap rounded z-2 fd-shadow-lg overflow-hidden mb-8">
          <div
            className="px-8 py-6 mo-light text-sm  tracking-wide"
            id="text-page"
          >
            <h1>Privacy Policy</h1>
            <h2>Introduction</h2>
            <p>
              FilmDoo Ltd ("<b>FilmDoo</b>" and/or "<b>We</b>") are committed to
              protecting and respecting your privacy and take this
              responsibility regarding the security of customer information very
              seriously. We will be clear and transparent about the information
              We are collecting and what We will do with that information.
            </p>
            <p>
              This Privacy Policy (the "<b>Policy</b>"), together with our{" "}
              <a href="/term-and-conditions" target="_blank">
                terms of use
              </a>{" "}
              and any other documents referred to on it, set out the basis on
              which any personal data We collect from you, or that you provide
              to us, will be processed by us. The Policy will cover the
              following areas:
            </p>
            <ul>
              <li>
                What personal data We collect and process about you in
                connection with your relationship with us as a customer and
                through your use of our website, mobile applications and online
                services;
              </li>
              <li>Where We obtain the data from;</li>
              <li>What We do with that data;</li>
              <li>How We store the data;</li>
              <li>Who We transfer/disclose that data to;</li>
              <li>How We deal with your data protection rights;</li>
              <li>And how We comply with the data protection rules.</li>
              <li>
                All personal data is collected and processed in accordance with
                United Kingdom and EU data protection laws.
              </li>
            </ul>
            <p>
              Please read the following carefully to understand our views and
              practices regarding your personal data and how We will treat it.
              By visiting{" "}
              <a href="https://www.filmdoo.com" target="_blank">
                www.filmdoo.com
              </a>{" "}
              you are accepting and consenting to the practices described in
              this Policy.{" "}
            </p>

            <h2>Data Controller</h2>
            <p>
              For the purpose of the Data Protection Act 1998 and the General
              Data Protection Regulation (GDPR) (collectively the "<b>Act</b>"),
              the data controller is FilmDoo Ltd of ARK Coworking, 237
              Pentonville Road, Kings Cross, London N1 9NG, United Kingdom.
            </p>
            <p>
              We have also appointed a Data Protection Officer ("<b>DPO</b>") to
              oversee compliance with this Policy. If you have any questions
              about this Policy or how We handle your personal information,
              please contact the DPO at{" "}
              <a href="mailto:wjpage@filmdoo.com" target="_blank">
                wjpage@filmdoo.com
              </a>
              . You have the right to make a complaint at any time to the
              supervisory authority.
            </p>

            <h3>Information We May Collect From You</h3>
            <p>We may collect and process the following data about you:</p>
            <ul>
              <li>
                <b>Information you give us.</b> You may give us information
                about you by filling in forms on our site{" "}
                <a href="https://www.filmdoo.com" target="_blank">
                  www.filmdoo.com
                </a>{" "}
                (our "<b>Site</b>") or by corresponding with us by phone,
                e-mail, through use of other websites owned by FilmDoo or
                otherwise. This includes information you provide when you
                register to use our Site, subscribe to our Site, search for a
                film recommendation, place an order on our Site, participate in
                discussion boards or other social media functions on our Site,
                enter a competition, promotion or survey and when you report a
                problem with our Site. The information you give us may include
                your name, address, e-mail address and phone number, financial
                and credit card information, personal description and
                photograph, film recommendations, favourite genres, favourite
                directors or any amount of other information in relation to your
                film or cinema tastes.
              </li>
              <li>
                <b>Information We collect about you.</b> With regard to each of
                your visits to our Site We may automatically collect the
                following information:
                <ul>
                  <li>
                    technical information, including the Internet protocol (IP)
                    address used to connect your computer to the Internet, your
                    login information, browser type and version, time zone
                    setting, browser plug-in types and versions, operating
                    system and platform;
                  </li>
                  <li>
                    information about your visit, including the full Uniform
                    Resource Locators (URL) clickstream to, through and from our
                    site (including date and time); products you viewed or
                    searched for; page response times, download errors, length
                    of visits to certain pages, page interaction information
                    (such as scrolling, clicks, and mouse-overs), and methods
                    used to browse away from the page and any phone number used
                    to call our customer service number.
                  </li>
                </ul>
              </li>
              <li>
                <b>Information We receive from other sources.</b> We may receive
                information about you if you use any of the other websites We
                operate or the other services We provide. We are also working
                closely with third parties (including, for example, business
                partners, sub-contractors in technical, payment and delivery
                services, advertising networks, analytics providers, search
                information providers, credit reference agencies) and may
                receive information about you from them.
              </li>
              <li>
                <b>
                  Information we may collect in relation to social networks.
                </b>{" "}
                If you use any of our social network applications, pages or
                plugins or you use one of our products or services that allow
                interaction with social networks, We may receive information
                relating to your social network accounts. For instance:
                <ul>
                  <li>
                    If you log-in to one of our websites or services using your
                    social network account, We may receive basic details from
                    your social network profile. The basic details We receive
                    may depend on your social network account privacy settings,
                    however, they might include your social network ID, name,
                    profile picture, gender and locale. We may also receive
                    additional information from your profile if you give us
                    permission to access it
                  </li>
                  <li>
                    If you click on a 'like', '+1' or 'tweet' or similar button
                    in one of our websites or services, We may record the fact
                    that you have done so. In addition, the content that you are
                    viewing may be posted to your social network profile or
                    feed. We may receive information about further interactions
                    with this posted content (for example, if your contacts
                    click on a link in the posted content), which We may
                    associate with the details that We store about you
                  </li>
                  <li>
                    If you 'like', '+1' or similar one of our pages on a social
                    network site, We may receive information about your social
                    network profile, depending on your social network account
                    privacy settings
                  </li>
                  <li>
                    For more information and for details about how you can
                    control access to your social network profile, you should
                    view the privacy policy and other guidance available on your
                    social network's website.
                  </li>
                </ul>
              </li>
            </ul>

            <h2>Uses Made Of The Information</h2>
            <p>We use information held about you in the following ways:</p>
            <ul>
              <li>
                <b>Information you give to us.</b> We will use this information:
                <ul>
                  <li>
                    to carry out our obligations arising from any contracts
                    entered into between you and us and to provide you with the
                    information, products and services that you request from us;
                  </li>
                  <li>
                    to provide you with information about other goods and
                    services We offer that are similar to those that you have
                    already purchased or enquired about;
                  </li>
                  <li>
                    to provide you, or permit selected third parties to provide
                    you, with information about goods or services We feel may
                    interest you. If you are an existing customer, We will only
                    contact you by electronic means (e-mail or SMS) with
                    information about goods and services similar to those which
                    were the subject of a previous sale or negotiations of a
                    sale to you. If you are a new customer, and where We permit
                    selected third parties to use your data, We (or they) will
                    contact you by electronic means only if you have consented
                    to this. If you do not want us to use your data in this way,
                    or to pass your details on to third parties for marketing
                    purposes, please tick the relevant box situated on the form
                    on which We collect your data or on the registration form.
                    You will also be given the opportunity on every
                    e-communication that We send you to indicate that you no
                    longer wish to receive our direct marketing material.
                  </li>
                  <li>to notify you about changes to our service; and</li>
                  <li>
                    to ensure that content from our site is presented in the
                    most effective manner for you and for your computer.
                  </li>
                </ul>
              </li>
              <li>
                <b>Information We collect about you.</b> We will use this
                information:
                <ul>
                  <li>
                    to administer our Site and for internal operations,
                    including troubleshooting, data analysis, testing, research,
                    statistical and survey purposes;
                  </li>
                  <li>
                    to improve our site to ensure that content is presented in
                    the most effective manner for you and for your computer;
                  </li>
                  <li>
                    to allow you to participate in interactive features of our
                    service, when you choose to do so;
                  </li>
                  <li>
                    as part of our efforts to keep our Site safe and secure;
                  </li>
                  <li>
                    to measure or understand the effectiveness of advertising We
                    serve to you and others, and to deliver relevant advertising
                    to you; and
                  </li>
                  <li>
                    to make suggestions and recommendations to you and other
                    users of our Site about goods or services that may interest
                    you or them.
                  </li>
                </ul>
              </li>
              <li>
                <b>Information We receive from other sources.</b> We may combine
                this information with information you give to us and information
                We collect about you. We may use this information and the
                combined information for the purposes set out above (depending
                on the types of information We receive).
              </li>
              <li>
                <b>
                  We will only process your personal data where we have a legal
                  basis to do so.
                </b>{" "}
                The legal basis will depend on the reasons We have collected and
                need to use your personal data for. We will not retain your data
                for longer than is necessary to fulfil the purpose it is being
                processed for. To determine the appropriate retention period, We
                consider the amount, nature and sensitivity of the personal
                data, the purposes for which We process it and whether We can
                achieve those purposes through other means.
              </li>
            </ul>

            <h2>Disclosure Of Your Information</h2>
            <p>
              We may share your personal information with any member of our
              group, which means our subsidiaries, our ultimate holding company
              and its subsidiaries, as defined in section 1159 of the UK
              Companies Act 2006.
            </p>
            <p>
              We may share your information with selected third parties
              including:
            </p>
            <ul>
              <li>
                Business partners, suppliers and sub-contractors for the
                performance of any contract We enter into with them or you.
              </li>
              <li>
                Advertisers and advertising networks that require the data to
                select and serve relevant adverts to you and others. We do not
                disclose information about identifiable individuals to our
                advertisers, but We may provide them with aggregate information
                about our users (for example, We may inform them that 500 men
                aged under 30 have clicked on their advertisement on any given
                day). We may also use such aggregate information to help
                advertisers reach the kind of audience they want to target (for
                example, women living in London). We may make use of the
                personal data We have collected from you to enable us to comply
                with our advertisers' wishes by displaying their advertisement
                to that target audience.
              </li>
              <li>
                Analytics and search engine providers that assist us in the
                improvement and optimisation of our site.
              </li>
              <li>
                Credit and debit card companies which facilitate your payments
                to us, and anti-fraud screening, which may need information
                about your method of payment and to process payment or ensure
                the security of your payment transaction.
              </li>
              <li>
                Legal and other professional advisers, law courts and law
                enforcement bodies in all countries We operate in in order to
                enforce our legal rights in relation to our contract with you;
              </li>
              <li>
                Social media: You may be able to access third party social media
                services through our website or App or before coming to our
                website or App. When you are registered with your social service
                account, We will obtain the personal information you choose to
                share with us through these social media services pursuant to
                their privacy settings in order to improve and personalize your
                use of our website or App. We may also use social media plugins
                on our website or App. As a result your information will be
                shared with your social media provider and possibly presented on
                your social media profile to be shared with others in your
                network. Please refer to the privacy policy of these third-party
                social media providers to find out more about these practices.
              </li>
            </ul>
            <p>We may disclose your personal information to third parties:</p>
            <ul>
              <li>
                In the event that We sell or buy any business or assets, in
                which case We may disclose your aggregated data, but not
                personal data, to the prospective seller or buyer of such
                business or assets.
              </li>
              <li>
                If FilmDoo or substantially all of its assets are acquired by a
                third party, in which case personal data held by it about its
                customers will be one of the transferred assets.
              </li>
              <li>
                If We are under a duty to disclose or share your personal data
                in order to comply with any legal obligation, or in order to
                enforce or apply our terms of use (www.filmdoo.com/termsofuse)
                and other agreements; or to protect the rights, property, or
                safety of FilmDoo, our customers, or others. This includes
                exchanging information with other companies and organisations
                for the purposes of fraud protection and credit risk reduction.
              </li>
            </ul>

            <h2>Security Of Your Personal Data</h2>
            <p>
              We follow strict security procedures in the storage and disclosure
              of your personal data, and to protect it against accidental loss,
              destruction or damage. The data you provide to us is protected
              using SSL (Secure Socket Layer) technology. SSL is the industry
              standard method of encrypting personal information so that they
              can be securely transferred over the Internet.{" "}
            </p>
            <p>
              All payment details are transmitted over SSL and processed and
              stored in compliance with Payment Card Industry Data Security
              Standards (PCI DSS).
            </p>
            <p>
              We may disclose your information to trusted third parties for the
              purposes set out in this Policy. We require all third parties to
              have appropriate technical and operational security measures in
              place to protect your personal data, in line with United Kingdom
              and EU law on data protection rules. While countries outside the
              EEA do not always have strong data protection laws, We require all
              services providers to process your information in a secure manner
              and in accordance with United Kingdom and EU law on data
              protection. We utilise standard means under EU law to legitimise
              data transfers outside the EU.
            </p>

            <h2>Where We Store Your Personal Data</h2>
            <p>
              The data that We collect from you may be transferred to, and
              stored at, a destination outside the European Economic Area ("
              <b>EEA</b>"). It may also be processed by staff operating outside
              the EEA who work for us or for one of our suppliers. Such staff
              maybe engaged in, among other things, the fulfilment of your
              order, the processing of your payment details and the provision of
              support services. By submitting your personal data, you agree to
              this transfer, storing or processing. We will take all steps
              reasonably necessary to ensure that your data is treated securely
              and in accordance with this Policy.
            </p>
            <p>
              All information you provide to us is stored on our secure servers.
              Any payment transactions will be encrypted using SSL or similar
              technology. Where We have given you (or where you have chosen) a
              password which enables you to access certain parts of our site,
              you are responsible for keeping this password confidential. We ask
              you not to share a password with anyone.
            </p>

            <h2>Cookies</h2>
            <p>
              Our website uses cookies to distinguish you from other users of
              our Site. This helps us to provide you with a good experience when
              you browse our Site and also allows us to improve on our Site.
              This may include cookies of media and advertising partners that
              are being placed on your machine when visiting our website or App.
              Please visit our partners' websites for information on their
              privacy and cookie policy.
            </p>
            <p>
              Cookies are small text files that are transferred to your
              computer's hard drive through your web browser to enable us to
              recognise your browser and help us to track visitors to our site;
              thus enabling us to understand better the products and services
              that will be most suitable to you. A cookie contains your contact
              information and information to allow us to identify your computer
              when you navigate around our site for the purpose of helping you
              accomplish your interactions. Most Web browsers automatically
              accept cookies, but, if you wish, you can change these browser
              settings by accepting, rejecting and deleting cookies. The "help"
              portion of the toolbar on most browsers will tell you how to
              prevent your browser from accepting new cookies, how to have the
              browser notify you when you receive a new cookie, or how to
              disable cookies altogether. If you choose to change these
              settings, you may find that certain functions and features will
              not work as intended. The cookies We use do not detect any
              information stored on your computers.
            </p>
            <p>
              For more information about cookies and how to stop cookies being
              installed visit the following website:{" "}
              <a href="http://www.allaboutcookies.org" target="_blank">
                http://www.allaboutcookies.org
              </a>
              .
            </p>

            <h2>Your Data Protection Rights</h2>
            <p>
              You have the right to ask us not to process your personal data for
              marketing purposes. We will usually inform you (before collecting
              your data) if We intend to use your data for such purposes or if
              We intend to disclose your information to any third party for such
              purposes. You can exercise your right to prevent such processing
              by checking certain boxes on the forms We use to collect your
              data. You can also exercise the right at any time by contacting us
              at{" "}
              <a target="_blank" href="mailto:info@filmdoo.com">
                info@filmdoo.com
              </a>
              .
            </p>
            <p>
              Our site may, from time to time, contain links to and from the
              websites of our partner networks, advertisers and affiliates. If
              you follow a link to any of these websites, please note that these
              websites have their own privacy policies and that We do not accept
              any responsibility or liability for these policies. Please check
              these policies before you submit any personal data to these
              websites.
            </p>
            <p>Under certain circumstances, by law you have the right to:</p>
            <ul>
              <li>
                <b>Request information</b> about whether We hold personal
                information about you, and, if so, what that information is and
                why We are holding/using it.
              </li>
              <li>
                <b>Request access</b> to your personal information (commonly
                known as a "data subject access request"). This enables you to
                receive a copy of the personal information We hold about you and
                to check that We are lawfully processing it.
              </li>
              <li>
                <b>Request correction</b> of the personal information that We
                hold about you. This enables you to have any incomplete or
                inaccurate information We hold about you corrected.
              </li>
              <li>
                <b>Request erasure</b> of your personal information. This
                enables you to ask us to delete or remove personal information
                where there is no good reason for us continuing to process it.
                You also have the right to ask us to delete or remove your
                personal information where you have exercised your right to
                object to processing (see below).
              </li>
              <li>
                <b>Object to processing</b> of your personal information where
                We are relying on a legitimate interest (or those of a third
                party) and there is something about your particular situation
                which makes you want to object to processing on this ground. You
                also have the right to object where We are processing your
                personal information for direct marketing purposes.
              </li>
              <li>
                <b>Object to automated decision-making including profiling</b>,
                that is not to be subject of any automated decision-making by us
                using your personal information or profiling of you.
              </li>
              <li>
                <b>Request the restriction of processing</b> of your personal
                information. This enables you to ask us to suspend the
                processing of personal information about you, for example if you
                want us to establish its accuracy or the reason for processing
                it.
              </li>
              <li>
                <b>Request transfer</b> of your personal information in an
                electronic and structured form to you or to another party
                (commonly known as a right to "data portability"). This enables
                you to take your data from us in an electronically useable
                format and to be able to transfer your data to another party in
                an electronically useable format.
              </li>
              <li>
                <b>Withdraw consent.</b> In the limited circumstances where you
                may have provided your consent to the collection, processing and
                transfer of your personal information for a specific purpose,
                you have the right to withdraw your consent for that specific
                processing at any time. Once We have received notification that
                you have withdrawn your consent, We will no longer process your
                information for the purpose or purposes you originally agreed
                to, unless We have another legitimate basis for doing so in law.
              </li>
            </ul>
            <p>
              If you want to exercise any of these rights, then please email our
              DPO at{" "}
              <a href="mailto:wjpage@filmdoo.com" target="_blank">
                wjpage@filmdoo.com
              </a>{" "}
              or our general info account at{" "}
              <a href="mailto:info@filmdoo.com" target="_blank">
                info@filmdoo.com
              </a>{" "}
              or contact our DPO by post at FilmDoo Limited, ARK Coworking, 237
              Pentonville Road, Kings Cross, London N1 9NG, United Kingdom.
            </p>

            <h2>Access To Information</h2>
            <p>
              You will not have to pay a fee to access your personal information
              (or to exercise any of the other rights). However, We may charge a
              reasonable fee if your request for access is clearly unfounded or
              excessive. Alternatively, We may refuse to comply with the request
              in such circumstances.
            </p>
            <p>
              We may need to request specific information from you to help us
              confirm your identity and ensure your right to access the
              information (or to exercise any of your other rights). This is
              another appropriate security measure to ensure that personal
              information is not disclosed to any person who has no right to
              receive it.
            </p>

            <h2>Changes To Our Privacy Policy</h2>
            <p>
              Your use of FilmDoo, and any disputes arising from it, is subject
              to this Policy and all of its dispute resolution provisions
              including arbitration, limitation on damages and choice of law. We
              reserve the right to change our Policy at any time. Non-material
              changes and clarifications will take effect immediately, and
              material changes will take effect within thirty (30) days of their
              posting on this site. If We make changes, We will post them and
              will indicate at the top of this page the Policy's new effective
              date. If We make material changes to this Policy, We will notify
              you here, by email, or through notice on our home page. We
              encourage you to refer to this Policy on an ongoing basis so that
              you understand our current Policy. Unless stated otherwise, our
              current Policy applies to all information that We have about you
              and your account.
            </p>

            <h2>Contact</h2>
            <p>
              Questions, comments and requests regarding this Policy are
              welcomed and should be addressed to{" "}
              <a href="mailto:info@filmdoo.com" target="_blank">
                info@filmdoo.com
              </a>{" "}
              .
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
