import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import variants from "../../components/animationVariants";
import Images from "../../../assets/images";

function Benefits({ openCreateAccountModal }) {
  const controls = useAnimation(),
    [ref, inView] = useInView(),
    [showGifs, setShowGifs] = useState(false),
    [showGifs2, setShowGifs2] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      setTimeout(() => setShowGifs(true), 2000);
      setTimeout(() => setShowGifs2(true), 3000);
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      className="py-12 lg:py-28 bg-white mb-8"
    >
      <motion.div
        variants={variants.fromBottom}
        className="max-w-xl mx-auto lg:max-w-6xl px-12"
      >
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8 text-center">
          <div>
            <div className="h-28 w-full">
              {showGifs && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.ticket}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-7">
              <dt className="text-lg leading-6 font-bold text-navy-700">
                Access VIP Events
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                Access VIP events, including webinars and virtual roundtable
                discussions.
              </dd>
            </div>
          </div>

          <div>
            <div className="h-28 w-full">
              {showGifs && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.news}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-7">
              <dt className="text-lg leading-6 font-bold text-navy-700">
                Access to New & Exclusive Educational Content
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                Access vlogs, blogs, e-newsletters and special offers.
              </dd>
            </div>
          </div>

          <div>
            <div className="h-28 w-full">
              {showGifs && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.diploma}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-7">
              <dt className="text-lg leading-6 font-bold text-navy-700">
                FilmDoo School Reward & Recognition Program
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                Be part of the FilmDoo school reward and recognition program,
                which includes certificates and rewards.
              </dd>
            </div>
          </div>
        </dl>
      </motion.div>

      <motion.div
        variants={variants.fromBottom2}
        className="max-w-xl mx-auto lg:max-w-6xl px-12 pt-12"
      >
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8 text-center">
          <div>
            <div className="h-28 w-full">
              {showGifs2 && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.analytics}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-7">
              <dt className="text-lg leading-6 font-bold text-navy-700">
                Free Training & Classes
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                Access free and unlimited CPD facilitated by FilmDoo experts
              </dd>
            </div>
          </div>

          <div>
            <div className="h-28 w-full">
              {showGifs2 && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.browser}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-7">
              <dt className="text-lg leading-6 font-bold text-navy-700">
                Join an Inspirational Community
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                Be part of our active and inspirational online community via
                LinkedIn, Facebook, Twitter and Instagram.
              </dd>
            </div>
          </div>

          <div>
            <div className="h-28 w-full">
              {showGifs2 && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.chat}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-7">
              <dt className="text-lg leading-6 font-bold text-navy-700">
                Get Involved & Have Your Say
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                Get involved to make FilmDoo Academy even better. Have your
                input on our product roadmap, new features and game-changing
                functionalities.
              </dd>
            </div>
          </div>
        </dl>
      </motion.div>
    </motion.div>
  );
}

export default Benefits;
