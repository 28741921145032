import React, { useLayoutEffect, useState } from "react";
import ReactPlayer from "react-player";
import PlayIcon from "../../components/icons/playIcon";
import TeacherProgress from "./teacherProgress";
import StudentProgress from "./studentProgress";
import VolumeIcon from "../../components/icons/volumenIcon";
import FullScreenIcon from "../../components/icons/fullScreenIcon";
import Emojis from "../../../assets/images/emojis";
import { motion, AnimatePresence } from "framer-motion";
import PlayerEmojiChoice from "./playerEmojiChoice";
import document from "global/document";
import Unmute from "../overlays/unmute";
import window from "global";

function PlayerWrapper({
  latestEmotes,
  activity,
  classRoom,
  student,
  lesson,
  onProgress,
  volume,
  onSeek,
  player,
  pauseVideo,
  playVideo,
  seekTo,
  setCurrentlySeeking,
  setVolume,
  subtitles,
  onEnded,
}) {
  const [ready, setReady] = useState(false);
  const onError = () => {
    // for now just show the youtube/vimeo error so they can at least see the error
    // TODO show them a custom error and message us the error message
    lesson.setMuted(false);
  };

  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight - 50);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div
      className={`player playerWrapper 
        ${activity.type === "watching" ? "show" : "hide"}
        ${lesson.muted ? "muted" : "notMuted"}
      `}
    >
      {classRoom.name && student.isPrefect && (
        <div className="roomCode mo-light text-xs text-navy-600">
          Room Name:<b className="text-lg">{classRoom.name}</b>
        </div>
      )}

      <PlayerEmojiChoice {...{ classRoom, student }} />

      {activity.type === "watching" && lesson.playing && <Unmute />}
      <ReactPlayer
        {...{ onProgress, onSeek, volume, onEnded, onError }}
        onReady={() => setReady(true)}
        muted={lesson.muted}
        playsinline={true}
        playing={ready && lesson.playing}
        url={lesson.videoUrl}
        id="reactPlayer"
        ref={player}
        progressInterval={100}
        width="100%"
        height={height}
        config={{
          youtube: {
            origin: document.domain,
            playerVars: {
              disablekb: 1,
              rel: 0,
              showinfo: 0,
              ecver: 2,
              cc_load_policy: subtitles ? 1 : 0,
              cc_lang_pref: subtitles,
            },
          },
          vimeo: {
            playerOptions: {
              playsinline: 1,
              controls: 1,
            },
          },
        }}
      />

      <div className="w-20 fixed z-10 player-emojis mr-2">
        <AnimatePresence>
          {latestEmotes.map((emote) => (
            <motion.div
              initial={{ opacity: 0.5, scale: 1.05 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.1 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              className="px-1 w-full rounded-lg bg-coral-500 text-xs text-center text-white mo-medium pt-2 pb-1 my-2"
            >
              <div className="mx-2">
                {Emojis[emote.emojiCharacter][emote.emojiEmotion]}
              </div>
              {emote.name}
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <div className="playerControlls">
        <div
          className={`playButton ${
            student.isPrefect ? "playButtonActive" : ""
          }`}
          onClick={lesson.playing ? pauseVideo : playVideo}
        >
          <div className="playButtonFiller" />
          <PlayIcon playing={lesson.playing} />
        </div>

        {student.isPrefect ? (
          <TeacherProgress {...{ seekTo, setCurrentlySeeking }} />
        ) : (
          <StudentProgress />
        )}

        <div className="playerSettings">
          <VolumeIcon {...{ setVolume, volume }} />
          <FullScreenIcon />
        </div>
      </div>
    </div>
  );
}

export default PlayerWrapper;
