import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import window from "global/window";

function Prices({
  openCreateAccountModal,
  openLogin,
  showSubscription,
  setShowSubscription,
}) {
  const [user, setUser] = useState();
  const handleShowSubscription = () => setShowSubscription(true);
  useEffect(() => {
    setUser(JSON.parse(window.localStorage.getItem("fda_user")));
  }, [showSubscription]);

  const plans = [
    {
      name: "Basic",
      text:
        "Instantly increase your student engagement with game-based lessons on film and video",
      price: "FREE",
      increment: "",
      hideButton: !!user,
      button: user ? "Already Created" : "Create Account",
      onClick: openCreateAccountModal,
      bullets: [
        "14-Day Free Trial to our Premium Features",
        "Access our growing library of ready-to-use interactive lessons",
        "Easily supports up to 50 students per game at any one time",
        "Search and filter for lessons by subjects, language level and themes",
        "Flexibility to use the lesson different modes: in classrooms, during online lessons or for homework and/or self-study",
        "Receive free 15-minute Training session",
      ],
    },
    {
      name: "Teacher Pro",
      text:
        "Empower teachers to turn any film and video into an interactive lesson with our easy-to-use course authoring tool",
      price: "£9",
      increment: "/month",
      hideButton: user && user.permission_level > 1,
      button:
        user && user.permission_level > 1
          ? "Already Pro"
          : user
          ? "Upgrade Account"
          : "Login To Upgrade",
      onClick: user ? handleShowSubscription : openLogin,
      bullets: [
        "All of the BASIC Plan, plus:",
        "Access to our superfast course-authoring tool",
        "Unlimited ability to edit other lessons and tailor as you like",
        "Email and phone customer support",
        "Access to premium webinars, tutorials and additional training",
        "Available support to assist with film and video recommendations",
        "Invitation to FilmDoo Academy VIP Membership Programme",
      ],
    },
    {
      name: "Department Level",
      text:
        "Empower the teachers in your department to transform student outcomes with films- and video-based learning",
      price: "£199",
      increment: "/year",
      button: "Contact Us",
      onClick: () => {
        window.location.href = "/contact";
      },
      bullets: [
        "All of the TEACHER PRO Plan, plus:",
        "Great value for your department on a per teacher basis",
        "Private training sessions by the FilmDoo Academy team",
        "Access to Dedicated Department Success Manager for onboarding & support",
      ],
    },
    {
      name: "School Level",
      text:
        "Introduce your school to the next-generation platform that connects to the modern students",
      price: "£999",
      increment: "/year",
      button: "Contact Us",
      onClick: () => {
        window.location.href = "/contact";
      },
      bullets: [
        "All of the DEPARMENT LEVEL Plan, plus:",
        "Great value for your school on a per teacher basis",
        "Private training sessions by the FilmDoo Academy team",
        "Access to Dedicated School Success Manager for onboarding & support",
      ],
    },
  ];

  const advancedPlans = [
    {
      type: "Multi-Academy Trust, Local Authorities & Districts",
      text:
        "For more than one schools looking to enjoy the collective benefits of our premium Customized offer.",
      button: "Request A Quote",
      onClick: () => {
        window.location.href = "/contact";
      },
      bullets: [
        "All of the SCHOOL LEVEL Plan, plus:",
        "Great value for your schools on a per teacher basis",
        "Private training sessions by the FilmDoo Academy team",
        "Access to Dedicated Programme Success Manager for onboarding & support",
      ],
    },
    {
      type: "Language School & Language Teachers",
      text:
        "For language schools and language teachers, FilmDoo Academy is also able to offer access to FilmDoo’s extensive award-winning international film collections to be used as part of the edutainment platform.",
      button: "Request A Quote",
      onClick: () => {
        window.location.href = "/contact";
      },
      bullets: [
        "All of the SCHOOL LEVEL Plan, plus:",
        "Access to hundreds of films and short films, which teachers can use to turn into interactive lessons & games",
        "Students can enjoy a rich, powerful catalogue of contemporary authentic films in English, French, Spanish, German, Mandarin Chinese and many more languages!",
        "Private training sessions by the FilmDoo Academy team",
        "Access to Dedicated Language Success Manager for onboarding & support",
      ],
    },
  ];

  return (
    <div className="relative z-20 max-w-6xl mx-auto py-24 px-4 sm:px-6 lg:px-8 -mt-80">
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.6, delay: 1.1 },
        }}
        className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4"
      >
        {plans.map((plan, key) => (
          <div
            key={key}
            className="bg-white border border-navy-100 rounded-lg fd-shadow-sm divide-y divide-navy-200"
          >
            <div className="p-6">
              <h2 className="text-lg leading-6 font-bold text-navy-900">
                {plan.name}
              </h2>
              <p className="mt-4 text-sm text-navy-500 h-24">{plan.text}</p>
              <p className="mt-8">
                <span className="text-4xl font-extrabold text-navy-700">
                  {plan.price}
                </span>
                <span className="text-base font-medium text-navy-500">
                  {plan.increment}
                </span>
              </p>
              <button
                onClick={plan.onClick}
                disabled={plan.hideButton}
                className={`
                      ${
                        plan.hideButton
                          ? "bg-gray-400"
                          : "transition duration-500 ease-in-out bg-pink-600 hover:bg-pink-700 fd-shadow-sm hover:shadow"
                      }
                      mt-4 block w-full rounded-md py-2 text-sm font-semibold text-white text-center`}
              >
                {plan.button}
              </button>
            </div>
            <div className="pt-6 pb-8 px-6">
              <h3 className="text-xs font-medium text-navy-900 tracking-wide uppercase">
                What's included
              </h3>
              <ul className="mt-6 space-y-4">
                {plan.bullets.map((bullet, key) => (
                  <li key={key} className="flex space-x-2">
                    <svg
                      className="flex-shrink-0 h-3 w-3 mt-1 text-teal-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-sm text-navy-500">{bullet}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </motion.div>

      {advancedPlans.map((plan, key) => (
        <motion.div
          key={key}
          initial={{ y: 200, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { duration: 0.6, delay: 2 + key },
          }}
          className="border border-navy-100 mt-8 rounded-lg fd-shadow overflow-hidden lg:max-w-none lg:flex"
        >
          <div className="flex-1 bg-white px-6 py-8 lg:p-12">
            <div className="lg:flex w-full">
              <div className="flex-1">
                <h2 className="text-2xl font-extrabold text-navy-900 sm:text-3xl">
                  {plan.type}
                </h2>
                <p className="mt-6 text-base text-navy-500">{plan.text}</p>
              </div>
              <div className="lg:flex-shrink-0">
                <div className="rounded-md -mt-2 mr-2">
                  <button
                    onClick={plan.onClick}
                    className="shadow-sm hover:shadow transition duration-500 ease-in-out flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700"
                  >
                    {plan.button}
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="flex items-center">
                <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-pink-600">
                  What's included
                </h4>
                <div className="flex-1 border-t-2 border-pink-100" />
              </div>
              <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                {plan.bullets.map((bullet, key) => (
                  <li key={key} className="flex items-start lg:col-span-1">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-5 w-5 text-teal-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="ml-3 text-sm text-navy-700">{bullet}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
}

export default Prices;
