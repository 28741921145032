import React, { useEffect } from "react";
import Images from "../../assets/images";
import { Link } from "react-router-dom";

function Footer() {
  useEffect(() => {
    // manually load react pixel to use on SSR
    import("react-facebook-pixel")
      .then((module) => module.default)
      .then((ReactPixel) => {
        ReactPixel.init("2736582213241656");
        ReactPixel.pageView();
      });

    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/5eea4c769e5f69442290c46d/default";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return (
    <footer className="bg-gray-900" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-6xl mx-auto py-12 px-12 lg:py-16 lg:px-8">
        <div className="pb-8 xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      to={{ pathname: "/webinars" }}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Our Webinars
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={{ pathname: "/for_schools" }}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      For Schools
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={{ pathname: "/languages" }}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Languages and Films
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={{ pathname: "/for_teachers" }}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      For Teachers
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={{
                        pathname:
                          "/a-teachers-guide-to-finding-film-and-video-resources-for-lessons",
                      }}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      How To Find Film & Video Resources For Your Lessons
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={{
                        pathname:
                          "/white_paper/5_reasons_you_should_be_using_film_and_video_as_part_of_your_online_lessons",
                      }}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      How To Use Film & Video in Your Lessons
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      to={{ pathname: "/contact" }}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Contact Us
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={{ pathname: "/about_us" }}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      About Us
                    </Link>
                  </li>

                  <li>
                    <a
                      href="https://www.filmdoo.com"
                      target="_blank"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      FilmDoo.com
                    </a>
                  </li>

                  <li>
                    <Link
                      to={{ pathname: "/sample_lessons" }}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Sample Lessons
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={{ pathname: "/testimonials" }}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Testimonials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{ pathname: "/pricing" }}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Pricing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="mt-12 md:mt-0"></div>
              <div className="mt-12 md:mt-0">
                <ul className="mt-4 space-y-4">
                  <li>
                    <a
                      href="https://www.filmdoo.com/welcome-upload/"
                      target="_blank"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Are You A Filmmaker?
                    </a>
                  </li>

                  <li>
                    <p className="text-base text-gray-300 hover:text-white">
                      &nbsp;
                    </p>
                  </li>

                  <li>
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/term-and-conditions"
                      target="_blank"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-6 md:space-y-0 mt-8 border-t border-gray-700 md:pt-8 md:flex items-center justify-center md:justify-between">
          <p className="mt-8 text-base text-gray-400 md:mt-0 text-center">
            © 2020 FilmDoo
          </p>
          <div className="justify-center">{Images.creativeEurope}</div>
          <div className="flex space-x-6 justify-center">
            <a
              href="https://www.facebook.com/FilmDooAcademy/"
              target="_blank"
              className="transition duration-500 ease-in-out text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">Facebook</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>

            <a
              href="https://www.linkedin.com/company/filmdoo"
              target="_blank"
              className="transition duration-500 ease-in-out text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">LinkedIn</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 512 512"
              >
                <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm-74.390625 387h-62.347656v-187.574219h62.347656zm-31.171875-213.1875h-.40625c-20.921875 0-34.453125-14.402344-34.453125-32.402344 0-18.40625 13.945313-32.410156 35.273437-32.410156 21.328126 0 34.453126 14.003906 34.859376 32.410156 0 18-13.53125 32.402344-35.273438 32.402344zm255.984375 213.1875h-62.339844v-100.347656c0-25.21875-9.027343-42.417969-31.585937-42.417969-17.222656 0-27.480469 11.601563-31.988282 22.800781-1.648437 4.007813-2.050781 9.609375-2.050781 15.214844v104.75h-62.34375s.816407-169.976562 0-187.574219h62.34375v26.558594c8.285157-12.78125 23.109375-30.960937 56.1875-30.960937 41.019531 0 71.777344 26.808593 71.777344 84.421874zm0 0" />
              </svg>
            </a>

            <a
              href="https://www.youtube.com/watch?v=G7Hq5JTkix0&list=PLtRcolvk3SQKqZJdut8Z1yjrNUupm5OlI"
              target="_blank"
              className="transition duration-500 ease-in-out text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">YouTube</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="-21 -117 682.66672 682"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0"
                />
              </svg>
            </a>

            <a
              href="https://twitter.com/FilmdooAcademy"
              target="_blank"
              className="transition duration-500 ease-in-out text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">Twitter</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>

            <a
              href="https://www.instagram.com/filmdoo_academy"
              target="_blank"
              className="transition duration-500 ease-in-out text-gray-400 hover:text-gray-300"
            >
              <span className="sr-only">Instagram</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
