import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import { useInView } from "react-intersection-observer";
import Images from "../../../assets/images";
import BgParticles from "../../components/bgParticles";

function Hero({ setMasterShowMembership }) {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <main className="w-full bg-gray-100 sloppy-bottom overflow-hidden pt-4 lg:pt-10">
      <BgParticles />

      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="relative max-w-5xl mx-auto lg:py-32 pt-24 pb-14 px-8"
      >
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div
            variants={variants.fromLeft}
            className="col-span-12 text-left"
          >
            <h1 className="lg:mt-2 text-5xl lg:text-6xl font-semibold text-navy-700 leading-tight mb-4">
              Our Free <spam className="text-teal-600">VIP Membership</spam>
            </h1>
          </motion.div>

          <motion.div
            variants={variants.fromLeft2}
            className="md:max-w-2xl lg:col-span-6 text-left"
          >
            <p className="text-navy-600 font-bold mt-3 text-xl lg:text-2xl mx-auto lg:w-full font-bold">
              Welcome to FilmDoo Academy’s Free Membership Programme
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto lg:w-full">
              Join the fastest growing, free membership for primary schools,
              secondary schools, multi academy trusts and educational
              institutions around the world.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto lg:w-full">
              Join hundreds of schools, receive the latest news, engage in high
              quality discussions and keep your finger on the edtech pulse
            </p>

            <div className="mt-3 lg:mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 pt-4">
              <button
                onClick={setMasterShowMembership}
                className="transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-lg transform focus:outline-none rounded-md fd-shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white bg-pink-600 md:py-4 md:text-lg md:px-10"
              >
                Join Today
              </button>
            </div>
          </motion.div>
          <motion.div
            variants={variants.fromRight}
            className="relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 flex flex-col lg:col-span-6"
          >
            <div className="relative mx-auto w-full lg:max-w-lg mb-6 lg:mb-12 mt-4">
              {Images.students.e}
            </div>
          </motion.div>
        </div>
      </motion.div>
    </main>
  );
}

export default Hero;
