import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Images from "../../assets/images";
import { Link } from "react-router-dom";
import variants from "./animationVariants";

function SampleLessons({ forLanguages, extraHeadings, noButton }) {
  const controls = useAnimation(),
    [ref, inView] = useInView(),
    flags = [
      { label: "Chinese", id: "chinese" },
      { label: "Korean", id: "korean" },
      { label: "Portuguese", id: "portuguese" },
      { label: "Arabic", id: "arabic" },
      { label: "English", id: "english" },
      { label: "German", id: "german" },
      { label: "French", id: "french" },
      { label: "Spanish", id: "spanish" },
      { label: "Dutch", id: "dutch" },
      { label: "Luxembourgish", id: "luxembourgish" },
    ],
    stem = [
      { label: "Maths", id: "maths" },
      { label: "History", id: "history" },
      { label: "COVID-19 Safety", id: "virus" },
      { label: "Computer Science", id: "computer_science" },
      { label: "Geography", id: "geography" },
      { label: "Media Studies & Film", id: "media_studies" },
      { label: "Food Technology", id: "food_technology" },
      { label: "English Literature", id: "english_literature" },
      { label: "Diversity & Inclusion", id: "diversity" },
    ];

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      className="bg-white"
    >
      {extraHeadings && (
        <motion.div
          variants={variants.fromBottom}
          className="bg-white overflow-hidden"
        >
          <div className="max-w-5xl mx-auto text-center pt-16 px-12 sm:pt-20 sm:px-6">
            <h2 className="text-4xl font-extrabold text-navy-600 sm:text-5xl">
              Ready-To-Use Sample Language Lessons
            </h2>
            <p className="mt-4 text-lg leading-6 text-navy-500">
              See what other teachers have created and get inspired. These are
              free resources for you to try out.
            </p>
            <p className="mt-6 text-xl font-bold leading-6 text-pink-600 italic ">
              Language lessons using authentic, engaging films & videos
            </p>
          </div>
        </motion.div>
      )}

      <motion.div
        variants={variants.fromBottom2}
        className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
      >
        {forLanguages && (
          <p className="text-center text-sm lg:text-md text-navy-600 uppercase tracking-wide tracking-wider">
            Example Language Lessons
          </p>
        )}

        {!forLanguages && (
          <p className="text-center text-sm lg:text-md text-navy-600 uppercase tracking-wide tracking-wider">
            CHECK OUT OUR READY-TO-USE SAMPLE LESSONS
          </p>
        )}

        {forLanguages && (
          <div className="mt-6 grid gap-0.5 grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 lg:mt-8 ">
            {flags.map((flag) => (
              <Link
                to={{ pathname: "/sample/" + flag.id }}
                className="transition duration-700 ease-in-out rounded hover:bg-teal-100 transform focus:outline-none col-span-1 flex flex-col py-4 px-6 lg:px-8 bg-gray-50 cursor-pointer mx-auto justify-center"
              >
                <img
                  className="w-52 rounded block"
                  src={Images.flags[flag.id]}
                  alt={flag.label}
                />
                <p className="w-full text-center mt-1 text-navy-600 text-lg font-bold">
                  {flag.label}
                </p>
              </Link>
            ))}
          </div>
        )}

        {!forLanguages && (
          <div className="mt-6 grid gap-0.5 grid-cols-3 lg:mt-8 max-w-xl mx-auto">
            <p className="w-full text-center mt-1 text-navy-600 text-sm font-bold">
              STEM
            </p>
            <p className="w-full text-center mt-1 text-navy-600 text-sm font-bold">
              Humanities
            </p>
            <p className="w-full text-center mt-1 text-navy-600 text-sm font-bold">
              Special Courses
            </p>
            {stem.map((flag) => (
              <Link
                to={{ pathname: "/sample/" + flag.id }}
                className="transition duration-700 ease-in-out rounded hover:bg-teal-100 transform focus:outline-none col-span-1 flex flex-col py-4 px-6 lg:px-8 cursor-pointer bg-gray-50 mx-auto justify-center"
              >
                <img
                  className="w-52 rounded block"
                  src={Images.flags[flag.id]}
                  alt={flag.label}
                />
                <p className="w-full text-center mt-1 text-navy-600 text-sm">
                  {flag.label}
                </p>
              </Link>
            ))}
          </div>
        )}

        {!noButton && (
          <div className="my-12 text-center">
            <Link
              to={{ pathname: "/sample_lessons" }}
              className="transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-lg transform focus:outline-none mx-auto rounded-md fd-shadow items-center px-8 py-3 border border-transparent text-base font-medium text-white bg-pink-600 md:py-4 md:text-lg md:px-10"
            >
              See More Sample Lessons
            </Link>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
}

export default SampleLessons;
