import React, {useContext, useMemo } from 'react'
import { motion } from "framer-motion";
import { Context } from "../context";

function StudentProgress() {

  const
    { lesson, student, activity } = useContext(Context),
    responses = useMemo(() => !student.responses[0] ? [] :
      student.responses.reduce((returnArray, response) => {
        returnArray[response.key] = response.correctFirstTry;
        return returnArray;
      }, {}), [student.responses]),
    mapTimes = t => 100-( (t - lesson.starttime) * 100 / lesson.duration ) + '%';

  return (
    <motion.div
      id="playerProgressBar">

      <motion.div
        animate={{ right:mapTimes(lesson.currentTime) }}
        transition={{ ease: "linear", duration: 0.35 }}
        className="playerSeekLocation" />

      <div className="progressLine">
        {useMemo( () => lesson.activities
          .filter(activity => !!activity && !!activity.time)
          .map((question,key) => <div
              {...{key}}
              className="questionLine"
              style={{right:mapTimes(question.time) }}>
              <div
                className="questionDot"
                style={{
                  background: typeof(responses[question.key]) !== 'boolean' ?
                    '#31376E' : responses[question.key] ?
                      '#F7FCFD' : '#ff6453'
                }}>
              </div>
            </div>
        ), [lesson.activities, mapTimes])}
      </div>

    </motion.div>
  )
}

export default StudentProgress;
