import React, { useState, useEffect } from 'react';

function Stats() {
    const [users, setUsers] = useState([]);
    const combine = a => a.email + a.time.split('-');

    useEffect(()=>{
        fetch('/api/v1/stats')
            .then(response => response.json() )
            .then(response => {

                const uniqueStats = Array.from(
                    new Set(response
                        .map(a => combine(a)))
                ).map(combined =>
                    response.find(a => combine(a) === combined)
                )
                const groupedStatsObject = uniqueStats.reduce((p, c) => {
                    let email = c.email;
                    if (!p.hasOwnProperty(email)) {
                        p[email] = 0;
                    }
                    p[email]++;
                    return p;
                }, {});
                const groupedStatsArray = Object.keys(groupedStatsObject).map( key => {
                    let otherDetails = response.filter(r => r.email === key)[0];
                    return {
                        name: otherDetails.username,
                        courses: otherDetails.count,
                        email: key,
                        login: groupedStatsObject[key]
                    }})

                groupedStatsArray.sort( (a, b) => a.login < b.login ? 0 : -1 );
                setUsers(groupedStatsArray);

            });
    },[])

    return(
        <div className="my-12 mx-auto max-w-4xl">
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="fd-shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Username
                                </th>
                                <th scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Email
                                </th>
                                <th scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Number of days logged in
                                </th>
                                <th scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Number of courses created
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map( (user, key) =>

                                <tr className={`${key%2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-teal-600">
                                        <a target="_blank" href={'/content?author='+user.name}>
                                            {user.name}
                                        </a>

                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {user.email}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {user.login}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {user.courses}
                                </td>
                                </tr>

                            )}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Stats;