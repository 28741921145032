import React, { useContext } from "react";
import { motion } from "framer-motion";
import LeaderboardPositions from "../components/leaderboardPositions";
import { Context } from "../context";
import Div100vh from "react-div-100vh";

function Leaderboard() {
  const { classRoom, activity, lesson } = useContext(Context),
    variants = {
      header: {
        visible: {
          y: 0,
          transition: {
            duration: 0.4,
            staggerChildren: 0.3,
            delayChildren: 1,
          },
        },
        hidden: {
          y: -400,
          transition: {
            duration: 1,
            staggerChildren: 0.1,
            staggerDirection: -1,
          },
        },
      },
      text: {
        visible: { y: 0, opacity: 1 },
        hidden: { y: -50, opacity: 0 },
      },
      position: {
        visible: {
          y: 0,
          transition: { ease: "easeOut" },
        },
        hidden: {
          y: 200,
          transition: { ease: "easeOut" },
        },
      },
      list: {
        visible: {
          transition: {
            staggerChildren: 1 / classRoom.leaderboard.length,
            delayChildren: 1.7,
            staggerDirection: -1,
          },
        },
        hidden: {
          transition: { staggerChildren: 0.1 },
        },
      },
    };

  return (
    <Div100vh className="overflow-y-auto overflow-x-hidden w-full">
      <motion.div
        variants={variants.header}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="bg-navy-700 leaderboard-heading relative"
      >
        <div className="text-center">
          <div className="overflow-hidden pt-12 pb-16">
            <motion.p
              variants={variants.text}
              className="px-4 sm:px-2 w-full max-w-2xl mx-auto mt-1 text-2xl pf-medium text-white sm:text-3xl lg:text-4xl"
            >
              {activity.nextActivity === "end-game" && !lesson.playing
                ? "Final Scores"
                : "Leaderboard"}
            </motion.p>
          </div>
        </div>
      </motion.div>

      <div className="w-full max-w-2xl mx-auto sm:px-2 -mt-6">
        <motion.ul
          layout
          variants={variants.list}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="leaderboard-list"
        >
          {classRoom.leaderboard
            .slice(0, 30)
            .sort((a, b) => b.score - a.score)
            .map((person) => (
              <li className="overflow-hidden py-3">
                <motion.div variants={variants.position}>
                  <LeaderboardPositions {...{ person }} />
                </motion.div>
              </li>
            ))}
        </motion.ul>
      </div>
    </Div100vh>
  );
}

export default Leaderboard;
