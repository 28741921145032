import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import variants from "../../components/animationVariants";

function HomeCreate({ openCreateAccountModal }) {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      className="bg-navy-600 overflow-hidden"
    >
      <motion.div
        variants={variants.fromBottom}
        className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8"
      >
        <h2 className="text-4xl font-extrabold text-teal-300 sm:text-5xl">
          <span className="block">
            Why not try it out and{" "}
            <span className="text-white">
              create your own video-based lesson?
            </span>
          </span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-navy-100">
          We will fully support you, your school and your students throughout
          this journey. Check out FilmDoo Academy today!
        </p>
        <button
          onClick={openCreateAccountModal}
          className="transition duration-700 ease-in-out hover:bg-teal-400 hover:fd-shadow-black transform focus:outline-none shadow-2xl mt-8 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-navy-700 bg-teal-500 sm:w-auto"
        >
          Get started for free
        </button>
      </motion.div>
    </motion.div>
  );
}

export default HomeCreate;
