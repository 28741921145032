import React, { useState, useEffect } from "react";
import CheckBox from "../components/icons/checkbox";
import { motion, AnimatePresence } from "framer-motion";
import window from "global/window";
import sessionStorage from "sessionstorage";
import Menu from "../../website/components/menu";
import Images from "../../assets/images";
import Hashids from "hashids/cjs";
const hashids = new Hashids();

function Profile(props) {
  const [lessons, setLessons] = useState([]),
    pilots = require("./pilots.json"),
    [profileData, setProfileData] = useState({}),
    fetchLessons = () => {
      let profileId = props.match.params.profileId.toLowerCase();

      fetch("/api/v1/user/getByName/" + profileId, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((user) => {
          if (!user.id) {
            window.location.href = "/";
            return;
          }

          fetch("/api/v1/lesson/getAll", {
            body: JSON.stringify({
              user: user.id,
            }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Cache-Control": "no-cache",
            },
            method: "POST",
          })
            .then((response) => response.json())
            .then((data) => {
              setLessons(data.lessons);
            });
        });
    },
    handleLoadLessonClick = (lessonId) => {
      // ensure they don't load a previous game
      sessionStorage.removeItem("classRoomName");
      sessionStorage.removeItem("name");
      window.open(`/party/${hashids.encode(lessonId)}`, "_blank").focus();
    };

  useEffect(() => {
    setProfileData(pilots[0][props.match.params.profileId.toLowerCase()]);
  }, []);

  useEffect(() => {
    if (profileData === undefined) {
      window.location.href = "/";
    } else if (profileData.title) {
      fetchLessons();
    }
  }, [profileData]);

  return (
    <>
      <Menu />
      <div className="flex flex-col pt-20" style={{ minHeight: "100vh" }}>
        <div className="max-w-5xl w-full mx-auto px-4 sm:px-6 lg:px-8 mt-16">
          {profileData && profileData.title && (
            <div className="w-full mx-auto relative bg-white flex flex-wrap rounded-lg z-2 fd-shadow-lg overflow-hidden mb-8">
              <div
                className="w-full md:w-1/3"
                style={{ backgroundColor: profileData.colour }}
              >
                <img
                  src={Images.profiles[profileData.image]}
                  alt={profileData.title}
                  className="mx-auto"
                  style={{ height: "90px", minHeight: "100%" }}
                />
              </div>
              <div className="w-full md:w-1/4 flex flex-col flex-grow flex-shrink">
                <h2
                  className="pr-6 pf-medium fd-grey w-full font-bold text-5xl text-gray-900 ml-6 pt-2"
                  style={{ color: profileData.colour }}
                >
                  {profileData.title}
                </h2>
                <p
                  className="break-normal font-normal text-gray-600 text-base md:text-m ml-6 mo-light mb-2"
                  style={{ color: profileData.colour }}
                >
                  {profileData.tag}
                </p>
              </div>
            </div>
          )}

          <AnimatePresence exitBeforeEnter>
            {lessons.map((lesson) => (
              <motion.div
                initial={{ opacity: 0, y: 100, scale: 0.9 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9, transition: { duration: 0.1 } }}
                key={lesson._id}
                className="w-full mx-auto relative bg-white flex flex-wrap rounded-lg z-2 fd-shadow-lg overflow-hidden mb-8"
              >
                <div className="w-full md:w-1/3 rounded-t">
                  <img
                    src={lesson.thumbnail}
                    className="h-full w-full shadow"
                    alt={lesson.name}
                  />
                </div>
                <div className="w-full md:w-1/3 flex flex-col flex-grow flex-shrink">
                  <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden fd-shadow-lg">
                    <div className="inline-block pt-5 px-3 float-right">
                      {lesson.info && lesson.info.language && (
                        <span className="cursor-pointer rounded py-1 px-3 bg-teal-300 text-white mo-medium text-sm ml-2">
                          {lesson.info.language.label}
                        </span>
                      )}
                      {lesson.info && lesson.info.languageLevel && (
                        <span className="cursor-pointer rounded py-1 px-3 bg-teal-300 text-white mo-medium text-sm ml-2">
                          {lesson.info.languageLevel.label}
                        </span>
                      )}
                    </div>
                    <h3 className="pf-medium fd-grey w-full font-bold text-2xl text-navy-700 pt-4 px-6">
                      {lesson.name}
                    </h3>
                    <p className="mb-1 w-5/6 text-navy-500 text-xs md:text-sm px-6">
                      {lesson.description}
                    </p>
                    <p className="mb-2 w-5/6 text-xs md:text-sm px-6 pb-2">
                      {lesson.info &&
                        lesson.info.tags.map((tag) => (
                          <a className="mr-2 text-navy-400 cursor-pointer">
                            {tag.label}
                          </a>
                        ))}
                    </p>
                  </div>
                  <div className="flex-none bg-gray-100 mt-auto bg-white rounded-b rounded-t-none overflow-hidden fd-shadow-lg p-3">
                    <div className="flex items-center justify-between">
                      <div className="flex pl-4">
                        <svg
                          className="h-5 w-5 text-navy-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <p className="text-navy-600 mo-light text-xs md:text-sm flex-1 ml-1">
                          {Math.round(
                            (parseInt(lesson.lesson.endTime) -
                              parseInt(lesson.lesson.startTime)) /
                              60
                          )}{" "}
                          MINUTES
                        </p>
                      </div>
                      <div className="flex pl-4">
                        <div className="relative h-5 w-5 text-navy-600">
                          <CheckBox checked={true} small={true} />
                        </div>
                        <p className="text-navy-600 mo-light text-xs md:text-sm flex-1 ml-1">
                          {lesson.lesson.activities.length} QUESTIONS
                        </p>
                      </div>

                      <div className="flex pl-4" />

                      <button
                        onClick={() => handleLoadLessonClick(lesson._id)}
                        className="shadow-sm h-full inline-block px-8 py-2 bg-teal-600 flex text-white text-sm uppercase rounded mo-medium align-middle font-semibold fd-transition"
                      >
                        <p className="flex-1 mr-3">Play Now</p>
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
}

export default Profile;
