import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import variants from "../../components/animationVariants";
import { Link } from "react-router-dom";
import Images from "../../../assets/images";
function HomeFeatured({ openCreateAccountModal }) {
  const controls = useAnimation(),
    [ref, inView] = useInView(),
    [showGifs, setShowGifs] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      setTimeout(() => setShowGifs(true), 1000);
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      className="py-16 lg:py-28 bg-white mb-8"
    >
      <motion.div
        variants={variants.fromBottom}
        className="max-w-xl mx-auto lg:max-w-6xl px-12"
      >
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8 text-center">
          <div>
            <div className="h-28 w-full">
              {showGifs && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.chat}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-7">
              <dt className="text-lg leading-6 font-medium text-navy-700 font-bold">
                Listen and ask questions while hearing from other teachers in
                our events
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                Join our webinars and online events to learn more about how
                teachers and educators increase student engagement with FilmDoo
                Academy
              </dd>
              <dd className="transition duration-500 ease-in-out hover:text-pink-700 transform text-pink-500 pt-4 cursor-pointer">
                <Link to={{ pathname: "/webinars" }}>
                  Our Events & Webinars <span aria-hidden="true">→</span>
                </Link>
              </dd>
            </div>
          </div>

          <div>
            <div className="h-28 w-full">
              {showGifs && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.shield}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-7">
              <dt className="text-lg leading-6 font-medium text-navy-700 font-bold">
                Private and secure, we take online security seriously
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                We are fully GDPR compliant. Our technology operates to the
                highest of safety standards. We do everything we can to ensure a
                safe and effective service for everyone.
              </dd>
              <dd className="transition duration-500 ease-in-out hover:text-pink-700 transform text-pink-500 pt-4 cursor-pointer">
                <Link to={{ pathname: "/privacy-policy" }}>
                  Our privacy policy <span aria-hidden="true">→</span>
                </Link>
              </dd>
            </div>
          </div>

          <div>
            <div className="h-28 w-full">
              {showGifs && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.globe}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-7">
              <dt className="text-lg leading-6 font-medium text-navy-700 font-bold">
                Utilised by schools & trusts worldwide
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                Get started for free today. We will fully support you, your
                school and your students throughout this journey. Enjoy FilmDoo
                Academy!
              </dd>
              <dd
                onClick={openCreateAccountModal}
                className="transition duration-500 ease-in-out hover:text-pink-700 transform text-pink-500 pt-4 cursor-pointer"
              >
                Get In Touch for A Free Trial <span aria-hidden="true">→</span>
              </dd>
            </div>
          </div>
        </dl>
      </motion.div>
    </motion.div>
  );
}

export default HomeFeatured;
