import React, { useContext } from "react";
import { Context } from "../context";
import {motion, AnimatePresence} from "framer-motion";

function Unmute() {
  const { lesson } = useContext(Context),
    handleUnmuteClick = () => {
      lesson.setPlaying(false);
      lesson.setMuted(false);
      lesson.setPlaying(true);
    }

  return (
    <AnimatePresence>
      { lesson.muted && lesson.playing &&
        <motion.div
            initial={{ opacity:0 }}
            animate={{ opacity:1, transition: { duration:0.3 } }}
            exit={{ opacity:0, transition: { duration:0.6 } }}
            onClick={handleUnmuteClick}
            style={{ zIndex:1 }}
            className="cursor-pointer bg-navy-blur absolute top-0 bottom-0 left-0 right-0 text-center">
          <div className="flex justify-center items-center text-center w-full h-full z-10 absolute top-0 left-0 bottom-0 right-0">
            <div className="overflow-hidden align-middle inline-block p-6 w-1/2">
              <button
                onClick={handleUnmuteClick}
                className="text-base cursor-pointer font-bold fd-shadow-xl text-center inline-block focus:outline-none w-auto lg:ml-6 bg-coral-500 rounded-lg px-5 py-3 leading-5 mo-bold text-white"
              >
                Tap to Unmute
                  <svg className="w-7 h-7 inline-block ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clipRule="evenodd" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                  </svg>
              </button>
            </div>
          </div>
        </motion.div>
      }
    </AnimatePresence>
  );
}

export default Unmute;
