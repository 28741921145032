import React from "react";
import FullDetails from "./fullDetails";

function Membership({ show, setShow }) {

    const props = {
        show,
        setShow,
        title: "Take Advantage of our Free Membership Program",
        handleSubmit: (postData) => {
            fetch(`/api/v1/contacts/membership`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(postData)
            })
                .then(res => res.json())
                .then( () => {

                    // manually load react pixel to use on SSR
                    import('react-facebook-pixel')
                        .then(module => module.default)
                        .then(ReactPixel => {
                            ReactPixel.track('SubmitApplication');
                        })

                    setShow(false);
                });
        }
    }

    return <FullDetails {...props} />;
}

export default Membership;
