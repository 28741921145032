import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

function TeacherImprove() {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <main className="w-full overflow-hidden">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="max-w-6xl mx-auto lg:py-32 py-6 md:py-24 px-10"
      >
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div
            variants={variants.fromLeft}
            className="sm:text-center md:max-w-lg md:mx-auto lg:col-span-6 lg:text-left"
          >
            <h2>
              <span className="block text-2xl tracking-tight sm:text-4xl pr-6">
                <span className="block text-pink-600 pt-8">
                  <b>Improve Student Learning</b> With Interactive Films &
                  Videos
                </span>
              </span>
            </h2>
            <p className="mt-3 text-lg text-navy-600 sm:mt-5 lg:text-lg">
              Deliver interactive, immersive and super engaging lessons.
            </p>
            <div className="mt-4 md:mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 pt-4">
              <div className="w-auto inline-block transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-lg transform focus:outline-none bg-pink-600 py-3 rounded-md fd-shadow items-center justify-center">
                <Link
                  to={{ pathname: "/testimonials" }}
                  className="mx-8 text-base font-medium text-white block md:inline-block text-center"
                >
                  Hear What Other Teachers Are Saying
                </Link>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={variants.fromRight}
            className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center"
          >
            <iframe
              className="rounded-xl fd-shadow w-full h-72"
              src="https://www.youtube.com/embed/rAV-X_qRnG0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </motion.div>
        </div>
      </motion.div>
    </main>
  );
}

export default TeacherImprove;
