import React, { useState, useContext, useEffect, useRef } from "react";
import Options from "../components/options";
import {
  AnimatePresence,
  motion,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import Question from "../components/question";
import Cta from "../../../components/common/cta";
import { Context } from "../context";
import Div100vh from "react-div-100vh";
import window from "global";

function MultipleChoice() {
  const ctaButtonRef = useRef(null),
    { activity, classRoom, student } = useContext(Context),
    variants = {
      wrapper: {
        visible: {
          opacity: 1,
          transition: {
            duration: 0.3,
            staggerChildren: 0.1,
            delayChildren: 0.7,
          },
        },
        hidden: {
          opacity: 0,
          transition: {
            duration: 0.3,
            staggerChildren: 0.1,
            staggerDirection: -1,
          },
        },
      },
    },
    { duration } = activity,
    { correct, question, answers } = activity.data,
    [showButton, setShowButton] = useState(false),
    [countDown, setCountDown] = useState(11),
    [progressStop, setProgressStop] = useState(false),
    [disabledOptions, setDisabledOptions] = useState(false),
    [wrongCount, setWrongCount] = useState(0),
    [correctCount, setCorrectCount] = useState(0),
    [selectedCount, setSelectedCount] = useState(0),
    [options, setOptions] = useState(
      answers.map((a, key) => ({
        text: a,
        correct: correct.includes(key),
        selected: false,
        feedback: "",
        position: key,
        key,
      }))
    ),
    answerCount = typeof correct === "object" ? correct.length : 1,
    instructionText =
      answerCount === 1 ? "Select One" : "Select All That Apply",
    giveFeedback = () => {
      // update styles of the options
      setOptions((prev) =>
        prev.map((a) => {
          if (!a.feedback)
            a.feedback = !a.selected ? "" : a.correct ? "correct" : "wrong";
          return a;
        })
      );
    },
    revealCorrect = () =>
      setOptions((prev) =>
        prev.map((a) => {
          if (a.correct && !a.feedback) a.selected = true;
          return a;
        })
      ),
    saveScore = (score) => {
      setShowButton(false);
      setDisabledOptions(true);
      setProgressStop(true);

      setTimeout(() => {
        classRoom.setOverlay("score");
        student.setWaiting("leaderboard", activity.time);
      }, 1000);

      student.set("responses", [
        ...student.responses,
        {
          key: activity.key,
          question,
          answer: answers[correct],
          correctFirstTry: !wrongCount && score > 0,
          score,
        },
      ]);
    };

  useEffect(() => {
    setWrongCount(options.filter((a) => a.feedback === "wrong").length);
    setSelectedCount(options.filter((a) => a.selected && !a.feedback).length);
    setCorrectCount(options.filter((a) => a.feedback === "correct").length);
  }, [options]);

  // update countdown and timeout when complete or when score is set
  useEffect(() => {
    // scroll to top so they see the question first
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // if they already answered the question and they're refreshing the page force them to move to the leaderboard
    if (
      student.responses.filter((response) => response.key === activity.key)[0]
    ) {
      classRoom.setOverlay("score");
      student.setWaiting("leaderboard", activity.time);
    }

    student.setIsWaiting(false);
    const interval = setInterval(() => {
      setCountDown((prev) => {
        const newCount = prev - 1;

        if (newCount === 0 || progressStop) {
          clearInterval(interval);

          // if they ran out for time set the score to zero
          if (newCount === 0 && !progressStop && classRoom.overlay !== "score")
            saveScore(0);
        }

        return progressStop ? prev : newCount;
      });
    }, 100 * duration);
  }, []);

  useEffect(() => {
    if (showButton) ctaButtonRef.current.scrollIntoView({ behavior: "smooth" });
  }, [showButton]);

  // show button when correct number of answers selected
  // if they have all the correct answers complete
  useEffect(() => {
    if (progressStop) return;

    const correctNumberOfOptionsSelected =
      answerCount === 1
        ? selectedCount === 1
        : selectedCount > 0 && selectedCount + correctCount > 1;

    // show submit button
    setShowButton(correctNumberOfOptionsSelected);

    // if failed
    if (wrongCount + answerCount === options.length && wrongCount !== 0) {
      saveScore(0);
      revealCorrect();
    }

    // if succeeded
    else if (correctCount === answerCount) {
      const scored = wrongCount ? 1 : countDown + 1,
        confetiCount = (scored * 200) / (wrongCount ? wrongCount * 4 : 1);
      saveScore(scored);
      classRoom.setRain(confetiCount);
    }
  }, [wrongCount, correctCount, selectedCount]);

  return (
    <Div100vh>
      <motion.div
        className="w-full h-full flex flex-col justify-between overflow-y-auto overflow-x-hidden relative"
        variants={variants.wrapper}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <div>
          <motion.div
            variants={variants.wrapper}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <Question
              {...{
                question,
                instructionText,
                countDown,
                progressStop,
                duration,
                wrongCount,
              }}
            />
          </motion.div>

          <motion.div
            variants={variants.wrapper}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <Options
              {...{
                options,
                setOptions,
                disabledOptions,
                answerCount,
              }}
            />
          </motion.div>
        </div>

        <AnimatePresence>
          {showButton && (
            <div className="w-full" ref={ctaButtonRef}>
              <Cta onClick={giveFeedback}>
                <span className="font-bold">Submit Answer</span>
              </Cta>
            </div>
          )}
        </AnimatePresence>
      </motion.div>
    </Div100vh>
  );
}

export default MultipleChoice;
