import React from "react";
import { motion } from "framer-motion";
import "../../assets/css/pluss-loader.css";

function Loading() {
  return (
    <motion.div
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="text-center absolute z-50 bg-navy-700 flex justify-center items-center w-full h-full absolute top-0 left-0 bottom-0 right-0"
    >
      <div className="overflow-hidden align-middle inline-block center-position">
        <span className="plus-loader" />
        <p className="mt-2 text-center w-full text-xl mo-light text-white sm:text-2xl lg:text-3xl">
          Loading...
        </p>
      </div>
    </motion.div>
  );
}

export default Loading;
