import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import { useInView } from "react-intersection-observer";
import Images from "../../../assets/images";
import BgParticles from "../../components/bgParticles";
import { Link } from "react-router-dom";

function Hero({ openCreateAccountModal }) {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <main className="w-full bg-gray-100 sloppy-bottom overflow-hidden pt-4 lg:pt-10">
      <BgParticles />

      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="relative max-w-6xl mx-auto lg:py-32 pt-24 pb-14 px-8"
      >
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div
            variants={variants.fromLeft}
            className="md:mx-auto col-span-7"
          >
            <h1 className="lg:mt-2 text-5xl lg:text-6xl font-semibold text-navy-700 leading-tight mb-4">
              FilmDoo Academy{" "}
              <span className="text-teal-600">Sample Lessons</span>
            </h1>
          </motion.div>

          <motion.div
            variants={variants.fromLeft}
            className="md:max-w-2xl lg:col-span-6 text-left lg:pr-4 pt-2"
          >
            <p className="text-navy-600 text-base lg:text-lg mx-auto w-full font-bold">
              Use this free resource to try out FilmDoo Academy with your
              students today!
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              All these lessons have been created by our community of teachers
              and are a great example of how FilmDoo interactive film- and
              video-based lessons can add to your classroom or remote teaching.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              Lesson time will never be the same again, when your students are
              having so much fun while learning at the same time!
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              We continue to add new subjects and new lessons and build out our
              ready-to-use free sample lesson library.
            </p>
          </motion.div>
          <motion.div
            variants={variants.fromRight}
            className="relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 flex flex-col lg:col-span-6"
          >
            <div className="relative mx-auto w-full lg:max-w-lg mb-6 lg:mb-12 mt-8 lg:mt-0">
              {Images.students.h}
            </div>
          </motion.div>
        </div>

        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div
            variants={variants.fromLeft2}
            className="relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 flex flex-col lg:col-span-6"
          >
            <div className="relative mx-auto w-full lg:max-w-lg mb-6 lg:mb-12 mt-8 lg:mt-0">
              {Images.students.f}
            </div>
          </motion.div>

          <motion.div
            variants={variants.fromRight2}
            className="md:max-w-2xl lg:col-span-6 text-left lg:pl-4 pt-2"
          >
            <p className="text-navy-600 text-base lg:text-lg mx-auto w-full font-bold">
              Check out FilmDoo Academy’s Free Membership Programme
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              Why not join our free Membership Programme to be the among the
              first to check out our new sample lessons?
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              Or even better, get involed and help contribute to our library of
              free sample lessons available to help teachers and students around
              the world!
            </p>

            <div className="py-3 text-sm">
              <Link
                to={{ pathname: "/membership" }}
                className="text-teal-600 font-semibold"
              >
                Find Out More <span aria-hidden="true">→</span>
              </Link>
            </div>

            <div className="sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 pt-4">
              <button
                onClick={openCreateAccountModal}
                className="transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-lg transform focus:outline-none rounded-md fd-shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white bg-pink-600 md:py-4 md:text-lg md:px-10"
              >
                Join Now
              </button>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </main>
  );
}

export default Hero;
