import React from "react";
import AnswerDetails from "./answerDetails";

function MultipleChoiceDetails({ forceRefresh, updateInteraction, data, id }) {
  const questionLength = 80,
      setData = ({ question, answers, correct }) => {
          updateInteraction({
              key:id,
              data: {
                  question: question === undefined ? data.question : question,
                  answers: answers === undefined ? data.answers : answers,
                  correct: correct === undefined ? data.correct : correct
              }
          })
      },
    questionTip =
      data.question.length === 0
        ? `Max ${questionLength} characters`
        : questionLength - data.question.length + " characters left",
    handleQuestionChange = (event) => {
      let newQuestion = event.target.value;
      if (newQuestion.length <= questionLength)
          setData({
              question: newQuestion
          });
    },
    handleAddAnswer = () =>
        setData({answers: [...data.answers, ""]}),
    handleClickAnswerDelete = (answerIndex) => {
      // ensure that any correct answer indexes are also updated
      setData({
          answers: data.answers.filter( (answer, index) => index !== answerIndex ),
          correct: data.correct.map(index =>
              index >= answerIndex ? index - 1 : index
          )
      })
      forceRefresh();
    };

  return (
    <>
      <div className="w-full mb-5">
        <label
          htmlFor="question"
          className="block mb-1 font-medium text-navy-700"
        >
          Question
        </label>
        <div className="mt-1">
          <input
            onChange={handleQuestionChange}
            type="text"
            name="question"
            placeholder="type the question here..."
            className="mt-1 block w-full inset-shadow rounded-md py-2 px-3 focus:outline-none focus:ring-teal-500 focus:border-indigo-500 sm:text-sm"
            value={data.question}
          />
        </div>
        <div className="mt-1 text-sm text-navy-600 text-right italic">
          {questionTip}
        </div>
      </div>

      <div className="w-full mb-5 ">
        <label className="block mb-1 font-medium text-navy-700">Answers <span className="text-xs block">(Select all correct answers)</span></label>

        <div className="">
          {data.answers && data.answers.map((answer, index) => (
            <AnswerDetails
              key={index}
              correct={data.correct.includes(index)}
              {...{
                answer,
                index,
                data,
                setData,
                forceRefresh,
                handleClickAnswerDelete,
              }}
            />
          ))}
        </div>

        { (!data.answers || data.answers.length !== 4) && (
          <a
            onClick={handleAddAnswer}
            className="block mb-1 font-medium text-coral-700 inline-flex justify-center cursor-pointer mt-2 w-full"
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <span>Add another answer</span>
          </a>
        )}
      </div>
    </>
  );
}

export default MultipleChoiceDetails;
