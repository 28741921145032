import React, { useEffect } from "react";
import Images from "../../../assets/images";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import { Link } from "react-router-dom";
import BgParticles from "../../components/bgParticles";

function HomeDetails() {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <main className="w-full bg-gray-100 sloppy-top sloppy-bottom overflow-hidden relative">
      <BgParticles color="pink" />
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="max-w-6xl mx-auto lg:py-32 py-16 md:py-24 px-12 relative"
      >
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div
            variants={variants.fromLeft}
            className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left"
          >
            <h2>
              <span className="block text-2xl tracking-tight sm:text-4xl pr-12">
                <span className="block text-pink-600 pt-8">
                  A <b>Refreshingly Fun</b> Tool That’s Proven To{" "}
                  <b>Engage Students</b> & Help <b>Accelerate Progress</b>
                </span>
              </span>
            </h2>
            <p className="mt-3 text-base text-navy-600 sm:mt-5 lg:text-lg">
              9 students in 10 said they prefer FilmDoo Academy to other
              traditional styles of learning because the format is flexible and
              entertaining!
            </p>
            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 pt-4">
              <Link
                to={{ pathname: "/sample_lessons" }}
                className="transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-lg transform focus:outline-none rounded-md fd-shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white bg-pink-600 md:py-4 md:text-lg md:px-10"
              >
                See Sample Lessons
              </Link>
            </div>
          </motion.div>
          <motion.div
            variants={variants.fromRight}
            className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center"
          >
            <div className="relative mx-auto w-full lg:max-w-lg">
              {Images.studentGroup}
            </div>
          </motion.div>
        </div>

        <div className="lg:grid lg:grid-cols-12 lg:gap-8 lg:pt-36">
          <motion.div
            variants={variants.fromLeft2}
            className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center hidden lg:block"
          >
            <div className="relative mx-auto w-full lg:max-w-lg ">
              {Images.withTeacher}
            </div>
          </motion.div>
          <motion.div
            variants={variants.fromRight2}
            className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left"
          >
            <h2>
              <span className="block text-2xl tracking-tight sm:text-4xl pr-6">
                <span className="block text-pink-600 pt-8">
                  Hear The <b>Great Things Teachers</b> Using FilmDoo Academy{" "}
                  <b>Say</b>
                </span>
              </span>
            </h2>
            <p className="mt-3 text-base text-navy-600 sm:mt-5 lg:text-lg">
              Hear from real teachers using FilmDoo Academy in a variety of high
              impact ways. Everything from in-class, to homework and revision,
              and educating students off-site.
            </p>
            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0 pt-4">
              <Link
                to={{ pathname: "/testimonials" }}
                className="transition duration-500 ease-in-out hover:bg-pink-700 hover:fd-shadow-lg transform focus:outline-none rounded-md fd-shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white bg-pink-600 md:py-4 md:text-lg md:px-10"
              >
                Read Testimonials
              </Link>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </main>
  );
}

export default HomeDetails;
