import React from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import Images from "../../../assets/images";

function GuideFindingFilms() {
  return (
    <div className="bg-gray-100">
      <Menu />
      <div className="flex-grow container px-4 md:px-0 max-w-6xl mx-auto pt-32">
        <div className="sm:w-3/4 mx-auto relative bg-white rounded-lg flex flex-wrap rounded z-2 fd-shadow-lg overflow-hidden pb-4 mb-8">
          <div className="px-12 py-6 text-sm  tracking-wide">
            <h1 className="text-3xl lg:text-4xl font-semibold text-navy-700 leading-tight">
              A Teacher's Guide to{" "}
              <span className="text-teal-600">
                Finding Film & Video Resources
              </span>{" "}
              for Interactive Lessons
            </h1>
            {Images.blog.one}
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              Around the world, teachers have had to reinvent themselves more in
              the past year than they did in the previous two decades. The
              global pandemic has played a core role in this adjustment, though,
              thankfully, new technologies have been put at the teacher’s
              disposal to help them adjust to these fast-changing circumstances.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              With great online options, however, comes an overload of choices
              that a teacher now has to make, and how to impart the traditional
              curriculum using these new tools might feel more overwhelming than
              ever. Luckily, FilmDoo Academy is here to help on your search! Our
              take on the current education landscape can be summed up in three
              simple tenets:
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              – We believe a teacher’s time is better spent discussing their
              topics with their students, and planning a class without having to
              worry too much about the methods of teaching.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              – We believe in the power of video, especially nowadays and with
              younger students. Learning effectively while having fun and
              exploring thought-provoking content is our main goal!
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              – We believe you as a teacher are the best resource the student
              has to learn from, and it is our job to help you with the “how”.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              With this in mind, here are a few tips on how to find the
              appropriate resources, create your own educational content and
              modernise your classes, whether you are teaching your students
              virtually or in a traditional classroom.
            </p>
            {Images.blog.two}
            <h2 className="text-xl lg:text-2xl font-bold text-navy-700 leading-tight">
              5 Key Things to Consider for Educational Content
            </h2>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              <b>1. The Goals of Your Lesson</b>
            </p>
            <p className="text-navy-600 mt-0 text-base mx-auto w-full pr-6">
              When designing a lesson for the digital age, being clear in the
              goal for your content is the key starting point of your class
              prep.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              <b>2. Quantity and Depth</b>
            </p>
            <p className="text-navy-600 mt-0 text-base mx-auto w-full pr-6">
              Most of our fellow teachers have discovered that covering subjects
              quickly or overloading students with information is not effective
              in the long run, so keeping this is mind when planning your
              strategy is essential.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              <b>3. Reliable and Good Quality Resources</b>
            </p>
            <p className="text-navy-600 mt-0 text-base mx-auto w-full pr-6">
              When deciding on content, we should be considering the following:
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              (a) To what extent does the teacher need to choose the content for
              a lesson and to what extent should students be free to choose the
              content and resources that suit them?
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              (b) To what extent does the instructor need to deliver content
              themselves, such as through a lecture or PowerPoint slides, when
              user-friendly resources are so freely available elsewhere? What is
              the added value you are providing by delivering the content
              yourself?
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              (c) To what extent do we need to provide criteria or guidelines to
              students for choosing and using openly accessible resources, and
              what is the best way to do that?
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              <b>4. Course Structure</b>
            </p>
            <p className="text-navy-600 mt-0 text-base mx-auto w-full pr-6">
              Naturally, the educational content you’ll incorporate into your
              class ought to fit your curriculum and overall course planning.
              Within this structure, it’s worth considering how you could
              incorporate your resources into other parts of your course and
              different subject areas, making the most out of what you have.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              <b>5. Learner Activities</b>
            </p>
            <p className="text-navy-600 mt-0 text-base mx-auto w-full pr-6">
              This depends on the subject you are teaching. For topics where
              memorisation is important, for instance, automated tests such as
              computer-marked assignments might be the way to go. If that’s what
              you’re looking for, then multiple choice questions are great way
              to go about it, and our edtech tool might just be the right fit
              for you (in which case, sign up today for your free trial!).
            </p>
            {Images.blog.three}
            <h2 className="text-xl lg:text-2xl font-bold text-navy-700 leading-tight">
              How to Find the Right Educational Resources: 3 Important Tips
            </h2>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              In our experience working with teachers, this part might be easier
              said than done. Here are 3 important tips provided by fellow
              educators:
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              – To find the appropriate language learning resources, try
              searching in your target language on the platform of your
              choosing. If you are teaching French or Spanish, for example, put
              in your key words in said language on not just YouTube and Vimeo,
              but also Google, Spotify and any other media libraries at your
              disposal. The sky is the limit when it comes to places to find
              good content.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              – Aim for content that goes beyond your lesson points: Short films
              are a great gateway to start a discussion on culture, different
              opinions and even important moral lessons for your students.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              – Don’t disregard non-dialogue videos: They can be very effective
              for younger students or for learning basic vocabulary if you are
              teaching a language. You can find amazing animated short films
              without dialogue on Youtube and Vimeo, and again great quality
              alters the learning result. If you are a FilmDoo Academy member,
              we can get you a list of our top shorts, already curated and
              tagged for easier revision.{" "}
              <a href="/contact">Get in touch for more info!</a>
            </p>
            {Images.blog.four}
            <h2 className="text-xl lg:text-2xl font-bold text-navy-700 leading-tight">
              DIY: 5 Tips for Creating Your Own Educational Content
            </h2>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              – Create Content that is Relevant and up to date.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              – If you are recording yourself, be mindful of camera angles,
              sound quality, your background and other surrounding factors that
              could enhance or distract from the content of your lesson.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              – Tailor the content for your target student – think age,
              background, interests. We know this can be challenging for a
              larger group but, more often than not, you can find common find
              common ground within a single class.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              – Be specific and use real-life examples.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              – Interactivity is key: Make your lesson as much of a game as
              possible. FilmDoo Academy can help you with that. Feel free to
              message us for more info:{" "}
              <a href="mailto:info@filmdoo.academy">info@filmdoo.academy</a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GuideFindingFilms;
