import React, { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Context } from "../context";
import Cta from "../../../components/common/cta";
import Emojis from "../../../assets/images/emojis";
import Div100vh from "react-div-100vh";
import window from "global/window";

function Pause() {
  const { lesson, classRoom, student } = useContext(Context),
    variants = {
      wrapper: {
        visible: {
          opacity: 1,
          transition: {
            duration: 0.3,
            staggerChildren: 0.1,
            delayChildren: 0.7,
          },
        },
        hidden: {
          opacity: 0,
          transition: {
            duration: 0.8,
            staggerChildren: 0.05,
            staggerDirection: -1,
          },
        },
      },
      header: {
        visible: {
          y: 0,
          transition: {
            duration: 0.4,
          },
        },
        hidden: {
          y: -400,
          transition: {
            duration: 0.8,
          },
        },
      },
      text: {
        visible: { y: 0, opacity: 1 },
        hidden: { y: -50, opacity: 0 },
      },
    };

  const [userEmail, setUserEmail] = useState("");
  useEffect(() => {
    if (window.localStorage) {
      const user = JSON.parse(window.localStorage.getItem("fda_user"));
      if (user && user.email) {
        setUserEmail(user.email);
      }
    }
  });

  const handleClickPlay = () => {
    // log the first time the click play
    if (student.isPrefect && lesson.starttime === lesson.currentTime) {
      fetch("/api/v1/log/lessons", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          title: lesson.videoTitle,
          lessonId: window.location.href.split("/").pop().split("?")[0],
          studentCount: classRoom.allStudents.length,
        }),
        method: "POST",
      });
    }

    lesson.set("lastSavedPlayingState", true);
  };

  return (
    <Div100vh>
      <motion.div
        className="flex flex-wrap content-center relative w-full h-full"
        variants={variants.wrapper}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <div className="mx-auto max-w-2xl text-center">
          <div className="overflow-hidden">
            <motion.p
              variants={variants.text}
              className="mx-4 md:mx-6 text-md md:text-xl lg:text-2xl text-coral-300 pf-medium-italic"
            >
              {lesson.videoTitle}
            </motion.p>
          </div>

          <div className="overflow-hidden">
            <motion.p
              variants={variants.text}
              className="mx-12 mt-6 text-sm mo-light text-coral-200 leading-snug lg:text-lg"
            >
              Friends can join by going to the website:
            </motion.p>
          </div>

          <div className="overflow-hidden">
            <motion.p
              variants={variants.text}
              className="mx-12 py-3 text-2xl mo-light -mt-2 text-white sm:text-4xl leading-snug lg:text-5xl"
            >
              WWW.FDO.OOO
            </motion.p>
          </div>

          <div className="overflow-hidden">
            <motion.p
              variants={variants.text}
              className="mx-12 mt-2 text-xs sm:text-sm mo-light text-coral-200 leading-tight lg:leading-snug lg:text-lg"
            >
              and using room name:
            </motion.p>
          </div>

          <div className="overflow-hidden">
            <motion.p
              variants={variants.text}
              className="mx-12 text-2xl mo-light text-white lg:-mt-2 leading-tight sm:text-4xl lg:leading-snug lg:text-5xl"
            >
              {classRoom.name}
            </motion.p>
          </div>

          <motion.div variants={variants.text} className="mx-4 lg:mx-8">
            {student.isPrefect && (
              <>
                <p className="block relative text-xs lg:text-md text-coral-200 text-sm leading-5 mo-light pt-2">
                  {classRoom.oneLocation
                    ? "Video will play on this screen"
                    : "Video will play on everyones screen"}
                </p>
                <button
                  type="button"
                  aria-pressed="false"
                  onClick={() =>
                    classRoom.set("oneLocation", !classRoom.oneLocation)
                  }
                  style={{ bottom: "-5px" }}
                  className="relative inline-block bg-coral-50 relative inline-flex flex-shrink-0 h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                >
                  <span
                    aria-hidden="true"
                    className={` ${
                      classRoom.oneLocation ? "translate-x-0" : "translate-x-6"
                    } inline-block h-5 w-5 rounded-full bg-coral-600 fd-shadow transform ring-0 transition ease-in-out duration-200`}
                  />
                </button>
                <p className="pl-2 inline-block text-xs lg:text-md text-coral-200 text-xs leading-5 mo-light">
                  {classRoom.oneLocation
                    ? "(for when you're in the same location)"
                    : "(for when you're in different locations)"}
                </p>
              </>
            )}
          </motion.div>

          {student.isPrefect && (
            <div
              className="w-full"
              style={{
                transform: classRoom.isDesktop ? null : "scale(0.75, 0.75)",
              }}
            >
              <Cta onClick={handleClickPlay}>Play Video</Cta>
            </div>
          )}
        </div>

        {classRoom.allStudents.map((student) => (
          <AnimatePresence>
            {!lesson.playing && student.emojiPosition && (
              <motion.div
                initial={{ scale: 0 }}
                animate={{
                  scale: classRoom.isDesktop ? 1 : 0.8,
                  transition: { delay: 0.2, duration: 0.5, ease: "easeOut" },
                }}
                exit={{ scale: 0, transition: { duration: 0.1 } }}
                className="waiting-screen-emoji-box text-center"
                style={student.emojiPosition}
              >
                <div className="emoji-circle">
                  {Emojis[student.emojiCharacter][student.emojiEmotion]}
                </div>
                <p
                  className={`${
                    classRoom.isDesktop
                      ? "text-sm xl:text-md"
                      : "text-lg xl:text-xl -mt-1"
                  } w-full text-center text-white mo-medium leading-tight`}
                >
                  <span>{student.name}</span>
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        ))}
      </motion.div>
    </Div100vh>
  );
}

export default Pause;
