import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import variants from "../../components/animationVariants";
import Images from "../../../assets/images";

function LanguageFeatured({ openCreateAccountModal }) {
  const controls = useAnimation(),
    [ref, inView] = useInView(),
    [showGifs, setShowGifs] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      setTimeout(() => setShowGifs(true), 1300);
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      className="py-14 lg:py-28 bg-white text-center"
    >
      <motion.div
        variants={variants.fromBottom}
        className="max-w-xl mx-auto px-12 sm:px-6 lg:max-w-6xl"
      >
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 gap-8 lg:gap-20">
          <div>
            <div className="h-28 w-full">
              {showGifs && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.globe}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-5">
              <dt className="text-lg font-bold leading-6 font-medium text-navy-700">
                Give an introduction to a culture, not just a language
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                Films open up the colourful world of another culture: the sights
                and traditions of a different place. Inspire language learning
                by this experience!
              </dd>
            </div>
          </div>

          <div>
            <div className="h-28 w-full">
              {showGifs && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.heart}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-5">
              <dt className="text-lg font-bold leading-6 font-medium text-navy-700">
                Make learning meaningful with emotional engagement
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                The emotional scenes of films allow students to develop empathy
                and to participate in meaningful class discussions.
              </dd>
            </div>
          </div>

          <div>
            <div className="h-28 w-full">
              {showGifs && (
                <motion.div
                  className="text-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1, transition: { duration: 0.4 } }}
                >
                  <span className="h-28 w-28 filter-teal inline-block rounded-full">
                    <img
                      className="h-28 w-28 icons-world"
                      src={Images.icons.chat}
                    />
                  </span>
                </motion.div>
              )}
            </div>
            <div className="mt-5">
              <dt className="text-lg font-bold leading-6 font-medium text-navy-700">
                Discover different native accents and dialects
              </dt>
              <dd className="mt-2 text-base text-navy-300">
                Global cinema offers students the chance to listen and learn
                from a variety of accents in their authentic context.
              </dd>
            </div>
          </div>
        </dl>
      </motion.div>
    </motion.div>
  );
}

export default LanguageFeatured;
