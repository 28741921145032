import React, { useEffect, useState } from "react";
import Index from "../../components/menu";
import Prices from "../../components/prices";
import Menu from "../../components/menu";
import BgParticles from "../../components/bgParticles";
import Footer from "../../components/footer";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import { useInView } from "react-intersection-observer";

function Pricing() {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [showSubscription, setShowSubscription] = useState(false);
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false);
  const [masterShowLogin, setMasterShowLogin] = useState(false);
  const openCreateAccountModal = () => setMasterShowCreateAccount(true);
  const openLogin = () => setMasterShowLogin(true);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <>
      <Menu
        {...{
          masterShowCreateAccount,
          setMasterShowCreateAccount,
          masterShowLogin,
          setMasterShowLogin,
          showSubscription,
          setShowSubscription,
        }}
      />
      <main className="w-full bg-gray-100 overflow-hidden pt-4 lg:pt-10 sloppy-bottom pb-20">
        <BgParticles />
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          className="relative max-w-6xl mx-auto lg:py-32 pt-20 pb-8 px-8"
        >
          <motion.div
            variants={variants.fromLeft}
            className="sm:flex sm:flex-col"
          >
            <h1 className="text-5xl font-extrabold text-navy-700">
              <span className="text-teal-600">FilmDoo Academy</span> Pricing
              Plan
            </h1>
            <p className="mt-5 text-xl text-navy-500">
              Improve student engagement and transform learning outcomes.
            </p>
            <p className="text-xl text-navy-500">
              Get started today to turn any film and video into an interactive
              lesson in just a few minutes!
            </p>
            <p className="mt-4 text-xl font-bold text-navy-500">
              Choose from a variety of plan that’s perfect for you:
            </p>
          </motion.div>
        </motion.div>
      </main>
      <Index />
      <Prices
        {...{
          openCreateAccountModal,
          openLogin,
          showSubscription,
          setShowSubscription,
        }}
      />
      <Footer />
    </>
  );
}

export default Pricing;
