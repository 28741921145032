import React from 'react';
import { motion } from "framer-motion";

function ModalBg({ children, setShow, show }) {

  const
    handleCloseClick = () => setShow(false),
    variants = {
      shade: {
        hidden: {opacity: 0},
        visible: {opacity: 1}
      },
      modal: {
        hidden: { opacity:0, y:50, scale:0.9 },
        visible: { opacity:1, y:0, scale:1, transition: { delay: 0.15 }  }
      },
      bg: {
        hidden: {
            display: "none",
            transition: { delay: 0.5, duration:0 }
        },
        visible: {
            display: "block",
            transition: { duration:0 }
        }
      }
    };

  return (
    <motion.div
      initial={false}
      animate={ show ? 'visible' : 'hidden' }
      variants={variants.bg}
      className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <motion.div
          variants={variants.shade}
          transition={{ duration:0.2 }}
          onClick={ handleCloseClick }
          className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </motion.div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />&#8203;

        <motion.div
          variants={variants.modal}
          className="inline-block relative align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden fd-shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-auto sm:p-6 z-10"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            {children}
        </motion.div>

      </div>
    </motion.div>

  )
}

export default ModalBg;
