export default {
    fromRight:{
        visible: { opacity: 1, x: 0, transition: { type: "easeOut", delay:0.3, duration:0.7 } },
        hidden: { opacity: 0, x: 200 }
    },
    fromLeft:{
        visible: { opacity: 1, x: 0, transition: { type: "easeOut", delay:0.3, duration:0.7 } },
        hidden: { opacity: 0, x: -200 }
    },
    fromBottom:{
        visible: { opacity: 1, y: 0, transition: { type: "easeOut", delay:0.3, duration:0.7 } },
        hidden: { opacity: 0, y: 200 }
    },

    fromRight2:{
        visible: { opacity: 1, x: 0, transition: { type: "easeOut", delay:0.5, duration:0.7 } },
        hidden: { opacity: 0, x: 200 }
    },
    fromLeft2:{
        visible: { opacity: 1, x: 0, transition: { type: "easeOut", delay:0.5, duration:0.7 } },
        hidden: { opacity: 0, x: -200 }
    },
    fromBottom2:{
        visible: { opacity: 1, y: 0, transition: { type: "easeOut", delay:0.5, duration:0.7 } },
        hidden: { opacity: 0, y: 200 }
    }

}