import React, { useState } from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import Images from "../../../assets/images";

function FirstPlayThrough() {
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false);

  return (
    <div className="bg-gray-100">
      <Menu
        {...{
          masterShowCreateAccount,
          setMasterShowCreateAccount,
        }}
      />
      <div className="flex-grow container px-4 md:px-0 max-w-6xl mx-auto pt-32">
        <div className="sm:w-3/4 mx-auto relative bg-white rounded-lg flex flex-wrap rounded z-2 fd-shadow-lg overflow-hidden pb-4 mb-8">
          <div className="px-12 py-6 text-sm  tracking-wide">
            <h1 className="text-3xl lg:text-4xl font-semibold text-navy-700 leading-tight">
              How It Works: A Brief Guide to{" "}
              <span className="text-teal-600">
                Playing Your First Interactive,{" "}
              </span>
              FilmDoo Academy Lesson
            </h1>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              Are you new to FilmDoo Academy and wondering what it’s all about?
              The best place to start might be to play through your very first
              lesson and, what’s more, we’d be happy to guide you through it!
            </p>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              Your first lesson can be played on your own or as part of a group
              using any of the free, interactive lessons available on our
              platform. For this example, we’re going to play through a 3-minute
              English lesson titled “Directions” that you can find here:{" "}
              <a
                href="https://www.filmdoo.academy/party/6wn"
                className="underline"
                target="_blank"
              >
                https://www.filmdoo.academy/party/6wn
              </a>
            </p>

            {Images.blog.first1}

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              However, feel free to instead browse our{" "}
              <a
                className="underline"
                href="https://www.filmdoo.academy/sample/arabic"
                target="_blank"
              >
                Sample Lessons
              </a>{" "}
              page to find another lesson - perhaps one that matches your
              subject of choice. We have a range of example lessons in a variety
              of subjects that are free to check out.
            </p>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              Once you’ve found an example lesson that you’d like to give a try,
              simply click the button labelled “PLAY NOW” to get started.
            </p>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              You will then be directed to a page that looks like this:
            </p>

            {Images.blog.first2}

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              If you are playing as part of a group, other people can join you
              by going to the URL{" "}
              <a
                href="https://www.filmdoo.academy/party"
                className="underline"
                target="_blank"
              >
                WWW.FDO.OOO
              </a>{" "}
              and using the room name provided (in the above case, the room name
              is “RAVEN”).
            </p>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              Once everyone is ready to start, click the “PLAY VIDEO” button and
              the lesson will begin.
            </p>
            {Images.blog.first3}

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              Throughout the lesson, the video will pause for a series of
              multiple choice questions based around the content of the video.
              In this example, the subject of the lesson is “English”, so the
              questions are intended to check the user’s grasp of the English
              language:
            </p>
            {Images.blog.first4}

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              Once you think you know the answer, click the option of your
              choice then click “SUBMIT ANSWER”.
            </p>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              If you answered correctly, you will be awarded a certain number of
              points depending on how quickly you answered the question within
              the time limit. If you guess wrong the first time, you can still
              earn one point if your second guess is correct.
            </p>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              If you are playing with a group, you will also see a Leaderboard
              with everyone’s total scores at the end of every question:
            </p>
            {Images.blog.first5}

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              Once the lesson has played through, you will get to see your final
              score, along with a summary of your performance on each question:
            </p>
            {Images.blog.first6}
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              The host of the lesson will also be able to click “Download
              Results” for a spreadsheet summary showing the performance of each
              player in the group.
            </p>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              So, you have now played through your first FilmDoo Academy lesson.
              If you have any further questions or feedback about your
              experience so far then feel free to message us at:{" "}
              <a href="mailto:info@filmdoo.academy" className="underline">
                info@filmdoo.academy
              </a>
            </p>

            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full pr-6">
              FilmDoo Academy’s catalogue of film- and video-based lessons is
              always expanding as teachers and educators around the world
              continue to create new, interactive learning content. However, if
              you’d rather create your own learning resources and games using
              the FilmDoo Academy course authoring tool, then{" "}
              <span
                className="underline cursor-pointer"
                onClick={() => setMasterShowCreateAccount(true)}
              >
                sign up for a free trial of our Teacher Pro plan today!
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FirstPlayThrough;
