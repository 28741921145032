import React, { useState, useEffect } from "react";
import Wizard from "../components/wizard";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import MiniMenu from "../../components/common/miniMenu";
import window from "global/window";

function Authoring(props) {
  const [user, setUser] = useState({}),
    [lessonId, setLessonId] = useState(props.match.params.lessonId),
    [steps, setSteps] = useState([
      {
        title: "Select Video",
        description: "to use in your course",
        state: "current",
      },
      {
        title: "Cut/Crop Video",
        description: "you don't have to use it all",
        state: "upcoming",
      },
      {
        title: "Add Interactions",
        description: "To Show During Video",
        state: "upcoming",
      },
      {
        title: "Add Title",
        description: "description and save",
        state: "upcoming",
      },
    ]),
    [current, setCurrent] = useState(0),
    [url, setUrl] = useState(""),
    [thumbnail, setThumbnail] = useState(""),
    [startTime, setStartTime] = useState(0),
    [endTime, setEndTime] = useState(0),
    [interactions, setInteractions] = useState([]),
    [info, setInfo] = useState({ tags: [] }),
    save = (exitAfter) => {
      if (!user.id) return;

      let // double check all the interactions have questions, answers and correct answers
        // correct answer might be a number or a string and might be in array or on it's own
        activities = interactions.filter(
          (interaction) =>
            interaction.data.question &&
            interaction.data.answers[0] &&
            (Number.isInteger(parseInt(interaction.data.correct)) ||
              Number.isInteger(parseInt(interaction.data.correct[0])))
        ),
        course = {
          startTime: startTime.toFixed(2),
          endTime: endTime.toFixed(2),
          author: user.id,
          host: url,
          title: info.title,
          description: info.description,
          replace: lessonId,
          thumbnail,
          activities,
          info: {
            language: info.language,
            languageLevel: info.languageLevel,
            tags: info.tags,
            subtitles: info.subtitles,
          },
        },
        apiUrl = lessonId ? "/api/v1/lesson/replace" : "/api/v1/lesson/new";

      fetch(apiUrl, {
        body: JSON.stringify(course),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
      })
        .then((res) => res.json())
        .then((lesson) => {
          if (!lessonId && lesson.id) {
            setLessonId(parseInt(lesson.id));
            window.history.replaceState(
              {},
              null,
              window.location.href.replace(/\/$/, "") + "/" + lesson.id
            );
          }
          if (exitAfter) {
            window.location.href = "/content?author=" + user.username;
          }
        });
    },
    handleStepClick = (position, allowProgress) => {
      save();
      if (steps[position].state === "upcoming" && !allowProgress) return;
      setCurrent(position);
      setSteps((prev) =>
        prev.map((step, key) => {
          if (key === position) step.state = "current";
          else if (step.state === "current") step.state = "completed";
          return step;
        })
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    exit = (event) => {
      event.preventDefault();
      save(true);
    },
    stepElement = [
      <Step1 {...{ handleStepClick, url, setUrl, thumbnail, setThumbnail }} />,
      <Step2
        {...{
          handleStepClick,
          url,
          endTime,
          setEndTime,
          startTime,
          setStartTime,
        }}
      />,
      <Step3
        {...{
          handleStepClick,
          url,
          startTime,
          endTime,
          interactions,
          setInteractions,
        }}
      />,
      <Step4
        {...{
          handleStepClick,
          exit,
          url,
          startTime,
          endTime,
          interactions,
          info,
          setInfo,
        }}
      />,
    ][current];

  useEffect(() => {
    if (lessonId) {
      fetch("/api/v1/lesson/getById/" + lessonId)
        .then((res) => res.json())
        .then((data) => {
          if (!data.host) {
            window.location.href = "/authoring";
          }

          const activities = data.activities,
            info = data.info;

          setUrl(
            data.host.includes("/")
              ? data.host
              : data.host === "youtube"
              ? "https://www.youtube.com/watch?v=" + data.code
              : "https://vimeo.com/" + data.code
          );
          setStartTime(parseFloat(data.starttime));
          setEndTime(parseFloat(data.endtime));

          if (data.thumbnail) {
            setThumbnail(data.thumbnail);
          }

          setInfo({
            title: data.title,
            description: data.description,
            subtitles: info ? info.subtitles : "",
            language: info ? info.language : "",
            languageLevel: info ? info.languageLevel : "",
            tags: info ? info.tags : [],
          });

          setInteractions(
            activities
              .map((activity, index) => {
                activity.key = index;
                activity.time = parseFloat(activity.time);

                // TODO need to remove both of these when we add more interactions
                // ensure that any correct answer numbers are in an array
                if (typeof activity.data.correct === "number")
                  activity.data.correct = [activity.data.correct];

                // ensure there is at lest one answer
                if (!activity.data.answers) activity.data.answers = [""];

                return activity;
              })
              .sort((a, b) => (a.time > b.time ? 1 : -1))
          );

          // allow them to skip through the wizard
          setSteps((prev) =>
            prev.map((step, index) => {
              step.state = index === 0 ? "current" : "completed";
              return step;
            })
          );
        });
    }
  }, [lessonId]);

  useEffect(() => {
    setUser(JSON.parse(window.localStorage.getItem("fda_user")));
  }, []);

  return (
    <>
      <MiniMenu />
      <Wizard {...{ steps, handleStepClick }} />
      <div
        id="authoring"
        className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-3xl mx-auto">{stepElement}</div>
      </div>
    </>
  );
}

export default Authoring;
