import React from "react";

const Emojis = {
  ghost: {
    happy: <img style={{ width:'84%', margin:'0 8%' }} src={require('./ghost/happy.svg')} alt="Happy Ghost" />,
    sad: <img style={{ width:'84%', margin:'0 8%' }} src={require('./ghost/sad.svg')} alt="Sad Ghost" />,
    angry: <img style={{ width:'84%', margin:'0 8%' }} src={require('./ghost/angry.svg')} alt="Angry Ghost" />,
    love: <img style={{ width:'84%', margin:'0 8%' }} src={require('./ghost/love.svg')} alt="Love Ghost" />,
    wow: <img style={{ width:'84%', margin:'0 8%' }} src={require('./ghost/wow.svg')} alt="Wow Ghost" />,
  },
  hero: {
    happy: <img style={{ width:'90%', margin:'0 5%' }} width="100%" src={require('./hero/happy.svg')} alt="Happy Hero" />,
    sad: <img style={{ width:'90%', margin:'0 5%' }} src={require('./hero/sad.svg')} alt="Sad Hero" />,
    angry: <img style={{ width:'90%', margin:'0 5%' }} src={require('./hero/angry.svg')} alt="Angry Hero" />,
    love: <img style={{ width:'90%', margin:'0 5%' }} src={require('./hero/love.svg')} alt="Love Hero" />,
    wow: <img style={{ width:'90%', margin:'0 5%' }} src={require('./hero/wow.svg')} alt="Wow Hero" />,
  },
  robot: {
    happy: <img style={{ width:'90%', margin:'0 5%' }} width="100%" src={require('./robot/happy.svg')} alt="Happy Robot" />,
    sad: <img style={{ width:'90%', margin:'0 5%' }} src={require('./robot/sad.svg')} alt="Sad Robot" />,
    angry: <img style={{ width:'90%', margin:'0 5%' }} src={require('./robot/angry.svg')} alt="Angry Robot" />,
    love: <img style={{ width:'90%', margin:'0 5%' }} src={require('./robot/love.svg')} alt="Love Robot" />,
    wow: <img style={{ width:'90%', margin:'0 5%' }} src={require('./robot/wow.svg')} alt="Wow Robot" />,
  },
};

export default Emojis;
