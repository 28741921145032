import {useEffect, useState} from 'react';

export default ( socket ) => {
  const
    [playing, setPlaying] = useState(false),
    [muted, setMuted] = useState(true),
    [currentTime, setCurrentTime] = useState(0),
    [lesson, setLesson] = useState({
      videoTitle:'',
      videoUrl:'',
      activities: [],
      starttime: 0,
      endtime: 0,
      subtitles: '',
      lastSavedPlayingState:false,
      duration:0,
      lastUpdated:0
    }),
    end = () => socket.emit('end lesson', {}),
    set = (attribute, newState) => {
      setLesson(prev => {
        // TODO ensure only prefect can update these.
        // or maybe in future allow any student to change the time
        prev.lastSavedCurrentTime = currentTime;
        prev.lastSavedPlayingState = playing;
        return { ...prev, [attribute]:newState }
      });

      if(socket)
        socket.emit('update lesson', ({ ...lesson, [attribute]:newState, attribute }));
    };

  useEffect(() => {
    if(socket) {
      socket.on('lesson changes', updatedLesson => {
        if (updatedLesson.lastUpdated > lesson.lastUpdated )
          setLesson(prev => ({ ...prev, ...updatedLesson}) );
      });


    }
  }, [socket]);

  return { ...lesson, set, end, playing, setPlaying, currentTime, setCurrentTime, muted, setMuted };
};
