import React, { useState, useEffect } from 'react';

function Stats() {

    const [users, setUsers] = useState([]);
    useEffect(()=>{
        fetch('/api/v1/stats/lessons')
            .then(response => response.json() )
            .then(response => {
                const groups = response.reduce((arr, r) => ({
                    ...arr,
                    [r.email]: [...(arr[r.email] || []), r]
                }), {});

                setUsers(Object.keys(groups).map(email => {

                    const played = groups[email].length;

                    return {
                        email,
                        played,
                        students: Math.ceil(groups[email].reduce((a, b) => +a + +b.student_count, 0) / played)
                    }
                }));



            });
    },[])

    return(
        <div className="my-12 mx-auto max-w-4xl">
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Email
                                    </th>
                                    <th scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        num of games
                                    </th>
                                    <th scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Avrg ppl per game
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {users.map( (user, key) =>

                                    <tr className={`${key%2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {user.email}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {user.played}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {user.students}
                                        </td>
                                    </tr>

                                )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stats;