import React, { useEffect, useState } from "react";
import cheerio from "cheerio";
import request from "request";
import Menu from "../../components/menu";
import Footer from "../../components/footer";

function Blog(props) {
  const { slug } = props.match.params;
  const [__html, setHtml] = useState();
  const [masterShowCreateAccount, setMasterShowCreateAccount] = useState(false);

  const handleClick = () => {
    setMasterShowCreateAccount(true);
  };

  useEffect(() => {
    [...document.getElementsByClassName("signup-button")].forEach((el) => {
      el.addEventListener("click", handleClick);
    });
  }, [__html]);

  useEffect(() => {
    return () => {
      [...document.getElementsByClassName("signup-button")].forEach((el) => {
        el.removeEventListener("click", handleClick);
      });
    };
  }, []);

  useEffect(() => {
    const url = {
      "guide-to-finding-film-and-video-resources":
        "https://docs.google.com/document/u/1/d/e/2PACX-1vQWrtK_fXxp7tLIQ1ur99F-9z7mDuBKgPYtV--PwCvaTQWAgc-SqC41koVXzrYIIg/pub",
      "tips-for-creating-the-perfect-primary-school-lesson":
        "https://docs.google.com/document/d/e/2PACX-1vSW-hFf_CvkQjLjk-4CKYbHpGD0t9cDIsU-gWbOCgK1lOynt68vbvvdpphnEio0ZA/pub",
      "reasons-to-use-film-with-sen-students":
        "https://docs.google.com/document/d/e/2PACX-1vSzmu7tQkLO5nwNRizzSYDNBna6rBPqW5hOQOwHiid67yj_NP9vQ9qgM40Hp5BWtw/pub",
      "reasons-to-use-film-for-language-lessons":
        "https://docs.google.com/document/d/e/2PACX-1vRdQ_yUdfUXyTm_Se1whIh8lYD9iju2MPNOmOqkf_hQm_r8mpjy_MHoYbUP1CBFPw/pub",
      "reasons-to-use-film-for-online-lessons":
        "https://docs.google.com/document/d/e/2PACX-1vTdUCkX3fREmo4ItLJU1bjUqWPyJVnrgXMQba4YH1uy_uigHRgiMxLDAjLNN8twsg/pub",
      "playing-your-first-interactive-lesson":
        "https://docs.google.com/document/d/e/2PACX-1vRD7Xh_K1W8Fr_V4jBtQRxeY6dAG9LiOnv-t0d_xJjv_btU7Dp8RHmJYCYJ0c-c7A/pub",
      "guide-to-hosting-your-first-interactive-group-lesson":
        "https://docs.google.com/document/d/e/2PACX-1vQV9yWw8URtWPEvogfWHCveHYAQrNm4yNouv7uejx6oljqzMzUcRPDQEsU_1w41Pw/pub",
      "personalise-pre-built-lessons":
        "https://docs.google.com/document/d/e/2PACX-1vSIKkmdWWjAb-i6mLxyTzTtYPJ9N_amQfhMPjrcDlEJS1fvglCDjtowDks6YParNA/pub",
      "creating-your-first-interactive-lesson":
        "https://docs.google.com/document/d/e/2PACX-1vR6UJGm_2-HTp-mgK-XYb0BybDsPV4kO-MfAw0P4lhcjIvB9FpgoV_n-n9LLv6lxQ/pub",
      "free-access-for-your-whole-school":
        "https://docs.google.com/document/d/e/2PACX-1vQbIjnlCfEcqOjBkHbmgBjC6y0M3hk5__6D1jiJxr4uz1z3J93CmvIc5UPHO5ngzXNUVDQUTCw0Jq1U/pub",
      "win-free-access-for-your-whole-school":
        "https://docs.google.com/document/d/e/2PACX-1vQfbgfHXnzs44OpMy6nTa1n0NatG_f9F42X40hItFcSPgPVo3dYQPutd8UCs1xSJKugCALuSsijwh7V/pub",
      "edtech-teachers-you-should-subscribe-too":
        "https://docs.google.com/document/d/e/2PACX-1vROgb9sKbJoUIpdQ4s4bFZhBIR3r22Tvfn4pAmpfEQIFl500n6a8MmfY3MUXiN-ZIFSZWCZZZD7vRIy/pub",
    }[slug.toLowerCase()];

    request(
      {
        method: "GET",
        url,
      },
      (err, res, body) => {
        if (err) return console.error(err);

        let $ = cheerio.load(body);
        $(
          "#footer, #header, style, #title-banner, #banners, #interval, #title"
        ).remove();

        $("h1").addClass(
          "font-serif text-3xl lg:text-4xl font-semibold text-navy-700 leading-tight"
        );
        $("h2").addClass(
          "text-xl lg:text-2xl font-bold text-navy-700 leading-tight mt-6"
        );
        $("h3").addClass(
          "text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full font-bold -mb-3"
        );
        $("p, ul, ol").addClass(
          "text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full"
        );
        $("p, span").each(function () {
          if ($(this).text().trim() === "" && !$(this).find("img")) {
            $(this).remove();
          }
          if ($(this).text().trim() === "<sign-up-button>") {
            $(this).replaceWith(`
                <div class="w-full text-center">
                    <button class="signup-button my-2 transition duration-500 ease-in-out hover:bg-pink-700 transform focus:outline-none inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md fd-shadow-sm text-base font-medium transition-all text-white bg-pink-600 hover:bg-pink-700">
                        Sign Up Today
                    </button>
                </div>
            `);
          }

          if ($(this).text().trim() === "<video-ignite>") {
            $(this).replaceWith(`
                <div class="w-full flex justify-center py-6">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/9COc9mR1UE4" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            `);
          }
        });
        $("*").removeAttr("style");

        $("a").addClass("cursor-pointer underline");

        $("img").addClass("w-full my-6 rounded-lg min-h-12");

        const html = $.html();
        setHtml(html);
      }
    );
  }, [slug]);
  return (
    <div className="bg-gray-100">
      <Menu
        {...{
          masterShowCreateAccount,
          setMasterShowCreateAccount,
        }}
      />
      <div className="flex-grow container md:px-4 md:px-0 max-w-5xl mx-auto pt-32">
        <div className="w-full mx-auto relative bg-white rounded-lg flex flex-wrap rounded z-2 fd-shadow-lg overflow-hidden pb-4 md:mb-8">
          <div className="px-4 md:px-8 lg:px-12 w-full py-6 text-sm tracking-wide injected-blog">
            <div dangerouslySetInnerHTML={{ __html }} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
