import React, { useContext, useMemo, useEffect } from 'react';
import {motion} from "framer-motion";
import {Context} from '../context'

function IntroduceNextActivity() {

  const
    { activity, classRoom } = useContext(Context),
    activityType = activity.nextActivity.replace('-', ' '),
    variants = {
      text: {
        visible: { y:0, opacity:1 },
        hidden:{ y:-50, opacity:0 }
      },
    }

  // used in case they rejoin while a question is starting.
  useEffect(()=>{
    if(!activity.nextActivity) {
       classRoom.setOverlay(activity.type)
    }
  },[])

  return (

    <div className="flex justify-center items-center w-full h-full absolute top-0 left-0 bottom-0 right-0">
      <div className="overflow-hidden align-middle inline-block">
        <motion.p
          variants={variants.text}
          className="text-center w-full text-3xl mo-light text-white sm:text-4xl lg:text-5xl">
          {activityType} - get ready...
        </motion.p>
      </div>
    </div>

  )
}

export default IntroduceNextActivity
