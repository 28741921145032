import React, { useState } from 'react';
import { motion  } from "framer-motion"

function FullScreenIcon() {

  const
    [variants, setVariants] = useState('out'),
    wrapperElement =  document.getElementById("root"),
    corners = [
      { x: -3, y:-3, d: "M28 13V17H17C13.6863 17 11 19.6863 11 23V28H7V23C7 17.4772 11.4772 13 17 13H28Z" },
      { x: 3, y:3, d: "M36 51L36 47L47 47C50.3137 47 53 44.3137 53 41L53 36L57 36L57 41C57 46.5228 52.5228 51 47 51L36 51Z" },
      { x: 3, y:-3, d: "M36 13V17H47C50.3137 17 53 19.6863 53 23V28H57V23C57 17.4772 52.5228 13 47 13H36Z" },
      { x: -3, y:3, d: "M28 51L28 47L17 47C13.6863 47 11 44.3137 11 41L11 36L7 36L7 41C7 46.5228 11.4772 51 17 51L28 51Z" }
    ],

    isFullScreen = () => document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement,
    openFullScreen = () => {
      if (wrapperElement.requestFullscreen) wrapperElement.requestFullscreen();
      else if (wrapperElement.mozRequestFullScreen) wrapperElement.mozRequestFullScreen();
      else if (wrapperElement.webkitRequestFullscreen) wrapperElement.webkitRequestFullscreen();
      else if (wrapperElement.msRequestFullscreen) wrapperElement.msRequestFullscreen();
    },
    closeFullScreen = ()  => {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else if (document.msExitFullscreen)  document.msExitFullscreen();
    },
    fullScreenClick = () => {
      isFullScreen() ?
        closeFullScreen():
        openFullScreen();
    };

  return (
    <motion.svg
      onClick={fullScreenClick}
      onHoverStart={() => setVariants('hovering')}
      onHoverEnd={() => setVariants('out')}
      viewBox="0 0 63 63" xmlns="http://www.w3.org/2000/svg" className="fullScreenIcon">

      {corners.map( ({x,y,d}, key) =>
          <motion.path
            {...{key, d}}
            fill="#2F356D"
            variants={{
              hovering: { x, y, fill:"#111111" },
              out: { x:0, y:0, fill:"#2F356D" }
            }}
            animate={variants}
          />
        )}
    </motion.svg>

  )
}

export default FullScreenIcon;
