import React, { useState, useEffect } from "react";
import Images from "../../../assets/images";
import { AnimatePresence } from "framer-motion";
import AboutDropDown from "./aboutDropDown";
import StartedDropDown from "./startedDropDown";
import MobileMenu from "./mobileMenu";
import MenuCta from "./cta";
import {
  LoginModal,
  CreateAccountModal,
  SubscribeModal,
  ThankYouModal,
} from "../modals";
import window from "global/window";
import { Link } from "react-router-dom";

function Menu({
  masterShowCreateAccount,
  setMasterShowCreateAccount,
  masterShowLogin,
  setMasterShowLogin,
  showSubscription,
  setShowSubscription,
}) {
  const [showAboutDropDown, setShowAboutDropDown] = useState(false);
  const [showStartedDropDown, setShowStartedDropDown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const handleMouseLeave = () => {
    setShowAboutDropDown(false);
    setShowStartedDropDown(false);
  };
  const handleHoverAboutUs = () => {
    setShowAboutDropDown(true);
  };
  const handleHoverStarted = () => {
    setShowStartedDropDown(true);
  };
  const handleClickHamburger = () => {
    setShowMobileMenu(true);
  };
  const handleLoginClick = () => {
    setShowLogin(true);
  };
  const handleCreateAccountClick = () => {
    setShowCreateAccount(true);
  };
  const handleLogoutClick = () => {
    window.localStorage.removeItem("fda_user");
    setLoggedIn(false);
    window.location.reload();
  };
  const handleViewLessons = () => {
    window.location.href = "/content?author=" + loggedIn.username;
  };

  useEffect(() => {
    const cookie = window.localStorage.getItem("fda_user");
    const json = JSON.parse(cookie);
    setLoggedIn(json);
  }, []);

  // used so we can open the create account modal from on page links
  useEffect(() => {
    if (masterShowCreateAccount) setShowCreateAccount(true);
  }, [masterShowCreateAccount]);
  useEffect(() => {
    if (!showCreateAccount && setMasterShowCreateAccount)
      setMasterShowCreateAccount(false);
  }, [showCreateAccount]);

  useEffect(() => {
    if (!showLogin && setMasterShowLogin) setMasterShowLogin(false);
  }, [showLogin]);
  useEffect(() => {
    if (masterShowLogin) setShowLogin(true);
  }, [masterShowLogin]);

  return (
    <>
      <LoginModal
        show={showLogin}
        setShow={setShowLogin}
        setShowCreateAccount={setShowCreateAccount}
      />
      <CreateAccountModal
        show={showCreateAccount}
        setShow={setShowCreateAccount}
        setShowLogin={setShowLogin}
        setShowThankYou={setShowThankYou}
      />
      <SubscribeModal show={showSubscription} setShow={setShowSubscription} />
      <ThankYouModal show={showThankYou} setShow={setShowThankYou} />
      <div
        onMouseLeave={handleMouseLeave}
        className="w-full fixed top-0 left-0 right-0 z-40"
      >
        <div className="relative bg-white w-full fd-shadow bg-white z-30">
          <MenuCta />
          <div className="max-w-8xl mx-auto flex justify-between items-center px-6 py-5 sm:py-4 xl:justify-start xl:space-x-8">
            <div>
              <Link to={{ pathname: "/" }} className="flex max-h-12">
                {Images.logo}
              </Link>
            </div>
            <div
              onClick={handleClickHamburger}
              className="-mr-2 -my-2 xl:hidden"
            >
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-navy-400 hover:text-navy-500 hover:bg-navy-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <div className="hidden xl:flex-1 xl:flex xl:items-center xl:justify-between">
              <nav className="flex space-x-7">
                <Link
                  to={{ pathname: "/for_schools" }}
                  className="text-base font-medium text-navy-500 hover:text-navy-900"
                >
                  For Schools
                </Link>
                <Link
                  to={{ pathname: "/languages" }}
                  className="text-base font-medium text-navy-500 hover:text-navy-900"
                >
                  Languages & Film
                </Link>

                <Link
                  to={{ pathname: "/testimonials" }}
                  className="text-base font-medium text-navy-500 hover:text-navy-900"
                >
                  Testimonials
                </Link>

                <div className="relative">
                  <button
                    type="button"
                    onMouseEnter={handleHoverStarted}
                    className="group bg-white rounded-md text-navy-500 inline-flex items-center text-base font-medium hover:text-navy-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                  >
                    <Link
                      to={{
                        pathname: "/blog/playing-your-first-interactive-lesson",
                      }}
                    >
                      Getting Started
                    </Link>

                    <svg
                      className="ml-0.5 h-5 w-5 text-navy-400 group-hover:text-navy-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  <AnimatePresence exitBeforeEnter>
                    {showStartedDropDown && <StartedDropDown />}
                  </AnimatePresence>
                </div>

                <Link
                  to={{ pathname: "/webinars" }}
                  className="text-base font-medium text-navy-500 hover:text-navy-900"
                >
                  Webinars
                </Link>

                <div className="relative">
                  <button
                    type="button"
                    onMouseEnter={handleHoverAboutUs}
                    className="group bg-white rounded-md text-navy-500 inline-flex items-center text-base font-medium hover:text-navy-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                  >
                    <Link to={{ pathname: "/about_us" }}>About Us</Link>

                    <svg
                      className="ml-0.5 h-5 w-5 text-navy-400 group-hover:text-navy-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  <AnimatePresence exitBeforeEnter>
                    {showAboutDropDown && <AboutDropDown />}
                  </AnimatePresence>
                </div>
              </nav>
              <div className="flex items-center md:ml-12">
                <nav className="flex space-x-5">
                  {loggedIn && (
                    <button
                      onClick={handleLogoutClick}
                      className="text-base font-medium text-navy-500 hover:text-navy-900"
                    >
                      Logout
                    </button>
                  )}
                  {!loggedIn && (
                    <button
                      onClick={handleLoginClick}
                      className="text-base font-medium text-navy-500 hover:text-navy-900"
                    >
                      Login
                    </button>
                  )}
                </nav>
                {!loggedIn && (
                  <button
                    onClick={handleCreateAccountClick}
                    className="transition duration-500 ease-in-out hover:bg-pink-700 transform focus:outline-none ml-6 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium transition-all text-white bg-pink-600 hover:bg-pink-700"
                  >
                    Activate Free Account
                  </button>
                )}
                {loggedIn && (
                  <button
                    onClick={handleViewLessons}
                    className="transition duration-500 ease-in-out hover:bg-pink-700 transform focus:outline-none ml-6 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md fd-shadow-sm text-base font-medium transition-all text-white bg-pink-600 hover:bg-pink-700"
                  >
                    My Lessons
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden">
          <AnimatePresence exitBeforeEnter>
            {showMobileMenu && (
              <MobileMenu
                {...{
                  setShowMobileMenu,
                  handleCreateAccountClick,
                  handleViewLessons,
                  handleLoginClick,
                  handleLogoutClick,
                  loggedIn,
                }}
              />
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
}

export default Menu;
