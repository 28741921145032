import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import variants from "../../components/animationVariants";
import { useInView } from "react-intersection-observer";
import BgParticles from "../../components/bgParticles";
import Images from "../../../assets/images";

function Hero() {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <main className="w-full bg-gray-100 overflow-hidden pt-4 pt-10 sloppy-bottom-navy">
      <BgParticles />
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        className="relative max-w-6xl mx-auto lg:py-32 pt-24 pb-14 px-12"
      >
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <motion.div variants={variants.fromLeft} className="col-span-12">
            <p className="text-sm lg:text-md text-navy-600 uppercase tracking-wide">
              About FilmDoo Academy
            </p>
            <h1 className="mt-2 text-5xl lg:text-6xl font-semibold text-navy-600  leading-tight mb-43">
              The <span className="text-teal-600">FilmDoo</span> Story
            </h1>

            <p className="text-teal-600 font-bold mt-5 mb-2 text-lg lg:text-xl mx-auto w-full font-bold">
              DOO - (THAI) TO WATCH; LOOK; SEE
            </p>
          </motion.div>

          <motion.div
            variants={variants.fromLeft}
            className="md:max-w-2xl lg:col-span-7 text-left lg:pr-4 pt-2"
          >
            <p className="text-navy-600 text-base lg:text-lg mx-auto w-full font-bold">
              FilmDoo Academy is a multi-award winning organisation dedicated to
              transforming education around the world. We are regarded as the
              pioneers and industry leaders for film- and video-based learning.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              2015 saw the birth of{" "}
              <a
                className="underline font-bold"
                href="https://www.filmdoo.com"
                target="_blank"
              >
                FilmDoo
              </a>
              , our movie-streaming platform helping people to discover and
              watch great films from around the world. FilmDoo now has over
              280,000 registered users from 194 countries and over 3,000 films.
              We partner with major organisations across technology, film,
              language learning and edtech. Today, FilmDoo has one of the
              world’s largest international film catalogues online. With a clear
              vision to close the language and cultural gap around the world and
              to transform education using the power of films and videos, our
              exciting and innovative journey began.
            </p>
          </motion.div>
          <motion.div
            variants={variants.fromRight}
            className="relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 flex flex-col lg:col-span-5"
          >
            <div className="relative mx-auto w-full lg:max-w-lg mb-6 lg:mb-12 mt-8 lg:mt-0">
              <img
                src={Images.filmdoo.b}
                className="mt-2 w-full rounded-lg fd-shadow"
              />
            </div>
          </motion.div>

          <motion.div
            variants={variants.fromLeft2}
            className="relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 flex flex-col lg:col-span-5"
          >
            <div className="relative mx-auto w-full lg:max-w-lg mb-6 lg:mb-12 mt-8 lg:mt-0">
              <img
                src={Images.filmdoo.a}
                className="w-full rounded-lg fd-shadow mt-2 "
              />
            </div>
          </motion.div>
          <motion.div
            variants={variants.fromRight2}
            className="md:max-w-2xl lg:col-span-7 text-left lg:pr-4 pt-2"
          >
            <p className="text-navy-600 text-base mx-auto w-full">
              In 2019, FilmDoo undertook scientific research to study the impact
              which film has on teaching and learning. The outcome of this
              research confirmed that student engagement is positively
              correlated to the use of film and video - driven by the positive
              experience and improved motivations associated with learning
              through engaging, multimodal audiovisual content.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              Raising student engagement and motivation levels has been a major
              problem in remote and online learning - now more relevant than
              ever, as schools, universities and language schools have all
              needed to go online in light of the pandemic. At FilmDoo, we saw
              an opportunity to use film and video to help. So we launched
              FilmDoo Academy to help bring interactive classroom and online
              learning through any film and video.
            </p>
          </motion.div>

          <motion.div
            variants={variants.fromRight2}
            className="md:max-w-2xl lg:col-span-7 text-left lg:pr-4 pt-2"
          >
            <p className="text-navy-600 text-base mx-auto w-full">
              FilmDoo Academy, the birth-child of FilmDoo, provides teachers and
              educators with the most cost-effective, highest quality and most
              engaging educational tool on the market today. Our game-based
              edtech tool helps turn any film and video into interactive
              learning content. With our easy-to-use course authoring tool,
              teachers have the ability to create bespoke lessons and bring
              interactive learning through any film and video in a matter of
              minutes! Not only that, teachers are able to check for
              understanding of knowledge retention and “recall” by adding
              quizzes, questions and puzzles to the video.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              Using film and video, teachers around the world ignite their
              students' passion and thirst for learning, whilst engaging them in
              a format that comes naturally - in particular, for the average Gen
              Z student who now spends four hours a day watching mobile videos.
              Whilst traditional teaching methods struggle to keep students
              engaged, the modern students now respond to film- and video-based
              learning - which is at the heart of FilmDoo.
            </p>
            <p className="text-navy-600 mt-2 lg:mt-5 text-base mx-auto w-full">
              We work with the world’s largest dedicated learning institutions
              to bring engaging and impactful video tools to assist in all
              subjects, across all year groups and from the classroom to
              homework, to intervention to home and remote teaching and
              everything in between!
            </p>
          </motion.div>
          <motion.div
            variants={variants.fromLeft2}
            className="relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 flex flex-col lg:col-span-5"
          >
            <div className="lg:pl-10 relative mx-auto w-full lg:max-w-lg mb-6 lg:mb-12 mt-8 lg:mt-0">
              <img
                src={Images.filmdoo.c}
                className="mt-2 w-full rounded-lg fd-shadow"
              />
              <img
                src={Images.filmdoo.d}
                className="mt-6 w-full rounded-lg fd-shadow"
              />
            </div>
          </motion.div>

          <motion.div
            variants={variants.fromBottom2}
            className="md:mx-auto col-span-12"
          >
            <p className="mt-5 mb-8 lg:mt-8 text-xl lg:text-2xl w-full text-center mx-auto w-full uppercase font-extrabold text-pink-600">
              Join the FilmDoo Revolution!
            </p>
          </motion.div>
        </div>
      </motion.div>
    </main>
  );
}

export default Hero;
