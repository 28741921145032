import React, { useState, useEffect } from "react";
import Footer from "../../components/footer";
import Menu from "../../components/menu";
import CheckBox from "../../../webapp/components/icons/checkbox";
import Images from "../../../assets/images";
import { motion, AnimatePresence } from "framer-motion";
import window from "global/window";
import sessionStorage from "sessionstorage";
import Hashids from "hashids/cjs";
const hashids = new Hashids();

function Sample(props) {
  const [listName, setListName] = useState(props.match.params.listName),
    [lessons, setLessons] = useState([]),
    [timestamp, setTimestamp] = useState(0),
    samples = {
      arabic: { title: "Arabic", colour: "#095e34", language: "arabic" },
      chinese: { title: "Chinese", colour: "#de2910", language: "chinese" },
      danish: { title: "Danish", colour: "#de2910", language: "danish" },
      english: { title: "English", colour: "#22B0B0", language: "english" },
      "business-english": {
        title: "Business English",
        colour: "#22B0B0",
        tags: "businessEnglish",
      },
      korean: { title: "Korean", colour: "#d02934", language: "korean" },
      portuguese: {
        title: "Portuguese",
        colour: "#006600",
        language: "portuguese",
      },
      french: { title: "French", colour: "#042992", language: "french" },
      german: { title: "German", colour: "#db0a16", language: "german" },
      dutch: { title: "Dutch", colour: "#22B0B0", language: "dutch" },
      spanish: { title: "Spanish", colour: "#c41126", language: "spanish" },
      luxembourgish: {
        title: "Luxembourgish",
        colour: "#0097d5",
        language: "luxembourgish",
      },
      virus: { title: "COVID 19", colour: "#DF681E", tags: "covid" },
      history: { title: "History", colour: "#ba3a35", tags: "history" },
      media_studies: {
        title: "Media Studies",
        colour: "#111111",
        tags: "mediaStudies",
      },
      diversity: {
        title: "Diversity & Inclusion",
        colour: "#BF00FF",
        tags: "diversity",
      },
      maths: { title: "Maths", colour: "#158c9d", tags: "maths" },
      english_literature: {
        title: "English Literature",
        colour: "#86492b",
        tags: "englishLiterature",
      },
      geography: { title: "Geography", colour: "#279de2", tags: "geography" },
      food_technology: {
        title: "Food Technology",
        colour: "#35b16a",
        tags: "foodTechnology",
      },
      computer_science: {
        title: "Computer Science",
        colour: "#00194a",
        tags: "computerScience",
      },
    },
    handleLoadLessonClick = (lessonId) => {
      // ensure they don't load a previous game
      sessionStorage.removeItem("classRoomName");
      sessionStorage.removeItem("name");
      console.log(hashids.encode(lessonId));
      window.open(`/party/${hashids.encode(lessonId)}`, "_blank").focus();
    };

  useEffect(() => {
    if (!samples[listName]) return;

    let fetchData = {
      user: 78,
      timestamp: +new Date(),
    };

    if (samples[listName].language)
      fetchData.language = samples[listName].language;
    if (samples[listName].tags) fetchData.language = samples[listName].tags;

    fetch("/api/v1/lesson/getAll", {
      body: JSON.stringify(fetchData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        // sending and checking timestamp to remove race conditions
        if (parseInt(data.timestamp) > timestamp) {
          setLessons(data.lessons);
          setTimestamp(parseInt(timestamp));
        }
      });

    window.history.replaceState("", "", "/sample/" + listName);
  }, [listName]);

  return (
    <>
      <Menu />
      <div className="flex flex-row flex-1 bg-gray-100 h-full items-stretch">
        <div className="flex-1 max-w-xs bg-navy-600  fd-shadow-xl">
          <div className="pt-5 pb-6 h-full pt-26">
            <div className="flex-grow mt-5 flex flex-col mt-20">
              <nav
                style={{ minWidth: "65px" }}
                className="flex-1 px-2 space-y-1 text-navy-50"
              >
                <h3 className="hidden lg:block px-3 text-xs pt-6 font-semibold text-teal-400 uppercase tracking-wider">
                  Languages
                </h3>

                {Object.keys(samples).map((sample) => (
                  <>
                    <button
                      onClick={() => setListName(sample)}
                      style={
                        listName === sample
                          ? {
                              color: "white",
                              backgroundColor: samples[sample].colour + 99,
                            }
                          : {}
                      }
                      className="group rounded-md py-2 px-3 flex items-center text-sm font-medium w-full focus:outline-none"
                    >
                      <img
                        className="h-6 w-6 mr-2 rounded-sm fd-shadow-lg"
                        src={Images.flags[sample]}
                        style={{
                          filter: listName === sample ? "" : "grayscale(85%)",
                        }}
                        alt={samples[sample].title}
                      />

                      <span className="hidden lg:block text-left">
                        {samples[sample].title}
                      </span>
                    </button>

                    {sample === "luxembourgish" && (
                      <h3 className="hidden lg:block px-3 pt-6 text-xs font-semibold text-teal-400 uppercase tracking-wider">
                        Other Subjects
                      </h3>
                    )}
                  </>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <div className="max-w-4xl w-full mx-auto px-4 sm:px-6 lg:px-8 mt-6 pt-24">
          {samples[listName] && (
            <div className="w-full mx-auto relative bg-white flex flex-wrap rounded-lg z-2 fd-shadow-lg overflow-hidden mb-8">
              <div
                className="w-full md:w-1/3"
                style={{ backgroundColor: samples[listName].colour + 99 }}
              >
                <img
                  src={Images.flags[listName]}
                  alt={samples[listName].title}
                  className="mx-auto"
                  style={{ height: "90px" }}
                />
              </div>
              <div className="w-full md:w-1/4 flex flex-col flex-grow flex-shrink">
                <h2
                  className="pf-medium fd-grey w-full font-bold text-5xl text-gray-900 ml-6 pt-2"
                  style={{ color: samples[listName].colour }}
                >
                  {samples[listName].title}
                </h2>
                <p
                  className="break-normal font-normal text-gray-600 text-base md:text-m ml-6 mo-light mb-2"
                  style={{ color: samples[listName].colour + 99 }}
                >
                  Try these sample video courses...
                </p>
              </div>
            </div>
          )}

          <AnimatePresence exitBeforeEnter>
            {lessons.map((lesson) => (
              <motion.div
                key={lesson._id}
                initial={{ opacity: 0, y: 100, scale: 0.9 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{
                  opacity: 0,
                  y: -20,
                  scale: 1.05,
                  transition: { duration: 0.1 },
                }}
                className="w-full mx-auto relative bg-white flex flex-wrap rounded-lg z-2 fd-shadow-lg overflow-hidden mb-8"
              >
                <div className="w-full md:w-1/3 rounded-t">
                  <img
                    src={lesson.thumbnail}
                    className="h-full w-full shadow"
                    alt={lesson.name}
                  />
                </div>

                <div className="w-full md:w-1/3 flex flex-col flex-grow flex-shrink">
                  <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden fd-shadow-lg">
                    <div className="inline-block pt-5 px-3 float-right">
                      {lesson.difficulty && (
                        <span className="cursor-pointer rounded py-1 px-3 bg-gray-300 text-white mo-medium text-sm ml-2">
                          {lesson.difficulty}
                        </span>
                      )}
                    </div>
                    <h3 className="pf-medium fd-grey w-full font-bold text-2xl text-navy-700 pt-4 px-6">
                      {lesson.name}
                    </h3>

                    <p className="mb-1 w-5/6 text-navy-500 text-xs md:text-sm px-6">
                      {lesson.description}
                    </p>
                  </div>

                  <div className="flex-none bg-gray-100 mt-auto bg-white rounded-b rounded-t-none overflow-hidden fd-shadow-lg p-3">
                    <div className="flex items-center justify-between">
                      <div className="flex pl-4">
                        <svg
                          className="h-5 w-5 text-navy-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <p className="text-navy-600 mo-light text-xs md:text-sm flex-1 ml-1">
                          {Math.round(
                            (lesson.lesson.endTime - lesson.lesson.startTime) /
                              60
                          )}{" "}
                          MINUTES
                        </p>
                      </div>
                      <div className="flex pl-4">
                        <div className="relative h-5 w-5 text-navy-600">
                          <CheckBox checked={true} small={true} />
                        </div>
                        <p className="text-navy-600 mo-light text-xs md:text-sm flex-1 ml-1">
                          {lesson.lesson.activities.length} QUESTIONS
                        </p>
                      </div>

                      <div className="flex pl-4" />

                      <button
                        onClick={() => handleLoadLessonClick(lesson._id)}
                        className="shadow-sm h-full inline-block px-8 py-2 bg-teal-600 flex text-white text-sm uppercase rounded mo-medium align-middle font-semibold fd-transition focus:outline-none"
                      >
                        <p className="flex-1 mr-3">Play Now</p>
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Sample;
