import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

function VolumeIcon({ setVolume, volume }) {
  const
    [volumeLevel, setVolumeLevel] = useState(volume),
    onMouseMove = e => { setVolumeLevel(e.nativeEvent.offsetX / 50) }

  useEffect(()=>setVolumeLevel(volume),[volume]);

  return (
    <motion.svg
      onClick={() => setVolume(volumeLevel)}
      onHoverEnd={() => setVolumeLevel(volume)}
      viewBox="0 0 63 63"
      xmlns="http://www.w3.org/2000/svg"
      className="volumeIcon"
      onMouseMove={onMouseMove}
    >
      <motion.rect
        x="0"
        y="13"
        width="4"
        height="38"
        fill="transparent"
      />

      <motion.rect
        fill={volumeLevel > 0.3 ? "#2F356D" : "#ffffff" }
        x="10"
        y="36"
        width="4"
        height="15"
      />
      <motion.rect
        fill={volumeLevel > 0.45 ? "#2F356D" : "#ffffff" }
        x="22"
        y="28"
        width="4"
        height="23"
      />
      <motion.rect
        fill={volumeLevel > 0.6 ? "#2F356D" : "#ffffff" }
        x="34"
        y="20"
        width="4"
        height="31"
      />

      <motion.rect
        fill={volumeLevel > 0.75 ? "#2F356D" : "#ffffff" }
        x="46"
        y="13"
        width="4"
        height="38"
      />
    </motion.svg>
  );
}

export default VolumeIcon;
