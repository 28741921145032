import React from 'react'
import { AnimatePresence, motion } from "framer-motion";

function Background({children, dark, hide, clickthrough }) {
  const blobVariants = {
    1:{
      viewBox:"0 0 376 463",
      hidden: { y:200 },
      light: { d: `M208 531C217.5 502.5 229.636 497.224 208 421.5C183 334 212.108 283.616 248.5 262.5C289 239 338.5 190.5 374.5 102C410.5 13.5 445.711 42.4681 464.5 128.5C483.5 215.5 497 285.5 497 361C497 438.193 517.24 510.76 450 578C374.5 653.5 341.5 657.5 298.5 646C196.386 618.691 198.801 558.598 208 531Z` },
      dark: { d: `M80 199.5C101.2 209.1 135 218.467 154 219C175.5 219.603 195.786 218.451 237.5 212.961C279 207.5 292 197 355 210.5C418 224 438.5 404.5 390 478C341.814 551.023 121 515.5 50 509.5C-26.9193 503 -15.7027 465.391 -25 308C-32 189.5 -17.566 178.461 -0.499983 178.461C17 178.461 53.5 187.5 80 199.5Z`, }
    },
    2:{
      viewBox:"0 0 376 493",
      hidden: { y: -200, x:200 },
      light: { d:"M107 314.999C138.5 288.38 159.8 289.646 185.5 288.38C211.201 287.114 218.501 285.875 263.5 291.5C308.5 297.125 331.501 311.999 391.5 296.5C451.5 281 519.231 201.5 499.027 84.6462C478.824 -32.2077 -144.278 -208.5 -101.5 141.5C-58.7219 491.5 75.5008 341.619 107 314.999Z" },
      dark: { d:"M229.5 113.5C195.5 168.5 197.901 214.472 218 274.5C238.099 334.528 278.5 369.104 315.5 384C352.5 398.896 360 436.5 385 494.5C410 552.5 507.5 208 499.027 84.646C490.554 -38.708 305.501 -281 302.5 -166.5C299.5 -52 263.5 58.5001 229.5 113.5Z" }
    },
    3:{
      viewBox:"0 0 376 225",
      hidden: { y:200 },
      light: { d: "M235 40.3873C140.387 22.4276 72.7986 31.7871 26.5 55.3372C-19.7986 78.8872 -55 122.837 -48 179.337C-40.9999 235.837 66.5736 295.238 221.5 271.337C376.426 247.436 423 247.337 401.842 192.011C380.684 136.685 329.613 58.3471 235 40.3873Z"},
      dark: { d: "M195.5 249.5C100.887 231.54 -44 303 -31.4999 116.5C-18.9999 -69.9999 -55.5 173 -48.5 229.5C-41.5 286 80.0739 345.901 235 322C389.927 298.099 436 172.5 399.5 211C363 249.5 290.113 267.46 195.5 249.5Z"}
    }
  }

  return (
    <>
      <div id="content" className={`${clickthrough ? 'clickthrough' : ''}`}>
        {children}
      </div>
      <div className={`background 
          ${dark ? 'background-dark'  : ''} 
          ${clickthrough ? 'clickthrough' : ''} 
          ${hide ? 'background-hidden' : ''}`} >
        <AnimatePresence>
          {!hide && [3, 2, 1].map(n =>
            <motion.svg
              initial={blobVariants[n].hidden}
              animate={{ x:0, y:0 }}
              transition={{ease: "easeInOut", duration:0.3 }}
              exit={blobVariants[n].hidden}
              id={`blob${n}`} key={n} preserveAspectRatio="none" viewBox={blobVariants[n].viewBox}
              xmlns="http://www.w3.org/2000/svg">
              <motion.path
                d={blobVariants[n].dark.d}
                variants={blobVariants[n]}
                animate={dark ? "dark" : "light"}
                transition={{ease: "easeInOut", duration: 0, type: "tween"}}
              />
            </motion.svg>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}

export default Background
