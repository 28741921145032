import React, { useEffect } from "react";
import Images from "../../../assets/images";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import variants from "../../components/animationVariants";
import { Link } from "react-router-dom";

function HomeCtas({ openCreateAccountModal }) {
  const controls = useAnimation(),
    [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      className="bg-navy-600 sloppy-bottom pb-20 bg-fixed bg-cover overflow-hidden"
      style={{
        backgroundImage: Images.sofaFriends,
      }}
    >
      <motion.div
        variants={variants.fromBottom}
        className="pt-16 mx-auto max-w-6xl px-12 sm:pt-24"
      >
        <div className="text-center">
          <h3 className="text-teal-200 text-4xl md:text-5xl">
            <span className="block mb-2 text-sm sm:text-md md:text-xl">
              Re-Ignite Your Students'
            </span>
            <h2 className="block text-white font-extrabold tracking-tight ">
              Thirst <i>for</i> Learning
            </h2>
          </h3>
        </div>
      </motion.div>

      <div className="lg:flex lg:justify-center text-center lg:text-left max-w-7xl mx-auto">
        <motion.div
          variants={variants.fromLeft}
          className="w-full lg:w-2/5 max-w-7xl py-8 px-4 sm:px-6 lg:px-8 lg:py-20 md:inline-block"
        >
          <h2 className="text-2xl md:font-extrabold tracking-tight text-white sm:text-3xl">
            <span className="block">For Schools & Academies</span>
          </h2>
          <p className="text-base text-navy-200 mt-1 md:mt-2  sm:text-md lg:text-lg">
            Learn how FilmDoo Academy supports schools and academies
          </p>

          <div className="py-3 text-sm">
            <Link
              to={{ pathname: "/for_schools" }}
              className="transition duration-500 ease-in-out hover:text-teal-600 hover:fd-shadow transform text-teal-300 font-semibold"
            >
              Read More <span aria-hidden="true">→</span>
            </Link>
          </div>

          <div className="mt-2 flex justify-center flex-col lg:justify-start text-center">
            <div className="inline-flex justify-center lg:justify-start">
              <button
                onClick={openCreateAccountModal}
                className="transition duration-500 ease-in-out hover:bg-teal-400 hover:fd-shadow-black focus:outline-none inline-flex items-center justify-center px-5 py-3 fd-shadow-xl text-sm font-medium rounded-md text-navy-600 bg-teal-600"
              >
                Activate Free Account
              </button>
            </div>
            <div className="justify-center lg:justify-start inline-flex mt-4">
              <Link
                to={{ pathname: "/webinars" }}
                className="transition duration-500 ease-in-out hover:bg-navy-700 hover:text-white hover:fd-shadow-black focus:outline-none inline-flex items-center justify-center px-5 py-3 border border-transparent text-sm font-medium rounded-md text-navy-50 border-2 border border-white"
              >
                Join Our Webinars & Online Events
              </Link>
            </div>
          </div>
        </motion.div>

        <motion.div
          variants={variants.fromRight}
          className="w-full lg:w-2/5 max-w-7xl py-8 px-4 sm:px-6 lg:px-8 lg:py-20 md:inline-block"
        >
          <h2 className="text-2xl md:font-extrabold tracking-tight text-white sm:text-3xl">
            <span className="block">
              For Whole School, Heads of Department and Individual Teachers
            </span>
          </h2>
          <p className="text-base text-navy-200 mt-1 md:mt-2 sm:text-md lg:text-lg">
            Learn More About FilmDoo by Joining in Now
          </p>

          <div className="py-3 text-sm">
            <Link
              to={{ pathname: "/for_teachers" }}
              className="transition duration-500 ease-in-out hover:text-teal-600 hover:fd-shadow text-teal-300 font-semibold"
            >
              Read More <span aria-hidden="true">→</span>
            </Link>
          </div>

          <div className="mt-2 flex justify-center flex-col lg:justify-start text-center">
            <div className="inline-flex justify-center lg:justify-start">
              <button
                onClick={openCreateAccountModal}
                className="transition duration-500 ease-in-out hover:bg-teal-400 hover:fd-shadow-black focus:outline-none inline-flex items-center justify-center px-5 py-3 fd-shadow-xl text-sm font-medium rounded-md text-navy-600 bg-teal-600"
              >
                Activate Free Account
              </button>
            </div>
            <div className="justify-center inline-flex mt-4 lg:justify-start">
              <Link
                to={{ pathname: "/sample_lessons" }}
                className="transition duration-500 ease-in-out hover:bg-navy-700 hover:text-white hover:fd-shadow-black focus:outline-none inline-flex items-center justify-center px-5 py-3 border border-transparent text-sm font-medium rounded-md text-navy-50 border-2 border border-white"
              >
                See Sample Lessons
              </Link>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default HomeCtas;
