import React, {useState} from "react";

function AnswerDetails({answer, index, correct, forceRefresh, handleClickAnswerDelete, setData, data }) {

    const
        answerLength = 80,
        answerTip = !answer || answer.length === 0 ? `Max ${answerLength} characters` : answerLength-answer.length + ' characters left',
        handleAnswerChange = event => {
            let newAnswer = event.target.value;
            if(newAnswer.length <= answerLength) {
                setData({
                    answers: data.answers
                        .map( (answer, i) =>
                            index===i ?
                                newAnswer :
                                answer
                        )
                })
            }
            forceRefresh();
        },
        handleCorrectClick = () => {
            setData({
                correct: correct ?
                    data.correct.filter(num => num !== index) :
                    [...data.correct, index]
            })
            forceRefresh();
        }

    return (

        <div className="flex -mr-7">
            <div
                className="text-navy-600 cursor-pointer w-4  h-10 relative mt-1 mr-2">
                <input id="remember_me" name="remember_me" type="checkbox"
                       className="inset-shadow mt-2 h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
                       onChange={handleCorrectClick}
                       checked={correct} />
            </div>
            <div className="flex-grow">

                <div key={index}>
                    <div className="mt-1">
                        <input
                            onChange={handleAnswerChange}
                            type="text"
                            placeholder="type the answer here..."
                            className="mt-1 block w-full inset-shadow  rounded-md py-2 p-3 focus:outline-none focus:ring-teal-500 focus:border-indigo-500 sm:text-sm"
                            value={answer} />

                    </div>
                    <div className="mt-1 text-sm text-navy-600 text-right italic">
                        {answerTip}
                    </div>
                </div>

            </div>
            <div
                onClick={()=>handleClickAnswerDelete(index)}
                className="h-full py-3 cursor-pointer pl-2">
                <svg className="h-5 w-5 text-coral-600"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                     aria-hidden="true">
                    <path fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clipRule="evenodd"/>
                </svg>
            </div>
        </div>
    )
}

export default AnswerDetails;